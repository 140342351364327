<form>
  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'catalog.racks.search.label' | translate }}
    </mat-label>
    <input matInput type="text" [formControl]="pnSearch" [matAutocomplete]="pnOptions" (blur)="getRackRails()" />
    <img matSuffix src="../../../assets/icons/searchIcon.svg" alt="search_icon" class="imgIcon" />
    <mat-autocomplete #pnOptions="matAutocomplete" autoActiveFirstOption>
      <mat-option *ngFor="let part of filteredPNs | async" [value]="part.number + ' - ' + part.description">
        {{ part.number }} - {{ part.description }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint>
      {{ 'catalog.racks.search.selectPN' | translate }}
    </mat-hint>
  </mat-form-field>
  <button mat-flat-button color="primary" class="reload-button" (click)="getRackRails()">
    <img src="../../../../assets/icons/refreshIcon.svg" color="white" width="24px" height="24px" alt="refresh" />
  </button>
</form>

<br />

<div class="loading-container">
  <!-- Loading indicator -->
  <loading-on-page [isLoading]="loading"></loading-on-page>

  <table mat-table [dataSource]="railsInformation">
    <caption hidden>
      Table for search stored boxes on rack rails from PN
    </caption>
    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef>{{ 'catalog.racks.search.table.region' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.region }}</td>
    </ng-container>

    <ng-container matColumnDef="railId">
      <th mat-header-cell *matHeaderCellDef>{{ 'catalog.racks.search.table.railId' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <ng-container matColumnDef="pn">
      <th mat-header-cell *matHeaderCellDef>{{ 'catalog.racks.search.table.pn' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{ getNumberOfBoxesWithPN(row) > 0 ? pnSearch.value.split(' - ')[0] : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="boxesQty">
      <th mat-header-cell *matHeaderCellDef>{{ 'catalog.racks.search.table.boxQty' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ getNumberOfBoxesWithPN(row) }}</td>
    </ng-container>

    <ng-container matColumnDef="partQty">
      <th mat-header-cell *matHeaderCellDef>{{ 'catalog.racks.search.table.partQty' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ getNumberOfPartWithPN(row) }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <!-- Row shown when there is no matching data. -->
      <td class="mat-cell" [colSpan]="displayedColumns.length" *ngIf="pnSearch.value !== ''">
        {{ 'catalog.racks.search.emptyData' | translate }}
      </td>

      <!-- Row shown when there isn't any part number selected -->
      <td class="mat-cell" [colSpan]="displayedColumns.length" *ngIf="pnSearch.value === ''">
        {{ 'catalog.racks.search.needToSelect' | translate }}
      </td>
    </tr>
  </table>
</div>
