import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LogService } from '@services/api/log.service';
import { CanAccessHome } from '../route/guards/can-access-home';
import { CanAccessRestrictedPages } from '../route/guards/can-access-restricted-pages';
import { CanActivateLogin } from '../route/guards/can-activate-login';
import { CheckAuthenticationTokenInterceptor } from '../interceptor/check-authentication-token.interceptor';
import { LocalDataService } from '../services/local-data/local.data.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true
    }),
    HttpClientModule
  ],
  exports: [
    TranslateModule,
  ],
  providers: [
    CanAccessRestrictedPages,
    CanActivateLogin,
    CanAccessHome,
    LocalDataService,
    LogService,
    CheckAuthenticationTokenInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: CheckAuthenticationTokenInterceptor, multi: true, },
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('You should import core module only in the root module.');
    }
  }

}
