import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InstructionApiService } from '@services/api';
import { DateUtilsService } from '@services/date/date-utils.service';
import { DialogService } from '@services/dialog/dialog.service';
import { AppToastService } from '@services/index';
import { InstructionManagementDialogService } from '@services/instructions/instruction-management-dialog.service';
import { InstructionUtilsService } from '@services/instructions/instruction-service-utils.service';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { MetadataService } from '@services/metadata/metadata.service';
import { Instruction, InstructionType } from 'src/app/models';

@Component({
  selector: 'instruction-card',
  templateUrl: './instruction-card.component.html',
  styleUrls: ['./instruction-card.component.scss'],
})
export class InstructionCardComponent implements OnInit {
  @Input() instruction!: Instruction;
  @Input() showActions: boolean = true;
  @Input() showTimeIndicator: boolean = true;
  @Output() instructionChanged = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private instructionApi: InstructionApiService,
    private dialogService: DialogService,
    private toastService: AppToastService,
    private instructionsDialog: InstructionManagementDialogService,
    private metadataService: MetadataService,
    public instructionUtils: InstructionUtilsService,
    private loadingIndicator: LoadingIndicatorService,
    public dateUtilsService: DateUtilsService
  ) {}

  ngOnInit(): void {
    return;
  }

  getInstructionIconFromType() {
    if (this.instruction.instructionType === InstructionType.storing) {
      return 'blue_storing_icon.svg';
    } else {
      return 'blue_picking_icon.svg';
    }
  }

  async cancelInstruction() {
    const isReallocationTail =
      this.instruction.instructionType === InstructionType.picking && this.instruction.previousInstructionId != null;
    const dialogReponse = await this.dialogService.openConfirmDialog(
      this.translate.instant('dialog.instructions.cancelInstruction.title'),
      this.translate.instant('dialog.instructions.cancelInstruction.description', {
        id: this.instruction.instructionId,
        origin: this.instruction.origin,
        destination: this.instruction.destination,
      })
    );

    if (dialogReponse === 'confirm') {
      this.instructionApi
        .cancelInstruction(this.instruction.instructionId, false)
        .then(_ => {
          this.toastService.success(this.translate.instant('toast.success.totemServices.instructionCanceled'));
          this.instructionChanged.emit({
            instructionIdChanged: this.instruction.instructionId,
            requestAllInstructions: isReallocationTail,
          });
        })
        .catch(error => {
          if (error.error.code) {
            if (this.metadataService.errorMetadata[error.error.code]) {
              this.toastService.error(this.metadataService.getErrorDescriptionFromErrorCode(error.error.code));
            } else {
              this.toastService.error(
                this.translate.instant('toast.errors.totemServices.errorToCancel', { errorCode: error.error.code })
              );
            }
          } else {
            this.toastService.error(this.translate.instant('toast.errors.totemServices.instructionCanceled'));
          }
        });
    }
  }

  async unpickInstruction() {
    const dialog = this.instructionsDialog.openUnpickInstructionDialog(this.instruction);

    dialog
      .afterClosed()
      .toPromise()
      .then(async (value: { isComplete: boolean }) => {
        if (value.isComplete) {
          this.loadingIndicator.show();
          try {
            await this.instructionApi.unpickInstruction(this.instruction.instructionId).toPromise();
            this.toastService.success(this.translate.instant('toast.success.totemServices.instructionUnpicked'));
            this.instructionChanged.emit({
              instructionIdChanged: this.instruction.instructionId,
              requestAllInstructions: true,
            });
          } catch (e: any) {
            this.toastService.error(this.metadataService.getErrorDescriptionFromErrorCode(e.error.code));
          }

          this.loadingIndicator.hide();
        }
      });
  }

  openConcludeInstructionDialog() {
    let dialog;

    dialog = this.instructionsDialog.openConcludeInstructionDialog(this.instruction);

    dialog
      .afterClosed()
      .toPromise()
      .then((value: { isCompleted: boolean }) => {
        if (value.isCompleted) {
          this.instructionChanged.emit({
            instructionIdChanged: this.instruction.instructionId,
            requestAllInstructions: false,
          });
        }
      });
  }

  getColorFromCreationTime(): string {
    if (!this.showTimeIndicator) {
      return '#fff';
    }
    const createdDate = new Date(this.instruction.createdAt + 'Z');

    if (this.dateUtilsService.compareTwoDates(createdDate) >= 1000 * 3600) {
      return '#eb445a';
    }
    return '#e2a200';
  }
}
