import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IAutoComplete } from 'src/app/interfaces/autocomplete/iauto-complete';
import { AvailableFilter } from 'src/app/models/autocomplete';
import { Token } from '../autocomplete-token/token';
import { TokenState } from 'src/app/models/token';

export class Autocomplete implements IAutoComplete {
  private translateService: TranslateService;
  private availableFilters: AvailableFilter[] = [];
  private formCtrl: FormControl;
  private sub: Subscription;
  private suggestions: string[] = [];
  private tokens: Token[] = [];

  constructor(filters: AvailableFilter[], translateService: TranslateService, formControl: FormControl) {
    this.formCtrl = formControl;
    this.availableFilters = filters;
    this.translateService = translateService;

    this.sub = this.formCtrl.valueChanges.subscribe({
      next: value => {
        this.tokens = [];
        this.generateTokens(value);
        this.generateSuggestions();
      },
    });
  }

  hasErrors(): boolean {
    if (this.tokens.some(t => t.hasErrors())) {
      this.formCtrl.setErrors({ error: true });
      return true;
    }

    this.formCtrl.setErrors(null);
    return false;
  }

  getErrors(): string {
    let errors: string = this.translateService.instant('common.errors');

    for (let i = 0; i < this.tokens.length; i++) {
      if (this.tokens[i].hasErrors()) {
        errors += ` [${i + 1}]: ${this.tokens[i].getErrors().join(', ')}`;
      }
    }

    return errors;
  }

  getSuggestions(): string[] {
    return this.suggestions;
  }

  getTokens(): Token[] {
    return this.tokens;
  }

  destroy() {
    this.sub.unsubscribe();
  }

  private generateTokens(formStr: string) {
    const splittedStr = formStr.split(/ AND | OR | and | or /);

    for (const str of splittedStr) {
      const filters = this.availableFilters.filter(f => f.name === str.split('=')[0]);

      if (filters.length > 0) {
        this.tokens.push(new Token(str, this.translateService, filters[0]));
      } else {
        this.tokens.push(new Token(str, this.translateService, undefined));
      }
    }
  }

  private generateSuggestions() {
    const lastToken = this.tokens.slice(-1)[0];
    const clearStr = this.getClearStrControl(lastToken);

    switch (lastToken.getState()) {
      case TokenState.ATTRIBUTION_SIGN:
      case TokenState.OPTION_INCOMPLETE:
        this.suggestions = this.generateFilterOptionSuggestions(lastToken, clearStr);
        break;
      case TokenState.COMPLETED:
        this.suggestions = [this.formCtrl.value.trim() + ' AND '];
        break;
      default:
        this.suggestions = this.getFilterNames(lastToken).map(f => `${clearStr}${f}=`);
        break;
    }
  }

  private getClearStrControl(lastToken: Token): string {
    const ctrlValue: string = this.formCtrl.value;
    const idx = ctrlValue.lastIndexOf(lastToken.getStr());

    return ctrlValue.slice(0, idx);
  }

  private getFilterNames(token: Token): string[] {
    const filterNames = this.availableFilters.map(f => f.name);

    return filterNames.filter(f => f.toLowerCase().includes(token.getStr().toLowerCase()));
  }

  private getFilterOptions(token: Token): string[] {
    const filter = this.availableFilters.find(f => f.name.toLowerCase() === token.getData().filterName.toLowerCase());

    if (filter) {
      const tokenData = token.getData();
      const lastOption = tokenData.filterOptions.slice(-1)[0] ?? '';

      if (filter.options) {
        return filter.options
          .filter(o => !tokenData.filterOptions.includes(o) && o.toLowerCase().includes(lastOption.toLowerCase()))
          .slice(0, 100);
      }
    }

    return [];
  }

  private generateFilterOptionSuggestions(lastToken: Token, clearStr: string) {
    const options = this.getFilterOptions(lastToken);

    if (options.length > 0) {
      const tokenData = lastToken.getData();

      if (tokenData.filterOptions.length > 1) {
        return options.map(
          o => `${clearStr}${tokenData.filterName}=${tokenData.filterOptions.slice(0, -1).join(',')},${o}`
        );
      } else {
        return options.map(o => `${clearStr}${tokenData.filterName}=${o}`);
      }
    }
    return [];
  }
}
