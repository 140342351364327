<!--
File: \src\app\pages\racks\import\racks.preview.dialog.html
Project: boxcar-console
Created Date: 2022-05-17 13:01:30
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-05-17	JF	Racks file preview dialog template.
-->

<h1 mat-dialog-title>{{'catalog.review.title' | translate}}</h1>

<div mat-dialog-content>
  <p>{{'catalog.review.checkData' | translate}}</p>

  <!-- File contents preview -->
  <table class="table">
    <caption style="display: none">
      Rack rails info preview
    </caption>
    <thead>
      <tr>
        <th>{{'catalog.racks.region' | translate}}</th>
        <th>{{'catalog.racks.name' | translate}}</th>
        <th>{{'catalog.racks.maxBoxes' | translate}}</th>
        <th>{{'catalog.racks.column' | translate}}</th>
        <th>{{'catalog.racks.level' | translate}}</th>
        <th>{{'catalog.racks.boxType' | translate}}</th>
        <th>{{'catalog.racks.partNumber' | translate}}</th>
        <th>{{'catalog.racks.operationMode' | translate}}</th>
        <th>{{'catalog.racks.type' | translate}}</th>
        <th>{{'catalog.racks.inputSideTag' | translate}}</th>
        <th>{{'catalog.racks.outputSideTag' | translate}}</th>
        <th>{{'catalog.racks.state' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rackRail of data.racksCatalogRows | slice:0:10">
        <td>{{ rackRail.regionPrefix }}</td>
        <td>{{ rackRail.name }}</td>
        <td>{{ rackRail.maxBoxes }}</td>
        <td>{{ rackRail.column }}</td>
        <td>{{ rackRail.level }}</td>
        <td>{{ commaSeparatedArray(rackRail.allowedContainerModels) }}</td>
        <td>{{ getPartNumbersFromSuggestedParts(rackRail.suggestedParts) }}</td>
        <td>{{ getOperationMode(rackRail.operationMode) }}</td>
        <td>{{ getRailType(rackRail.railType) }}</td>
        <td>{{ commaSeparatedArray(rackRail.inputSideRFIDs) }}</td>
        <td>{{ commaSeparatedArray(rackRail.outputSideRFIDs) }}</td>
        <td>{{ rackRail.state }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="data">
    {{'catalog.review.sendButton' | translate}}
  </button>
  <button mat-button (click)="onCloseDialog()">{{'catalog.review.cancelButton' | translate}}</button>
</div>
