/*
 * File: \src\app\services\api\metadata.api.service.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 15:00:52
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Service created for refactoring.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@services/api';

import { LogDictsDto, StateDictDto } from 'src/app/models/metadata';

@Injectable({
  providedIn: 'root',
})
export class MetadataApiService {
  constructor(private httpClient: HttpClient) {}

  /** Returns log metadata dictionary. */
  getLogMetadata() {
    return this.httpClient.get<LogDictsDto>(baseUrl + `metadata/log`).toPromise();
  }

  /** Returns box states metadata dictionary. */
  getBoxMetadata() {
    return this.httpClient.get<StateDictDto>(baseUrl + `metadata/box`).toPromise();
  }

  // Return all metadata errors.
  getErrorMetadata() {
    return this.httpClient.get(`${baseUrl}metadata/errors`).toPromise();
  }

  // Return all metadata reasons.
  getReasonsMetadata() {
    return this.httpClient.get(`${baseUrl}metadata/reasons`).toPromise();
  }

  // Return all metadata manual reasons.
  getManualReasonsMetadata() {
    return this.httpClient.get(`${baseUrl}catalog/reasons`).toPromise();
  }
}
