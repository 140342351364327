/*
 * File: \src\app\logs-management\logs.component.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Component refactoring.
 * 2022-04-27	JF	Added I18N support.
 */

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MetadataService } from '@services/metadata/metadata.service';

import { TranslateService } from '@ngx-translate/core';
import { LoggerApiService } from '@services/api';
import { DataLogsService } from '@services/logs/data-logs.service';
import { LogDTO, LogLocal, LogQuery } from 'src/app/models/logger';
import { MatDialog } from '@angular/material/dialog';
import { LogDetailsDialogComponent } from '@components/log-details-dialog/log-details-dialog.component';

@Component({
  selector: 'boxcar-console-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  logs: any[] = [];
  displayedColumns: string[] = ['login', 'timestamp', 'eventClassNumber', 'contextId', 'contextTag', 'data'];

  dataSource: MatTableDataSource<LogLocal>;

  logMetadata: any;
  isLoading = false;
  selectedGravity = '';
  pageIndex: number = 0;
  pageSize: number = 10;
  totalLength: number = 0;
  filterDetails: LogQuery = {
    pageNum: this.pageIndex + 1,
    qtyPerPage: this.pageSize,
    filter: '',
  };

  constructor(
    public dialog: MatDialog,
    private apiService: LoggerApiService,
    private metadataService: MetadataService,
    private translateService: TranslateService,
    private dataTransform: DataLogsService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  async ngOnInit() {
    this.isLoading = true;
    this.logMetadata = this.metadataService.getLogMetadata();
    let localLogs: LogLocal[] = [];

    await this.apiService.getLogEntries(this.filterDetails).then(async logs => {
      localLogs = await Promise.all(
        logs.logs.map(async (log: LogDTO) => {
          const newLog: LogLocal = {
            login: log.login,
            timestamp: new Date(log.timestamp + 'Z').toLocaleString('default'),
            contextTag: this.transformContext(log.contextTag),
            contextId: log.contextId,
            data: await this.dataTransform.getLexicalDataFromJSON(log.data, log.eventDetailNumber, log.code),
            eventClassNumber: this.logMetadata.eventClassDict[log.eventClassNumber].namePT,
            eventDetailNumber: this.logMetadata.eventDetailDict[log.eventDetailNumber].namePT,
            gravity: this.logMetadata.eventDetailDict[log.eventDetailNumber].gravity,
            isDetailButtonNedded: this.isDetailButtonNedded(log.eventDetailNumber),
            rawLog: log
          };
          return newLog;
        })
      );

      this.dataSource = new MatTableDataSource(localLogs);
      this.totalLength = logs.logsQuantity;
    });

    this.isLoading = false;
  }

  async getData() {
    this.isLoading = true;
    let localLogs: LogLocal[] = [];

    await this.apiService.getLogEntries(this.filterDetails).then(async logs => {
      localLogs = await Promise.all(
        logs.logs.map(async (log: LogDTO) => {
          const newLog: LogLocal = {
            login: log.login,
            timestamp: new Date(log.timestamp + 'Z').toLocaleString('default'),
            contextTag: this.transformContext(log.contextTag),
            contextId: log.contextId,
            data: await this.dataTransform.getLexicalDataFromJSON(log.data, log.eventDetailNumber, log.code),
            eventClassNumber: this.logMetadata.eventClassDict[log.eventClassNumber].namePT,
            eventDetailNumber: this.logMetadata.eventDetailDict[log.eventDetailNumber].namePT,
            gravity: this.logMetadata.eventDetailDict[log.eventDetailNumber].gravity,
            isDetailButtonNedded: this.isDetailButtonNedded(log.eventDetailNumber),
            rawLog: log
          };
          return newLog;
        })
      );

      this.dataSource = new MatTableDataSource(localLogs);
      this.totalLength = logs.logsQuantity;
    });

    this.isLoading = false;
  }

  async applyFilter(event: Event) {
    // Get filter from page and set for api.
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterDetails.filter = filterValue.trim().toLowerCase();

    // Reset api page num to 1.
    this.filterDetails.pageNum = 1;

    await this.getData();

    // Reset paginator page num.
    this.pageIndex = 0;
  }

  onGravityFilterChange() {
    // Get gravity from page and set for api.
    this.filterDetails.gravity = [...this.selectedGravity];

    // Reset api page num to 1.
    this.filterDetails.pageNum = 1;

    this.getData();

    // Reset paginator page num.
    this.pageIndex = 0;
  }

  changePaginator(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.filterDetails.pageNum = this.pageIndex + 1;
    this.filterDetails.qtyPerPage = this.pageSize;

    this.getData();
  }

  transformContext(context: string) {
    if (context === 'box') {
      return this.translateService.instant('logs.box');
    } else if (context === 'user') {
      return this.translateService.instant('logs.user');
    } else if (context === 'assembly_detail') {
      return this.translateService.instant('logs.tag');
    } else if (context === 'box_assembly') {
      return this.translateService.instant('logs.tag');
    } else if (context === 'rfid_allotment') {
      return this.translateService.instant('logs.caseRegistry');
    } else if (context === 'instruction') {
      return this.translateService.instant('logs.instruction');
    } else if (context === 'container') {
      return this.translateService.instant('logs.container');
    }
    return this.translateService.instant('logs.noContext');
  }

  isDetailButtonNedded(eventDetailNumber: number) {
    return eventDetailNumber === 11001 || eventDetailNumber === 11004;
  }

  openDetailsModal(data: any) {
    this.dialog.open(LogDetailsDialogComponent, {
      data
    });
  }
}
