<h1 mat-dialog-title>{{ 'instructions.prioritize' | translate }}</h1>
<div mat-dialog-content>
  <!-- Loading overlay -->
  <loading-on-page [isLoading]="isLoadingOrders"></loading-on-page>

  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'instructions.list.pendingOrders'  | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <waiting-order-card
      *ngFor="let instruction of this.waitingInstructions"
      [instruction]="instruction"
      (makeRequest)="makeRequest()">
    </waiting-order-card>

  </mat-expansion-panel>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close color="primary">
    {{ ('instructions.list.cancel' | translate).toUpperCase() }}
  </button>
  <button mat-button mat-dialog-close color="primary" (click)="prioritizeInstructions()">
    {{ ('instructions.list.prioritize' | translate).toUpperCase() }}
  </button>
</div>
