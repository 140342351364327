<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ decideTitleToShow() }}
    </mat-card-title>
    <mat-card-subtitle>
      <mat-chip-list multiple="true">
        <mat-chip (click)="handleChipClick($event, 0)" [selected]="chipsValue[0]">
          <mat-chip-avatar *ngIf="chipsValue[0]">
            <img src="../../../assets/icons/check_icon.svg" alt="check icon" />
          </mat-chip-avatar>
          {{ 'logs.logistics.allLogs' | translate }}
        </mat-chip>
        <mat-chip (click)="handleChipClick($event, 1)" [selected]="chipsValue[1]">
          <mat-chip-avatar *ngIf="chipsValue[1]">
            <img src="../../../assets/icons/check_icon.svg" alt="check icon" />
          </mat-chip-avatar>
          {{ 'logs.logistics.wrongDestinyLogs' | translate }}
        </mat-chip>
        <mat-chip (click)="handleChipClick($event, 2)" [selected]="chipsValue[2]">
          <mat-chip-avatar *ngIf="chipsValue[2]">
            <img src="../../../assets/icons/check_icon.svg" alt="check icon" />
          </mat-chip-avatar>
          {{ 'logs.logistics.automaticReturnLogs' | translate }}
        </mat-chip>
      </mat-chip-list>
    </mat-card-subtitle>
    <button mat-flat-button color="primary" class="reload-button" (click)="reloadTables(true)">
      <img src="../../../assets/icons/refreshIcon.svg" color="white" width="24px" height="24px" alt="refresh" />
    </button>
  </mat-card-header>
  <mat-card-content>
    <logistic-table
      *ngIf="chipsValue[0]"
      [refreshData]="refreshData[0]"
      [refreshIdx]="0"
      [showLoading]="showTableLoading"
      (resetRefreshData)="resetRefreshData($event)"></logistic-table>
    <wrong-destination-table
      *ngIf="chipsValue[1]"
      [showTitle]="showTitle[0]"
      [refreshData]="refreshData[1]"
      [refreshIdx]="1"
      [showLoading]="showTableLoading"
      (resetRefreshData)="resetRefreshData($event)"></wrong-destination-table>
    <automatic-return-table
      *ngIf="chipsValue[2]"
      [refreshData]="refreshData[2]"
      [refreshIdx]="2"
      [showLoading]="showTableLoading"
      (resetRefreshData)="resetRefreshData($event)"></automatic-return-table>
  </mat-card-content>
</mat-card>
