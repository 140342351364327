<h1 mat-dialog-title>{{ (title | translate).toUpperCase() }}</h1>
<div mat-dialog-content>
  <p>
    <strong>
      {{ description | translate }}
    </strong>
  </p>

  <div *ngIf="data.errorsList">
    <p>{{ 'common.errors' | translate }}</p>
    <p *ngFor="let error of data.errorsList">
      {{ error }}
    </p>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close color="primary">
    {{ ('instructions.list.return' | translate).toUpperCase() }}
  </button>
</div>
