/*
 * File: \src\app\app.module.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-26	JF	Module refactored.
 * 2022-04-26	JF	Adding I18N support.
 */

import { A11yModule } from '@angular/cdk/a11y';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MetadataService } from './services/metadata/metadata.service';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, MatNativeDateModule, NativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PsmmTagDetailDialogComponent } from '@pages/boxes/psmm-tag-detail.dialog/psmm-tag-detail.dialog';
import { RfidTagDetailDialogComponent } from '@pages/boxes/rfid-tag-detail.dialog/rfid-tag-detail.dialog';
import { RfidtagsPrintLayoutComponent } from '@pages/rfidPrint/rfidtags-print-layout.component';
import { AddRfidTagsComponent } from '@pages/rfidTags/add-rfid-tags/add-rfid-tags.component';
import { PrintTagsComponent } from '@pages/rfidTags/print-tags/print-tags.component';
import { DialogContentComponent, RfidTagsComponent } from '@pages/rfidTags/rfid-tags.component';
import { InstructionApiService } from '@services/api';
import { AppToastService, NoticeDialogService } from '@services/index';
import { DataTablesModule } from 'angular-datatables';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppToastsComponent } from './components/apptoast/apptoast.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import {
  BoxtypesComponent,
  InstructionListComponent,
  MergeResultDialogComponent,
  PartsComponent,
  PartsImportDialogComponent,
  PartsPreviewDialogComponent,
  PartsProductionGuideImportDialogComponent,
  PartsProductionGuidePreviewDialogComponent,
  PowerBIComponent,
  RackEditDialogComponent,
  RackQuantityDialogComponent,
  RacksComponent,
  RacksImportDialogComponent,
  RacksPreviewDialogComponent,
  RacksStateImportDialogComponent,
  RacksStatePreviewDialogComponent,
} from './pages';
import { BoxesComponent } from './pages/boxes/boxes.component';
import { LogsComponent } from './pages/logs-view/logs.component';
import { CreateDiaologComponent } from './pages/orders/create-order-dialog/create-order-dialog.component';
import { PrioritizeDiaologComponent } from '@pages/orders/prioritize-order-dialog/prioritize-order-dialog.component';
import { InstructionExpansionPanelsComponent } from './pages/orders/expansion-panels.component';
import { RootPageComponent } from './pages/root-page/root-page.component';
import { SectorsComponent } from './pages/sectors/sectors.component';
import { LocalDataService } from './services/local-data/local.data.service';
import { UserService } from './services/login/user.service';
import { CoreModule } from './shared/core.module';
import { appReducers } from './state/app.reducer';
import { appMetaReducers } from './state/index';
// eslint-disable-next-line max-len
import { ErrorDialogComponent } from '@components/error-dialog/error-dialog.component';
import { NoticeDialogComponent } from '@components/notice-dialog/notice-dialog';
import { RackContentsComponent } from '@pages/racks/racks-contents/racks-contents.component';
import { PalletTabDetailDialogComponent } from './pages/boxes/pallet-tab-detail.dialog/pallet-tab-detail.dialog.component';
import { PsmmTagCardComponent } from './pages/boxes/pallet-tab-detail.dialog/psmm-tag-card/psmm-tag-card.component';
// eslint-disable-next-line max-len
import { GenericBoxCardComponent } from './components/box-page-cards/generic-box-card/generic-box-card.component';
import { NormalBoxCardComponent } from './components/box-page-cards/normal-box-card/normal-box-card.component';
import { NormalPalletCardComponent } from './components/box-page-cards/normal-pallet-card/normal-pallet-card.component';
import { RackViewerComponent } from './components/rack-viewer/rack-viewer.component';
import { WarningDialogComponent } from './components/warning-dialog/warning-dialog.component';
import { ColorSubtitleComponent } from './pages/racks/color-subtitle/color-subtitle.component';
import { AddContainersInBatchComponent } from './pages/rfidTags/add-containers-in-batch/add-containers-in-batch.component';
// eslint-disable-next-line max-len
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RackTotemViewerComponent } from '@components/rack-totem-viewer/rack-totem-viewer.component';
import { RackDetailedAllocationDialogComponent } from '@pages/racks/racks-contents/rack-detailed-allocation-dialog/rack-detailed-allocation-dialog.component';
import { RackStructureComponent } from '@pages/racks/racks-structure/racks-structure.component';
import { TotemServicesComponent } from '@pages/totem-services/totem-services.component';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { LogisticsTableComponent } from './components/alert-tables/logistics-table/logistics-table.component';
import { WrongDestinationTableComponent } from './components/alert-tables/wrong-destination-table/wrong-destination-table.component';
import { AutomaticReturnTableComponent } from './components/alert-tables/automatic-return-table/automatic-return-table.component';
import { GenericPalletCardComponent } from './components/box-page-cards/generic-pallet-card/generic-pallet-card.component';
import { ResumedBoxCardComponent } from './components/box-page-cards/resume-box-card/resumed-box-card.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ContingencyFunctionsComponent } from './components/contingency-functions/contingency-functions.component';
import { ContingencyInstructionsManagerComponent } from './components/contingency-instructions-manager/contingency-instructions-manager.component';
import { ConcludeInstructionWithoutAddrsComponent } from './components/dialogs/conclude-instruction-without-addrs/conclude-instruction-without-addrs.component';
import { InDevelopmentDialogComponent } from './components/in-development-dialog/in-development-dialog.component';
import { InstructionCardComponent } from './components/instructions/instruction-card/instruction-card.component';
import { InstructionFilterComponent } from './components/instructions/instruction-filter/instruction-filter.component';
import { UnpickdialogComponent } from './components/instructions/instructions-management/unpickdialog/unpickdialog.component';
import { DoneOrdersComponent } from './components/instructions/orders-cards/done-order-card/done-order-card.component';
import { WaitingOrderCardComponent } from './components/instructions/orders-cards/waiting-order-card/waiting-order-card.component';
import { WorkingOrderCardComponent } from './components/instructions/orders-cards/working-order-card/working-order-card.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LoadingOnPageComponent } from './components/loading-on-page/loading-on-page.component';
import { MoveFlowAssociationDialogComponent } from './components/move-box-dialog/association-dialog/association-dialog.component';
import { AvailableTagComponent } from './components/move-box-dialog/available-tag-dialog/available-tag-dialog.component';
import { ModifyInstructionDialogComponent } from './components/move-box-dialog/modify-instruction-dialog/modify-instruction-dialog.component';
import { MoveBoxDialogComponent } from './components/move-box-dialog/move-box-dialog.component';
import { OperationTypeSelectionDialogComponent } from './components/move-box-dialog/operation-type-selection-dialog/operation-type-selection-dialog.component';
import { PlacedDetailDialogComponent } from './components/move-box-dialog/placed-detail-dialog/placed-detail-dialog.component';
import { RestoreConfirmationDialogComponent } from './components/move-box-dialog/restore-confirmation-dialog/restore-confirmation-dialog.component';
import { PaginatorPageSelectComponent } from './components/paginator-page-select/paginator-page-select.component';
import { ConcludeCreatedInstructionComponent } from './components/rack-totem-viewer/conclude-created-instruction/conclude-created-instruction.component';
import { ContinueFlowComponent } from './components/rack-totem-viewer/continue-flow/continue-flow.component';
import { ConfirmInfoComponent } from './components/rack-totem-viewer/picking-dialog/confirm-info/confirm-info.component';
import { PickingDialogComponent } from './components/rack-totem-viewer/picking-dialog/picking-dialog.component';
import { PickingOperationDialogComponent } from './components/rack-totem-viewer/picking-dialog/picking-operation-dialog/picking-operation-dialog.component';
import { CheckPsmmInfoComponent } from './components/rack-totem-viewer/storing-dialog/associate-dialog/associate-dialog.component';
import { CreateInstructionDialogComponent } from './components/rack-totem-viewer/storing-dialog/create-instruction-dialog/create-instruction-dialog.component';
import { StoringDialogComponent } from './components/rack-totem-viewer/storing-dialog/storing-dialog.component';
import { AlertComponent } from './pages/alerts/alerts.component';
import { RackSearchComponent } from './pages/racks/rack-search/rack-search.component';
import { ReadPSMMTagsReportsComponent } from './pages/read-psmm-tags-reports/read-psmm-tags-reports.component';
import { CreateUserDialogComponent } from './pages/user-management/create-user-dialog/create-user-dialog.component';
import { SwitchUserActivatedDialogComponent } from './pages/user-management/switch-user-active-dialog/switch-user-active-dialog.component';
import { UpdateUserDialogComponent } from './pages/user-management/update-user-dialog/update-user-dialog.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { QualityComponent } from './pages/quality/quality.component';
import { QualityDialogComponent } from './components/quality-dialog/quality-dialog.component';
import { QualityShippingListComponent } from './components/quality-shipping-list/quality-shipping-list.component';
import { QualityListComponent } from './components/quality-list/quality-list.component';
import { SettingsProfilesComponent } from './pages/settings-profiles/settings-profiles.component';
import { ForkliftsAndEquipmentsComponent } from './pages/forklifts-and-equipments/forklifts-and-equipments.component';
import { SettingsProfileDialogComponent } from './components/settings-profile-dialog/settings-profile-dialog.component';
import { ForkliftsListComponent } from './components/forklifts-list/forklifts-list.component';
import { ReadersListComponent } from './components/readers-list/readers-list.component';
import { TabletsListComponent } from './components/tablets-list/tablets-list.component';
import { ForkliftDialogComponent } from './components/forklift-dialog/forklift-dialog.component';
import { TabletDialogComponent } from './components/tablet-dialog/tablet-dialog.component';
import { ReaderDialogComponent } from './components/reader-dialog/reader-dialog.component';
import { PablistComponent } from './pages/pablist/pablist.component';
import { LogDetailsDialogComponent } from './components/log-details-dialog/log-details-dialog.component';

registerLocaleData(localePt);

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/', '.json');

export const jwtOptionsFactory = (tokenService: LocalDataService) => ({
  tokenGetter: () => tokenService?.getToken(),
  whitelistedDomains: ['localhost:5001'],
  allowedDomains: environment.allowedHosts,
});

@NgModule({
  declarations: [
    AppComponent,
    AppToastsComponent,
    NavBarComponent,
    RootPageComponent,
    RfidTagsComponent,
    DialogContentComponent,
    AddRfidTagsComponent,
    LogsComponent,
    SectorsComponent,
    BoxtypesComponent,
    PartsComponent,
    PartsImportDialogComponent,
    MergeResultDialogComponent,
    PartsPreviewDialogComponent,
    PartsProductionGuideImportDialogComponent,
    PartsProductionGuidePreviewDialogComponent,
    RacksComponent,
    RacksImportDialogComponent,
    RacksPreviewDialogComponent,
    RackContentsComponent,
    RacksStateImportDialogComponent,
    RacksStatePreviewDialogComponent,
    RackEditDialogComponent,
    RackQuantityDialogComponent,
    RfidtagsPrintLayoutComponent,
    BoxesComponent,
    PsmmTagDetailDialogComponent,
    RfidTagDetailDialogComponent,
    PrintTagsComponent,
    InstructionListComponent,
    InstructionExpansionPanelsComponent,
    CreateDiaologComponent,
    PrioritizeDiaologComponent,
    PalletTabDetailDialogComponent,
    PsmmTagCardComponent,
    ErrorDialogComponent,
    NoticeDialogComponent,
    AddContainersInBatchComponent,
    ColorSubtitleComponent,
    RackStructureComponent,
    PowerBIComponent,
    WarningDialogComponent,
    RackViewerComponent,
    NormalBoxCardComponent,
    GenericBoxCardComponent,
    NormalPalletCardComponent,
    GenericPalletCardComponent,
    RackSearchComponent,
    LoadingIndicatorComponent,
    LoadingOnPageComponent,
    PaginatorPageSelectComponent,
    AlertComponent,
    LogisticsTableComponent,
    WrongDestinationTableComponent,
    AutomaticReturnTableComponent,
    ReadPSMMTagsReportsComponent,
    TotemServicesComponent,
    RackTotemViewerComponent,
    PickingDialogComponent,
    StoringDialogComponent,
    PickingOperationDialogComponent,
    CheckPsmmInfoComponent,
    CreateInstructionDialogComponent,
    ConfirmInfoComponent,
    MoveBoxDialogComponent,
    OperationTypeSelectionDialogComponent,
    MoveFlowAssociationDialogComponent,
    ModifyInstructionDialogComponent,
    InstructionCardComponent,
    ContingencyFunctionsComponent,
    ContingencyInstructionsManagerComponent,
    ConfirmDialogComponent,
    ContinueFlowComponent,
    ConcludeCreatedInstructionComponent,
    InDevelopmentDialogComponent,
    UnpickdialogComponent,
    UserManagementComponent,
    SwitchUserActivatedDialogComponent,
    UpdateUserDialogComponent,
    CreateUserDialogComponent,
    ConcludeInstructionWithoutAddrsComponent,
    InstructionFilterComponent,
    WaitingOrderCardComponent,
    WorkingOrderCardComponent,
    DoneOrdersComponent,
    AvailableTagComponent,
    ResumedBoxCardComponent,
    PlacedDetailDialogComponent,
    RestoreConfirmationDialogComponent,
    RackDetailedAllocationDialogComponent,
    QualityComponent,
    QualityDialogComponent,
    QualityShippingListComponent,
    QualityListComponent,
    SettingsProfilesComponent,
    ForkliftsAndEquipmentsComponent,
    SettingsProfileDialogComponent,
    ForkliftsListComponent,
    ReadersListComponent,
    TabletsListComponent,
    ForkliftDialogComponent,
    TabletDialogComponent,
    ReaderDialogComponent,
    PablistComponent,
    LogDetailsDialogComponent,
  ],
  imports: [
    NgxMatTimepickerModule,
    InfiniteScrollModule,
    BrowserModule,
    DataTablesModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBarcode6Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    CoreModule,
    CommonModule,
    BrowserAnimationsModule,
    LayoutModule,
    OverlayModule,
    A11yModule,
    MatExpansionModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatChipsModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatTabsModule,
    NativeDateModule,
    StoreModule.forRoot(appReducers, {
      metaReducers: appMetaReducers,
      runtimeChecks: {
        //See https://ngrx.io/guide/store/configuration/runtime-checks#runtime-checks
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot(),
    NgbModule,
    EffectsModule.forRoot([]),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [LocalDataService],
      },
    }),
    MatTooltipModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    Storage,
    UserService,
    AppToastService,
    MetadataService,
    InstructionApiService,
    DatePipe,
    NoticeDialogService,
    LoadingIndicatorService,
  ],
  bootstrap: [AppComponent],
  exports: [CoreModule],
})
export class AppModule {
  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (window.navigator.language.indexOf('pt') !== -1) {
      translate.use('pt-BR');
    }
  }
}
