import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InstructionState, InstructionType } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class InstructionUtilsService {
  private readonly instructionStates = [
    {
      key: InstructionState.notStarted,
      value: this.translate.instant('instructions.states.notStarted'),
    },
    {
      key: InstructionState.started,
      value: this.translate.instant('instructions.states.started'),
    },
    {
      key: InstructionState.paused,
      value: this.translate.instant('instructions.states.paused'),
    },
    {
      key: InstructionState.canceled,
      value: this.translate.instant('instructions.states.canceled'),
    },
    {
      key: InstructionState.concluded,
      value: this.translate.instant('instructions.states.concluded'),
    },
  ];

  private readonly instructionTypes = [
    {
      key: InstructionType.storing,
      value: this.translate.instant('instructions.types.storing'),
    },
    {
      key: InstructionType.picking,
      value: this.translate.instant('instructions.types.picking'),
    },
    {
      key: InstructionType.reallocation,
      value: this.translate.instant('instructions.types.reallocation'),
    },
  ];

  constructor(private translate: TranslateService) {}

  getAllStates() {
    return Object.keys(InstructionState).filter(state => !isNaN(Number(state)));
  }

  getAllTypes() {
    return Object.keys(InstructionType).filter(state => !isNaN(Number(state)));
  }

  getStateText(instructionState: InstructionState) {
    switch (instructionState) {
      case InstructionState.canceled:
        return this.translate.instant('instructions.states.canceled');
      case InstructionState.concluded:
        return this.translate.instant('instructions.states.concluded');
      case InstructionState.notStarted:
        return this.translate.instant('instructions.states.notStarted');
      case InstructionState.paused:
        return this.translate.instant('instructions.states.paused');
      case InstructionState.started:
        return this.translate.instant('instructions.states.started');
    }
  }

  getTypeText(instructionType: InstructionType) {
    switch (instructionType) {
      case InstructionType.picking:
        return this.translate.instant('instructions.types.picking');
      case InstructionType.reallocation:
        return this.translate.instant('instructions.types.reallocation');
      case InstructionType.storing:
        return this.translate.instant('instructions.types.storing');
    }
  }

  getInstructionStates() {
    return this.instructionStates;
  }

  getInstructionTypes() {
    return this.instructionTypes;
  }
}
