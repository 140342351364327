/*
 * File: \src\app\pages\racks\import\racks.content.import.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-08-30 10:49:00
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type RacksStateImportDialogData = {
  selectedFile: File;
};

@Component({
  selector: 'racks-state-import-dialog',
  templateUrl: 'racks.state.import.dialog.html',
})
export class RacksStateImportDialogComponent {
  fileTypeAsJSON: string = '.json';

  rackRailStateJsonSnippet: string = `[{
      "id": 194,
      "regionPrefix": "E",
      "name": "E.1.3",
      "column": 1,
      "level": 3,
      "storedBoxes": 2,
      "storage": [
          {
              "partNumber": "5519011000650",
              "storedBoxes": 5,
              "order": 0
          },
          {
              "partNumber": "22690012041",
              "storedBoxes": 1,
              "order": 1
          }
      ]
    }]`;

  constructor(
    public dialogRef: MatDialogRef<RacksStateImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RacksStateImportDialogData
  ) {}

  onBrowseFile(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;

    // Single selection file on browse.
    if (fileList !== null) {
      this.data.selectedFile = fileList[0];
    }
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
