<h1 mat-dialog-title>{{ 'totemServices.picking.verifyDataTitle' | translate }}:</h1>

<p>
  {{ 'totemServices.picking.verifyDataDescription' | translate }}
</p>

<div mat-dialog-content [formGroup]="formGroup">
  <mat-form-field appearance="fill">
    <mat-label>
      {{ 'common.tagRFID' | translate }}
    </mat-label>
    <input matInput formControlName="rfidTagCtrl" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let rfidTag of allRfidTags" [value]="rfidTag.barcode">
        {{ rfidTag.barcode }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngFor="let psmmTagCtrl of psmmTagsCtrls.controls; index as i">
    <mat-label>
      {{ 'common.tagPsmm' | translate }}
    </mat-label>
    <input matInput [formControl]="getPsmmTagCtrlFromIndex(i)" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>
      {{ 'common.partNumber' | translate }}
    </mat-label>
    <input matInput formControlName="partNumberCtrl" />
  </mat-form-field>

  <button *ngIf="!updateIsOn" mat-button color="warn" (click)="onClickChangeInfo()">
    {{ 'common.buttons.changeData' | translate }}
  </button>

  <button *ngIf="updateIsOn" mat-button color="warn" (click)="onConfirmChanges()">
    {{ 'common.buttons.confirmChange' | translate }}
  </button>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!formGroup.disabled" (click)="onClickContinue()">
    {{ 'common.buttons.continue' | translate }}
  </button>

  <button mat-button color="warn" mat-dialog-close>
    {{ 'common.buttons.cancel' | translate }}
  </button>
</div>
