/*
 * File: \src\app\pages\boxes\boxes.component.ts
 * Project: boxcar-console
 * Created Date: 2022-02-18 08:26:16
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-11-23	TC	Add applyFilter() to use search bar
 * 2022-04-25	JF	Component refactored.
 * 2022-04-26	JF	Adding I18N support.
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MetadataService } from '@services/metadata/metadata.service';

import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PaginatorPageSelectComponent } from '@components/paginator-page-select/paginator-page-select.component';
import { TranslateService } from '@ngx-translate/core';
import { PackagingApiService } from '@services/api/packaging.api.service';
import { BoxDto } from 'src/app/models/packaging';
import { RfidTagDetailDialogComponent } from './rfid-tag-detail.dialog/rfid-tag-detail.dialog';
import { ContainerService } from '@services/container/container.service';
import { StateDictDto } from '@models/metadata';

@Component({
  selector: 'boxcar-console-boxes',
  templateUrl: './boxes.component.html',
  styleUrls: ['./boxes.component.scss'],
})
export class BoxesComponent implements OnInit {
  statesExplanation: string[] = [];

  boxes: BoxDto[] = [];
  length: number = 0;
  boxStateMetadata: StateDictDto = {};
  selectedState = '-1';
  search = '';
  tableIsLoading: boolean = false;

  pageSizeOptions: number[] = [10, 25, 50];

  // MatPaginator Output
  @ViewChild(PaginatorPageSelectComponent) paginator!: PaginatorPageSelectComponent;

  // Form for date.
  currentDateFilter: string = '';
  public date: Date | null = null;
  pipe = new DatePipe(navigator.language);

  constructor(
    public dialog: MatDialog,
    private apiService: PackagingApiService,
    private metadataService: MetadataService,
    private translateService: TranslateService,
    private containerUtils: ContainerService
  ) {
    this.statesExplanation = [
      this.translateService.instant('boxes.states.allBoxes'),
      this.translateService.instant('boxes.states.availableBoxes'),
      this.translateService.instant('boxes.states.processingBoxes'),
      this.translateService.instant('boxes.states.pickingBoxes'),
      this.translateService.instant('boxes.states.movingBoxes'),
      this.translateService.instant('boxes.states.inRackBoxes'),
      this.translateService.instant('boxes.states.toQualityBoxes'),
      this.translateService.instant('boxes.states.deliveredBoxes'),
      this.translateService.instant('boxes.states.receivedBoxes'),
    ];
  }

  async ngOnInit() {
    this.tableIsLoading = true;
    this.boxStateMetadata = await this.containerUtils.getBoxMetadata();

    await this.apiService.searchBoxes(1, 10).then(page => {
      this.length = page.containerQuantity;
      this.boxes = page.containersResponseDtos;
      this.tableIsLoading = false;
    });
  }

  async onPaginatorChange(pageEvent: PageEvent) {
    this.paginator.pageIndex = pageEvent.pageIndex;
    this.paginator.pageSize = pageEvent.pageSize;
    this.paginator.length = pageEvent.length;

    await this.makeRequest();
  }

  async makeRequest() {
    this.tableIsLoading = true;
    await this.apiService
      .searchBoxes(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize,
        this.selectedState === '-1' ? undefined : this.selectedState,
        this.currentDateFilter,
        this.search.trim().toLowerCase()
      )
      .then(page => {
        this.length = page.containerQuantity;
        this.boxes = page.containersResponseDtos;
        this.tableIsLoading = false;
      });
    this.tableIsLoading = false;
  }

  tryAgain() {
    this.boxStateMetadata = this.metadataService.getBoxMetadata();
  }

  async applyFilter() {
    this.paginator.pageIndex = 0;
    this.tableIsLoading = true;

    let pastSearch = this.search;
    setTimeout(async () => {
      if (pastSearch == this.search) {
        await this.makeRequest();
      } else {
        return;
      }
    }, 2000);
  }

  async changeSelectedState() {
    this.paginator.pageIndex = 0;
    this.tableIsLoading = true;

    await this.makeRequest();
  }

  async applyFilterDate(event?: MatDatepickerInputEvent<Date>) {
    if (event?.value != undefined) {
      const date = event.value;
      this.currentDateFilter = this.pipe.transform(date, 'yyyyMMdd')!;
    }
    this.paginator.pageIndex = 0;
    this.tableIsLoading = true;

    this.makeRequest();
  }

  clearDate(event: MouseEvent) {
    event.stopPropagation();
    this.date = null;
    this.currentDateFilter = '';
    this.applyFilter();
  }

  clearSearch(event: MouseEvent) {
    event.stopPropagation();
    this.search = '';
    this.applyFilter();
  }

  getExplainedState(state: string): string {
    return this.statesExplanation[Number(state) + 1];
  }

  totalPallet(assembly: any[]): number {
    let total = 0;
    if (assembly != null && assembly.length > 0) {
      for (let box of assembly) {
        for (let labelDto of box.labelDtos) {
          total += labelDto.quantity;
        }
      }
    }
    return total;
  }

  openRfidTagDetail(rfidTag: BoxDto) {
    this.dialog.open(RfidTagDetailDialogComponent, {
      data: { rfidTag },
      minWidth: '90vw',
      maxHeight: '95vh',
    });
  }
}
