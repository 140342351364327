/*
 * File: \src\app\pages\sectors\sectors.component.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Component refactored.
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { SectorDto } from 'src/app/models/packaging';
import { PaginatorPageSelectComponent } from '@components/paginator-page-select/paginator-page-select.component';
import { DataCatalogApiService } from '@services/api';

@Component({
  selector: 'boxcar-console-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.scss'],
})
export class SectorsComponent implements OnInit {
  sectors: any[] = [];
  displayedColumns: string[] = ['name', 'description', 'sourceDtos'];
  dataSource: MatTableDataSource<SectorDto>;
  isLoading: boolean = false;

  @ViewChild(PaginatorPageSelectComponent) paginator: PaginatorPageSelectComponent | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  constructor(private apiService: DataCatalogApiService) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit() {
    this.isLoading = true;
    await this.apiService.getSectors().then(sectors => {
      sectors = sectors.filter(sector => !sector.name.toLowerCase().includes('_null'));
      this.dataSource = new MatTableDataSource(sectors);
      if (this.paginator) this.dataSource.paginator = this.paginator?.paginator;
      this.dataSource.sort = this.sort;
    });
    this.isLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
