<h1 mat-dialog-title>{{ title }}</h1>

<h4 [innerHTML]="description">{{ description }}</h4>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" mat-dialog-close="confirm" [color]="confirmButtonColor">
    {{ 'common.buttons.confirm' | translate }}
  </button>

  <button mat-button mat-dialog-close="back">
    {{ 'common.buttons.back' | translate }}
  </button>
</div>
