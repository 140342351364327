<h1 mat-dialog-title>{{ 'totemServices.picking.selectBox' | translate : { rackName: data.rackRail.name } }}</h1>

<h4>{{ 'totemServices.picking.selectBoxDescription' | translate }}</h4>

<div mat-dialog-content>
  <h5>{{ (isStack() ? 'warehouse.racks.edit.lastPosition' : 'warehouse.racks.edit.firstPosition') | translate }}</h5>

  <div class="input-row" *ngFor="let partNumberForm of partNumberFormArray.controls; index as i">
    <!-- Part number selector field -->
    <mat-form-field appearance="fill" style="width: 60%">
      <mat-label>{{ 'catalog.racks.edit.partNumber' | translate }}</mat-label>
      <input matInput type="text" placeholder="Pick one" [formControl]="getPartNumberControlByIndex(i)" />
    </mat-form-field>

    <!-- Max boxes field -->
    <mat-form-field appearance="fill" style="width: 15%; margin-left: 6px">
      <!-- Data label -->
      <mat-label>{{ 'warehouse.racks.edit.storedBoxes' | translate }}</mat-label>

      <!-- Quantity of boxes -->
      <input
        id="items"
        type="number"
        placeholder="Ex.: 10"
        matInput
        min="0"
        [formControl]="getBoxQuantityControlByIndex(i)"
        max="{{ data.rackRail.maxBoxes }}"
        cdkFocusInitial />
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="onClickRemoveBox(partNumberForm.value, i)">
      {{ 'totemServices.picking.remove' | translate }}
    </button>
  </div>

  <br />

  <h5>{{ (isStack() ? 'warehouse.racks.edit.firstPosition' : 'warehouse.racks.edit.lastPosition') | translate }}</h5>
</div>

<!-- Dialog buttons -->
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="onCancel()">{{ 'common.buttons.cancel' | translate }}</button>
</div>
