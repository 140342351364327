/*
 * File: src\app\core\api\api.service.ts
 * Project: boxcar-console
 * Date: 2022-02-07 13:02:05
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-02-07 JF  Added catalog part calls.
 */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DataMergeRespDto } from 'src/app/models/catalog.response';

import { SectorDto } from 'src/app/models';
import {
  AssemblyDetailResponse,
  RegionDto,
  MaxAvailableContainer,
  PartDto,
  PartGroupDto,
  RackRailBasicInfo,
  RackRailContainersStorage,
  LocationDto,
  RackRegionDto,
  LocationSimplifiedDto,
} from 'src/app/models/datacatalog';
import { Observable } from 'rxjs';
import { baseUrl } from '.';

@Injectable({
  providedIn: 'root',
})
export class DataCatalogApiService {
  allPartsCached: PartDto[] = [];
  allDestinationsCached: RegionDto[] = [];
  allRackRailsCached: LocationDto[] = [];
  allSectorsCached: SectorDto[] = [];
  promiseRackRails: Promise<LocationDto[]> | undefined | void = undefined;
  promiseDestinationLocation: Promise<RegionDto[]> | undefined | void = undefined;
  promiseSectors: Promise<SectorDto[]> | undefined | void = undefined;
  promiseParts: Promise<PartDto[]> | undefined | void = undefined;

  constructor(private httpClient: HttpClient) {}

  /* Parts are organized in groups (a.k.a. families), the UI needs to get the groups before request
    a part set from the backend. */

  /** Returns the set of parts groups. */
  getPartGroups(): Promise<PartGroupDto[]> {
    return this.httpClient.get<PartGroupDto[]>(baseUrl + 'catalog/partgroup').toPromise();
  }

  /** Returns a set of parts belonging to a given group. */
  getParts(groupId: number): Promise<PartDto[]> {
    return this.httpClient.get<PartDto[]>(baseUrl + `catalog/partgroup/${groupId}/parts`).toPromise();
  }

  /** Uploads a set of parts to be added/merged to the part catalog. */
  mergeParts(parts: PartDto[]): Promise<DataMergeRespDto> {
    return this.httpClient.post<DataMergeRespDto>(baseUrl + 'catalog/parts', parts).toPromise();
  }

  /* Rack rail structure is organized by regions representing the rack physical space. */

  /** Return the set of registered rack regions. */
  getRackRegions(): Promise<RackRegionDto[]> {
    return this.httpClient.get<RackRegionDto[]>(baseUrl + `catalog/region`).toPromise();
  }

  /** Return a set of rack rails belonging to a given rack region. */
  getRackRails(rackRegionId: number, type?: string): Promise<LocationDto[]> {
    if (type) {
      const params = new HttpParams().append('type', type);
      return this.httpClient
        .get<LocationDto[]>(baseUrl + `catalog/rackregion/${rackRegionId}/rackrails`, { params })
        .toPromise();
    } else {
      return this.httpClient.get<LocationDto[]>(baseUrl + `catalog/rackregion/${rackRegionId}/rackrails`).toPromise();
    }
  }

  /** Get a single rack rail data by id. */
  getRackRail(rackRailId: number): Promise<LocationDto> {
    return this.httpClient.get<LocationDto>(baseUrl + `catalog/rackrails/${rackRailId}`).toPromise();
  }

  /** Get containers from rack rail id. You can pass a part number or current depth you want */
  getContainersFromRackRail(rackRailId: number, partNumber?: string, currentDepth?: number) {
    let params = new HttpParams();

    if (partNumber) {
      params = params.append('partNumber', partNumber);
    }

    if (currentDepth) {
      params = params.append('depth', currentDepth);
    }

    return this.httpClient.get<RackRailContainersStorage[]>(baseUrl + `catalog/rackrails/${rackRailId}/containers`, {
      params,
    });
  }

  // * Get rackrails with stored boxes with PN.
  getRackRailsFromPN(searchPN: string): Promise<LocationDto[]> {
    return this.httpClient.get<LocationDto[]>(baseUrl + `catalog/rackrails/pn/${searchPN}`).toPromise();
  }

  /** Uploads a set of rack definitions to be added/merged to the rack metadata. */
  mergeRackRails(rackRails: LocationDto[]): Promise<DataMergeRespDto> {
    return this.httpClient
      .post<DataMergeRespDto>(baseUrl + 'catalog/rackrails', rackRails, {
        headers: new HttpHeaders({ timeout: '360000' }),
      })
      .toPromise();
  }

  // Return a set of destinations for a new instruction.
  getDestinations(): Promise<RegionDto[]> {
    return this.httpClient.get<RegionDto[]>(baseUrl + 'catalog/destination').toPromise();
  }

  getSimplifiedLocationsData(): Observable<LocationSimplifiedDto[]> {
    return this.httpClient.get<LocationSimplifiedDto[]>(`${baseUrl}catalog/rackrails`);
  }

  // State saving methods

  async getAllPartsFromCache() {
    if (this.allPartsCached.length === 0 && this.promiseParts === undefined) {
      this.promiseParts = this.getParts(0);

      await this.promiseParts
        .then(response => {
          this.allPartsCached = response;
        })
        .catch(error => {
          throw new Error('Can not fetch all parts from backend');
        })
        .finally(() => {
          this.promiseParts = undefined;
        });
    } else if (this.promiseParts !== undefined) {
      await this.promiseParts;
    }
    return this.allPartsCached;
  }

  async getAllDestinationsFromCache() {
    if (this.allDestinationsCached.length === 0 && this.promiseDestinationLocation === undefined) {
      this.promiseDestinationLocation = this.getDestinations();

      await this.promiseDestinationLocation
        .then(response => {
          this.allDestinationsCached = response;
        })
        .catch(error => {
          throw new Error('Can not fetch all destinations from backend');
        })
        .finally(() => {
          this.promiseDestinationLocation = undefined;
        });
    } else if (this.promiseDestinationLocation !== undefined) {
      await this.promiseDestinationLocation;
    }
    return this.allDestinationsCached;
  }

  async getAllSectorsFromCache() {
    if (this.allSectorsCached.length === 0 && this.promiseSectors === undefined) {
      this.promiseSectors = this.getSectors();

      await this.promiseSectors
        .then(response => {
          this.allSectorsCached = response;
        })
        .catch(error => {
          throw new Error('Can not fetch all destinations from backend');
        })
        .finally(() => {
          this.promiseSectors = undefined;
        });
    } else if (this.promiseSectors !== undefined) {
      await this.promiseSectors;
    }
    return this.allSectorsCached;
  }

  async getAllRackRailsFromCache(forceRefresh: boolean = false) {
    if ((this.allRackRailsCached.length === 0 && this.promiseRackRails === undefined) || forceRefresh) {
      this.promiseRackRails = this.getRackRails(0);

      await this.promiseRackRails
        .then(response => {
          this.allRackRailsCached = response;
          this.promiseRackRails = undefined;
        })
        .catch(_ => {
          throw new Error('Can not fetch all rack rails from backend');
        });
    } else if (this.promiseRackRails !== undefined) {
      await this.promiseRackRails;
    }
    return this.allRackRailsCached;
  }

  // Get max available containers to register according to initial EPC.
  getMaxContainerAvailable(initialEPC: string): Observable<MaxAvailableContainer> {
    const query = new HttpParams().set('startEpc', initialEPC);
    return this.httpClient.get<MaxAvailableContainer>(`${baseUrl}catalog/containerbatch/max`, { params: query });
  }

  /** Return a list of box picking sectors. */
  getSectors() {
    return this.httpClient.get<SectorDto[]>(baseUrl + 'catalog/sectors').toPromise();
  }

  getRackRailsInfoFromEPC(epc: string) {
    return this.httpClient.get<RackRailBasicInfo>(`${baseUrl}catalog/rackrails/info/${epc}`).toPromise();
  }

  getAssemblyDetails(startDate: string, endDate: string, source?: string, partNumber?: string, locationData?: boolean): Observable<AssemblyDetailResponse> {
    let query = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate);

    if (source) query = query.append('source', source);
    if (partNumber) query = query.append('partNumber', partNumber);
    if (locationData) query = query.append('locationData', locationData);

    return this.httpClient.get<AssemblyDetailResponse>(`${baseUrl}catalog/assemblyDetails`, { params: query });
  }

  getPabList(searchString?: string): Observable<AssemblyDetailResponse> {
    let query = new HttpParams();
    if (searchString) query.set('search', searchString); 
      
    return this.httpClient.get<AssemblyDetailResponse>(`${baseUrl}catalog/pab/list`, { params: query })
  }
}
