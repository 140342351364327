import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserApiService } from '@services/api/users.service';
import { AppToastService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { MetadataService } from '@services/metadata/metadata.service';
import { UserRequest, UserRole } from '@models/users';

@Component({
  selector: 'boxcar-console-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss'],
})
export class CreateUserDialogComponent implements OnInit {
  passwordIsVisible: boolean = false;
  formCtrls: FormGroup = new FormGroup({
    loginCtrl: new FormControl(null, [Validators.required]),
    passwordCtrl: new FormControl(null, [Validators.required]),
    nameCtrl: new FormControl(null, [Validators.required]),
    emailCtrl: new FormControl(null, [Validators.required, Validators.email]),
    rolesCtrl: new FormControl(null),
  });
  allRoles: UserRole[] = [];

  constructor(
    private userApiSerice: UserApiService,
    private loadingIndicator: LoadingIndicatorService,
    private toastService: AppToastService,
    private translate: TranslateService,
    private metadataService: MetadataService,
    private dialogRef: MatDialogRef<CreateUserDialogComponent>
  ) {}

  async ngOnInit(): Promise<void> {
    this.allRoles = await this.userApiSerice.getRoles().toPromise();
  }

  onCreateClick() {
    this.loadingIndicator.show();
    const basic = this.formCtrls.get('loginCtrl')!.value + ':' + this.formCtrls.get('passwordCtrl')!.value;

    const user: UserRequest = {
      auth: btoa(basic),
      name: this.formCtrls.get('nameCtrl')!.value,
      email: this.formCtrls.get('emailCtrl')!.value,
      active: true,
      roles: [],
    };

    for (let role of this.formCtrls.get('rolesCtrl')!.value) {
      const userRole = this.allRoles.find(userRole => userRole.name === role);
      user.roles = user.roles.concat(userRole!.id);
    }

    const dialogReturn = { created: false };
    this.userApiSerice
      .postUsers(user)
      .toPromise()
      .then(_ => {
        dialogReturn.created = true;
        this.toastService.success(this.translate.instant('toast.success.userManagement.userCreation'));
      })
      .catch(err => {
        this.toastService.error(this.metadataService.getErrorDescriptionFromErrorCode(err.error.code));
      })
      .finally(() => {
        this.loadingIndicator.hide();
        this.dialogRef.close(dialogReturn);
      });
  }
}
