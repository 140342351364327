
<mat-card style="margin-top: 2rem; padding: 2rem;">
  <mat-card-title>Imprimir etiquetas adesivas</mat-card-title>
  <mat-card-subtitle style="margin-top: 1rem;">
    Insira a quantidade de etiquetas únicas que deseja gerar e clique em <strong>Gerar etiquetas</strong>.
    O diálogo de impressão do navegador será aberto com as etiquetas geradas.
  </mat-card-subtitle>
  <mat-card-content>
    <br>
    <form class="example-form" (ngSubmit)="onSubmit()" [formGroup]="form">
      <mat-form-field class="example-full-width" appearance="outline" [hideRequiredMarker]="true">
        <mat-label>Gerar etiquetas</mat-label>
        <input name="quantity" matInput type="number" formControlName="quantity" min="1" max="1000">
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit" style="margin: 1.5rem auto; padding: 5px 25px;" [disabled]="!form.valid">Gerar etiquetas</button>
    </form>
  </mat-card-content>
  <div>

  </div>

</mat-card>
