import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataCatalogApiService, PackagingApiService } from '@services/api';
import { ContainerService } from '@services/container/container.service';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Debounce } from 'src/app/common/debounce/debounce';
import {
  BoxAssembly,
  ContainerDto,
  InfoForAssociateBox,
  LabelDto,
  LabelStateResponseDTO,
  PartDto,
  RfidTagInfoDto,
  SourceDto,
} from 'src/app/models';

@Component({
  selector: 'boxcar-console-association-dialog',
  templateUrl: './association-dialog.component.html',
  styleUrls: ['./association-dialog.component.scss'],
})
export class MoveFlowAssociationDialogComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = new FormGroup({
    tagRfidCtrl: new FormControl('', [Validators.required]),
    partNumberCtrl: new FormControl('', [Validators.required]),
    sourceCtrl: new FormControl(''),
    destinationCtrl: new FormControl('FG01', [Validators.required]),
  });
  psmmTagsCtrl: FormGroup[] = [];
  subPsmmTags: Subscription[] = [];

  filteredPartNumbers!: Observable<PartDto[]>;
  allPartNumbers: PartDto[] = [];
  allInjectors: SourceDto[] = [];
  filteredInjectors!: Observable<SourceDto[]>;
  filteredRFIDs: RfidTagInfoDto[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { box: ContainerDto; rfid: RfidTagInfoDto },
    private dialogRef: MatDialogRef<MoveFlowAssociationDialogComponent>,
    private containerService: ContainerService,
    private translate: TranslateService,
    private catalogApi: DataCatalogApiService,
    private loadingIndicatorService: LoadingIndicatorService,
    private packagingApiService: PackagingApiService
  ) {
    this.allPartNumbers = [...this.catalogApi.allPartsCached];
    this.setSubscriptionOnFormValueChanges('partNumber');
  }

  async ngOnInit(): Promise<void> {
    this.loadingIndicatorService.show();
    this.setInitialState();
    this.allInjectors = await this.getAllInjectors();
    this.setSubscriptionOnFormValueChanges('injector');
    this.loadingIndicatorService.hide();
  }

  ngOnDestroy(): void {
    this.subPsmmTags.forEach(sub => sub.unsubscribe());
  }

  private setValidFields(box: BoxAssembly) {
    if (box.boxAssemblyInfoResponseDto.partNumber) {
      this.formGroup.get('partNumberCtrl')!.setValue(box.boxAssemblyInfoResponseDto.partNumber);
      this.formGroup.get('partNumberCtrl')!.disable();
    }
    if (box.boxAssemblyInfoResponseDto.manufactureSource) {
      this.formGroup.get('sourceCtrl')!.setValue(box.boxAssemblyInfoResponseDto.manufactureSource);
      this.formGroup.get('sourceCtrl')!.disable();
    }
  }

  private setSubscriptionOnPsmmTagCtrl(groupCtrls: FormGroup) {
    this.subPsmmTags.push(
      Debounce.debounceFormField(groupCtrls.get('psmmTag')! as FormControl, async value => {
        this.loadingIndicatorService.show();
        const response = await this.packagingApiService.verifyPSMMTag(value).toPromise();
        this.loadingIndicatorService.hide();
        return response;
      }).subscribe({
        next: (value: LabelStateResponseDTO) => {
          const date = new Date(value.psmmLabelDto.timestamp);
          const time = `${date.getHours()}:${date.getMinutes()}`;

          this.formGroup.get('partNumberCtrl')!.setValue(value.psmmLabelDto.partNumber);
          this.formGroup.get('sourceCtrl')!.setValue(value.psmmLabelDto.manufactureSource);

          groupCtrls.get('qty')!.setValue(value.psmmLabelDto.quantity);
          groupCtrls.get('qty')!.disable();
          groupCtrls.get('operator')!.setValue(value.psmmLabelDto.operator);
          groupCtrls.get('operator')!.disable();
          groupCtrls.get('shift')!.setValue(value.psmmLabelDto.shift);
          groupCtrls.get('shift')!.disable();
          groupCtrls.get('date')!.setValue(date);
          groupCtrls.get('date')!.disable();
          groupCtrls.get('time')!.setValue(time);
          groupCtrls.get('time')!.disable();
        },
      })
    );
  }

  private setGenericBoxFields() {
    const formGroup = new FormGroup({
      psmmTag: new FormControl(''),
      qty: new FormControl(1, [Validators.min(1)]),
      operator: new FormControl(''),
      shift: new FormControl(''),
      date: new FormControl(''),
      time: new FormControl(''),
    });

    this.psmmTagsCtrl.push(formGroup);
    this.setSubscriptionOnPsmmTagCtrl(formGroup);
  }

  private setFieldsFromLabelDto(labelDto: LabelDto, idx: number) {
    const date = new Date(labelDto.timestamp);
    const time = `${date.getHours()}:${date.getMinutes()}`;
    this.psmmTagsCtrl.push(
      new FormGroup({
        psmmTag: new FormControl(labelDto.labelcode),
        qty: new FormControl(labelDto.quantity, [Validators.min(1)]),
        operator: new FormControl(labelDto.operator),
        shift: new FormControl(labelDto.shift),
        date: new FormControl(date),
        time: new FormControl(time),
      })
    );
    this.psmmTagsCtrl[idx].disable();
    this.psmmTagsCtrl[idx].updateValueAndValidity();
  }

  private setRFIDTagCtrl() {
    this.formGroup.get('tagRfidCtrl')!.setValue(this.data.rfid.barcode);
    this.formGroup.get('tagRfidCtrl')!.disable();
  }

  private setInitialState() {
    const boxes = this.data.box.boxAssembly;

    this.setRFIDTagCtrl();

    if (boxes) {
      this.setValidFields(boxes);

      if (boxes.labelDtos.length > 0) {
        boxes.labelDtos.forEach((data, idx) => {
          this.setFieldsFromLabelDto(data, idx);
        });
      } else {
        this.setGenericBoxFields();
      }
    } else {
      this.setGenericBoxFields();
    }
  }

  private async getAllInjectors() {
    return await this.catalogApi.getSectors().then(sectors => {
      let injectors: SourceDto[] = [];

      sectors.forEach(sector => {
        injectors = injectors.concat(
          sector.sourceDtos.filter(
            sector =>
              !sector.description.toLowerCase().includes('picking') &&
              !sector.description.toLowerCase().includes('null')
          )
        );
      });

      return injectors;
    });
  }

  getDialogDescription() {
    const stateTranslation = this.containerService.getContainerStateTranslation(this.data.box);

    return this.translate.instant('totemServices.moveBox.associateBox.description', {
      rfid: this.data.rfid.barcode,
      state: stateTranslation.toUpperCase(),
    });
  }

  getFormControl(control: string, idx: number) {
    return this.psmmTagsCtrl[idx].get(control)! as FormControl;
  }

  private setSubscriptionOnFormValueChanges(ctrl: string) {
    switch (ctrl) {
      case 'partNumber':
        this.filteredPartNumbers = this.formGroup.get('partNumberCtrl')!.valueChanges.pipe(
          startWith(''),
          map(value =>
            this.allPartNumbers.filter(partNumber => partNumber.number.toLowerCase().includes(value.toLowerCase()))
          )
        );
        break;
      case 'injector':
        this.filteredInjectors = this.formGroup.get('sourceCtrl')!.valueChanges.pipe(
          startWith(''),
          map(value =>
            this.allInjectors.filter(
              injector =>
                injector.description.toLowerCase().includes(value.toLowerCase()) ||
                injector.name.toLowerCase().includes(value.toLowerCase())
            )
          )
        );
        break;
      default:
        break;
    }
  }

  updateQuantity(partNumber: PartDto) {
    this.psmmTagsCtrl[0].get('qty')!.setValue(partNumber.quantityPerBox ?? 0);
  }

  addNewTag() {
    this.setGenericBoxFields();
  }

  async removeTagFromIndex(idx: number) {
    const psmmTagCtrlGroup = this.psmmTagsCtrl.splice(idx, 1)[0];

    if (psmmTagCtrlGroup.disabled) {
      this.loadingIndicatorService.show();
      await this.packagingApiService.unreadPSMMTag(psmmTagCtrlGroup.get('psmmTag')!.value).toPromise();
      this.loadingIndicatorService.hide();
    }

    this.subPsmmTags.splice(idx, 1);
  }

  validateForm() {
    let labelDtosValid = true;

    for (let group of this.psmmTagsCtrl) {
      labelDtosValid = (group.valid || group.disabled) && labelDtosValid;
    }

    return this.formGroup.valid && labelDtosValid;
  }

  private validateLabelDtoForm(formGroup: FormGroup) {
    return formGroup.get('psmmTag')!.value !== ''
      ? formGroup.get('psmmTag')!.value !== '' &&
          formGroup.get('qty')!.value !== '' &&
          formGroup.get('date')!.value !== '' &&
          formGroup.get('operator')!.value !== '' &&
          formGroup.get('shift')!.value !== ''
      : formGroup.get('qty')!.value !== '';
  }

  onClickAssociateBox() {
    const partNumber = this.formGroup.get('partNumberCtrl')!.value.split(' - ')[0].trim();
    const source = this.formGroup.get('sourceCtrl')!.value.split(' - ')[0].trim();
    const labelDtos: any[] = [];
    this.psmmTagsCtrl.forEach(form => {
      if (this.validateLabelDtoForm(form)) {
        let date;
        let splittedTime;
        let timeZoneHoursOffset;

        if (form.get('date')!.value !== '') {
          date = new Date(form.get('date')!.value);

          splittedTime = form.get('time')!.value.split(':');
          timeZoneHoursOffset = date.getTimezoneOffset() / 60;

          date.setHours(splittedTime[0] - timeZoneHoursOffset, splittedTime[1]);
        }

        const labelDto = {
          labelcode: form.get('psmmTag')!.value as string,
          quantity: form.get('qty')!.value as number,
          timestamp: date ? date.toISOString() : null,
          operator: form.get('operator')!.value,
          shift: form.get('shift')!.value === 1 ? '' : form.get('shift')!.value,
          partNumber: this.formGroup.get('partNumberCtrl')!.value.split(' - ')[0],
          manufactureSource: this.formGroup.get('sourceCtrl')!.value
            ? this.formGroup.get('sourceCtrl')!.value.split(' - ')[0]
            : '',
        };

        labelDtos.push(labelDto);
      }
    });

    const associateInfo: InfoForAssociateBox = {
      packageEPC: this.data.rfid.epc,
      partNumber: partNumber,
      source: source,
      destination: this.formGroup.get('destinationCtrl')!.value,
      labelDtos: labelDtos,
    };

    this.dialogRef.close(associateInfo);
  }
}
