/*
 * File: \src\app\pages\parts\import\merge.result.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-05-17 09:29:28
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-05-17	JF	Upload result data.
 */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

import { DataMergeRespDto } from 'src/app/models/catalog.response';

export type MergeResultDialogData = {
  dataMergeRespDto: DataMergeRespDto;
};

@Component({
  selector: 'merge-result-dialog',
  templateUrl: 'merge.result.dialog.html',
  styleUrls: ['./merge.result.dialog.scss'],
})
export class MergeResultDialogComponent {
  constructor(
    private clipboard: Clipboard,
    public dialogRef: MatDialogRef<MergeResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MergeResultDialogData
  ) {}

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  onCopyToClipboard(): void {
    this.clipboard.copy(JSON.stringify(this.data.dataMergeRespDto, null, '  '));
  }
}
