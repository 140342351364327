/*
 * File: \src\app\pages\boxes\psmm-tag-detail.dialog\psmm-tag-detail.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-04-26 11:38:01
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'psmm-tag-detail-dialog',
  templateUrl: 'psmm-tag-detail.dialog.html',
})
export class PsmmTagDetailDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { labelDto: any; pn: string; source: string }) {}
}
