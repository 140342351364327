<!--
File: \src\app\pages\racks\racks-contents\rack-edit2.dialog\rack-edit2.dialog.html
Project: boxcar-console
Created Date: 2022-11-11 13:27:34
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2024-02-05	VAR	Updating rail properties for new block situations.
2022-11-11	JF	Upgraded rack layout edit for new relationships.
-->

<div class="container">
  <h1 mat-dialog-title>{{'catalog.racks.edit.title' | translate}}</h1>

  <form [formGroup]="form">
    <!-- Rack rail name -->
    <div class="row-fields">
      <div class="rail-name">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'catalog.racks.edit.name' | translate }}</mat-label>
          <input type="text" placeholder="Example: A.1.1" matInput required formControlName="name" />
        </mat-form-field>
      </div>

      <!-- Blocked properties -->
      <div class="state-properties">
        <mat-slide-toggle color="primary" formControlName="isFull">
          {{ 'catalog.racks.edit.railFull' | translate }}
        </mat-slide-toggle>
        <mat-slide-toggle color="primary" formControlName="isStoringBlocked">
          {{ 'catalog.racks.edit.blockStoring' | translate }}
        </mat-slide-toggle>
        <mat-slide-toggle color="primary" formControlName="isPickingBlocked">
          {{ 'catalog.racks.edit.blockPicking' | translate }}
        </mat-slide-toggle>
      </div>
    </div>

    <div mat-dialog-content>
      <!-- Operation Mode  ? -->
      <mat-form-field appearance="fill" style="width: 80%">
        <mat-label> {{ 'catalog.racks.selectOperationType' | translate}} </mat-label>

        <mat-select formControlName="operationMode">
          <mat-option [value]="('catalog.racks.types.static' | translate) ">
            {{ 'catalog.racks.types.static' | translate }}
          </mat-option>

          <mat-option [value]="('catalog.racks.types.dynamic' | translate) ">
            {{ 'catalog.racks.types.dynamic' | translate }}
          </mat-option>

          <mat-option [value]="('catalog.racks.types.fixed' | translate) ">
            {{ 'catalog.racks.types.fixed' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        style="margin-bottom: 1.34375em"
        *ngIf="partNumberControls.length === 0"
        (click)="addPartNumberControl()">
        {{ 'catalog.racks.edit.addPN' | translate }}
      </button>

      <!-- Array of part number entries -->
      <div *ngFor="let partNumberForm of partNumberControls; last as isLast; index as i;">
        <mat-form-field
          appearance="fill"
          [style]="
          {
            width:
            form.get('operationMode')!.value ===('catalog.racks.types.fixed' | translate) ? '50%' : '80%'
          }">
          <mat-label>{{ 'catalog.racks.edit.partNumber' | translate }}</mat-label>

          <input matInput type="text" [matAutocomplete]="auto" [formControl]="getPartNumberControlByIndex(i)" />

          <mat-autocomplete matNativeControl #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of getFilteredOptions(i) | async"
              [value]="option.number + ' - ' + option.description">
              {{option.number}} - {{option.description}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field
          *ngIf="form.get('operationMode')!.value === ('catalog.racks.types.fixed' | translate)"
          class="margin-left-input"
          appearance="fill"
          style="width: 25%">
          <mat-label>{{ 'warehouse.racks.editProps.maxQnt' | translate}}</mat-label>
          <input type="number" matInput [formControl]="getPartNumberMaxQuantityControlByIndex(i)" />
        </mat-form-field>

        <button mat-icon-button color="warn" (click)="removePartNumberControl(i)">
          <mat-icon>remove_circle</mat-icon>
        </button>

        <button mat-icon-button color="primary" *ngIf="isLast" (click)="addPartNumberControl()">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>

      <!-- Box type field -->
      <mat-form-field appearance="fill" style="width: 80%">
        <mat-label>{{ 'catalog.racks.edit.boxType' | translate }}</mat-label>
        <mat-select matNativeControl formControlName="boxType" multiple>
          <mat-option *ngFor="let option of data.boxTypes" [value]="option.name">
            {{option.name}} - {{option.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Picking destinations field  -->
      <mat-form-field appearance="fill" style="width: 80%">
        <mat-label>{{ 'catalog.racks.edit.destination' | translate }}</mat-label>
        <mat-select matNativeControl formControlName="destination" multiple>
          <mat-option *ngFor="let option of data.destinations" [value]="option.name">
            {{option.name}} - {{option.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Rack rail type field -->
      <mat-form-field appearance="fill" style="width: 37.5%">
        <!-- Part number field -->
        <mat-label>{{ 'catalog.racks.edit.railType' | translate }}</mat-label>
        <mat-select matNativeControl formControlName="type">
          <mat-option [value]="1"> {{ 'catalog.racks.edit.nominalRail' | translate }} </mat-option>
          <mat-option [value]="2"> {{ 'catalog.racks.edit.returningRail' | translate }} </mat-option>
          <mat-option [value]="4"> {{ 'catalog.racks.edit.fractioningRail' | translate }} </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Max boxes field -->
      <mat-form-field appearance="fill" style="width: 37.5%; margin-left: 5%">
        <!-- Part number field -->
        <mat-label>{{ 'catalog.racks.edit.capacity' | translate }}</mat-label>
        <input id="items" type="number" placeholder="Ex.: 10" matInput formControlName="maxBoxes" />
      </mat-form-field>

      <!-- Input side RFID tag EPC -->
      <!-- <mat-form-field appearance="fill" style="width: 80%">
        <mat-label>{{ 'catalog.racks.inputSideTag' | translate }}</mat-label>
        <input type="text" placeholder="Tag EPC: H00004..." matInput formControlName="inputEPC" />
      </mat-form-field> -->

      <!-- Output RFID tag EPC -->
      <!-- <mat-form-field appearance="fill" style="width: 80%">
        <mat-label>{{ 'catalog.racks.outputSideTag' | translate }}</mat-label>
        <input type="text" placeholder="Tag EPC: H00004..." matInput formControlName="outputEPC" />
      </mat-form-field> -->

      <button
        mat-raised-button
        color="primary"
        style="margin-bottom: 1.34375em"
        *ngIf="inputSideEpcControls.length === 0"
        (click)="addInputSideEpcControl()">
        Adicionar {{ 'catalog.racks.inputSideTag' | translate }}
      </button>

      <!-- Array of part number entries -->
      <div *ngFor="let inputSideEpcControl of inputSideEpcControls; last as isLast; index as i;">
        <mat-form-field appearance="fill" style="width: 80%">
          <mat-label> {{ 'catalog.racks.inputSideTag' | translate }} </mat-label>
          <input matInput type="text" [formControl]="getInputSideEpcControlByIndex(i)" />
        </mat-form-field>

        <button mat-icon-button color="warn" (click)="removeInputSideEpcControl(i)">
          <mat-icon>remove_circle</mat-icon>
        </button>

        <button mat-icon-button color="primary" *ngIf="isLast" (click)="addInputSideEpcControl()">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>

      <button
        mat-raised-button
        color="primary"
        style="margin-left: 0.8rem; margin-bottom: 1.34375em"
        *ngIf="outputSideEpcControls.length === 0"
        (click)="addOutputSideEpcControl()">
        Adicionar {{ 'catalog.racks.outputSideTag' | translate }}
      </button>

      <!-- Array of part number entries -->
      <div *ngFor="let outputSideEpcControl of outputSideEpcControls; last as isLast; index as i;">
        <mat-form-field appearance="fill" style="width: 80%">
          <mat-label> {{ 'catalog.racks.outputSideTag' | translate }} </mat-label>
          <input matInput type="text" [formControl]="getOutputSideEpcControlByIndex(i)" />
        </mat-form-field>

        <button mat-icon-button color="warn" (click)="removeOutputSideEpcControl(i)">
          <mat-icon>remove_circle</mat-icon>
        </button>

        <button mat-icon-button color="primary" *ngIf="isLast" (click)="addOutputSideEpcControl()">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>

    </div>

    <!-- Dialog buttons -->
    <div mat-dialog-actions>
      <button [disabled]="!validateForm()" mat-raised-button color="primary" (click)="onSubmit()">
        {{'catalog.racks.edit.save' | translate}}
      </button>
      <button mat-button (click)="onCancel()">{{'catalog.racks.edit.cancel' | translate}}</button>
    </div>
  </form>
</div>
