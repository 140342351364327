<h1 mat-dialog-title>{{ 'userManagement.dialogs.editUser' | translate }}</h1>

<h4>{{ 'userManagement.dialogs.editDescription' | translate : { user: data.user.login } }}</h4>

<div mat-dialog-content>
  <form [formGroup]="formCtrls">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'userManagement.table.headers.login' | translate }}</mat-label>
      <input type="text" matInput formControlName="loginCtrl" />
    </mat-form-field>

    <div class="margin-bottom-container">
      <button
        *ngIf="!changePassword; else changePasswordFields"
        mat-stroked-button
        color="primary"
        (click)="activePasswordField()">
        {{ 'common.buttons.userManagement.changePasswordButton' | translate }}
      </button>

      <ng-template #changePasswordFields>
        <mat-form-field appearance="outline" class="password-field">
          <mat-label>{{ 'userManagement.table.headers.password' | translate }}</mat-label>
          <input type="{{ passwordIsVisible ? 'text' : 'password' }}" matInput formControlName="passwordCtrl" />
          <button mat-icon-button matSuffix (click)="passwordIsVisible = !passwordIsVisible">
            <img
              width="24"
              alt="Toggle password visibility"
              src="../../../assets/icons/{{
                passwordIsVisible ? 'disable_visibility_icon.svg' : 'visibility_icon.svg'
              }}" />
          </button>
        </mat-form-field>

        <div class="button-followed-container">
          <button mat-flat-button color="warn" (click)="deactivatePassword()">
            {{ 'common.buttons.userManagement.leftPasswordButton' | translate }}
          </button>
        </div>
      </ng-template>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'userManagement.table.headers.name' | translate }}</mat-label>
      <input type="text" matInput formControlName="nameCtrl" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'userManagement.table.headers.email' | translate }}</mat-label>
      <input type="email" matInput formControlName="emailCtrl" />
    </mat-form-field>

    <div class="margin-bottom-container">
      <mat-checkbox matInput formControlName="isActiveCtrl" color="primary">
        {{ 'userManagement.inputs.userIsActive' | translate }}
      </mat-checkbox>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'userManagement.table.headers.roles' | translate }}</mat-label>
      <mat-select multiple formControlName="rolesCtrl">
        <mat-option *ngFor="let role of allRoles" [value]="role.name">{{ role.name.toUpperCase() }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="onEditClick()" [disabled]="!formCtrls.valid">
    {{ 'common.buttons.edit' | translate }}
  </button>

  <button mat-button [mat-dialog-close]="{ edited: false }">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</div>
