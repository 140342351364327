<!--
File: \src\app\pages\rfidTags\rfid-tags.component.html
Project: boxcar-console
Created Date: 2022-02-07 13:42:57
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-04-26	JF	Added I18N support.
-->

<div class="buttons">
  <div>
    <button
      mat-raised-button
      color="primary"
      type="button"
      style="margin: 1rem auto; padding: 5px 25px"
      (click)="goToAddTag()">
      {{ 'rfidTags.addTags' | translate }}
    </button>
    &nbsp;
    <button
      mat-raised-button
      color="primary"
      type="button"
      style="margin: 1rem auto; padding: 5px 25px"
      (click)="goToPrintTagPage()">
      {{ 'rfidTags.printStickers' | translate }}
    </button>
  </div>
  <div>
    <button
      mat-raised-button
      color="important"
      type="button"
      style="margin: 1rem auto; padding: 5px 25px"
      (click)="goToAddContainers()"
      [disabled]="!this.userIsVBL()">
      {{ 'rfidTags.addContainers' | translate }}
    </button>
  </div>
</div>

<br />
<br />
<mat-form-field appearance="outline">
  <mat-label>{{ 'rfidTags.search' | translate }}</mat-label>
  <input matInput (change)="applyFilter($event)" placeholder="Ex. 2021" #input />
</mat-form-field>

<div class="example-container mat-elevation-z2 loading-container">
  <!-- Loading indicator -->
  <loading-on-page [isLoading]="isLoadingResults"></loading-on-page>

  <table mat-table [dataSource]="dataSource">
    <caption style="display: none">
      Rfid tags info
    </caption>
    <!-- Position Column -->
    <ng-container matColumnDef="batchNumber">
      <th mat-header-cell *matHeaderCellDef>{{ 'rfidTags.code' | translate }}</th>
      <td mat-cell *matCellDef="let rfidtag">{{ rfidtag.batchNumber }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="epc">
      <th mat-header-cell *matHeaderCellDef>{{ 'rfidTags.epc' | translate }}</th>
      <td mat-cell *matCellDef="let rfidtag">{{ rfidtag.epc }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="barCode">
      <th mat-header-cell *matHeaderCellDef>{{ 'rfidTags.barcode' | translate }}</th>
      <td mat-cell *matCellDef="let rfidtag">{{ rfidtag.barcode }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="containerModel">
      <th mat-header-cell *matHeaderCellDef>{{ 'rfidTags.type' | translate }}</th>
      <td mat-cell *matCellDef="let rfidtag">{{ getTranslateFromType(rfidtag.entityType) }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="4">{{ 'rfidTags.noData' | translate }}: "{{ input.value }}"</td>
    </tr>
  </table>

  <paginator-page-select
    [pageSizeOptions]="[10, 25, 100]"
    [length]="resultsLength"
    (page)="onPaginatorChange($event)"
    aria-label="Select page of rfids">
  </paginator-page-select>
</div>

<br />
