import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'boxcar-console-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    return;
  }
}
