import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { defaultTheme } from '@components/rack-viewer/themes/rack-viewer-themes';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  MergeResultDialogComponent,
  MergeResultDialogData,
  RackEditDialogComponent,
  RackQuantityDialogComponent,
} from '@pages/index';
import { RackDetailedAllocationDialogComponent } from '@pages/racks/racks-contents/rack-detailed-allocation-dialog/rack-detailed-allocation-dialog.component';
import { BoxCarHttpErrorResponse, DataCatalogApiService, RfidApiService, boxCarCoreErrorDetails } from '@services/api';
import { AppToastService, NoticeDialogService, RackStateApiService } from '@services/index';
import { userData } from '@services/login/state/user-selectors';

import {
  BoxTypeDto,
  DataMergeRespDto,
  LocationDto,
  PartDto,
  RackRailStateDto,
  RackRailType,
  RackRegionDto,
  RailOperationMode,
  RailStorage,
  RegionDto,
  RegionStrategy,
  UserTypes,
  LocationState,
} from 'src/app/models';

@Component({
  selector: 'rack-viewer',
  templateUrl: './rack-viewer.component.html',
  styleUrls: ['./rack-viewer.component.scss'],
})
export class RackViewerComponent implements OnInit, OnChanges {
  // For overlay.
  isOneOpen: boolean = false;
  openedTrigger!: CdkConnectedOverlay | undefined;
  mouseIsOverOverlay: boolean = false;
  mouseIsOverBackdrop: boolean = false;

  // For data manipulation.
  rackRailLevels: LocationDto[][] = [];
  columns: number[] = [];
  boxTypes: BoxTypeDto[] = [];
  destinations: RegionDto[] = [];
  partsCatalog: PartDto[] = [];
  userRoles: string[] = [];
  types = UserTypes;
  storedPNs: string[] = [];
  localRackRails: LocationDto[] = [];
  dataMergeRespDto: DataMergeRespDto | undefined;
  floor?: LocationDto;
  boxesLimit: number = 25;
  isLoading: boolean = false;

  @Input() rackRails: LocationDto[] = [];
  @Input() totalCols: number = 0;
  @Input() totalLevels: number = 0;
  @Input() region: RackRegionDto = {
    id: 0,
    name: '',
    prefix: '',
    columns: 0,
    levels: 0,
    regionStrategy: 0,
    regionStorage: 1,
  };
  @Output() updateRail = new EventEmitter<number>();

  constructor(
    private toast: AppToastService,
    private catalogApiService: DataCatalogApiService,
    private store: Store,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private rackStateService: RackStateApiService,
    private noticeDialog: NoticeDialogService,
    private rfidApiService: RfidApiService
  ) {
    this.store.select(userData).subscribe({
      next: userData => {
        if (userData) {
          this.userRoles = [...userData.roles];
        }
      },
    });
  }

  ngOnInit(): void {
    return;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetData();
    if (this.rackRails.length !== 0) {
      this.localRackRails = [...this.rackRails.filter(rack => rack.level !== 0 && rack.column !== 0)];

      const haveFloor: boolean =
        this.rackRails.filter(
          rack => (rack.level === 0 || rack.level === null) && (rack.column === 0 || rack.column === null)
        ).length > 0;

      if (haveFloor) {
        this.floor = {
          ...this.rackRails.filter(
            rack => (rack.level === 0 || rack.level === null) && (rack.column === 0 || rack.column === null)
          )[0],
        };
      } else {
        this.floor = undefined;
      }

      // Create an array of number to columns.
      this.columns = this.columns.concat([...Array(this.totalCols + 1).keys()]);
      this.splitRailsLevels();
    }
  }

  // Manipulation data.
  private resetData() {
    this.rackRailLevels = [];
    this.columns = [];
    this.floor = undefined;
    this.storedPNs = [];
    this.localRackRails = [];
  }

  splitRailsLevels() {
    const rackRails = [...this.localRackRails];
    const startLevel = Math.min(...rackRails.map(rr => rr.level));

    for (let i = startLevel - 1; i < this.totalLevels; i++) {
      this.rackRailLevels.push(rackRails.splice(0, this.totalCols));
    }
  }

  colorForRackRail(rail: LocationDto) {
    const opacity = this.opacityForRackRail(rail);
    let color: string = '';

    if (rail.state !== LocationState.ACTIVE) {
      color = defaultTheme.itemInactiveColor;
    } else if (rail.railType === RackRailType.reverse) {
      color = defaultTheme.itemReturningColor;
    } else if (rail.suggestedParts.length === 0) {
      color = defaultTheme.itemWithoutPartColor;
    } else {
      color = defaultTheme.itemNominalColor;
    }

    color = color.replace('rgb', 'rgba');
    color = color.replace(')', `, ${opacity})`);

    return color;
  }

  opacityForRackRail(rail: LocationDto) {
    const maxBoxes = rail.maxBoxes > 0 ? rail.maxBoxes : 10;
    return 0.55 + 0.45 * (rail.storedBoxes / maxBoxes);
  }

  partDescriptionFromNumber(partNumber: string | undefined): string | undefined {
    return this.catalogApiService.allPartsCached.find(p => p.number == partNumber)?.description;
  }

  verifyUserRole(role: UserTypes) {
    return this.userRoles.includes(role) || this.userRoles.includes(UserTypes.admin);
  }

  // When edit boxes button is clicked.
  handleBoxQuantityPress(rail: LocationDto): void {
    this.partsCatalog = this.catalogApiService.allPartsCached;

    const dialogRef = this.matDialog.open(RackQuantityDialogComponent, {
      width: '640px',
      data: {
        rackRail: { ...rail },
        parts: this.partsCatalog,
        region: this.region.regionStrategy,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: async (rackRailStateDto: RackRailStateDto) => {
        this.isLoading = true;
        if (rackRailStateDto !== undefined) {
          await this.rackStateService
            .updateRackContents([rackRailStateDto])
            .then((response: DataMergeRespDto) => {
              this.dataMergeRespDto = response;
              if (response.errorRows === 0 && response.warnings === 0) {
                this.toast.success(
                  this.translate.instant('catalog.racks.edit.rackUpdateSuccess', { name: rackRailStateDto.name })
                );
              } else {
                this.toast.error(
                  this.translate.instant('catalog.racks.edit.rackUpdateFail', { name: rackRailStateDto.name })
                );

                this.showMergeResultDialog();
              }
              this.updateRail.emit(rail.id);
            })
            .catch((error: BoxCarHttpErrorResponse) => {
              this.noticeDialog.show(...boxCarCoreErrorDetails(error));
            });
        }
        this.isLoading = false;
      },
    });
  }

  // When configure rail button is clicked.
  handleEditPress(rail: LocationDto): void {
    this.partsCatalog = this.catalogApiService.allPartsCached;
    this.boxTypes = this.rfidApiService.allBoxTypesCached.filter(
      boxType => boxType.name.toLowerCase() !== 'virtual' && boxType.name.toLowerCase() !== '__null__'
    );

    if (this.region.regionStrategy !== RegionStrategy.jit) {
      this.boxTypes = this.boxTypes.filter(type => type.name.toLowerCase() !== 'pallet');
    }
    this.destinations = this.catalogApiService.allDestinationsCached;

    const dialogRef = this.matDialog.open(RackEditDialogComponent, {
      width: '640px',
      data: {
        rackRail: rail,
        parts: this.partsCatalog,
        boxTypes: this.boxTypes,
        destinations: this.destinations,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: async (modifiedRackRail: LocationDto) => {
        this.isLoading = true;
        if (modifiedRackRail !== undefined) {
          modifiedRackRail.suggestedParts = modifiedRackRail.suggestedParts.filter(part => part.partNumber !== '');
          await this.catalogApiService
            .mergeRackRails([modifiedRackRail])
            .then((response: DataMergeRespDto) => {
              this.dataMergeRespDto = response;
              if (response.errorRows === 0 && response.warnings === 0) {
                if (rail !== undefined) {
                  rail.name = modifiedRackRail.name;
                }
                this.toast.success(
                  this.translate.instant('catalog.racks.edit.rackUpdateSuccess', { name: modifiedRackRail.name })
                );
              } else {
                this.toast.error(
                  this.translate.instant('catalog.racks.edit.rackUpdateFail', { name: modifiedRackRail.name })
                );

                this.showMergeResultDialog();
              }
              this.updateRail.emit(rail.id);
            })
            .catch((error: BoxCarHttpErrorResponse) => {
              this.noticeDialog.show(...boxCarCoreErrorDetails(error));
            });
        }
        this.isLoading = false;
      },
    });
  }

  // When configure rail button is clicked.
  handleDetailedAllocationPress(rail: LocationDto): void {
    this.matDialog.open(RackDetailedAllocationDialogComponent, {
      width: '85vw',
      maxHeight: '90vh',
      data: {
        rackRail: rail,
        parts: this.partsCatalog,
        boxTypes: this.boxTypes,
        destinations: this.destinations,
      },
    });
  }

  showMergeResultDialog(): void {
    this.matDialog.open(MergeResultDialogComponent, {
      width: '100vh',
      data: {
        dataMergeRespDto: this.dataMergeRespDto,
      } as MergeResultDialogData,
    });
  }

  // * Overlay methods.
  onMouseEnter(trigger: CdkConnectedOverlay, id: number) {
    this.createOutcomeRackRailState(id);
    if (!this.isOneOpen) trigger.open = true;
  }

  onMouseOut(trigger: CdkConnectedOverlay) {
    if (!this.isOneOpen) trigger.open = false;
  }

  onRailClick(trigger: CdkConnectedOverlay) {
    if (!this.isOneOpen && this.openedTrigger === undefined) {
      this.isOneOpen = true;
      this.openedTrigger = trigger;
    }
  }

  closeBackdrop() {
    if (!this.mouseIsOverOverlay) {
      this.isOneOpen = false;
      if (this.openedTrigger) {
        this.openedTrigger.open = false;
        this.openedTrigger = undefined;
      }
    }
  }

  // Close backdrop only if mouse leave from backdrop and overlay.
  exitBackdrop() {
    this.mouseIsOverBackdrop = false;
    setTimeout(() => {
      this.closeBackdrop();
    }, 100);
  }

  // Close backdrop, if leave from overlay and backdrop.
  onExitOverlay() {
    this.mouseIsOverOverlay = false;
    setTimeout(() => {
      if (!this.mouseIsOverBackdrop) {
        this.closeBackdrop();
      }
    }, 100);
  }

  // * Dynamic allocation.
  createOutcomeRackRailState(railId: number) {
    let selectedRail: LocationDto = this.rackRails.find(rail => rail.id === railId)!;

    const rackRailStorages: string[] = [];
    if (selectedRail !== undefined) {
      selectedRail.boxesStorage.forEach(storage => {
        const position = rackRailStorages.findIndex(p => p === storage.partNumber);
        if (position === -1) {
          rackRailStorages.push(storage.partNumber);
        }
      });
    }
    this.storedPNs = [...rackRailStorages];
  }

  reduceBoxesStorage(storage: RailStorage[]): RailStorage[] {
    if (storage.length > this.boxesLimit) {
      return storage.slice(0, this.boxesLimit);
    }
    return storage;
  }

  findDescriptionByPartNumber(partNumber: string): string | undefined {
    return this.catalogApiService.allPartsCached.find(p => p.number === partNumber)?.description;
  }

  getQtyBoxPerPartNumber(partNumber: string, storage: RailStorage[]): number {
    return storage.filter(rail => rail.partNumber === partNumber).length;
  }

  colorFromIndex = (index: number): string => {
    const boxColorTable = ['blue', 'red', 'green', 'orange', 'lightblue'];

    const realIndex = index % boxColorTable.length;

    return boxColorTable[realIndex];
  };

  colorFromPartNumber(partNumber: string): string {
    return this.colorFromIndex(this.storedPNs.findIndex(x => x === partNumber));
  }

  rackRailClassFromOperationMode(operationMode: RailOperationMode) {
    switch (operationMode) {
      case RailOperationMode.dynamic:
        return 'dynamic';
      case RailOperationMode.fixed:
        return 'fixed';
      default:
        return '';
    }
  }
}
