<!--
File: src\app\racks\racks-data\racks-data.component.html
Project: boxcar-console
Created Date: 2022-02-18 11:02:20
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-02-18	JF	Rack catalog view. Initial version.
-->

<mat-card>
  <mat-card-title class="mt-2 mb-3">
    <!-- Panel title -->
    {{ 'catalog.racks.title' | translate }}
  </mat-card-title>

  <mat-card-content>
    <div class="wrapper">
      <div class="fields-wrapper">
        <!-- Dropdown menu for rack region selection -->
        <mat-form-field>
          <mat-label>{{ 'catalog.racks.dropdown' | translate }}</mat-label>
          <mat-select
            matNativeControl
            [disabled]="isLoading"
            [(ngModel)]="selectedRackRegion"
            (ngModelChange)="onChangeAnyFilter()">
            <mat-option *ngFor="let partGroup of rackRegions" [value]="partGroup">
              {{ partGroup.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="ml-4">
          <mat-label>{{ 'catalog.racks.selectType' | translate }}</mat-label>
          <mat-select
            matNativeControl
            [disabled]="isLoading"
            [(ngModel)]="selectedType"
            (ngModelChange)="onChangeAnyFilter()">
            <mat-option *ngFor="let type of allTypes" [value]="type.value">
              {{ type.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <!-- Import rack storage button -->
        <button mat-raised-button color="primary" class="ml-2" (click)="onExportRackStructure()" [disabled]="isLoading">
          {{ 'catalog.racks.exportButton' | translate }}
        </button>

        <!-- Triggers file input. -->
        <button
          mat-raised-button
          color="warn"
          class="ml-4 mb-1"
          (click)="showRacksImportDialog()"
          [disabled]="isLoading">
          {{ 'catalog.importStructure' | translate }}
        </button>

        <!-- Refresh button -->
        <button
          mat-flat-button
          color="primary"
          class="reload-button ml-4"
          (click)="refreshRails()"
          [disabled]="isLoading">
          <img src="../../../../assets/icons/refreshIcon.svg" color="white" width="24px" height="24px" alt="refresh" />
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div class="row geral-group" style="margin: 1rem auto">
  <div style="width: 100%">
    <!-- Rack rails table search -->
    <mat-form-field appearance="outline">
      <mat-label>{{ 'catalog.search' | translate }}</mat-label>
      <input
        matInput
        (keyup)="applyFilter()"
        placeholder="Ex. Prateleira E"
        [(ngModel)]="searchFilter"
        [disabled]="isLoading" />
    </mat-form-field>

    <!-- Rack rail's table -->
    <div class="mat-elevation-z2 loading-container">
      <!-- Loading indicator -->
      <loading-on-page [isLoading]="isLoading"></loading-on-page>

      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows aria-label="Rack rails data">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id.</th>
          <td mat-cell *matCellDef="let row">{{ row.id }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.racks.mode' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ getOperationTypeFromEnum(row.operationMode) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="region">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.racks.region' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.region }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.racks.name' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="maxBoxes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.racks.maxBoxes' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.maxBoxes }}</td>
        </ng-container>

        <ng-container matColumnDef="column">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.racks.column' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.column }}</td>
        </ng-container>

        <ng-container matColumnDef="level">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.racks.level' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.level }}</td>
        </ng-container>

        <ng-container matColumnDef="boxType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.racks.boxType' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.allowedContainerModels }}</td>
        </ng-container>

        <ng-container matColumnDef="partNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.racks.partNumber' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.suggestedParts[0] }}</td>
        </ng-container>

        <ng-container matColumnDef="inputSideTag">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.racks.inputSideTag' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.inputSideEpcs.join(', ') }}</td>
        </ng-container>

        <ng-container matColumnDef="outputSideTag">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.racks.outputSideTag' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.outputSideEpcs.join(', ') }}</td>
        </ng-container>

        <ng-container matColumnDef="pickingLocations">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.racks.pickingLocations' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.pickingLocations.toString() }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="displayedColumns.length">No data matching the filter "{{ searchFilter }}"</td>
        </tr>
      </table>

      <paginator-page-select
        [length]="dataSource.filteredData.length"
        [pageSizeOptions]="[10, 25, 100]"
        aria-label="Select page of users">
      </paginator-page-select>
    </div>
  </div>
</div>
