/*
 * File: \src\app\interceptor\check-authentication-token.interceptor.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Component refactored.
 */

import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';

@Injectable()
export class CheckAuthenticationTokenInterceptor implements HttpInterceptor {
  constructor(private jwtHelperService: JwtHelperService, private store: Store, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !request.url.startsWith('.') &&
      !request.url.startsWith('assets') &&
      request.url.indexOf('/login') === -1 &&
      this.jwtHelperService.isTokenExpired()
    ) {
      localStorage.clear();
      this.router.navigate(['/']);
      return EMPTY;
    }
    return next.handle(request);
  }
}
