import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { defaultTheme } from '@components/rack-viewer/themes/rack-viewer-themes';
import { Store } from '@ngrx/store';
import { DataCatalogApiService, RfidApiService } from '@services/api';
import { userData } from '@services/login/state/user-selectors';

import {
  BoxTypeDto,
  DataMergeRespDto,
  RegionDto,
  PartDto,
  LocationDto,
  RackRailType,
  RackRegionDto,
  RailOperationMode,
  RailStorage,
  RegionStrategy,
  UserTypes,
  LocationState,
} from 'src/app/models';
import { PickingDialogComponent } from './picking-dialog/picking-dialog.component';
import { StoringDialogComponent } from './storing-dialog/storing-dialog.component';
import { InDevelopmentDialogService } from '@services/dialog/in-development-dialog.service';
import { TotemDialogResponse } from 'src/app/models/totem';

@Component({
  selector: 'rack-totem-viewer',
  templateUrl: './rack-totem-viewer.component.html',
  styleUrls: ['./rack-totem-viewer.component.scss'],
})
export class RackTotemViewerComponent implements OnInit, OnChanges {
  locationState = LocationState;

  // For overlay.
  isOneOpen: boolean = false;
  openedTrigger!: CdkConnectedOverlay | undefined;
  mouseIsOverOverlay: boolean = false;
  mouseIsOverBackdrop: boolean = false;

  // For data manipulation.
  rackRailLevels: LocationDto[][] = [];
  columns: number[] = [];
  boxTypes: BoxTypeDto[] = [];
  destinations: RegionDto[] = [];
  partsCatalog: PartDto[] = [];
  userRoles: string[] = [];
  types = UserTypes;
  storedPNs: string[] = [];
  localRackRails: LocationDto[] = [];
  dataMergeRespDto: DataMergeRespDto | undefined;
  floor?: LocationDto;
  boxesLimit: number = 25;
  isLoading: boolean = false;

  @Input() rackRails: LocationDto[] = [];
  @Input() totalCols: number = 0;
  @Input() totalLevels: number = 0;
  @Input() region: RackRegionDto = {
    id: 0,
    name: '',
    prefix: '',
    columns: 0,
    levels: 0,
    regionStrategy: 0,
    regionStorage: 1,
  };
  @Output() updateRail = new EventEmitter();
  @Output() updateInstructions = new EventEmitter();

  constructor(
    private catalogApiService: DataCatalogApiService,
    private store: Store,
    private dialogService: MatDialog,
    private rfidApiService: RfidApiService,
    private inDevService: InDevelopmentDialogService
  ) {
    this.store.select(userData).subscribe({
      next: userData => {
        if (userData) {
          this.userRoles = [...userData.roles];
        }
      },
    });
  }

  ngOnInit(): void {
    return;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetData();
    if (this.rackRails.length !== 0) {
      this.localRackRails = [...this.rackRails.filter(rack => rack.level !== 0 && rack.column !== 0)];

      const haveFloor: boolean = this.rackRails.filter(rack => rack.level === 0 && rack.column === 0).length > 0;
      if (haveFloor) {
        this.floor = { ...this.rackRails.filter(rack => rack.level === 0 && rack.column === 0)[0] };
      } else {
        this.floor = undefined;
      }

      // Create an array of number to columns.
      this.columns = this.columns.concat([...Array(this.totalCols + 1).keys()]);
      this.splitRailsLevels();
    }
  }

  // Manipulation data.
  private resetData() {
    this.rackRailLevels = [];
    this.columns = [];
    this.floor = undefined;
    this.storedPNs = [];
    this.localRackRails = [];
  }

  splitRailsLevels() {
    const rackRails = [...this.localRackRails];

    for (let i = 0; i < this.totalLevels; i++) {
      this.rackRailLevels.push(rackRails.splice(0, this.totalCols));
    }
  }

  colorForRackRail(rail: LocationDto) {
    const opacity = this.opacityForRackRail(rail);
    let color: string = '';

    if (rail.state !== LocationState.ACTIVE) {
      color = defaultTheme.itemInactiveColor;
    } else if (rail.railType === RackRailType.reverse) {
      color = defaultTheme.itemReturningColor;
    } else if (rail.suggestedParts.length === 0) {
      color = defaultTheme.itemWithoutPartColor;
    } else {
      color = defaultTheme.itemNominalColor;
    }

    color = color.replace('rgb', 'rgba');
    color = color.replace(')', `, ${opacity})`);

    return color;
  }

  opacityForRackRail(rail: LocationDto) {
    const maxBoxes = rail.maxBoxes > 0 ? rail.maxBoxes : 10;
    return 0.55 + 0.45 * (rail.storedBoxes / maxBoxes);
  }

  partDescriptionFromNumber(partNumber: string | undefined): string | undefined {
    return this.catalogApiService.allPartsCached.find(p => p.number == partNumber)?.description;
  }

  isStoringAvailable(rail: LocationDto) {
    return rail.state === LocationState.ACTIVE && rail.railType !== RackRailType.reverse;
  }

  isPickingAvailable(rail: LocationDto) {
    return rail.storedBoxes !== 0;
  }

  openPickingDialog(rail: LocationDto) {
    this.partsCatalog = this.catalogApiService.allPartsCached;
    this.boxTypes = this.rfidApiService.allBoxTypesCached.filter(
      boxType => boxType.name.toLowerCase() !== 'virtual' && boxType.name.toLowerCase() !== '__null__'
    );

    if (this.region.regionStrategy !== RegionStrategy.jit) {
      this.boxTypes = this.boxTypes.filter(type => type.name.toLowerCase() !== 'pallet');
    }
    this.destinations = this.catalogApiService.allDestinationsCached;

    const dialog = this.dialogService.open(PickingDialogComponent, {
      width: '640px',
      data: {
        rackRail: rail,
        parts: this.partsCatalog,
        region: this.region,
      },
      disableClose: true,
    });

    dialog
      .afterClosed()
      .toPromise()
      .then((dialogResponse: { response: TotemDialogResponse; concludedRail?: LocationDto }) => {
        if (dialogResponse.response === TotemDialogResponse.created) {
          this.updateInstructions.emit();
        } else if (dialogResponse.response === TotemDialogResponse.concluded) {
          this.updateRail.emit(rail.id);
          if (dialogResponse.concludedRail) {
            this.updateRail.emit(dialogResponse.concludedRail.id);
          }
        }
      });
  }

  openStoringDialog(rail: LocationDto) {
    const dialog = this.dialogService.open(StoringDialogComponent, {
      width: '640px',
      data: { selectedRail: rail, allRackRails: this.localRackRails },
      disableClose: true,
    });

    dialog
      .afterClosed()
      .toPromise()
      .then((dialogResponse: { response: TotemDialogResponse; concludedRail?: LocationDto }) => {
        if (dialogResponse.response === TotemDialogResponse.concluded) {
          if (dialogResponse.concludedRail) {
            this.updateRail.emit(dialogResponse.concludedRail.id);
          }
        } else if (dialogResponse.response === TotemDialogResponse.created) {
          this.updateInstructions.emit();
        }
      });
  }

  // * Overlay methods.
  onMouseEnter(trigger: CdkConnectedOverlay, id: number) {
    this.createOutcomeRackRailState(id);
    if (!this.isOneOpen) trigger.open = true;
  }

  onMouseOut(trigger: CdkConnectedOverlay) {
    if (!this.isOneOpen) trigger.open = false;
  }

  onRailClick(trigger: CdkConnectedOverlay) {
    if (!this.isOneOpen && this.openedTrigger === undefined) {
      this.isOneOpen = true;
      this.openedTrigger = trigger;
    }
  }

  closeBackdrop() {
    if (!this.mouseIsOverOverlay) {
      this.isOneOpen = false;
      if (this.openedTrigger) {
        this.openedTrigger.open = false;
        this.openedTrigger = undefined;
      }
    }
  }

  // Close backdrop only if mouse leave from backdrop and overlay.
  exitBackdrop() {
    this.mouseIsOverBackdrop = false;
    setTimeout(() => {
      this.closeBackdrop();
    }, 100);
  }

  // Close backdrop, if leave from overlay and backdrop.
  onExitOverlay() {
    this.mouseIsOverOverlay = false;
    setTimeout(() => {
      if (!this.mouseIsOverBackdrop) {
        this.closeBackdrop();
      }
    }, 100);
  }

  // * Dynamic allocation.
  createOutcomeRackRailState(railId: number) {
    let selectedRail: LocationDto = this.rackRails.find(rail => rail.id === railId)!;

    const rackRailStorages: string[] = [];
    if (selectedRail !== undefined) {
      selectedRail.boxesStorage.forEach(storage => {
        const position = rackRailStorages.findIndex(p => p === storage.partNumber);
        if (position === -1) {
          rackRailStorages.push(storage.partNumber);
        }
      });
    }
    this.storedPNs = [...rackRailStorages];
  }

  reduceBoxesStorage(storage: RailStorage[]): RailStorage[] {
    if (storage.length > this.boxesLimit) {
      return storage.slice(0, this.boxesLimit);
    }
    return storage;
  }

  findDescriptionByPartNumber(partNumber: string): string | undefined {
    return this.catalogApiService.allPartsCached.find(p => p.number === partNumber)?.description;
  }

  colorFromIndex = (index: number): string => {
    const boxColorTable = ['blue', 'red', 'green', 'orange', 'lightblue'];

    const realIndex = index % boxColorTable.length;

    return boxColorTable[realIndex];
  };

  colorFromPartNumber(partNumber: string): string {
    return this.colorFromIndex(this.storedPNs.findIndex(x => x === partNumber));
  }

  rackRailClassFromOperationMode(operationMode: RailOperationMode) {
    switch (operationMode) {
      case RailOperationMode.dynamic:
        return 'dynamic';
      case RailOperationMode.fixed:
        return 'fixed';
      default:
        return '';
    }
  }
}
