<!--
File: \src\app\pages\parts\import\parts.preview.dialog.html
Project: boxcar-console
Created Date: 2022-05-17 08:38:45
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-05-17	JF	Parts preview dialog template
-->

<h1 mat-dialog-title>{{'catalog.review.title' | translate}}</h1>

<div mat-dialog-content>
  <p>{{'catalog.review.checkData' | translate}}</p>

  <!-- File contents preview -->
  <table class="table">
    <caption style="display: none">
      Import part info preview
    </caption>
    <thead>
      <tr>
        <th>{{'catalog.parts.partnumber' | translate}}</th>
        <th>{{'catalog.parts.externalCode' | translate}}</th>
        <th>{{'catalog.parts.description' | translate}}</th>
        <th>{{'catalog.parts.qtyPerBox' | translate}}</th>
        <th>{{'catalog.parts.boxType' | translate}}</th>
        <th>{{'catalog.parts.group' | translate}}</th>
        <th>{{'catalog.parts.suggestedDestination' | translate}}</th>
        <th>{{'catalog.parts.storage' | translate}}</th>
        <th>{{'catalog.parts.maxPickingInstructions' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let part of data.partsCatalogRows | slice:0:10">
        <td>{{ part.number }}</td>
        <td>{{ part.externalCode }}</td>
        <td>{{ part.description }}</td>
        <td class="text-right">{{ part.quantityPerBox }}</td>
        <td>{{ part.containerModel }}</td>
        <td>{{ part.group }}</td>
        <td>{{ part.suggestedDeliveryLocation }}</td>
        <td>{{ getStringRegionType(part.partStrategy) }}</td>
        <td>{{ part.maxPickingInstructions }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="data">
    {{'catalog.review.sendButton' | translate}}
  </button>
  <button mat-button (click)="onCloseDialog()">{{'catalog.review.cancelButton' | translate}}</button>
</div>
