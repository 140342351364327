import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RfidAllotmentRequestDto, RfidManufacturerDto } from 'src/app/models/tags';
import { RfidApiService } from '@services/api/rfid.api.service';
import { LoadingIndicatorService } from '@services/loading/loading.service';

@Component({
  selector: 'boxcar-console-add-rfid-tags',
  templateUrl: './add-rfid-tags.component.html',
  styleUrls: ['./add-rfid-tags.component.scss'],
})
export class AddRfidTagsComponent implements OnInit {
  form!: FormGroup;
  rfidManufacturers: RfidManufacturerDto[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private apiService: RfidApiService,
    private snackBar: MatSnackBar,
    private loadingIndicator: LoadingIndicatorService
  ) {}

  ngOnInit(): void {
    this.apiService.getRfidManufacturers().then(
      // Remove paper and virtual from manufacturers.
      manufacturers =>
        (this.rfidManufacturers = manufacturers.filter(
          manufacture =>
            manufacture.manufacturerName.toLowerCase() !== 'paper' &&
            manufacture.manufacturerName.toLowerCase() !== 'virtual'
        ))
    );

    this.form = this.formBuilder.group({
      manufacturerId: new FormControl('', [Validators.required]),
      batchNumber: new FormControl('', [Validators.required]),
      startEPC: new FormControl('', [Validators.required]),
      quantity: new FormControl('', [Validators.required]),
    });
  }

  async onSubmit() {
    this.loadingIndicator.show();
    try {
      const request: RfidAllotmentRequestDto = {
        manufacturerId: Number(this.form.get('manufacturerId')?.value),
        batchNumber: this.form.get('batchNumber')?.value,
        startEPC: this.form.get('startEPC')?.value,
        quantity: this.form.get('quantity')?.value,
        fIsPaper: false,
      };

      await this.apiService.createRfidAllotment(request);

      this.snackBar.open('Lote de etiquetas cadastrado', 'Fechar', {
        duration: 2000,
      });

      this.resetFormFields();
    } catch (error: any) {
      const message = error.error.message ? error.error.message : error.error.title ? error.error.title : error.message;
      await this.snackBar.open(message, 'Fechar', {
        duration: 3000,
      });
    }
    this.loadingIndicator.hide();
  }

  private resetFormFields() {
    this.form.get('startEPC')?.reset();
    this.form.get('manufacturerId')?.reset();
    this.form.get('batchNumber')?.reset();
    this.form.get('quantity')?.reset();

    this.form.get('startEPC')?.markAsPending();
    this.form.get('manufacturerId')?.markAsPending();
    this.form.get('batchNumber')?.markAsPending();
    this.form.get('quantity')?.markAsPending();
  }
}
