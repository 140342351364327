import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PackagingApiService } from '@services/api';
import { AppToastService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { ContainerDto, DataPsmmTagContingency, LocationDto, RfidTagInfoDto } from 'src/app/models';
import { CheckPsmmInfoComponent as AssociateDialogComponent } from './associate-dialog/associate-dialog.component';
import { CreateInstructionDialogComponent } from './create-instruction-dialog/create-instruction-dialog.component';
import { InstructionManagementDialogService } from '@services/instructions/instruction-management-dialog.service';
import { TotemDialogResponse } from 'src/app/models/totem';

@Component({
  selector: 'boxcar-console-storing-dialog',
  templateUrl: './storing-dialog.component.html',
  styleUrls: ['./storing-dialog.component.scss'],
})
export class StoringDialogComponent implements OnInit {
  psmmTagsControls: FormArray = new FormArray([new FormControl('', [Validators.required])] as FormControl[]);
  isCheckingInformation: boolean = false;
  containerStoredData!: ContainerDto;
  rfidStoredData!: RfidTagInfoDto;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { selectedRail: LocationDto; allRackRails: LocationDto[] },
    private boxApi: PackagingApiService,
    private loadingService: LoadingIndicatorService,
    private toastService: AppToastService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<StoringDialogComponent>,
    private packagingService: PackagingApiService,
    private matDialog: MatDialog,
    private concludeInstructionService: InstructionManagementDialogService
  ) {}

  ngOnInit(): void {
    return;
  }

  getControlFromIndex(idx: number): FormControl {
    return this.psmmTagsControls.at(idx) as FormControl;
  }

  addNewTag() {
    this.psmmTagsControls.push(new FormControl('', [Validators.required]));
  }

  removeTagFromIndex(idx: number) {
    this.psmmTagsControls.removeAt(idx);
  }

  private getContainerData(rfid: RfidTagInfoDto) {
    return this.packagingService.getPackageFromRFID(rfid.barcode).toPromise();
  }

  private async openConcludeInstructionDialog(instructionId: number) {
    if (this.rfidStoredData) {
      this.loadingService.show();
      await this.getContainerData(this.rfidStoredData).then(response => {
        this.containerStoredData = response;
        this.loadingService.hide();
      });
      const dialog = this.concludeInstructionService.openConcludeInstructionDialog(
        this.containerStoredData.instructions![0]
      );

      dialog
        .afterClosed()
        .toPromise()
        .then((value: { isCompleted: boolean; concludedRail: LocationDto }) => {
          if (value.isCompleted) {
            this.dialogRef.close({ response: TotemDialogResponse.concluded, concludedRail: value.concludedRail });
          } else {
            this.dialogRef.close({ response: TotemDialogResponse.created });
          }
        });
    } else {
      this.toastService.error(this.translate.instant('toast.errors.totemServices.invalidInstructionInfo'));
    }
  }

  private openContinueFlowDialog(instructionId: number) {
    const dialog = this.concludeInstructionService.openContinueFlowDialog();

    dialog
      .afterClosed()
      .toPromise()
      .then((dialogResponse: { continueFlow: boolean }) => {
        if (dialogResponse.continueFlow) {
          this.openConcludeInstructionDialog(instructionId);
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.created });
        }
      });
  }

  private openCreateInstructionDialog() {
    const dialog = this.matDialog.open(CreateInstructionDialogComponent, {
      data: {
        rail: this.data.selectedRail,
        regionRackRails: this.data.allRackRails,
        container: this.containerStoredData,
      },
      width: '640px',
    });

    dialog
      .afterClosed()
      .toPromise()
      .then((dialogResponse: undefined | { instructionId: number }) => {
        if (dialogResponse) {
          this.openContinueFlowDialog(dialogResponse.instructionId);
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.none });
        }
      });
  }

  private openAssociateDialog(radInfos: DataPsmmTagContingency[]) {
    const dialog = this.matDialog.open(AssociateDialogComponent, {
      data: { rail: this.data.selectedRail, radData: radInfos },
      width: '640px',
    });

    dialog
      .afterClosed()
      .toPromise()
      .then(async (dialogResponse: undefined | { containerLifeCycleId: number; rfid: RfidTagInfoDto }) => {
        if (dialogResponse) {
          this.rfidStoredData = { ...dialogResponse.rfid };

          if (this.rfidStoredData) {
            this.loadingService.show();
            this.containerStoredData = await this.getContainerData(this.rfidStoredData);
            this.loadingService.hide();
          }

          this.openCreateInstructionDialog();
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.none });
        }
      });
  }

  private async verifyBoxes() {
    let hasInconsistency = false;

    this.loadingService.show();
    for (const control of this.psmmTagsControls.controls) {
      await this.packagingService.searchBoxes(1, 2, undefined, undefined, control.value).then(response => {
        if (response.containerQuantity > 0) {
          control.setErrors({ tagUnavailable: true });
          hasInconsistency = true;
        }
      });
    }

    return hasInconsistency;
  }

  private async getRadInfos() {
    const psmmTagsResponse: DataPsmmTagContingency[] = [];
    let hasInconsistency = false;

    for (const control of this.psmmTagsControls.controls) {
      if (control.value) {
        await this.boxApi
          .verifyPSMMTag(control.value)
          .toPromise()
          .then(response => {
            psmmTagsResponse.push({
              tag: control.value,
              radResponse: response,
            });
          })
          .catch(err => {
            if (err.error.code !== 3010 && err.error.code !== 3001) {
              psmmTagsResponse.push({
                tag: control.value,
                radResponse: null,
              });
            } else {
              switch (err.error.code) {
                case 3010:
                  this.toastService.error(this.translate.instant('toast.errors.totemServices.psmmTagAlreadyRead'));
                  break;
                // ERROR: Don't find on system.
                case 3001:
                  this.toastService.error(this.translate.instant('toast.errors.totemServices.tagNotFound'));
                  break;
              }
              this.dialogRef.close({ response: TotemDialogResponse.none });
              hasInconsistency = true;
            }
          });
        if (hasInconsistency) {
          return null;
        }
      }
    }
    this.loadingService.hide();

    return psmmTagsResponse;
  }

  // Verify all PSMM Tags entered by user, and if everything is ok, continue the storing flow.
  async verifyPSMMTags() {
    const hasInconsistency = await this.verifyBoxes();

    if (hasInconsistency) {
      this.toastService.error(this.translate.instant('toast.errors.totemServices.psmmTagsInconsistency'));
    } else {
      const radInfo = await this.getRadInfos();

      if (radInfo !== null) {
        this.openAssociateDialog(radInfo);
      }
    }

    this.loadingService.hide();
  }

  onCancel() {
    this.dialogRef.close(TotemDialogResponse.none);
  }
}
