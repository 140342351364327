import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'boxcar-console-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
  title: string = '';
  description: string = '';

  constructor(
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      description: string;
      errorsList?: string[];
    }
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.description = this.data.description;
  }
}
