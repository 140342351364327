<div class="box-card">
  <mat-card class="box-mat-card mat-elevation-z0">
    <div class="box-card-header">
      <!-- Header for standard boxes -->
      <div *ngIf="box.container.containerModel.toLowerCase() !== 'virtual'">
        <span *ngIf="isJITContainer()" [innerHTML]="getFormatedJITLabel(box.container.activeBarcode)"></span>
        <span *ngIf="!isJITContainer()">
          RFID <strong>{{ box.container.activeBarcode ?? box.container.barcodes![0] }}</strong>
        </span>
        <mat-card-subtitle>{{ 'boxes.type' | translate }} {{ box.container.containerModel }}</mat-card-subtitle>
      </div>
      <!-- Header for Virtual Boxes -->
      <div *ngIf="box.container.containerModel.toLowerCase() === 'virtual'">
        {{ 'boxes.type' | translate }} <strong>{{ box.container.containerModel }}</strong>
        <mat-card-subtitle>{{ 'boxes.directSent' | translate }}</mat-card-subtitle>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
        <img src="../../../assets/icons/moreVertIcon.svg" width="24px" height="24px" alt="menu more options icon" />
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openRfidTagDetail()">
          <span>{{ 'boxes.seeDetails' | translate }}</span>
        </button>
      </mat-menu>
    </div>

    <!-- Cards for boxes. -->
    <div class="card-content">
      <!-- Empty box -->
      <div
        *ngIf="
          box.boxAssemblies.length <= 0 ||
          (box.boxAssemblies.length === 1 && box.boxAssemblies[0].labelDtos.length <= 0)
        ">
        <div class="empty-barcode-list">
          {{ 'boxes.noPSMMLabel' | translate }}
        </div>
        <mat-card-content>
          <p>
            <strong>{{ 'boxes.contentInfo' | translate }}</strong>
          </p>
          <span> {{ 'boxes.partNumber' | translate }} <span style="color: #3880ff"> - </span> </span>
          <br />
          <span>{{ 'boxes.source' | translate }} - </span>
          <br />
          <span>{{ 'boxes.totalQuantity' | translate }} 0 </span>
        </mat-card-content>
      </div>

      <!-- Bar codes for standard boxes -->
      <div *ngFor="let assembly of box.boxAssemblies">
        <div
          class="barcodes-list"
          *ngIf="assembly.labelDtos !== undefined && assembly.labelDtos !== null && assembly.labelDtos.length > 0">
          <mat-card
            class="barcode-card"
            *ngFor="let psmmTag of assembly.labelDtos"
            (click)="
              openPsmmTagDetail(
                psmmTag,
                assembly.boxAssemblyInfoResponseDto.partNumber +
                  ' - ' +
                  assembly.boxAssemblyInfoResponseDto.partDescription,
                assembly.boxAssemblyInfoResponseDto.manufactureSource +
                  ' - ' +
                  assembly.boxAssemblyInfoResponseDto.manufactureSourceDescription
              )
            ">
            <img src="assets/images/barcode.svg" alt="" />

            <ng-container *ngIf="psmmTag.labelcode; else genericLabel">
              {{ 'boxes.barCode' | translate }} <strong>{{ psmmTag.labelcode }}</strong>
            </ng-container>

            <ng-template #genericLabel>
              <strong>{{ 'boxes.genericLabel' | translate }}</strong>
            </ng-template>
          </mat-card>
        </div>

        <mat-card-content *ngIf="assembly.labelDtos.length > 0">
          <p>
            <strong>{{ 'boxes.contentInfo' | translate }}</strong>
          </p>
          <span
            >{{ 'boxes.partNumber' | translate
            }}<span style="color: #3880ff">
              {{ assembly.boxAssemblyInfoResponseDto.partNumber }} -
              {{ assembly.boxAssemblyInfoResponseDto.partDescription }}</span
            ></span
          ><br />
          <span
            >{{ 'boxes.source' | translate }} {{ assembly.boxAssemblyInfoResponseDto.manufactureSource }} -
            {{ assembly.boxAssemblyInfoResponseDto.manufactureSourceDescription }} </span
          ><br />
          <span *ngIf="assembly.labelDtos !== undefined"
            >{{ 'boxes.totalQuantity' | translate }} {{ total(assembly.labelDtos) }}</span
          >
        </mat-card-content>
      </div>
      <div class="box-card-footer">
        <span style="display: flex; gap: 5px">
          {{ 'boxes.state' | translate }}
          <strong *ngIf="box.container.entityState !== undefined && boxStateMetadata">{{
            boxStateMetadata[box.container.entityState]?.namePT ?? box.container.entityState
          }}</strong>
          <mat-spinner *ngIf="!boxStateMetadata" [diameter]="15">{{ tryagain() }}</mat-spinner>
        </span>
        <p>{{ 'boxes.inCharge' | translate }} {{ box.container.teamLeader }}</p>
      </div>
    </div>
  </mat-card>
</div>
