/*
 * File: \src\app\services\login\state\user.reducer.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-27	JF	Code reviewed.
 */

import { createReducer, on } from '@ngrx/store';
import * as userActions from './user.actions';
import { LoginStatus, UserState } from './user.state';

/**
 * Initial state of User Module
 */
const initialState = {
  status: LoginStatus.notLogged,
  userData: {},
} as UserState;

/**
 * User reducer
 */
export const userReducer = createReducer(
  initialState,

  on(userActions.loginSucceeded, (state, payload) =>
    ({ ...state, userData: payload.userData, status: LoginStatus.logged })),

  on(userActions.loginFailed, (state) => ({
    ...state, userData: undefined, status: LoginStatus.notLogged
  })),

  on(userActions.logout, (state) => ({
    ...state, userData: undefined, status: LoginStatus.notLogged
  })),
);
