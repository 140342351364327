import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxAssembly } from 'src/app/models';

@Component({
  selector: 'boxcar-console-pallet-tab-detail.dialog',
  templateUrl: './pallet-tab-detail.dialog.component.html',
  styleUrls: ['./pallet-tab-detail.dialog.component.scss'],
})
export class PalletTabDetailDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: BoxAssembly) {}

  ngOnInit(): void {
    return;
  }

  total(labelDtos: any[]) {
    let total = 0;
    if (labelDtos != null && labelDtos.length > 0) {
      labelDtos.forEach(label => {
        total += label.quantity;
      });
    }
    return total;
  }
}
