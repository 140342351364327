export type TotemGenerateRequestInfos = {
  instructionType: number;
  containerLifeCycleId: number;
  origin: string | null;
  originColumn: number | null;
  originLevel: number | null;
  destination: string | null;
  destinationLevel: number | null;
  destinationColumn: number | null;
};

export type TotemAssociateResponse = {
  containerLifeCycleId: number;
};

export enum TotemDialogResponse {
  none = 'Dialog canceled',
  created = 'Instruction created',
  concluded = 'Instruction concluded',
}
