<h1 mat-dialog-title>{{ 'totemServices.storing.createInstructionTitle' | translate }}</h1>

<h4>{{ 'totemServices.storing.createInstructionDescription' | translate }}</h4>

<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>
      {{ 'common.address' | translate }}
    </mat-label>

    <mat-select [formControl]="getControlFromForm('destinationCtrl')">
      <mat-option value="selected"> {{ 'common.selectedAddress' | translate }}: ({{ data.rail.name }}) </mat-option>

      <mat-option value="auto">
        {{ 'common.autoAddress' | translate }}
      </mat-option>

      <mat-option value="custom">
        {{ 'common.selectCustomAddress' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="isCustom">
    <h4>
      {{ 'totemServices.storing.customAddressInstructionCreation' | translate }}
    </h4>

    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'common.address' | translate }}
      </mat-label>

      <input matInput [matAutocomplete]="autoDestination" [formControl]="getControlFromForm('customDestinationCtrl')" />

      <mat-autocomplete #autoDestination="matAutocomplete">
        <mat-option *ngFor="let rail of filteredRails" [value]="rail.name">
          {{ rail.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="createInstruction()">
    {{ 'common.buttons.instruction.create' | translate }}
  </button>
  <button mat-button color="warn" mat-dialog-close>
    {{ 'common.buttons.cancel' | translate }}
  </button>
</div>
