import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataCatalogApiService, InstructionApiService } from '@services/api';
import { AppToastService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { MetadataService } from '@services/metadata/metadata.service';
import {
  Instruction,
  InstructionConcludeRequestBody,
  InstructionState,
  InstructionType,
  LocationDto,
  LocationSimplifiedDto,
  RackRailType,
} from 'src/app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'boxcar-console-conclude-created-instruction',
  templateUrl: './conclude-created-instruction.component.html',
  styleUrls: ['./conclude-created-instruction.component.scss'],
})
export class ConcludeCreatedInstructionComponent implements OnInit {
  addressCtrl: FormControl;
  allAddresses: LocationSimplifiedDto[] = [];
  filteredAddresses: LocationSimplifiedDto[] = [];
  addressSub!: Subscription;
  selectedRail?: LocationDto;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { instruction: Instruction; state: InstructionState },
    private dialogRef: MatDialogRef<ConcludeCreatedInstructionComponent>,
    private catalogApi: DataCatalogApiService,
    private loadingIndicator: LoadingIndicatorService,
    private instructionApi: InstructionApiService,
    private toastService: AppToastService,
    private translate: TranslateService,
    private metadataService: MetadataService
  ) {
    this.addressCtrl = new FormControl('', [Validators.required, this.isValidOption()]);
    this.setInitialValueOfCtrl();
  }

  async ngOnInit(): Promise<void> {
    await this.getAddresses();
    this.setSubscriptionForDestinationCtrl();
  }

  private setInitialValueOfCtrl() {
    if (this.data.instruction.instructionType !== InstructionType.picking) {
      this.addressCtrl.setValue(this.data.instruction.destination);
    } else {
      this.addressCtrl.setValue(this.data.instruction.origin);
    }
  }

  private isValidOption(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.filteredAddresses?.find(rail => rail.name.trim().toLowerCase() === control.value.trim().toLowerCase())) {
        return null;
      }
      return { invalidData: true };
    };
  }

  private async getAddresses() {
    if (!this.loadingIndicator.isOpen()) {
      this.loadingIndicator.show();
    }

    this.allAddresses = await this.getAllRackRails();

    this.filteredAddresses = [...this.allAddresses];

    this.loadingIndicator.hide();
  }

  private getAllRackRails() {
    return this.catalogApi.getSimplifiedLocationsData().toPromise();
  }

  private setSubscriptionForDestinationCtrl() {
    this.addressSub = this.addressCtrl.valueChanges.subscribe({
      next: (value: string) => {
        this.filteredAddresses = [
          ...this.allAddresses.filter(destination => destination.name.toLowerCase().includes(value.toLowerCase())),
        ];
      },
    });
  }

  private async getConcludeRequestBasedOnInstructionType(): Promise<InstructionConcludeRequestBody> {
    const genericRequest: InstructionConcludeRequestBody = {
      containerEpc: this.data.instruction.containerEpcs[0] ?? this.data.instruction.activeBarcode,
      wasAutomatic: false,
    };

    const railId = this.allAddresses.find(value => value.name === this.addressCtrl.value)!.id;
    this.selectedRail = await this.catalogApi.getRackRail(railId);

    switch (this.data.instruction.instructionType) {
      case InstructionType.reallocation:
        var originLocationEpc = this.data.instruction.origin.toLocaleUpperCase().includes('CHAO') ? 'N/A' : this.data.instruction.originEpcs[0];
        var destinationLocationEpc = this.selectedRail.name.toLocaleUpperCase().includes('CHAO') ? 'N/A' : this.selectedRail.inputSideEpcs![0];

        return {
          ...genericRequest,
          destinationLocationEpc,
          originLocationEpc,
        };
      case InstructionType.picking:
        return { ...genericRequest, originLocationEpc: this.selectedRail.outputSideEpcs![0] };
      case InstructionType.storing:
        var destinationLocationEpc = this.selectedRail.name.toLocaleUpperCase().includes('CHAO') ? 'N/A' : this.selectedRail.inputSideEpcs![0]

        return { ...genericRequest, destinationLocationEpc };
      default:
        return genericRequest;
    }
  }

  async concludeInstruction() {
    this.loadingIndicator.show();
    try {
      if (this.data.instruction.instructionState === InstructionState.notStarted) {
        await this.instructionApi.pickInstruction(this.data.instruction.instructionId).toPromise();
      }

      const concludeBodyRequest = await this.getConcludeRequestBasedOnInstructionType();

      await this.instructionApi
        .concludeInstruction(this.data.instruction.instructionId, concludeBodyRequest)
        .toPromise();

      this.toastService.success(this.translate.instant('toast.success.totemServices.instructionConclude'));
      this.loadingIndicator.hide();
      this.dialogRef.close({ isCompleted: true, concludedRail: this.selectedRail });
    } catch (e: any) {
      if (this.metadataService.errorMetadata[e.error.code]) {
        this.toastService.error(this.metadataService.getErrorDescriptionFromErrorCode(e.error.code));
      } else {
        this.toastService.error(
          this.translate.instant('toast.errors.totemServices.errorToConclude', { errorCode: e.error.code })
        );
      }

      this.loadingIndicator.hide();
      this.dialogRef.close({ isCompleted: false, concludedRail: this.selectedRail });
    }
  }
}
