/*
 * File: src\app\racks\racks.component.ts
 * Project: boxcar-console
 * Created Date: 2022-02-18 13:02:17
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-02-18	JF	Initial version
 * 2022-05-18	JF	Added import of epc data from CSV file.
 * 2022-10-13 VR  Refactoring rack pages.
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { RackStructureComponent } from './racks-structure/racks-structure.component';

@Component({
  selector: 'boxcar-console-racks',
  templateUrl: './racks.component.html',
  styleUrls: ['./racks.component.scss'],
})
export class RacksComponent implements OnInit {
  @ViewChild('rackStructure') rackStructure!: RackStructureComponent;
  constructor() {
    return;
  }

  ngOnInit(): void {
    return;
  }

  refreshTabs(event: MatTabChangeEvent) {
    if (event.index === 1) {
      this.rackStructure.getRackData();
    }
  }
}
