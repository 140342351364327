/*
 * File: \src\app\pages\racks\racks-contents\ract-quantity-dialog\rack-quantity.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-09-06 11:25:28
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  PartDto,
  RackQuantityDialogData,
  RackRailStateDto,
  RackRailStorageDto,
  RegionStrategy,
  StorageType,
} from 'src/app/models';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// The application allows rack rail individual edition via modal dialog on
// the rack grid view.

@Component({
  selector: 'rack-quantity-dialog',
  templateUrl: 'rack-quantity.dialog.html',
})
export class RackQuantityDialogComponent implements OnInit, OnDestroy {
  rackRailStorage: RackRailStorageDto[] = [];
  partNumberFormArray = new FormArray([new FormControl('', Validators.required)]);
  boxQuantityFormArray = new FormArray([new FormControl(0, [Validators.required, Validators.min(0)])]);
  filteredOptions: Observable<PartDto[]>[] = [];
  partNumbers: string[] = [];
  rackRailStateDto: Partial<RackRailStateDto> = {};
  rackRailIsJIT: boolean;
  isFloor: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RackQuantityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RackQuantityDialogData
  ) {
    if (this.data.rackRail.column === 0 && this.data.rackRail.level === 0) {
      this.isFloor = true;
    }

    this.subscribeChangesOnPartNumbers();
    this.setControlsInitialState();
    this.rackRailIsJIT = this.data.region.regionStrategy === RegionStrategy.jit;
  }

  ngOnInit(): void {
    return;
  }

  ngOnDestroy(): void {
    this.createOutcomeRackRailState();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private subscribeChangesOnPartNumbers() {
    this.partNumberFormArray.valueChanges.subscribe(p => (this.partNumbers = p));
  }

  private createOutcomeRackRailState() {
    this.rackRailStateDto.id = this.data.rackRail.id;
    this.rackRailStateDto.regionPrefix = this.data.rackRail.regionPrefix;
    this.rackRailStateDto.name = this.data.rackRail.name;
    this.rackRailStateDto.level = this.data.rackRail.level;
    this.rackRailStateDto.column = this.data.rackRail.column;

    this.rackRailStateDto.storage = [];

    for (let i = 0; i < this.partNumberFormArray.length; i++) {
      const partNumberAndDescription = this.partNumberFormArray.at(i).value;
      const partNumberOnly =
        partNumberAndDescription.indexOf(' - ') !== -1
          ? partNumberAndDescription.split(' - ')[0].trim()
          : partNumberAndDescription;

      this.rackRailStateDto.storage.push({
        partNumber: partNumberOnly,
        storedBoxes: this.boxQuantityFormArray.at(i).value,
        order: i + 1,
      } as RackRailStorageDto);
    }
  }

  private setSuggestedPartOnFirstControl() {
    const mainPartNumber = this.data.rackRail.suggestedParts[0].partNumber;
    const suggestedPartValue = `${mainPartNumber} - ${this.findDescriptionByPartNumber(mainPartNumber)}`;

    this.partNumberFormArray.controls[0].setValue(suggestedPartValue);
  }

  private setControlsInitialState() {
    this.setPartNumberControlFilter(0);

    if (this.data.rackRail.boxesStorage.length > 0) {
      this.sortBoxesByDepth();
      this.reduceRackRailStorage();
      this.createAdditionalPartNumberControls();
      this.setInitialPartNumberControlsValue();
    } else if (this.data.rackRail.suggestedParts.length > 0) {
      this.setSuggestedPartOnFirstControl();
    }

    // Verify if is stack, invert PN controls and box quantities.
    if (this.isStack()) {
      this.partNumberFormArray.controls = [...this.partNumberFormArray.controls].reverse();
      this.boxQuantityFormArray.controls = [...this.boxQuantityFormArray.controls].reverse();
    }
  }

  private sortBoxesByDepth() {
    this.data.rackRail.boxesStorage = [...this.data.rackRail.boxesStorage]?.sort(
      (a, b) => a.currentDepth - b.currentDepth
    );
  }

  private reduceRackRailStorage() {
    // If floor agroup part number.
    if (this.isFloor) {
      this.data.rackRail.boxesStorage.forEach((storage, index) => {
        const position = this.rackRailStorage.findIndex(p => p.partNumber === storage.partNumber);
        if (position === -1) {
          this.rackRailStorage.push({
            partNumber: storage.partNumber,
            storedBoxes: 1,
            order: 0,
          });
        } else {
          this.rackRailStorage[position].storedBoxes++;
          this.rackRailStorage[position].order = index;
        }
      });
    }

    // Else display on rack rail order.
    else {
      let lastPartNumber: string = '';
      let order: number = 0;
      this.data.rackRail.boxesStorage.forEach(box => {
        if (lastPartNumber !== box.partNumber) {
          lastPartNumber = box.partNumber;
          this.rackRailStorage.push({
            partNumber: box.partNumber,
            storedBoxes: 1,
            order: order,
          });
          order += 1;
        } else {
          this.rackRailStorage[order - 1].storedBoxes += 1;
        }
      });
    }
  }

  private setInitialPartNumberControlsValue() {
    const numberOfStoredParts = this.rackRailStorage.length ?? 0;
    for (let index = 0; index < numberOfStoredParts; index++) {
      this.partNumberFormArray
        .at(index)
        .setValue(
          this.rackRailStorage[index].partNumber +
            ' - ' +
            this.findDescriptionByPartNumber(this.rackRailStorage[index].partNumber)
        );

      this.boxQuantityFormArray.at(index).setValue(this.rackRailStorage[index].storedBoxes);
    }
  }

  findDescriptionByPartNumber(partNumber: string): string | undefined {
    return this.data.parts.find(p => p.number === partNumber)?.description;
  }

  private createAdditionalPartNumberControls() {
    const numberOfContainedParts = this.rackRailStorage.length ?? 0;

    for (let index = 1; index < numberOfContainedParts; index++) {
      this.addInputControlPair();
    }
  }

  addInputControlPair() {
    this.partNumberFormArray.push(new FormControl('', Validators.required));
    this.setPartNumberControlFilter(this.partNumberFormArray.length - 1);

    this.boxQuantityFormArray.push(new FormControl('', Validators.required));
  }

  removeInputControlPair(index: number) {
    this.partNumberFormArray.removeAt(index);
    this.boxQuantityFormArray.removeAt(index);
  }

  private setPartNumberControlFilter(i: number) {
    this.filteredOptions.push(
      this.partNumberFormArray.at(i).valueChanges.pipe(
        startWith(''),
        map(value => this._filterPart(value))
      )
    );
  }

  getFilteredOptions(i: number): Observable<PartDto[]> {
    return this.filteredOptions[i];
  }

  private _filterPart(value: string): PartDto[] {
    const filterValue = value.toLowerCase();

    return this.data.parts.filter(option => (option.number + option.description).toLowerCase().includes(filterValue));
  }

  getPartNumberControlByIndex(index: number): FormControl {
    return this.partNumberFormArray.at(index) as FormControl;
  }

  getBoxQuantityControlByIndex(index: number): FormControl {
    return this.boxQuantityFormArray.at(index) as FormControl;
  }

  isStack(): boolean {
    return this.data.rackRail.storageType === StorageType.stack;
  }
}
