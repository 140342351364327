import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContainerDto } from '@models/packaging';

@Component({
  selector: 'available-tag-dialog',
  templateUrl: './available-tag-dialog.component.html',
  styleUrls: ['./available-tag-dialog.component.scss'],
})
export class AvailableTagComponent {
  container: ContainerDto;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { container: ContainerDto }) {
    this.container = this.data.container;
  }
}
