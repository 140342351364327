<h1 mat-dialog-title>{{ 'dialog.instructions.concludeInstruction.title' | translate }}</h1>

<h4>
  {{
    'dialog.instructions.concludeInstruction.descriptionWithoutAddress'
      | translate : { origin: data.instruction.origin, destination: data.instruction.destination }
  }}
</h4>

<div mat-dialog-content></div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="concludeInstruction()">
    {{ 'common.buttons.instruction.conclude' | translate }}
  </button>
  <button mat-button [mat-dialog-close]="{ isCompleted: false }" color="warn">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</div>
