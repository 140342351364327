/*
 * File: src\app\racks\racks.component.ts
 * Project: boxcar-console
 * Created Date: 2022-02-18 13:02:17
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-02-18	JF	Initial version
 * 2022-05-18	JF	Added import of epc data from CSV file.
 * 2022-11-09	JF	Updated import to use a JSON file instead.
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';

import { DataCatalogApiService, NoticeDialogService } from '@services/index';

import { MatDialog } from '@angular/material/dialog';
import { ModifiedRackRailDto, LocationDto, RackRegionDto, RailOperationMode } from 'src/app/models/datacatalog';
import { MergeResultDialogComponent, MergeResultDialogData } from '../..';
import { DataMergeRespDto } from '../../../models/catalog.response';
import { RacksImportDialogComponent, RacksImportDialogData } from '../import/racks.import.dialog';
import { RacksPreviewDialogComponent, RacksPreviewDialogData } from '../import/racks.preview.dialog';

import { ErrorDialogComponent } from '@components/error-dialog/error-dialog.component';
import { PaginatorPageSelectComponent } from '@components/paginator-page-select/paginator-page-select.component';
import { TranslateService } from '@ngx-translate/core';
import { boxCarCoreErrorDetails, BoxCarHttpErrorResponse } from '@services/api';
import { LoadingIndicatorService } from '@services/loading/loading.service';

@Component({
  selector: 'racks-structure',
  templateUrl: './racks-structure.component.html',
  styleUrls: ['./racks-structure.component.scss'],
})
export class RackStructureComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'type',
    'region',
    'name',
    'maxBoxes',
    'column',
    'level',
    'boxType',
    'partNumber',
    'inputSideTag',
    'outputSideTag',
    'pickingLocations',
  ];

  dataSource: MatTableDataSource<ModifiedRackRailDto>;
  isLoading: boolean = false;

  @ViewChild(PaginatorPageSelectComponent) paginator: PaginatorPageSelectComponent | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  // Data table options.
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  // Internal state variables

  selectedRackRegion: RackRegionDto = {
    id: 0,
    name: this.translate.instant('catalog.racks.allRackRegions'),
    prefix: 'ALL',
    columns: 0,
    levels: 0,
    regionStrategy: 0,
    regionStorage: 1,
  };

  allTypes: { value: string; display: string }[] = [
    {
      value: this.translate.instant('catalog.racks.types.all'),
      display: this.translate.instant('catalog.racks.types.all').toUpperCase(),
    },
    { value: 'dynamic', display: this.translate.instant('catalog.racks.types.dynamic') },
    { value: 'static', display: this.translate.instant('catalog.racks.types.static') },
    { value: 'fixed', display: this.translate.instant('catalog.racks.types.fixed') },
  ];

  selectedType: string = this.allTypes[0].value;

  rackRegions: RackRegionDto[] = [];
  rackRails: LocationDto[] = [];
  catalogRows: LocationDto[] = [];
  searchFilter = '';

  dataMergeRespDto: DataMergeRespDto | undefined;

  constructor(
    private apiService: DataCatalogApiService,
    private matDialog: MatDialog,
    private noticeDialog: NoticeDialogService,
    private translate: TranslateService,
    private loadingIndicator: LoadingIndicatorService,
    private dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.rackRegions = [this.selectedRackRegion];
    this.getRackData();
  }

  openErrorDialog(title: string, description: string, errors: string[]) {
    this.dialog.open(ErrorDialogComponent, {
      data: {
        title: title,
        description: description,
        errorsList: errors,
      },
      minWidth: '300px',
      maxWidth: '75vw',
    });
  }

  private async getRackRailsData(region: number, type?: string, forceRefresh: boolean = false) {
    this.isLoading = true;
    if (region === 0 && type === undefined) {
      await this.apiService
        .getAllRackRailsFromCache(forceRefresh)
        .then(rackRails => {
          this.rackRails = rackRails;
          const modifiedRails = this.rackRails.map(rail => {
            const parts = rail.suggestedParts.map(part => part.partNumber);
            return {
              ...rail,
              suggestedParts: parts,
            };
          });
          this.dataSource = new MatTableDataSource(modifiedRails);
          this.dataSource.sort = this.sort;
          if (this.paginator) {
            this.dataSource.paginator = this.paginator?.paginator;
          }
        })
        .catch((error: BoxCarHttpErrorResponse) => {
          this.noticeDialog.show(...boxCarCoreErrorDetails(error));
        });
    } else {
      // Fetch the first rack region dataset.
      await this.apiService
        .getRackRails(region, type)
        .then(rackRails => {
          this.rackRails = rackRails;
          const modifiedRails = this.rackRails.map(rail => {
            const parts = rail.suggestedParts.map(part => part.partNumber);
            return {
              ...rail,
              suggestedParts: parts,
            };
          });
          this.dataSource = new MatTableDataSource(modifiedRails);
          this.dataSource.sort = this.sort;
          if (this.paginator) {
            this.dataSource.paginator = this.paginator?.paginator;
          }
        })
        .catch((error: BoxCarHttpErrorResponse) => {
          this.noticeDialog.show(...boxCarCoreErrorDetails(error));
        });
    }
    this.isLoading = false;
  }

  public async getRackData() {
    this.isLoading = true;
    await this.apiService
      .getRackRegions()
      .then(async rackRegions => {
        // Add an initial entry for unfiltered data select.
        this.rackRegions = this.rackRegions.concat(rackRegions);
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });

    await this.getRackRailsData(this.selectedRackRegion.id);
    this.applyFilter();

    this.isLoading = false;
  }

  createRackRailExportLink(rackRailsStorage: Partial<LocationDto>[]) {
    const downloadLink = document.createElement('a');
    const now = new Date();

    downloadLink.download = `rack_${
      this.selectedRackRegion.prefix
    }_Structure-(${now.toLocaleDateString()}-${now.toLocaleTimeString()}).json`;

    const jsonData = 'text/json; charset=utf-8, ' + encodeURIComponent(JSON.stringify(rackRailsStorage, null, '  '));
    downloadLink.href = 'data:' + jsonData;

    downloadLink.click();
  }

  prepareRackRailExportData(rackRails: LocationDto[]): Partial<LocationDto>[] {
    const rackRailsStorage: Partial<LocationDto>[] = [];

    rackRails.forEach(rackRail => {
      const rackRailForExport: Partial<LocationDto> = {
        id: rackRail.id,
        regionPrefix: rackRail.regionPrefix,
        railType: rackRail.railType,
        name: rackRail.name,
        column: rackRail.column,
        level: rackRail.level,
        maxBoxes: rackRail.maxBoxes,
        state: rackRail.state,
        operationMode: rackRail.operationMode,
        suggestedParts: rackRail.suggestedParts,
        allowedContainerModels: rackRail.allowedContainerModels,
        inputSideRFIDs: rackRail.inputSideEpcs !== null ? rackRail.inputSideEpcs : [],
        outputSideRFIDs: rackRail.outputSideEpcs !== null ? rackRail.outputSideEpcs : [],
        pickingLocations: rackRail.pickingLocations ?? [],
      };
      rackRailsStorage.push(rackRailForExport);
    });

    return rackRailsStorage;
  }

  onExportRackStructure() {
    this.loadingIndicator.show();

    const rackRailsExport: Partial<LocationDto>[] = this.prepareRackRailExportData(this.rackRails);

    this.createRackRailExportLink(rackRailsExport);

    this.loadingIndicator.hide();
  }

  async onChangeAnyFilter() {
    let type: string | undefined = this.selectedType;

    if (type === this.allTypes[0].value) {
      type = undefined;
    }
    this.isLoading = true;

    await this.getRackRailsData(this.selectedRackRegion.id, type);
    this.applyFilter();

    this.isLoading = false;
  }

  showRacksImportDialog(): void {
    const dialogRef = this.matDialog.open(RacksImportDialogComponent, {
      width: '80vh',
      data: {},
    });

    dialogRef.afterClosed().subscribe((dialogData: RacksImportDialogData) => {
      if (dialogData != undefined) {
        this.onConfirmFileRead(dialogData.selectedFile);
      }
    });
  }

  /** File will be processed. */
  onConfirmFileRead(selectedFile: File): void {
    // Read and check file contents;
    selectedFile
      ?.text()
      .then(contents => {
        this.catalogRows = JSON.parse(contents);
        this.showRacksPreviewDialog();
      })
      .catch(error => {
        this.noticeDialog.show(error);
      });
  }

  showRacksPreviewDialog(): void {
    const dialogRef = this.matDialog.open(RacksPreviewDialogComponent, {
      width: '120vh',
      data: {
        racksCatalogRows: this.catalogRows,
      } as RacksPreviewDialogData,
    });

    dialogRef.afterClosed().subscribe((dialogData: RacksPreviewDialogData) => {
      if (dialogData != undefined) {
        this.onConfirmDataUpload();
      }
    });
  }

  /** Send processed data to the backend for update. */
  async onConfirmDataUpload() {
    updateEpcFieldsFromRFIDArray(this.catalogRows);

    this.loadingIndicator.show();

    await this.apiService
      .mergeRackRails(this.catalogRows)
      .then(response => {
        this.dataMergeRespDto = response;
        this.showMergeResultDialog();
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        let errors: string[] = [];
        error.error.errors['$[0].type'].forEach((value: string) => {
          errors.push(value);
        });
        this.openErrorDialog('common.alert', 'warehouse.racks.structure.import.error', errors);
      });

    this.loadingIndicator.hide();
  }

  showMergeResultDialog(): void {
    this.matDialog
      .open(MergeResultDialogComponent, {
        width: computeMergeDialogWith(this.dataMergeRespDto),
        data: {
          dataMergeRespDto: this.dataMergeRespDto,
        } as MergeResultDialogData,
      })
      .afterClosed()
      .subscribe({
        next: _ => {
          this.getRackData();
        },
      });
  }

  applyFilter() {
    this.dataSource.filter = this.searchFilter.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getOperationTypeFromEnum(opType: RailOperationMode) {
    switch (opType) {
      case RailOperationMode.static:
        return this.translate.instant('catalog.racks.types.static');
      case RailOperationMode.dynamic:
        return this.translate.instant('catalog.racks.types.dynamic');
      case RailOperationMode.fixed:
        return this.translate.instant('catalog.racks.types.fixed');
      default:
        return this.translate.instant('catalog.racks.types.static');
    }
  }

  refreshRails() {
    this.getRackRailsData(this.selectedRackRegion.id, undefined, true);
  }
}

function updateEpcFieldsFromRFIDArray(catalogRows: LocationDto[]) {
  catalogRows.forEach(e => {
    e.inputSideEpcs = e.inputSideRFIDs;
    e.outputSideEpcs = e.outputSideRFIDs;
  });
}

function firstElementFrom(inputSideRFIDs: string[]): string | null {
  return inputSideRFIDs != null && inputSideRFIDs.length > 0 ? inputSideRFIDs[0] : null;
}

function computeMergeDialogWith(dataMergeRespDto: DataMergeRespDto | undefined): string {
  return dataMergeRespDto && dataMergeRespDto.logs?.length > 0 ? '100vh' : '60vh';
}
