/*
 * File: \src\app\parts\parts.component.ts
 * Project: boxcar-console
 * Created Date: 2022-02-08 14:02:32
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-02-08	JF	Part list UI. Initial version.
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { AppToastService, DataCatalogApiService, NoticeDialogService } from '@services/index';
import { DataMergeRespDto } from 'src/app/models/catalog.response';
import { PartDto, PartGroupDto, RegionStrategy } from 'src/app/models/datacatalog';
import { MatDialog } from '@angular/material/dialog';
import { PartsImportDialogComponent, PartsImportDialogData } from './import/parts.import.dialog';
import { PartsPreviewDialogComponent, PartsPreviewDialogData } from './import/parts.preview.dialog';
import {
  PartsProductionGuideImportDialogComponent,
  PartsProductionGuideImportDialogData,
} from './import/partsProductionGuide.import.dialog';
import {
  PartsProductionGuidePreviewDialogComponent,
  PartsProductionGuidePreviewDialogData,
} from './import/partsProductionGuide.preview.dialog';
import { MergeResultDialogComponent, MergeResultDialogData } from '..';
import { boxCarCoreErrorDetails, BoxCarHttpErrorResponse } from '@services/api';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { PaginatorPageSelectComponent } from '@components/paginator-page-select/paginator-page-select.component';

@Component({
  selector: 'boxcar-console-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.scss'],
})
export class PartsComponent implements OnInit {
  displayedColumns: string[] = [
    'number',
    'externalCode',
    'description',
    'quantityPerBox',
    'minRequiredStock',
    'maxAllowedStock',
    'deliveryQuantityTarget',
    'containerModel',
    'group',
    'suggestedDeliveryLocation',
    'partStrategy',
    'suggestedProductionSource',
    'injectionMold',
    'consumption_qty',
    'maxPickingInstructions'
  ];

  dataSource: MatTableDataSource<any>;

  @ViewChild(PaginatorPageSelectComponent) paginator: PaginatorPageSelectComponent | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  // Data table options.
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  // Internal state variables

  selectedPartGroup: number = 0;
  partGroups: PartGroupDto[] = [];
  csvTextRows: string[] = [];
  catalogRows: PartDto[] = [];
  isLoading: boolean = false;

  dataMergeRespDto: DataMergeRespDto | undefined;

  constructor(
    private apiService: DataCatalogApiService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private noticeDialog: NoticeDialogService,
    private loadingIndicator: LoadingIndicatorService,
    private toastService: AppToastService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit() {
    this.isLoading = true;
    await this.apiService
      .getPartGroups()
      .then(partGroups => {
        const allPartGroups = this.translate.instant('catalog.parts.allPartGroups');
        // Add an initial entry for unfiltered data select.
        this.partGroups = [{ id: 0, name: allPartGroups }];
        this.partGroups = this.partGroups.concat(partGroups);

        // Fetch the first part group dataset.
        this.apiService
          .getParts(this.partGroups[0]?.id)
          .then(parts => {
            this.dataSource = new MatTableDataSource(parts);
            if (this.paginator) this.dataSource.paginator = this.paginator?.paginator;
            this.dataSource.sort = this.sort;
          })
          .catch((error: BoxCarHttpErrorResponse) => {
            this.noticeDialog.show(...boxCarCoreErrorDetails(error));
          });
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
    this.isLoading = false;
  }

  async makeRequest() {
    this.isLoading = true;

    // Fetch the first part group dataset.
    await this.apiService
      .getParts(this.partGroups[0]?.id)
      .then(parts => {
        this.dataSource = new MatTableDataSource(parts);
        if (this.paginator) this.dataSource.paginator = this.paginator?.paginator;
        this.dataSource.sort = this.sort;
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
    this.isLoading = false;
  }

  /** Handles group list selection. */
  async onChangeGroup() {
    this.isLoading = true;
    await this.apiService
      .getParts(this.selectedPartGroup)
      .then(parts => {
        const filter = this.dataSource.filter;
        this.dataSource = new MatTableDataSource(parts);
        if (this.paginator) this.dataSource.paginator = this.paginator?.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filter = filter;
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
    this.isLoading = false;
  }

  showPartsImportDialog(): void {
    const dialogRef = this.matDialog.open(PartsImportDialogComponent, {
      width: '500px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((dialogData: PartsImportDialogData) => {
      if (dialogData != undefined) {
        this.onConfirmFileRead(dialogData.selectedFile);
      }
    });
  }

  /** File will be processed. */
  onConfirmFileRead(selectedFile: File): void {
    selectedFile
      ?.text()
      .then(contents => {
        this.csvTextRows = contents.split(/\r\n|\n/);
        this.catalogRows = this.getPartsFromTextRows(this.csvTextRows);

        this.showPartsPreviewDialog();
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
  }

  showPartsPreviewDialog(): void {
    const dialogRef = this.matDialog.open(PartsPreviewDialogComponent, {
      minWidth: '500px',
      width: '80vw',
      maxHeight: '95vh',
      data: {
        partsCatalogRows: this.catalogRows,
      } as PartsPreviewDialogData,
    });

    dialogRef.afterClosed().subscribe((dialogData: PartsPreviewDialogData) => {
      if (dialogData != undefined) {
        this.onConfirmDataUpload();
      }
    });
  }

  /** Send processed data to the backend for update. */
  async onConfirmDataUpload() {
    this.loadingIndicator.show();
    await this.apiService.getParts(0).then(response => {
      for (let part of this.catalogRows) {
        if (response.find(partData => partData.number === part.number) != undefined) {
          const partData: PartDto = response.find(partData => partData.number === part.number)!;
          part.suggestedProductionSource = partData.suggestedProductionSource;
          part.injectionMold = partData.injectionMold;
          part.minRequiredStock = partData.minRequiredStock;
          part.maxAllowedStock = partData.maxAllowedStock;
          part.deliveryQuantityTarget = partData.deliveryQuantityTarget;
          part.consumption_qty = partData.consumption_qty;
        }
      }
    });
    await this.apiService
      .mergeParts(this.catalogRows)
      .then(response => {
        this.dataMergeRespDto = response;
        this.showMergeResultDialog();
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
    this.loadingIndicator.hide();
  }

  showMergeResultDialog(): void {
    const dialogRef = this.matDialog.open(MergeResultDialogComponent, {
      minWidth: '500px',
      width: '80vw',
      maxHeight: '95vh',
      data: {
        dataMergeRespDto: this.dataMergeRespDto,
      } as MergeResultDialogData,
    });

    dialogRef.afterClosed().subscribe(x => {
      this.makeRequest();
    });
  }

  getPartsFromTextRows(csvTextRows: string[]): PartDto[] {
    const expectedColumns = 8;
    const partArray: PartDto[] = [];

    // Always assume first row is for headers.
    for (let i = 1; i < csvTextRows.length; i++) {
      const rowColumns = csvTextRows[i].split(/,|;/);

      if (rowColumns.length >= expectedColumns) {
        const part: PartDto = {
          id: 0,
          number: rowColumns[0]?.trim(),
          externalCode: rowColumns[1]?.trim(),
          description: rowColumns[2]?.trim(),
          quantityPerBox: rowColumns[3]?.trim() === '' ? 0 : parseInt(rowColumns[3]?.trim()),
          containerModel: rowColumns[4]?.trim(),
          group: rowColumns[5]?.trim(),
          suggestedDeliveryLocation: rowColumns[6]?.trim(),
          partStrategy: parseInt(rowColumns[7]?.trim()),
          suggestedProductionSource: '',
          injectionMold: '',
          minRequiredStock: 0,
          maxAllowedStock: 0,
          deliveryQuantityTarget: 0,
          consumption_qty: 0,
          maxPickingInstructions: isNaN(parseInt(rowColumns[8]?.trim())) ? 2 : parseInt(rowColumns[8]?.trim())
        };

        if (isNaN(<number>part.quantityPerBox)) {
          part.quantityPerBox = 0;
        }
        partArray.push(part);
      }
    }

    return partArray;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getStringRegionType(num: number) {
    if (num) {
      switch (num) {
        case RegionStrategy.jis:
          return 'JIS';
        case RegionStrategy.jit:
          return 'JIT';
        default:
          return this.translate.instant('catalog.parts.unmapped');
      }
    }
  }

  // ProductionGuide data stream

  showPartsProductionGuideImportDialog(): void {
    const dialogRef = this.matDialog.open(PartsProductionGuideImportDialogComponent, {
      width: '500px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((dialogData: PartsProductionGuideImportDialogData) => {
      if (dialogData != undefined) {
        this.onConfirmProductionGuideFileRead(dialogData.selectedFile);
      }
    });
  }

  /** File will be processed. */
  onConfirmProductionGuideFileRead(selectedFile: File): void {
    selectedFile
      ?.text()
      .then(contents => {
        this.csvTextRows = contents.split(/\r\n|\n/);
        this.catalogRows = this.getProductionGuideFromTextRows(this.csvTextRows);

        this.showProductionGuidePreviewDialog();
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
  }

  showProductionGuidePreviewDialog(): void {
    const dialogRef = this.matDialog.open(PartsProductionGuidePreviewDialogComponent, {
      minWidth: '500px',
      width: '80vw',
      maxHeight: '95vh',
      data: {
        partsCatalogRows: this.catalogRows,
      } as PartsProductionGuidePreviewDialogData,
    });

    dialogRef.afterClosed().subscribe((dialogData: PartsProductionGuidePreviewDialogData) => {
      if (dialogData != undefined) {
        this.onConfirmProductionGuideDataUpload();
      }
    });
  }

  /** Send processed data to the backend for update. */
  async onConfirmProductionGuideDataUpload() {
    this.loadingIndicator.show();
    await this.apiService
      .getParts(0)
      .then(response => {
        for (let part of this.catalogRows) {
          if (response.find(partData => partData.number === part.number) != undefined) {
            const partData: PartDto = response.find(partData => partData.number === part.number)!;
            part.externalCode = partData.externalCode;
            part.description = partData.description;
            part.quantityPerBox = partData.quantityPerBox;
            part.containerModel = partData.containerModel;
            part.group = partData.group;
            part.suggestedDeliveryLocation = partData.suggestedDeliveryLocation;
            part.partStrategy = partData.partStrategy;
            part.maxPickingInstructions = partData.maxPickingInstructions;
          } else {
            this.catalogRows = this.catalogRows.filter(partData => partData != part);
            this.toastService.error(
              this.translate.instant('toast.errors.productionGuide.partInexistent') + part.number
            );
          }
        }
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });

    await this.apiService
      .mergeParts(this.catalogRows)
      .then(response => {
        this.dataMergeRespDto = response;
        this.showMergeResultDialog();
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
    this.loadingIndicator.hide();
  }

  getProductionGuideFromTextRows(csvTextRows: string[]): PartDto[] {
    const expectedColumns = 6;
    const partArray: PartDto[] = [];

    // Always assume first row is for headers.
    for (let i = 1; i < csvTextRows.length; i++) {
      const rowColumns = csvTextRows[i].split(/,|;/);

      if (rowColumns.length >= expectedColumns) {
        const part: PartDto = {
          id: 0,
          number: rowColumns[0]?.trim(),
          externalCode: '',
          description: '',
          quantityPerBox: 0,
          minRequiredStock: isNaN(parseInt(rowColumns[1]?.trim())) ? 0 : parseInt(rowColumns[1]?.trim()),
          maxAllowedStock: isNaN(parseInt(rowColumns[2]?.trim())) ? 0 : parseInt(rowColumns[2]?.trim()),
          deliveryQuantityTarget: isNaN(parseInt(rowColumns[3]?.trim())) ? 0 : parseInt(rowColumns[3]?.trim()),
          containerModel: '',
          group: '',
          suggestedDeliveryLocation: '',
          partStrategy: 0,
          suggestedProductionSource: rowColumns[4].trim(),
          injectionMold: rowColumns[5].trim(),
          consumption_qty: isNaN(parseInt(rowColumns[6]?.trim())) ? 0 : parseInt(rowColumns[6]?.trim()),
          maxPickingInstructions: 0
        };

        if (isNaN(<number>part.quantityPerBox)) {
          part.quantityPerBox = 0;
        }
        partArray.push(part);
      }
    }

    return partArray;
  }
}
