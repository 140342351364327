import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { boxCarCoreErrorDetails, BoxCarHttpErrorResponse, DataCatalogApiService, RfidApiService } from '@services/api';
import { NoticeDialogService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { BatchCreationDto, BatchCreationReturn, BoxTypeDto } from 'src/app/models';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'boxcar-console-add-containers-in-batch',
  templateUrl: './add-containers-in-batch.component.html',
  styleUrls: ['./add-containers-in-batch.component.scss'],
})
export class AddContainersInBatchComponent implements OnInit {
  form!: FormGroup;
  descriptionEPC!: string;
  allBoxesTypes: BoxTypeDto[] = [];
  filteredOptions: Observable<BoxTypeDto[]>;
  disableButton: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private dataCatalogService: DataCatalogApiService,
    private rfidService: RfidApiService,
    private router: Router,
    private noticeDialog: NoticeDialogService,
    private loadingIndicator: LoadingIndicatorService
  ) {
    // Create form group.
    this.form = this.formBuilder.group({
      startEPC: new FormControl('', null, [this.validateInitialEPC()]),
      containerModel: new FormControl({ value: '', disabled: true }, [Validators.required]),
      quantity: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.max(500),
        Validators.min(1),
      ]),
    });

    // Make container model update when tap some string on form.
    this.filteredOptions = this.form.get('containerModel')!.valueChanges.pipe(
      startWith(''),
      map(value => this.filterModel(value || ''))
    );
  }

  ngOnInit(): void {
    // Receive all box types.
    this.rfidService
      .getBoxTypes()
      .then(response => {
        this.allBoxesTypes = response;

        // Remove Virtual types, and null.
        let removeIndex = this.allBoxesTypes.findIndex(epc => epc.name === '__NULL__');
        this.allBoxesTypes.splice(removeIndex, 1);

        removeIndex = this.allBoxesTypes.findIndex(epc => epc.name === 'virtual');
        this.allBoxesTypes.splice(removeIndex, 1);

        removeIndex = this.allBoxesTypes.findIndex(epc => epc.name === 'Virtual');
        this.allBoxesTypes.splice(removeIndex, 1);
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
  }

  validateInitialEPC(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value === '' || control.value === null) {
        return of(null);
      }
      return this.dataCatalogService.getMaxContainerAvailable(control.value).pipe(
        map(maxQuantity => {
          if (maxQuantity.maxContainerQuantity === 0) {
            return { invalidEPC: 'rfidTags.invalidEPCZeroQnt' };
          }
          this.setQntModelStatus(true, maxQuantity.maxContainerQuantity);
          return null;
        }),
        catchError(() => {
          this.setQntModelStatus(false, null);
          return of({ invalidEPC: 'rfidTags.invalidEPCNotEncountered' });
        })
      );
    };
  }

  isValidModel(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.allBoxesTypes.some(boxDto => boxDto.name == control.value)) {
        return null;
      } else {
        return { invalidModel: 'rfidTags.invalidModelNotEncountered' };
      }
    };
  }

  filterModel(value: string) {
    const filterValue = value.toLowerCase();
    return this.allBoxesTypes.filter(boxDto => boxDto.name.toLowerCase().includes(filterValue));
  }

  setQntModelStatus(validEPC: boolean, qnt: number | null) {
    const qntCtr = this.form.get('quantity');
    const modelCtr = this.form.get('containerModel');

    if (qntCtr != null && modelCtr != null) {
      if (validEPC) {
        qntCtr.setValue(qnt);
        qntCtr.enable();
        modelCtr.enable();
      } else {
        qntCtr.setValue(qnt);
        qntCtr.disable();
        modelCtr.disable();
      }
    }
  }

  buttonDisable(): boolean {
    if (this.form.valid && this.form.get('startEPC')!.value !== '' && this.form.get('startEPC')!.value !== null) {
      return false;
    } else {
      return true;
    }
  }

  printTags(data: BatchCreationReturn) {
    // Get only the numbers for bar codes.
    data.paperEpcs.forEach((value, index, array) => {
      array[index] = value.slice(12);
    });

    // Get only suffix of epcs.
    data.rfidEpcs.forEach((value, index, array) => {
      array[index] = value.slice(-13);
    });
    this.router.navigate(
      [
        '/',
        {
          outlets: {
            print: ['print'],
          },
        },
      ],
      { state: { rfids: data.paperEpcs, identification: data.rfidEpcs } }
    );
    setTimeout(() => {
      window.print();
      this.router.navigate([{ outlets: { print: null } }]);
    }, 1000);
  }

  async onSubmit() {
    this.loadingIndicator.show();
    const newBatchContainer: BatchCreationDto = {
      containerModel: this.form.get('containerModel')!.value,
      containerQtd: this.form.get('quantity')!.value,
      initialEpc: this.form.get('startEPC')!.value,
    };
    await this.rfidService
      .createRfidContainerBatch(newBatchContainer)
      .then(response => {
        this.printTags(response);
        this.form.reset();
        this.setQntModelStatus(false, null);
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
    this.loadingIndicator.hide();
  }
}
