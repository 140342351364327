import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateUtilsService {
  /**
   * @param date Date to get string
   * @returns String of date passed
   */
  formatDate(date: string): string {
    if (!date.endsWith('Z')) return new Date(date + 'Z').toLocaleString();

    return new Date(date).toLocaleString();
  }

  /**
   * If date2 not passed, the date will be used is actual date.
   * @param date1 : Date to compare
   * @param date2 : Date to compare
   * @returns number on milliseconds between two dates.
   */
  compareTwoDates(date1: Date, date2: Date = new Date()) {
    let lessActualDate = date1.getTime();
    let moreActualDate = date2.getTime();

    if (lessActualDate > moreActualDate) {
      const aux = lessActualDate;
      lessActualDate = moreActualDate;
      moreActualDate = aux;
    }

    return moreActualDate - lessActualDate;
  }
}
