/*
 * File: \src\app\rfid-tags\rfid-tags.component.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-26	JF	Component refactored.
 */

import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { userSelectors } from '@services/login/state';
import { UserTypes } from 'src/app/models';
import { RfidTagInfoDto } from 'src/app/models/tags';
import { RfidApiService } from '@services/api/rfid.api.service';
import { PaginatorPageSelectComponent } from '@components/paginator-page-select/paginator-page-select.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'boxcar-console-rfid-tags',
  templateUrl: './rfid-tags.component.html',
  styleUrls: ['./rfid-tags.component.scss'],
})
export class RfidTagsComponent implements AfterViewInit {
  userRoles: string[] = [];

  rfidtags: RfidTagInfoDto[] = [];
  pageEvent: PageEvent = {
    length: 0,
    pageIndex: 0,
    pageSize: 10,
    previousPageIndex: 0,
  };
  appliedFilter: string = '';

  displayedColumns: string[] = ['batchNumber', 'epc', 'barCode', 'containerModel'];
  dataSource: MatTableDataSource<RfidTagInfoDto>;

  resultsLength = 0;
  isLoadingResults = true;

  @ViewChild(PaginatorPageSelectComponent) paginator!: PaginatorPageSelectComponent;

  constructor(
    private apiService: RfidApiService,
    private router: Router,
    public dialog: MatDialog,
    private translate: TranslateService,
    private store: Store,
  ) {
    this.dataSource = new MatTableDataSource(this.rfidtags);
    if (this.paginator) this.paginator.paginator = {} as MatPaginator;

    this.store
      .select(userSelectors.userData)
      .subscribe({
        next: userData => {
          if (userData) {
            this.userRoles = userData.roles;
          }
        },
      })
      .unsubscribe();
  }

  goToAddTag() {
    this.router.navigate(['/rfidTags/add']);
  }

  goToPrintTagPage() {
    this.router.navigate(['/rfidTags/print']);
  }

  goToAddContainers() {
    this.router.navigate(['/rfidTags/addContainers']);
  }

  userIsVBL() {
    return this.userRoles.includes(UserTypes.VBL);
  }

  async ngAfterViewInit() {
    this.isLoadingResults = true;

    await this.apiService
      .getRfidTags(this.appliedFilter, this.pageEvent.pageIndex + 1, this.pageEvent.pageSize)
      .then(data => {
        this.resultsLength = data.tagQuantity;
        this.dataSource = new MatTableDataSource(data.rfidTagInfoDtos);
        this.isLoadingResults = false;
      });
  }

  async onPaginatorChange(pageEvent: PageEvent) {
    this.pageEvent = pageEvent;
    this.isLoadingResults = true;

    await this.apiService
      .getRfidTags(this.appliedFilter, this.pageEvent.pageIndex + 1, this.pageEvent.pageSize)
      .then(data => {
        this.resultsLength = data.tagQuantity;
        this.dataSource = new MatTableDataSource(data.rfidTagInfoDtos);
        this.isLoadingResults = false;
      });
  }

  async applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.appliedFilter = filterValue.trim().toLowerCase();
    this.isLoadingResults = true;

    await this.apiService
      .getRfidTags(this.appliedFilter, this.pageEvent.pageIndex + 1, this.pageEvent.pageSize)
      .then(data => {
        this.resultsLength = data.tagQuantity;
        this.dataSource = new MatTableDataSource(data.rfidTagInfoDtos);
        this.isLoadingResults = false;
        this.paginator.pageIndex = 0;
      });
  }

  getTranslateFromType(type: string) {
    switch (type) {
      case 'container':
        return this.translate.instant('common.container');
      case 'rail':
        return this.translate.instant('common.rail');
      default:
        return type;
    }
  }
}

@Component({
  selector: 'dialog-content',
  templateUrl: 'dialog-content.html',
  styleUrls: ['./rfid-tags.component.scss'],
})
export class DialogContentComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { barcodes: string[] }
  ) {}

  barcodes: string[] = [];
}
