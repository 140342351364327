/*
 * File: \src\app\services\models\instruction.ts
 * Project: boxcar-console
 * Created Date: 2022-05-24 08:07:48
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-05-24	JF	Instruction service types.
 */

import { RackRailType } from './datacatalog';

export enum InstructionState {
  notStarted = 0,
  started,
  paused,
  canceled,
  concluded,
}

export enum InstructionType {
  storing = 1,
  picking = 2,
  reallocation = 3,
}

export type InstructionTypeSectors = {
  storing: string[];
  picking: string[];
  reallocation: string[];
};

export const keyInstructionsType: InstructionTypeSectors = {
  picking: ['G1', 'H1', 'I1', 'PCDC'],
  storing: ['S1', 'S2', 'S3', 'S4'],
  reallocation: [],
};

export type InstructionRequestDto = {
  sectors: string[];
  destinations: string[];
  types?: number[];
  states: InstructionState[];
  search?: string;
  grouped: boolean;
  pageNum: number;
  qtyPerPage: number;
};

export type InstructionSearchQuery = {
  query: string;
  qtyPerPage: number;
  pageNum: number;
};

export type Instruction = {
  instructionId: number;
  sectors: string[];
  createdAt: string;
  instructionState: InstructionState;
  lastStateUpdate: string;
  activeBarcode: string;
  containerEpcs: string[];
  containerModel: string;
  partNumber: string;
  partDescription: string;
  instructionType: InstructionType;
  origin: string;
  originRailType: RackRailType;
  originEpcs: string[];
  destination: string;
  destinyRailType: RackRailType;
  destinationEpcs: string[];
  user: string;
  previousInstructionId?: number;
  previousInstructionState: InstructionState;
  boxesQty: number;
  containerLabelCodes: string[];
};

export type InstructionDto = {
  instructionsQuantity: number;
  instructions: Instruction[];
};

export type InstructionSummaryDto = {
  state: InstructionState;
  count: number;
  rackRailName: string;
};

export enum OrderTypes {
  WAITING = 0,
  IN_PROGRESS = 1,
  DONE = 2,
}

export type InstructionConcludeRequestBody = {
  destinationLocationEpc?: string;
  originLocationEpc?: string;
  containerEpc: string;
  wasAutomatic: boolean;
};

export type ActiveInstructionFilters = {
  id: boolean;
  type: boolean;
  state: boolean;
  rfid: boolean;
  epc: boolean;
  time: boolean;
  origin: boolean;
  destination: boolean;
  part: boolean;
};

export type QualityInstructionsBatchGenerateRequestDto = {
  labelcodes: string[];
};
