/*
 * File: logs.service.ts
 * Project: boxpart-console
 * File Created: Tuesday, 14th December 2021 18:17:30 pm
 * Author: Bruno Lopes (bruno.lorenco.lopes@gmail.com)
 * -----
 * Copyright 2021 - Von Braun Labs.
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2021-12-14	BL	Get log entries.
 * 2022-04-26	JF	Service refactoring.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { baseUrl } from '@services/api';
import { LogsDataTablesResponse } from 'src/app/models/logger';

@Injectable()
export class LogService {
  constructor(private http: HttpClient) {}

  public retrieveLogEntries(): Observable<LogsDataTablesResponse> {
    return this.http.get<LogsDataTablesResponse>(baseUrl + 'logger/logs');
  }
}
