/*
 * File: \src\app\services\api\boxing.api.service.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 14:52:22
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-11-23	TC	Add filter param
 * 2022-10-20	TC	Refactor searchBoxes params
 * 2022-04-25	JF	Service created for refactoring.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerDto, LabelStateResponseDTO, PackagingSearchResponse } from 'src/app/models/packaging';
import { baseUrl } from '@services/api';

@Injectable({
  providedIn: 'root',
})
export class PackagingApiService {
  constructor(private httpClient: HttpClient) {}

  /** Returns a filtered set of boxes. */
  searchBoxes(
    pageNum: number,
    qtyPerPage: number,
    state?: string,
    lastUpdate?: string,
    filter?: string
  ): Promise<PackagingSearchResponse> {
    let params = new HttpParams();

    if (state) params = params.append('state', state);
    if (lastUpdate) params = params.append('lastUpdate', lastUpdate);
    if (filter) params = params.append('filter', filter);
    params = params.append('pageNum', pageNum);
    params = params.append('qtyPerPage', qtyPerPage);

    return this.httpClient.get<PackagingSearchResponse>(baseUrl + `packaging/search`, { params }).toPromise();
  }

  verifyPSMMTag(psmmTag: string) {
    return this.httpClient.get<LabelStateResponseDTO>(`${baseUrl}packaging/labelstate/${psmmTag}`);
  }

  /** Unread label when label is already associated */
  unreadPSMMTag(psmmTag: string) {
    return this.httpClient.delete(`${baseUrl}packaging/unreadlabel/${psmmTag}`);
  }

  getPackageFromRFID(rfidCode: string) {
    return this.httpClient.get<ContainerDto>(`${baseUrl}packaging/info/${rfidCode}`);
  }

  liberateBox(rfidCode: string, isComplete: boolean = true) {
    return this.httpClient.patch<ContainerDto>(`${baseUrl}packaging/liberate`, {
      barcode: rfidCode,
      fIsComplete: isComplete,
    });
  }

  restoreBox(rfidCode: string) {
    return this.httpClient.patch<ContainerDto>(`${baseUrl}packaging/restore/${rfidCode}`, undefined);
  }

  getCurrentJITLabelNumber() {
    return this.httpClient.get<number>(`${baseUrl}packaging/currentJITLabelNumber`).toPromise();
  }
}
