/*
 * File: \src\app\pages\boxes\rfid-tag-detail.dialog\rfid-tag-detail.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-04-26 11:45:37
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { LoggerApiService } from '@services/api';
import { DataLogsService } from '@services/logs/data-logs.service';
import { MetadataService } from '@services/metadata/metadata.service';
import { LogDTO, LogLocal, LogQuery } from 'src/app/models/logger';
import { BoxDto } from 'src/app/models/packaging';

@Component({
  selector: 'rfid-tag-detail-dialog',
  templateUrl: 'rfid-tag-detail.dialog.html',
  styleUrls: ['rfid-tag-detail.dialog.scss'],
})
export class RfidTagDetailDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  displayedColumns: string[] = ['login', 'timestamp', 'eventClassNumber', 'contextId', 'contextTag', 'data'];

  dataSource: MatTableDataSource<LogLocal>;
  logMetadata: any;

  isLoading: boolean = false;

  pageIndex: number = 0;
  pageSize: number = 5;
  totalLength: number = 0;
  filterDetails: LogQuery = {
    pageNum: this.pageIndex + 1,
    qtyPerPage: this.pageSize,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { rfidTag: BoxDto },
    private loggerApiService: LoggerApiService,
    private metadataService: MetadataService,
    private translateService: TranslateService,
    private dataTransform: DataLogsService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit() {
    this.logMetadata = this.metadataService.getLogMetadata();

    if (
      this.data.rfidTag.container.activeBarcode &&
      !this.data.rfidTag.container.activeBarcode.toLowerCase().includes('__null')
    ) {
      await this.getData();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  async getData() {
    this.isLoading = true;
    let localLogs: LogLocal[] = [];

    await this.loggerApiService
      .getContainerLogs(this.data.rfidTag.container.activeBarcode!, this.filterDetails)
      .then(async logs => {
        localLogs = await Promise.all(
          logs.logs.map(async (log: LogDTO) => {
            const newLog: LogLocal = {
              login: log.login,
              timestamp: new Date(log.timestamp + 'Z').toLocaleString('default'),
              contextTag: this.transformContext(log.contextTag),
              contextId: log.contextId,
              data: await this.dataTransform.getLexicalDataFromJSON(log.data, log.eventDetailNumber, log.code),
              eventClassNumber: this.logMetadata.eventClassDict[log.eventClassNumber].namePT,
              eventDetailNumber: this.logMetadata.eventDetailDict[log.eventDetailNumber].namePT,
              gravity: this.logMetadata.eventDetailDict[log.eventDetailNumber].gravity,
            };
            return newLog;
          })
        );

        this.dataSource = new MatTableDataSource(localLogs);
        this.totalLength = logs.logsQuantity;
      });

    this.isLoading = false;
  }

  total(labelDtos: any[]) {
    let total = 0;
    if (labelDtos != null && labelDtos.length > 0) {
      labelDtos.forEach(label => {
        total += label.quantity;
      });
    }
    return total;
  }

  transformContext(context: string) {
    if (context === 'box') return this.translateService.instant('logs.box');
    if (context === 'user') return this.translateService.instant('logs.user');
    if (context === 'assembly_detail') return this.translateService.instant('logs.tag');
    if (context === 'box_assembly') return this.translateService.instant('logs.tag');
    if (context === 'rfid_allotment') return this.translateService.instant('logs.caseRegistry');
    if (context === 'instruction') return this.translateService.instant('logs.instruction');
    if (context === 'container') return this.translateService.instant('logs.container');
    if (context === 'container_lifecycle') return this.translateService.instant('logs.container');

    return this.translateService.instant('logs.noContext');
  }

  transformData(data: any) {
    // if have any data
    if (data) {
      try {
        const json = JSON.parse(data); // Try to parse (JSON string to JSON object)
        const newData = []; // Result array

        // Concatenates possible values in array
        if (json.knownBarCode) {
          newData.push(this.translateService.instant('rfidTags.case') + ' ' + json.knownBarCode);
        }
        if (json.barCode) {
          newData.push(this.translateService.instant('rfidTags.case') + ' ' + json.barCode);
        }
        if (json.barCodes) {
          json.barCodes.map((barcode: string) => newData.push(barcode));
        }
        if (json.fIsReopening) {
          newData.push(' ' + this.translateService.instant('rfidTags.reopened'));
        }
        if (json.labelcode) {
          newData.push(this.translateService.instant('rfidTags.tag') + ' ' + json.labelcode);
        }

        // if have any values, join and return
        // else return original data
        if (newData.length) {
          return newData.join(', ');
        } else {
          return data;
        }
      } catch (err) {
        // if there is any error return the original data
        return data;
      }
    }
    return 'Nenhum dado';
  }

  changePaginator(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.filterDetails.pageNum = this.pageIndex + 1;
    this.filterDetails.qtyPerPage = this.pageSize;

    this.getData();
  }

  formatBarcodes(barcodes: string[]) {
    return barcodes.join(', ');
  }
}
