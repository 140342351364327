<mat-card [style.--border-color]="getColorFromCreationTime()">
  <div class="content">
    <mat-card-title>
      <img [src]="'../../../assets/icons/' + getInstructionIconFromType()" width="72px" alt="Icon for instruction" />

      <div class="info">
        <p>#{{ instruction.instructionId }} - {{ instruction.partNumber }} - {{ instruction.partDescription }}</p>
        <div class="aditional-infos">
          <div class="subtitle-container">
            <p class="subtitle">
              {{ 'instructions.types.title' | translate }}
              {{ instructionUtils.getTypeText(instruction.instructionType) }}
            </p>
            <p class="secondary-info" *ngIf="instruction.instructionState !== null">
              {{ 'instructions.list.state' | translate }}:
              {{ instructionUtils.getStateText(instruction.instructionState) }}
            </p>
          </div>
          <p class="secondary-info">
            {{ 'instructions.list.creationDate' | translate }}:
            {{ dateUtilsService.formatDate(instruction.createdAt) }}
          </p>
          <p class="secondary-info" *ngIf="instruction.lastStateUpdate">
            {{ 'instructions.list.updateDate' | translate }}:
            {{ dateUtilsService.formatDate(instruction.lastStateUpdate) }}
          </p>
        </div>
      </div>
    </mat-card-title>

    <mat-card-content>
      <div class="instruction-infos">
        <div class="origin-info">
          <p class="title-info">
            {{ 'common.origin' | translate }}
          </p>
          <p class="main-info">
            {{ instruction.origin }}
          </p>
        </div>
        <div>
          <img src="../../../assets/icons/right_arrow_icon.svg" alt="right arrow" />
        </div>
        <div class="destination-infos">
          <p class="title-info">
            {{ 'common.destination' | translate }}
          </p>
          <p class="main-info">
            {{ instruction.destination }}
          </p>
        </div>
      </div>

      <div class="box-infos">
        <div class="boxes-qty" *ngIf="instruction.boxesQty > 1">
          <p class="title-info">
            {{ 'common.boxes' | translate }}
          </p>
          <p class="main-info">
            {{ instruction.boxesQty }}
          </p>
        </div>
        <div class="pallet-tag" *ngIf="instruction.containerModel.toLowerCase() === 'pallet'">
          <p class="title-info">
            {{ 'common.tag' | translate }}
          </p>
          <p class="main-info">
            {{ instruction.activeBarcode }}
          </p>
        </div>
      </div>
    </mat-card-content>
  </div>

  <mat-card-actions *ngIf="showActions">
    <button mat-raised-button color="primary" (click)="openConcludeInstructionDialog()">
      {{ ('common.buttons.instruction.conclude' | translate).toUpperCase() }}
    </button>

    <button
      mat-button
      color="warn"
      (click)="cancelInstruction()"
      *ngIf="instruction.instructionState === 0; else unpickButton">
      {{ ('common.buttons.instruction.cancel' | translate).toUpperCase() }}
    </button>
    <ng-template #unpickButton>
      <button mat-raised-button color="warn" (click)="unpickInstruction()">
        {{ ('common.buttons.instruction.unpick' | translate).toUpperCase() }}
      </button>
    </ng-template>
  </mat-card-actions>
</mat-card>
