import { Component, Input } from '@angular/core';

@Component({
  selector: 'boxcar-console-psmm-tag-card',
  templateUrl: './psmm-tag-card.component.html',
  styleUrls: ['./psmm-tag-card.component.scss'],
})
export class PsmmTagCardComponent {
  constructor() {
    return;
  }

  @Input()
  labelDto: any;

  @Input()
  pn: string | undefined;

  @Input()
  source: string | undefined;

  formatDate(strDate: string) {
    return new Date(strDate + 'Z').toLocaleString('default');
  }
}
