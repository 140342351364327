import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConcludeCreatedInstructionComponent } from '@components/rack-totem-viewer/conclude-created-instruction/conclude-created-instruction.component';
import { ContinueFlowComponent } from '@components/rack-totem-viewer/continue-flow/continue-flow.component';
import { UnpickdialogComponent } from '@components/instructions/instructions-management/unpickdialog/unpickdialog.component';
import { Instruction } from 'src/app/models';
import { ConcludeInstructionWithoutAddrsComponent } from '@components/dialogs/conclude-instruction-without-addrs/conclude-instruction-without-addrs.component';

@Injectable({
  providedIn: 'root',
})
export class InstructionManagementDialogService {
  constructor(private matDialog: MatDialog) {}

  /**
   *
   * @param instruction, Which will be unpicked
   * @returns A reference of dialog, when dialog closes returns "{isCompleted: boolean}"
   */
  openUnpickInstructionDialog(instruction: Instruction): MatDialogRef<UnpickdialogComponent> {
    return this.matDialog.open(UnpickdialogComponent, {
      disableClose: true,
      width: '640px',
      data: { instruction: instruction },
    });
  }

  openContinueFlowDialog(instruction?: Instruction): MatDialogRef<ContinueFlowComponent> {
    return this.matDialog.open(ContinueFlowComponent, {
      disableClose: true,
      width: '640px',
      data: {
        instruction: instruction,
      },
    });
  }

  /**
    Open conclude instruction flow.
    @param: Instruction, which will be concluded.
    @return: MatDialogRef, when dialog closes returns "{isCompleted: boolean}"
  **/
  openConcludeInstructionDialog(instruction: Instruction): MatDialogRef<ConcludeCreatedInstructionComponent> {
    return this.matDialog.open(ConcludeCreatedInstructionComponent, {
      data: {
        instruction: instruction,
      },
      disableClose: true,
      autoFocus: false,
    });
  }

  /**
   *
   * @param instruction, which will be concluded.
   * @returns: MatDialogRef, when dialog closes returns "{isCompleted: boolean}"
   */
  openConcludeInstructionWithoutSelectAddress(
    instruction: Instruction
  ): MatDialogRef<ConcludeInstructionWithoutAddrsComponent> {
    return this.matDialog.open(ConcludeInstructionWithoutAddrsComponent, {
      data: {
        instruction: instruction,
      },
      disableClose: true,
      autoFocus: false,
    });
  }
}
