import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  boxCarCoreErrorDetails,
  BoxCarHttpErrorResponse,
  DataCatalogApiService,
  InstructionApiService,
  MetadataApiService,
} from '@services/api';
import { RegionDto, PartDto } from 'src/app/models';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent } from '@components/warning-dialog/warning-dialog.component';
import { ErrorDialogComponent } from '@components/error-dialog/error-dialog.component';
import { NoticeDialogService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { MetadataService } from '@services/metadata/metadata.service';
import { PrioritizeDiaologComponent } from '../prioritize-order-dialog/prioritize-order-dialog.component';

@Component({
  selector: 'boxcar-create-order-diaolog',
  templateUrl: './create-order-dialog.component.html',
  styleUrls: ['./create-order-dialog.component.scss'],
})
export class CreateDiaologComponent implements OnInit {
  myControl = new FormControl('');
  filteredOptions: Observable<PartDto[]> = of([]);
  allParts: PartDto[] = [];
  allDestinations: RegionDto[] = [];
  selectedDestination: string = '';
  allMetadataErrors: any;
  visibleQtyBox: boolean = false;
  assembliesQtd: number = 1;
  isLoadingParts: boolean = true;

  constructor(
    private catalogApi: DataCatalogApiService,
    public translate: TranslateService,
    private instructionApiService: InstructionApiService,
    private metadataApi: MetadataApiService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private noticeDialog: NoticeDialogService,
    private loadingService: LoadingIndicatorService,
    private metadataService: MetadataService
  ) {}

  ngOnInit(): void {
    this.isLoadingParts = true;
    // Get all parts.
    this.catalogApi
      .getParts(0)
      .then(parts => {
        this.allParts = parts;
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => {
            return this._filter(value || '');
          })
        );
        this.isLoadingParts = false;
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });

    // Get all destinations.
    this.catalogApi
      .getDestinations()
      .then(destination => {
        this.allDestinations = destination;
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });

    // Get all metadata error.
    this.metadataApi
      .getErrorMetadata()
      .then(response => {
        this.allMetadataErrors = response;
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.allParts.filter(
      option =>
        option.description.toLowerCase().includes(filterValue) || option.number.toLowerCase().includes(filterValue)
    );
  }

  openErrorDialog(title: string, description: string) {
    this.dialog.open(ErrorDialogComponent, {
      data: {
        title: title,
        description: description,
      },
      minWidth: '300px',
      width: '450px',
    });
  }

  openWarningDialog(title: string, description: string) {
    this.dialog.open(WarningDialogComponent, {
      data: {
        title: title,
        description: description,
      },
      minWidth: '300px',
      width: '30%',
    });
  }

  verifyModel() {
    const selectedValue = this.myControl.value.split(' - ')[0];
    const part = this.allParts.find(part => part.number === selectedValue);

    if (part) {
      this.assembliesQtd = 1;
      if (part.containerModel === null) {
        this.openWarningDialog(
          'instructions.generationsErrors.warning',
          'instructions.generationsErrors.nullContainerModelDescription'
        );
      } else if (part.containerModel.toLowerCase() === 'pallet') {
        this.visibleQtyBox = true;
        return;
      }
    }
    this.visibleQtyBox = false;
  }

  async generateInstruction() {
    this.loadingService.show();

    if (this.selectedDestination !== '' && this.myControl.value !== '') {
      if (this.myControl.value.split(' - ')[0] === undefined) {
        this.openErrorDialog(
          'instructions.generationsErrors.invalidDataTitle',
          'instructions.generationsErrors.invalidDataDescription'
        );
      } else {
        // Split partNumber from partDescription
        this.myControl.setValue(this.myControl.value.split(' - ')[0]);

        const bodyRequest = {
          destination: this.selectedDestination.trim(),
          partNumber: this.myControl.value.trim(),
          assembliesQtd: this.assembliesQtd,
        };
        await this.instructionApiService
          .createInstruction(bodyRequest)
          .then(() => {
            this.snackBar.open('Pedido de picking gerado com sucesso.', undefined, { duration: 2000 });
          })
          .catch((response: BoxCarHttpErrorResponse) => {
            if(response.error.code != '5004'){
              let errorMessage = this.metadataService.getErrorDescriptionFromErrorCode(response.error.code);
              this.dialog.openDialogs.pop();
              this.openErrorDialog('instructions.generationsErrors.title', errorMessage);
            } else{
              this.openPrioritizesDialog();
            }
            
          });
      }
    } else {
      this.openErrorDialog(
        'instructions.generationsErrors.invalidDataTitle',
        'instructions.generationsErrors.invalidDataDescription'
      );
    }
    this.loadingService.hide();
  }

  verifyAssembliesQty() {
    if (this.assembliesQtd <= 0) {
      this.assembliesQtd = 1;
      this.openWarningDialog(
        'instructions.generationsErrors.title',
        'instructions.generationsErrors.invalidDataWarning'
      );
    }
  }

  openPrioritizesDialog() {
    this.dialog
      .open(PrioritizeDiaologComponent, {
        data:{
          destination: this.selectedDestination.trim(),
          partnumber: this.myControl.value.trim()
        }
      })
      .afterClosed()
      .subscribe();
  }
}
