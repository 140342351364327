/*
 * File: \src\app\pages\parts\import\parts.preview.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-02-08 14:02:32
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2023-07-14	PS	productionGuide UI. Initial version.
 */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { PartDto } from 'src/app/models/datacatalog';

export type PartsProductionGuidePreviewDialogData = {
  partsCatalogRows: PartDto[];
};

@Component({
  selector: 'partsProductionGuide-preview-dialog',
  templateUrl: 'partsProductionGuide.preview.dialog.html',
})
export class PartsProductionGuidePreviewDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PartsProductionGuidePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PartsProductionGuidePreviewDialogData,
    private translate: TranslateService
  ) {}

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
