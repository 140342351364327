import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataCatalogApiService } from '@services/api';
import { PartDto, LocationDto } from 'src/app/models';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'rack-search',
  templateUrl: './rack-search.component.html',
  styleUrls: ['./rack-search.component.scss'],
})
export class RackSearchComponent implements OnInit {
  pnSearch = new FormControl('');
  parts: PartDto[] = [];
  filteredPNs: Observable<PartDto[]> = of([]);
  railsInformation: LocationDto[] = [];
  displayedColumns = ['region', 'railId', 'pn', 'boxesQty', 'partQty'];
  loading = true;

  constructor(private catalogService: DataCatalogApiService) {
    this.catalogService.getParts(0).then(parts => {
      this.parts = parts;

      // Filter values of parts.
      this.filteredPNs = this.pnSearch.valueChanges.pipe(
        startWith(''),
        map(search => {
          return this.filter(search || '');
        })
      );

      this.loading = false;
    });
  }

  ngOnInit(): void {
    return;
  }

  private filter(value: string) {
    return this.parts.filter(
      part =>
        part.number.toLowerCase().includes(value.toLowerCase().trim()) ||
        part.description.toLowerCase().includes(value.toLowerCase().trim())
    );
  }

  getRackRails() {
    this.loading = true;

    if (this.pnSearch.value) {
      const numberOfPartNumber = this.pnSearch.value.split(' - ')[0];

      // Get information from API.
      this.catalogService.getRackRailsFromPN(numberOfPartNumber).then(rails => {
        this.railsInformation = rails;
        this.loading = false;
      });
    } else {
      this.railsInformation = [];
      this.loading = false;
    }
  }

  // Sum all boxes with PN.
  getNumberOfBoxesWithPN(rail: LocationDto): number {
    const numberOfPartNumber = this.pnSearch.value.split(' - ')[0];
    return rail.boxesStorage.filter(box => box.partNumber === numberOfPartNumber).length;
  }

  getNumberOfPartWithPN(rail: LocationDto): number {
    const numberOfPartNumber = this.pnSearch.value.split(' - ')[0];
    const boxesFromSearchedPN = rail.boxesStorage.filter(box => box.partNumber === numberOfPartNumber);
    let numberOfParts = 0;

    boxesFromSearchedPN.forEach(rail => {
      numberOfParts += rail.partQuantity;
    });

    return numberOfParts;
  }
}
