import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { appRoutes } from '../routes';

/**
 * This class verifies if the Login route can be activated or not
 */
@Injectable()
export class CanAccessHome implements CanActivate {
  constructor(private router: Router, public jwtHelper: JwtHelperService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return true;
  }
}
