<!--
File: \src\app\pages\racks\racks-contents\ract-quantity-dialog\rack-quantity.dialog.html
Project: boxcar-console
Created Date: 2022-09-06 11:25:24
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
-->

<h1 mat-dialog-title>{{'warehouse.racks.edit.title' | translate: {rackName: data.rackRail.name} }}</h1>

<h4>{{'warehouse.racks.edit.notes' | translate}} ({{data.rackRail.name}})</h4>

<div mat-dialog-content>
  <h5>{{ (isStack() ? 'warehouse.racks.edit.lastPosition' : 'warehouse.racks.edit.firstPosition') | translate }}</h5>

  <div *ngFor="let partNumberForm of partNumberFormArray.controls; last as isLast; index as i;">
    <!-- Part number selector field -->
    <mat-form-field appearance="fill" style="width: 60%">
      <mat-label>{{ 'catalog.racks.edit.partNumber' | translate }}</mat-label>
      <input
        matInput
        type="text"
        placeholder="Pick one"
        [matAutocomplete]="auto"
        [formControl]="getPartNumberControlByIndex(i)" />
      <mat-autocomplete matNativeControl #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of getFilteredOptions(i) | async"
          [value]="option.number + ' - ' + option.description">
          {{option.number}} - {{option.description}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!-- Max boxes field -->
    <mat-form-field appearance="fill" style="width: 15%; margin-left: 6px">
      <!-- Data label -->
      <mat-label>{{ 'warehouse.racks.edit.storedBoxes' | translate }}</mat-label>

      <!-- Quantity of boxes -->
      <input
        id="items"
        type="number"
        placeholder="Ex.: 10"
        matInput
        min="0"
        [formControl]="getBoxQuantityControlByIndex(i)"
        max="{{data.rackRail.maxBoxes}}"
        cdkFocusInitial />
    </mat-form-field>

    <!-- Add and removal buttons -->
    <button mat-icon-button color="warn" *ngIf="partNumberFormArray.length > 1" (click)="removeInputControlPair(i)">
      <mat-icon>remove_circle</mat-icon>
    </button>
    <button mat-icon-button color="primary" *ngIf="isLast && !rackRailIsJIT" (click)="addInputControlPair()">
      <mat-icon>add_circle</mat-icon>
    </button>
  </div>

  <br />

  <h5>{{ (isStack() ? 'warehouse.racks.edit.firstPosition' : 'warehouse.racks.edit.lastPosition') | translate }}</h5>
</div>

<!-- Dialog buttons -->
<div mat-dialog-actions>
  <button
    [disabled]="!boxQuantityFormArray.valid || !partNumberFormArray.valid"
    mat-raised-button
    [mat-dialog-close]="rackRailStateDto"
    color="primary">
    {{ 'catalog.racks.edit.save' | translate }}
  </button>

  <button mat-button (click)="onCancel()">{{ 'catalog.racks.edit.cancel' | translate }}</button>
</div>
