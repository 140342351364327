/*
 * File: \src\app\services\login\state\user.action-types.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-27	JF	Names refactored.
 */

/**
 * Action types of user module.
 */
export enum UserActionTypes {
  /** LOGIN PAGE */
  loginStarted = '[Login Page] Login Started',
  loginSucceeded = '[Login Page] Login Succeeded',
  loginFailed = '[Login Page] Login Failed',
  logout = '[Logout] Exited from app.'
}
