import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Instruction } from 'src/app/models';

@Component({
  selector: 'boxcar-console-unpickdialog',
  templateUrl: './unpickdialog.component.html',
})
export class UnpickdialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { instruction: Instruction },
    private translate: TranslateService
  ) {}

  getDescriptionFromInstruction(): string {
    return this.translate.instant('totemServices.instructionManagement.unpickFlow.description', {
      id: this.data.instruction.instructionId,
      partNumber: this.data.instruction.partNumber,
      partDescription: this.data.instruction.partDescription,
      user: this.data.instruction.user,
    });
  }
}
