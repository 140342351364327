/*
 * File: src/app/services/nav-menu/nav-menu.actions.ts
 * Project: boxcar-console
 * Created Date: 2022-09-22 10:00:46
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 *
 */

import { createAction, props } from '@ngrx/store';
import { NavBarMenu } from 'src/app/models/nav.menu';

export const loadNavBarMenu = createAction('Update NavBarMenu', props<{ defaultMenu: NavBarMenu }>());
