import { Injectable } from '@angular/core';
import { RfidTagInfoDto } from '@models/tags';
import { PackagingApiService } from '@services/api';
import { AppToastService } from '..';
import { TranslateService } from '@ngx-translate/core';
import { TotemApiService } from '@services/api/totem.service';
import { MetadataService } from '@services/metadata/metadata.service';
import { TotemGenerateRequestInfos } from '@models/totem';
import { ContainerDto } from '@models/packaging';
import { InstructionType } from '@models/instruction';

@Injectable({
  providedIn: 'root',
})
export class TotemService {
  constructor(
    private packagingApi: PackagingApiService,
    private toastService: AppToastService,
    private translate: TranslateService,
    private totemApi: TotemApiService,
    private metadataService: MetadataService
  ) {}

  async generateStoringInstruction(
    dialogReturn: {
      operationType: InstructionType;
      origin: { origin: string; originLevel: number; originColumn: number };
      destination: { destination: string; destinationLevel: number; destinationColumn: number } | string;
    },
    rfid: RfidTagInfoDto,
    container: ContainerDto
  ) {
    if (dialogReturn.destination === 'auto') {
      try {
        const container = await this.packagingApi.liberateBox(rfid.barcode, true).toPromise();

        this.toastService.success(this.translate.instant('toast.success.totemServices.instructionCreated'));
        return container.instructions![0];
      } catch (error: any) {
        const errorText = `${this.translate.instant(
          'toast.errors.totemServices.instructionCreated'
        )}: ${this.metadataService.getErrorDescriptionFromErrorCode(error.error.code)}`;
        this.toastService.error(errorText);

        return undefined;
      }
    } else {
      dialogReturn.destination = dialogReturn.destination as {
        destination: string;
        destinationLevel: number;
        destinationColumn: number;
      };

      const instructionInfos: TotemGenerateRequestInfos = {
        instructionType: dialogReturn.operationType,
        containerLifeCycleId: container.containerLifeCycleId!,
        origin: dialogReturn.origin.origin,
        originColumn: dialogReturn.origin.originColumn,
        originLevel: dialogReturn.origin.originLevel,
        destination: dialogReturn.destination.destination,
        destinationLevel: dialogReturn.destination.destinationLevel,
        destinationColumn: dialogReturn.destination.destinationColumn,
      };
      try {
        const instruction = await this.totemApi.generateInstruction(instructionInfos).toPromise();

        this.toastService.success(this.translate.instant('toast.success.totemServices.instructionCreated'));

        return instruction;
      } catch (error: any) {
        const errorText = `${this.translate.instant(
          'toast.errors.totemServices.instructionCreated'
        )}: ${this.metadataService.getErrorDescriptionFromErrorCode(error.error.code)} `;

        this.toastService.error(errorText);

        return undefined;
      }
    }
  }

  async generatePickingInstruction(
    dialogReturn: {
      operationType: InstructionType;
      origin: { origin: string; originLevel: number; originColumn: number };
      destination: { destination: string; destinationLevel: number; destinationColumn: number } | string;
    },
    container: ContainerDto
  ) {
    dialogReturn.destination = dialogReturn.destination as {
      destination: string;
      destinationLevel: number;
      destinationColumn: number;
    };

    const instructionInfos: TotemGenerateRequestInfos = {
      instructionType: dialogReturn.operationType,
      containerLifeCycleId: container.containerLifeCycleId!,
      origin: dialogReturn.origin.origin,
      originColumn: dialogReturn.origin.originColumn,
      originLevel: dialogReturn.origin.originLevel,
      destination: dialogReturn.destination.destination,
      destinationLevel: dialogReturn.destination.destinationLevel,
      destinationColumn: dialogReturn.destination.destinationColumn,
    };
    try {
      const instruction = await this.totemApi.generateInstruction(instructionInfos).toPromise();

      this.toastService.success(this.translate.instant('toast.success.totemServices.instructionCreated'));
      return instruction;
    } catch (error: any) {
      const errorText = `${this.translate.instant(
        'toast.errors.totemServices.instructionCreated'
      )}: ${this.metadataService.getErrorDescriptionFromErrorCode(error.error.code)}`;

      this.toastService.error(errorText);
      return undefined;
    }
  }

  async generateReallocationInstruction(
    dialogReturn: {
      operationType: InstructionType;
      origin: { origin: string; originLevel: number; originColumn: number };
      destination: { destination: string; destinationLevel: number; destinationColumn: number } | string;
    },
    container: ContainerDto
  ) {
    let reallocationInstructionInfo: TotemGenerateRequestInfos = {
      instructionType: dialogReturn.operationType,
      containerLifeCycleId: container.containerLifeCycleId!,
      origin: dialogReturn.origin.origin,
      originColumn: dialogReturn.origin.originColumn,
      originLevel: dialogReturn.origin.originLevel,
      destination: null,
      destinationLevel: null,
      destinationColumn: null,
    };
    if (dialogReturn.destination !== 'auto') {
      dialogReturn.destination = dialogReturn.destination as {
        destination: string;
        destinationLevel: number;
        destinationColumn: number;
      };

      reallocationInstructionInfo = {
        ...reallocationInstructionInfo,
        destination: dialogReturn.destination.destination,
        destinationLevel: dialogReturn.destination.destinationLevel,
        destinationColumn: dialogReturn.destination.destinationColumn,
      };
    }

    try {
      const instruction = await this.totemApi.generateInstruction(reallocationInstructionInfo).toPromise();

      this.toastService.success(this.translate.instant('toast.success.totemServices.instructionCreated'));

      return instruction;
    } catch (error: any) {
      const errorText = `${this.translate.instant(
        'toast.errors.totemServices.instructionCreated'
      )}: ${this.metadataService.getErrorDescriptionFromErrorCode(error.error.code)}`;

      this.toastService.error(errorText);

      return undefined;
    }
  }
}
