<button mat-raised-button color="warn" class="full-width" (click)="openMoveBoxDialog()">
  {{ ('totemServices.moveBox.title' | translate).toUpperCase() }}
</button>

<hr />

<mat-card>
  <img class="backgroundImage" src="../../../../assets/icons/stacked-Boxes-Icon.svg" alt="stacked boxes icons" />
  <mat-card-title class="mt-2 mb-3">
    {{ 'totemServices.warehouse' | translate }}
  </mat-card-title>
  <mat-card-content>
    <!-- Header region -->
    <div class="wrapper">
      <div>
        <div class="fields-wrapper">
          <mat-form-field>
            <mat-label>{{ 'catalog.racks.dropdown' | translate }}</mat-label>
            <mat-select
              matNativeControl
              [(ngModel)]="selectedRackRegion"
              (selectionChange)="updateRackRails()"
              [disabled]="isLoading">
              <mat-option [value]="noneRackRegion" selected>{{ noneRackRegion.name }}</mat-option>
              <mat-option *ngFor="let rackRegion of rackRegions" [value]="rackRegion">
                {{ rackRegion.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Update button -->
        <button
          mat-raised-button
          color="primary"
          class="ml-4"
          (click)="updateRackRails()"
          [disabled]="selectedRackRegion.id === 0 || isLoading">
          {{ 'catalog.racks.refreshButton' | translate }}
        </button>
      </div>
    </div>

    <div class="loading-container">
      <loading-on-page [isLoading]="isLoading"></loading-on-page>
      <rack-totem-viewer
        #rackViewer
        [region]="selectedRackRegion"
        [rackRails]="selectedRegionRacks"
        [totalCols]="totalCols"
        [totalLevels]="totalLevels"
        (updateRail)="updateRail($event)"
        (updateInstructions)="instructionCreated.emit()">
      </rack-totem-viewer>
    </div>

    <!-- Boxes buffer area -->
    <div class="bufferArea" *ngIf="selectedRackRegion.id !== 0">
      <!-- Color subtitle -->
      <boxcar-console-color-subtitle></boxcar-console-color-subtitle>

      <!-- Color Scale -->
      <div class="scaleContainer">
        <h2>{{ 'catalog.racks.subtitle.gradientTitle' | translate }}</h2>
        <div
          class="scaleColorContainer"
          [style.--initialColor]="gradientColor.gradientInitialColor"
          [style.--fullColor]="gradientColor.gradientFullColor"></div>
        <div class="textSubtitle">
          <span>{{ 'catalog.racks.subtitle.minQnt' | translate }}</span>
          <span>{{ 'catalog.racks.subtitle.maxQnt' | translate }}</span>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
