<!--
File: \src\app\parts\parts.component.html
Project: boxcar-console
Created Date: 2022-02-08 14:02:76
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-02-08	JF	Parts UI layout. Initial version.
2022-05-16	JF	Updated to use Material UI components.
-->

<mat-card>
  <mat-card-header>
    <mat-card-title>
      <!-- Panel title -->
      <h2>{{ 'catalog.parts.title' | translate }}</h2>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="wrapper">
      <div class="fields-wrapper">
        <!-- Dropdown menu for group selection -->
        <mat-form-field>
          <mat-label>{{ 'catalog.parts.dropdown' | translate }}</mat-label>
          <mat-select matNativeControl [(ngModel)]="selectedPartGroup" (selectionChange)="onChangeGroup()">
            <mat-option *ngFor="let partGroup of partGroups" [value]="partGroup.id">
              {{ partGroup.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <!-- Triggers file input. -->
        <button mat-raised-button matTooltip="{{ 'catalog.partTooltip' | translate }}" color="primary" class="ml-4 mb-1" (click)="showPartsImportDialog()">
          {{ 'catalog.importPartCSV' | translate }}
        </button>
        <button mat-raised-button matTooltip="{{ 'catalog.productionGuideTooltip' | translate }}" color="primary" class="ml-4 mb-1" (click)="showPartsProductionGuideImportDialog()">
          {{ 'catalog.importProductionGuideCSV' | translate }}
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div class="row geral-group" style="margin: 1rem auto">
  <div style="width: 100%">
    <div class="table-options">
      <!-- Parts table search -->
      <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. APILLAR INT RH BLACK" #input />
      </mat-form-field>

      <!-- Refresh button -->
      <button mat-flat-button color="primary" class="reload-button" (click)="onChangeGroup()">
        <img src="../../../../assets/icons/refreshIcon.svg" color="white" width="24px" height="24px" alt="refresh" />
      </button>
    </div>

    <!-- Parts table -->
    <div class="mat-elevation-z2 loading-container">
      <loading-on-page [isLoading]="isLoading"></loading-on-page>
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
        <caption style="display: none">
          Parts info
        </caption>

        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.partnumber' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.number }}</td>
        </ng-container>

        <ng-container matColumnDef="externalCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.externalCode' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.externalCode }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.description' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.description }}</td>
        </ng-container>

        <ng-container matColumnDef="quantityPerBox">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.qtyPerBox' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.quantityPerBox }}</td>
        </ng-container>

        <ng-container matColumnDef="minRequiredStock">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.minQty' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.minRequiredStock }}</td>
        </ng-container>

        <ng-container matColumnDef="maxAllowedStock">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.maxQty' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.maxAllowedStock }}</td>
        </ng-container>

        <ng-container matColumnDef="deliveryQuantityTarget">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.deliveryTarget' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.deliveryQuantityTarget }}</td>
        </ng-container>

        <ng-container matColumnDef="containerModel">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.boxType' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.containerModel }}</td>
        </ng-container>

        <ng-container matColumnDef="group">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.group' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.group }}</td>
        </ng-container>

        <ng-container matColumnDef="suggestedDeliveryLocation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'catalog.parts.suggestedDestination' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.suggestedDeliveryLocation }}</td>
        </ng-container>

        <ng-container matColumnDef="partStrategy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.storage' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ getStringRegionType(row.partStrategy) }}</td>
        </ng-container>

        <ng-container matColumnDef="suggestedProductionSource">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'catalog.parts.suggestedProductionSource' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.suggestedProductionSource }}</td>
        </ng-container>

        <ng-container matColumnDef="injectionMold">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.injectionMold' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.injectionMold }}</td>
        </ng-container>

        <ng-container matColumnDef="consumption_qty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.consumption_qty' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.consumption_qty }}</td>
        </ng-container>

        <ng-container matColumnDef="maxPickingInstructions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.maxPickingInstructions' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.maxPickingInstructions }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="displayedColumns.length">No data matching the filter "{{ input.value }}"</td>
        </tr>
      </table>

      <paginator-page-select
        [pageSizeOptions]="[10, 25, 100]"
        aria-label="Select page of users"
        [length]="dataSource.filteredData.length">
      </paginator-page-select>
    </div>
  </div>
</div>
