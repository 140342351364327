<h1 mat-dialog-title>{{ 'totemServices.instructionManagement.unpickFlow.title' | translate }}</h1>

<h4>{{ getDescriptionFromInstruction() }}</h4>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="{ isComplete: true }">
    {{ 'common.buttons.instruction.unpick' | translate }}
  </button>
  <button mat-button [mat-dialog-close]="{ isComplete: false }">
    {{ 'common.buttons.back' | translate }}
  </button>
</div>
