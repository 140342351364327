export type TokenData = {
  filterName: string;
  filterOptions: string[];
};

export enum TokenState {
  EMPTY = 0,
  NAMED = 1,
  ATTRIBUTION_SIGN = 2,
  OPTION_INCOMPLETE = 3,
  COMPLETED = 4,
}
