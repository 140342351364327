/*
 * File: \src\app\pages\racks\import\racks.content.preview.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-08-30 11:27:46
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RackRailStateDto, RackRailStorageDto } from 'src/app/models/datacatalog';

export type RacksStatePreviewDialogData = {
  racksStateRows: RackRailStateDto[];
};

@Component({
  selector: 'racks-state-preview-dialog',
  templateUrl: 'racks.state.preview.dialog.html',
})
export class RacksStatePreviewDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RacksStatePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RacksStatePreviewDialogData
  ) {}

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  public storageArrayToString(storageArray: RackRailStorageDto[]): string {
    return JSON.stringify(storageArray);
  }
}
