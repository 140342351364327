/*
 * File: \src\app\pages\racks\import\racks.preview.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-05-17 13:01:38
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-05-17	JF	Racks file preview dialog controller.
 */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { LocationDto, RackRailSuggestedParts, RailOperationMode, RackRailType } from 'src/app/models/datacatalog';

export type RacksPreviewDialogData = {
  racksCatalogRows: LocationDto[];
};

@Component({
  selector: 'racks-preview-dialog',
  templateUrl: 'racks.preview.dialog.html',
})
export class RacksPreviewDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RacksPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RacksPreviewDialogData,
    private translate: TranslateService
  ) {}

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  getOperationMode(operationMode: RailOperationMode) {
    switch (operationMode) {
      case RailOperationMode.dynamic:
        return this.translate.instant('catalog.racks.types.dynamic');
      case RailOperationMode.static:
        return this.translate.instant('catalog.racks.types.static');
      case RailOperationMode.fixed:
        return this.translate.instant('catalog.racks.types.fixed');
      default:
        break;
    }
  }

  getRailType(type: RackRailType) {
    switch (type) {
      case RackRailType.nominal:
        return this.translate.instant('catalog.racks.railType.nominal');
      case RackRailType.reverse:
        return this.translate.instant('catalog.racks.railType.reverse');
      case RackRailType.virtual:
        return this.translate.instant('catalog.racks.railType.virtual');
      case RackRailType.fractionating:
        return this.translate.instant('catalog.racks.railType.fractioning');
      default:
        break;
    }
  }

  getPartNumbersFromSuggestedParts(suggestedParts: RackRailSuggestedParts[]) {
    const partNumbers = suggestedParts.map(part => part.partNumber);

    return this.commaSeparatedArray(partNumbers);
  }

  commaSeparatedArray(elements: string[]): string {
    return elements !== undefined ? elements.join(', ') : '';
  }
}
