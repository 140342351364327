import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { appRoutes } from '../routes';

/**
 * This class verifies if the Login route can be activated or not
 */
@Injectable()
export class CanActivateLogin implements CanActivate {
  constructor(private router: Router, private jwtHelper: JwtHelperService) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const canActivateLogin: boolean = this.jwtHelper.isTokenExpired();
    return canActivateLogin ? canActivateLogin : this.router.parseUrl(appRoutes.DASHBOARD_SCREEN);
  }

}
