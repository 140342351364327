<h2 *ngIf="showTitle">
    {{ 'logs.logistics.automaticReturnLogs' | translate }}
</h2>

<div class="loading-container">
    <loading-on-page [isLoading]="isLoading" *ngIf="showLoading"></loading-on-page>
    <div
      class="table-wrapper"
      infinite-scroll
      [scrollWindow]="false"
      [infiniteScrollDistance]="1"
      (scrolled)="requestMoreData()">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows aria-label="All high and medium alerts">
        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <img *ngIf="row.icon" src="../../../../assets/icons/warning_icon.svg" alt="warning icon" />
          </td>
        </ng-container>
  
        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef>{{ 'common.timestamp' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.timestamp }}</td>
        </ng-container>

        <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef>{{ 'common.event' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.event }}</td>
          </ng-container>
  
        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef>{{ 'common.message' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <!-- <div class="message-container"> -->
            <span>
              <span class="title" [innerHTML]="row.message.title"></span>
              <span [innerHTML]="row.message.description"></span>
            </span>
          </td>
        </ng-container>
  
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="columnsToDisplay.length">
            {{ 'logs.noData' | translate }}
          </td>
        </tr>
  
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnsToDisplay"
          [style.--color]="getColorFromSeverity(row.severity)"></tr>
      </table>
    </div>
  </div>
  