import { Component, OnInit } from '@angular/core';
import { defaultTheme } from '@components/rack-viewer/themes/rack-viewer-themes';

@Component({
  selector: 'boxcar-console-color-subtitle',
  templateUrl: './color-subtitle.component.html',
  styleUrls: ['./color-subtitle.component.scss'],
})
export class ColorSubtitleComponent {
  // Colors catalog:
  rackRailColor = {
    nominal: defaultTheme.itemNominalColor,
    inactive: defaultTheme.itemInactiveColor,
    withoutPart: defaultTheme.itemWithoutPartColor,
    returning: defaultTheme.itemReturningColor,
  };

  constructor() {}
}
