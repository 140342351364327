import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataCatalogApiService } from '@services/api';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { ContainerDto, InstructionType, LocationSimplifiedDto, RackRailType } from 'src/app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'boxcar-console-operation-type-selection-dialog',
  templateUrl: './operation-type-selection-dialog.component.html',
  styleUrls: ['./operation-type-selection-dialog.component.scss'],
})
export class OperationTypeSelectionDialogComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({
    opTypeCtrl: new FormControl('', [Validators.required]),
    originCtrl: new FormControl('', [Validators.required]),
    destinationCtrl: new FormControl('', [Validators.required]),
  });
  selectedTypeTranslated: string = '';
  subscriptionOpType!: Subscription;
  subscriptionOrigin!: Subscription;
  subscriptionDestination!: Subscription;
  allRackRails: LocationSimplifiedDto[] = [];
  allPickingAreas: string[] = [];
  allInjectors: string[] = [];
  allAvailableOrigin: string[] = [];
  allAvailableDestination: string[] = [];
  filteredDestinations: string[] = [];
  filteredOrigins: string[] = [];

  promiseAllRackRails!: Promise<void>;
  isMakingRequest: boolean = true;

  constructor(
    private dialogRef: MatDialogRef<OperationTypeSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { containerInfo: ContainerDto },
    private translate: TranslateService,
    private catalogApi: DataCatalogApiService,
    private loadingIndicator: LoadingIndicatorService
  ) {
    this.subscribeOperationType();
    this.subscribeOrigin();
    this.subscribeDestination();
  }

  ngOnDestroy(): void {
    this.subscriptionOpType.unsubscribe();
    this.subscriptionDestination.unsubscribe();
    this.subscriptionOrigin.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.promiseAllRackRails = this.getAllRackRails();
    this.getAllPickingAreas();
    this.getAllInjectors();
  }

  private subscribeOperationType() {
    this.subscriptionOpType = this.form.get('opTypeCtrl')!.valueChanges.subscribe({
      next: value => {
        if (this.isMakingRequest) {
          this.loadingIndicator.show();
        }

        this.selectedTypeTranslated = this.translate.instant(`instructions.types.${value}`).toLowerCase();
        this.promiseAllRackRails.finally(() => {
          this.isMakingRequest = false;
          this.loadingIndicator.hide();
          this.setAvailableOriginAndDestinations(value);
        });
      },
    });
  }

  private subscribeOrigin() {
    this.subscriptionOrigin = this.form.get('originCtrl')!.valueChanges.subscribe({
      next: value => {
        this.filteredOrigins = [
          ...this.allAvailableOrigin.filter(railName => railName.toLowerCase().includes(value.toLowerCase())),
        ];
      },
    });
  }

  private subscribeDestination() {
    this.subscriptionDestination = this.form.get('destinationCtrl')!.valueChanges.subscribe({
      next: value => {
        this.filteredDestinations = [
          ...this.allAvailableDestination.filter(railName => railName.toLowerCase().includes(value.toLowerCase())),
        ];
      },
    });
  }

  private async getAllRackRails() {
    this.allRackRails = (await this.catalogApi.getSimplifiedLocationsData().toPromise());
  }

  private async getAllPickingAreas() {
    this.allPickingAreas = (await this.catalogApi.getAllDestinationsFromCache()).map(area => area.name);
  }

  private async getAllInjectors() {
    const invalidSectors = ['I1', 'G1', 'H1', 'PCDC', 'CDC'];
    const allSectors = (await this.catalogApi.getAllSectorsFromCache()).filter(s => !invalidSectors.includes(s.name));

    for (const sector of allSectors) {
      this.allInjectors = this.allInjectors.concat(sector.sourceDtos.map(i => i.name));
    }
  }

  private resetAllFields() {
    this.form.get('originCtrl')!.setValue('');
    this.form.get('originCtrl')!.enable();
    this.form.get('destinationCtrl')!.setValue('');
    this.form.get('destinationCtrl')!.enable();
  }

  private setAvailableOriginAndDestinations(operationType: string) {
    this.allAvailableDestination = [];
    this.allAvailableOrigin = [];
    this.resetAllFields();

    switch (operationType) {
      case 'reallocation':
        this.allAvailableOrigin = this.allRackRails
          .filter(rail => rail.railType === RackRailType.reverse || rail.railType === RackRailType.virtual)
          .map(rail => rail.name);

        this.allAvailableDestination.push(this.translate.instant('common.autoAddress'));
        this.filteredOrigins = [...this.allAvailableOrigin];
        this.allAvailableDestination = this.allAvailableDestination.concat(this.allRackRails.map(rail => rail.name));

        this.filteredDestinations = [...this.allAvailableDestination];
        break;
      case 'picking':
        this.allAvailableOrigin = this.allRackRails
          .filter(r => r.railType !== RackRailType.reverse)
          .map(rail => rail.name);
        this.filteredOrigins = [...this.allAvailableOrigin];

        this.allAvailableDestination = [...this.allPickingAreas];
        this.filteredDestinations = [...this.allAvailableDestination];
        break;
      case 'storing':
        if (this.data.containerInfo.boxAssembly!.boxAssemblyInfoResponseDto.manufactureSource) {
          this.form
            .get('originCtrl')!
            .setValue(this.data.containerInfo.boxAssembly!.boxAssemblyInfoResponseDto.manufactureSource);
          this.form.get('originCtrl')!.disable();
        } else {
          this.allAvailableOrigin = [...this.allInjectors];
          this.filteredOrigins = [...this.allInjectors];
        }

        this.allAvailableDestination.push(this.translate.instant('common.autoAddress'));
        this.allAvailableDestination = this.allAvailableDestination.concat(this.allRackRails.map(rail => rail.name));
        this.filteredDestinations = [...this.allAvailableDestination];
        break;
    }
  }

  private getOriginValue() {
    const opType = this.form.get('opTypeCtrl')!.value;
    const valueCtrl = this.form.get('originCtrl')!.value;

    if (opType !== 'storing') {
      console.log(valueCtrl.toLowerCase());

      const rackRail = this.allRackRails.find(rail => rail.name.toLowerCase() === valueCtrl.toLowerCase());

      return {
        origin: rackRail!.regionPrefix,
        originColumn: rackRail!.column,
        originLevel: rackRail!.level,
      };
    } else {
      return {
        origin: valueCtrl,
        originColumn: null,
        originLevel: null,
      };
    }
  }

  private getDestinationValue() {
    const opType = this.form.get('opTypeCtrl')!.value;
    const valueCtrl = this.form.get('destinationCtrl')!.value;

    if (opType === 'picking') {
      return {
        destination: valueCtrl,
        destinationColumn: null,
        destinationLevel: null,
      };
    } else if (valueCtrl === this.translate.instant('common.autoAddress')) {
      return 'auto';
    } else {
      const rackRail = this.allRackRails.find(rail => rail.name.toLowerCase() === valueCtrl.toLowerCase());

      return {
        destination: rackRail!.regionPrefix,
        destinationColumn: rackRail!.column,
        destinationLevel: rackRail!.level,
      };
    }
  }

  private getOperationType() {
    const opType = this.form.get('opTypeCtrl')!.value;

    switch (opType) {
      case 'storing':
        return InstructionType.storing;
      case 'picking':
        return InstructionType.picking;
      case 'reallocation':
        return InstructionType.reallocation;
      default:
        return undefined;
    }
  }

  onCreateInstruction() {
    const origin = this.getOriginValue();
    const destination = this.getDestinationValue();
    const operationType = this.getOperationType();

    this.dialogRef.close({
      operationType: operationType,
      origin: origin,
      destination: destination,
    });
  }
}
