import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { InstructionApiService } from '@services/api';
import { ActiveInstructionFilters, Instruction, InstructionSearchQuery, InstructionState } from 'src/app/models';
import { PaginatorPageSelectComponent } from '@components/paginator-page-select/paginator-page-select.component';
import { InstructionUtilsService } from '@services/instructions/instruction-service-utils.service';

@Component({
  selector: 'boxcar-console-contingency-instructions-manager',
  templateUrl: './contingency-instructions-manager.component.html',
  styleUrls: ['./contingency-instructions-manager.component.scss'],
})
export class ContingencyInstructionsManagerComponent implements OnInit {
  private readonly defaultQuery: string = `state=${InstructionState.notStarted},${InstructionState.paused},${InstructionState.started}`;
  instructions: Instruction[] = [];
  instructionsLength: number = 0;
  isLoading: boolean = false;
  requestParams: InstructionSearchQuery = {
    query: this.defaultQuery,
    pageNum: 1,
    qtyPerPage: 10,
  };
  activeFilters: ActiveInstructionFilters = {
    id: true,
    type: true,
    state: false,
    rfid: true,
    epc: true,
    time: true,
    origin: true,
    destination: true,
    part: true,
  };

  @ViewChild(PaginatorPageSelectComponent) paginator!: PaginatorPageSelectComponent;

  constructor(private instructionApi: InstructionApiService, public instructionUtils: InstructionUtilsService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.instructionApi
      .searchInstructions(this.requestParams)
      .toPromise()
      .then(response => {
        this.instructions = [...response.instructions];
        this.instructionsLength = response.instructionsQuantity;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  instructionChanged(data: { instructionIdChanged: number; requestAllInstructions: boolean }) {
    if (data.requestAllInstructions) {
      this.requestInstructions();
    } else {
      const changedInstructionIdx = this.instructions.findIndex(
        instruction => instruction.instructionId === data.instructionIdChanged
      );

      if (changedInstructionIdx !== -1) {
        this.instructions.splice(changedInstructionIdx, 1);
      }
    }
  }

  async requestInstructions() {
    this.isLoading = true;

    this.requestParams = {
      ...this.requestParams,
      pageNum: this.paginator.pageIndex + 1,
      qtyPerPage: this.paginator.pageSize,
    };

    await this.instructionApi
      .searchInstructions(this.requestParams)
      .toPromise()
      .then(response => {
        this.instructions = [...response.instructions];
        this.instructionsLength = response.instructionsQuantity;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onPaginatorChange(pageEvent: PageEvent) {
    this.paginator.pageIndex = pageEvent.pageIndex;
    this.paginator.pageSize = pageEvent.pageSize;
    this.paginator.length = pageEvent.length;

    this.requestInstructions();
  }

  async applyFilters(queryStr: string) {
    this.requestParams.query = queryStr === '' ? this.defaultQuery : queryStr + ' AND ' + this.defaultQuery;
    this.paginator.pageIndex = 0;

    await this.requestInstructions();
  }
}
