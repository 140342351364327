import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialogService: MatDialog) {}

  async openConfirmDialog(title: string, description: string, confirmButtonColor?: string): Promise<string> {
    const dialogResponse = await this.dialogService
      .open(ConfirmDialogComponent, {
        width: '500px',
        data: { title, description, confirmButtonColor},
      })
      .afterClosed()
      .toPromise();

    return dialogResponse;
  }
}
