
<mat-card style="margin-top: 2rem; padding: 2rem;">
  <mat-card-title>Cadastrar etiquetas em lote</mat-card-title>
  <mat-card-content>
    <br>
    <form class="example-form" (ngSubmit)="onSubmit()" [formGroup]="form">
      <mat-form-field class="example-full-width" appearance="outline" [hideRequiredMarker]="true">
        <mat-label>Código do lote</mat-label>
        <input name="batchNumber" matInput formControlName="batchNumber">
      </mat-form-field>

      <mat-form-field class="example-full-width" appearance="outline" [hideRequiredMarker]="true">
        <mat-label>Fabricante</mat-label>
        <mat-select name="manufacture" formControlName="manufacturerId">
          <mat-option *ngFor="let manufacturer of rfidManufacturers" value="{{manufacturer.manufacturerId}}">{{manufacturer.manufacturerName}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="example-full-width" appearance="outline" [hideRequiredMarker]="true">
        <mat-label>EPC inicial</mat-label>
        <input name="startEPC" matInput formControlName="startEPC">
      </mat-form-field>

      <mat-form-field class="example-full-width" appearance="outline" [hideRequiredMarker]="true">
        <mat-label>Quantidade</mat-label>
        <input name="quantity" matInput type="number" formControlName="quantity">
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit" style="margin: 1.5rem auto; padding: 5px 25px;">Cadastrar</button>
    </form>
  </mat-card-content>
  <div>

  </div>

</mat-card>
