<!--
File: \src\app\pages\rfidPrint\rfidtags-print-layout.component.html
Project: boxcar-console
Created Date: 2022-02-09 16:51:33
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-04-27	JF	Template refactored.
-->

<div *ngFor="let page of pages">
  <table>
    <caption hidden>
      Table for print tags
    </caption>
    <tbody>
      <th hidden>Print table</th>
      <tr *ngFor="let pairs of page; let i = index">
        <td>
          <div class="barcode" *ngIf="pairs[0] !== null">
            <img class="logo-psc" src="assets/images/logoPSC.png" alt="" />
            <ngx-barcode6
              [bc-value]="pairs[0].tag"
              [bc-display-value]="true"
              [bc-width]="3"
              [bc-margin-bottom]="0"
              [bc-font-size]="30">
            </ngx-barcode6>
            <span class="vertical" *ngIf="pairs[0].identification">RFID:{{ pairs[0].identification }}</span>
            <img class="powered-text" src="assets/images/powered-text.svg" alt="" />
          </div>
          <div class="barcode" *ngIf="pairs[0] === null">&nbsp;</div>
        </td>
        <td>
          <div class="barcode" *ngIf="pairs[1] !== null">
            <img class="logo-psc" src="assets/images/logoPSC.png" alt="" />
            <ngx-barcode6
              [bc-value]="pairs[1].tag"
              [bc-display-value]="true"
              [bc-width]="3"
              [bc-margin-bottom]="0"
              [bc-font-size]="30">
            </ngx-barcode6>
            <span class="vertical" *ngIf="pairs[0].identification">RFID:{{ pairs[0].identification }}</span>
            <img class="powered-text" src="assets/images/powered-text.svg" alt="" />
          </div>
          <div class="barcode" *ngIf="pairs[1] === null">&nbsp;</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
