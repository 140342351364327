import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@components/error-dialog/error-dialog.component';
import { Instruction, OrderTypes } from '@models/instruction';
import { TranslateService } from '@ngx-translate/core';
import { InstructionApiService } from '@services/api';
import { AppToastService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';

@Component({
  selector: 'waiting-order-card',
  templateUrl: './waiting-order-card.component.html',
  styleUrls: ['./waiting-order-card.component.scss', './../order-card.component.scss'],
})
export class WaitingOrderCardComponent {
  @Input() instruction?: Instruction;
  @Output() makeRequest: EventEmitter<OrderTypes> = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private loadingIndicator: LoadingIndicatorService,
    private instructionApiService: InstructionApiService,
    private toast: AppToastService,
    private matDialog: MatDialog
  ) {}

  formatDate(strDate: string) {
    if (strDate) {
      // Transform strDate in UTC timezone;
      return new Date(strDate + 'Z').toLocaleString('default');
    } else {
      return this.translate.instant('instructions.list.invalidData');
    }
  }

  private openErrorDialog(title: string, description: string) {
    this.matDialog.open(ErrorDialogComponent, {
      data: {
        title: title,
        description: description,
      },
      minWidth: '300px',
      width: '450px',
    });
  }

  async cancelOrder(idInstruction: number) {
    this.loadingIndicator.show();
    await this.instructionApiService
      .cancelInstruction(Number(idInstruction))
      .then(response => {
        this.toast.success(this.translate.instant('instructions.cancelOrders.success'));
        this.makeRequest.emit(OrderTypes.WAITING);
      })
      .catch(error => {
        this.openErrorDialog(
          this.translate.instant('instructions.cancelOrders.failureTitle'),
          this.translate.instant('instructions.cancelOrders.failureDescription')
        );
        this.makeRequest.emit(OrderTypes.WAITING);
      });
    this.loadingIndicator.hide();
  }
}
