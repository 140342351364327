/*
 * File: \src\app\services\models\login.ts
 * Project: boxcar-console
 * Created Date: 2022-04-26 10:18:15
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-26	JF	Types refactored.
 * 2022-09-21 VR  Creating user types.
 */

export type LoginRequestDto = {
  login: string;
  password: string;
};

export type LoginVersionDto = {
  application: string;
  version: string;
}

export type LoginResponseDto = {
  login: string;
  email: string;
  roles: string[];
  token: string;
};

export enum UserTypes {
  'expedition' = 'expedition-operator',
  'admin' = 'admin',
  'edition' = 'edition',
  'operation' = 'operation',
  'auditor' = 'auditor',
  'manager' = 'manager',
  'VBL' = 'VBL'
}
