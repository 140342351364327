<!--
File: \src\app\components\nav-bar\nav-bar.component.html
Project: boxcar-console
Created Date: 2022-02-07 13:42:57
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-04-26	JF	Template refactored.
-->

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    color="accent"
    mode="side"
    opened="true"
    color="accent"
    *ngIf="userLogged$ | async">
    <mat-toolbar>{{ 'menu.title' | translate }}</mat-toolbar>

    <!-- Menus and sub menus for navigation -->
    <mat-nav-list>
      <mat-expansion-panel *ngIf="navBarMenu.auditMenu !== undefined">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'application.menu.audit' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list *ngFor="let menuItem of navBarMenu.auditMenu">
          <a mat-list-item (click)="controlDrawer()" [routerLink]="menuItem.link" routerLinkActive="active">{{
            menuItem.key | translate
          }}</a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header *ngIf="navBarMenu.managementMenu !== undefined">
          <mat-panel-title>{{ 'application.menu.management' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list *ngFor="let menuItem of navBarMenu.managementMenu">
          <a mat-list-item (click)="controlDrawer()" [routerLink]="menuItem.link" routerLinkActive="active">{{
            menuItem.key | translate
          }}</a>
        </mat-nav-list>
      </mat-expansion-panel>
      <a
        *ngIf="navBarMenu.orderMenu !== undefined"
        class="menuItem"
        mat-list-item
        (click)="controlDrawer()"
        [routerLink]="navBarMenu.orderMenu.link"
        routerLinkActive="active"
        >{{ navBarMenu.orderMenu.key | translate }}</a
      >
      <a
        *ngIf="navBarMenu.reportMenu !== undefined"
        class="menuItem"
        mat-list-item
        (click)="controlDrawer()"
        [routerLink]="navBarMenu.reportMenu.link"
        routerLinkActive="active"
        >{{ navBarMenu.reportMenu.key | translate }}</a
      >
      <a
        *ngIf="navBarMenu.totemMenu !== undefined"
        class="menuItem"
        mat-list-item
        (click)="controlDrawer()"
        [routerLink]="navBarMenu.totemMenu.link"
        routerLinkActive="active"
        >{{ navBarMenu.totemMenu.key | translate }}</a
      >

      <a
        *ngIf="navBarMenu.qualityMenu !== undefined"
        class="menuItem"
        mat-list-item
        (click)="controlDrawer()"
        [routerLink]="navBarMenu.qualityMenu.link"
        routerLinkActive="active"
        >{{ navBarMenu.qualityMenu.key | translate }}</a
      >
    </mat-nav-list>
    <div class="version-logged">© Von Braun Labs - {{ version }}</div>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="userLogged$ | async">
        <img
          aria-label="Side nav toggle icon"
          src="../../../assets/icons/menuIcon.svg"
          width="24px"
          height="24px"
          alt="menu icon" />
      </button>
      <a routerLink="/" routerLinkActive="active">
        <img style="height: 50px; margin: 1rem" src="assets/images/logo.svg" alt="Logo boxcar" />
      </a>
      <h1>
        <span> {{ 'application.title' | translate }}</span>
      </h1>
      <span class="spacer"></span>
      <div *ngIf="userLogged$ | async">
        <span class="welcome-message" *ngIf="userData$ | async as userData"
          >{{ 'application.welcome' | translate }}, {{ userData.login }}</span
        >
      </div>
      <a routerLink="/user/login" routerLinkActive="active" mat-raised-button *ngIf="(userLogged$ | async) === false"
        >Login
      </a>
      <a (click)="doLogout()" mat-raised-button *ngIf="userLogged$ | async">Logout </a>
    </mat-toolbar>

    <div class="{{ currentRoute === '/home' || currentRoute === '/' ? 'root-page' : 'outlet' }}">
      <router-outlet></router-outlet>
      <div *ngIf="(userLogged$ | async) === false" class="version-nonlogged">© Von Braun Labs - {{ version }}</div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
