<h1 mat-dialog-title>
  {{ 'totemServices.moveBox.boxFound' | translate }}
</h1>

<h4>
  {{ getDialogDescription() }}
</h4>

<div mat-dialog-content>
  <form [formGroup]="formGroup">
    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'common.tagRFID' | translate }}
      </mat-label>
      <mat-hint>
        {{ 'totemServices.storing.form.rfidFormHint' | translate }}
      </mat-hint>
      <input matInput formControlName="tagRfidCtrl" [matAutocomplete]="autoRFID" />
      <mat-autocomplete #autoRFID="matAutocomplete">
        <mat-option *ngFor="let rfid of filteredRFIDs" [value]="rfid.barcode">
          {{ rfid.barcode }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'common.partNumber' | translate }}
      </mat-label>
      <input matInput formControlName="partNumberCtrl" [matAutocomplete]="autoPartNumber" />
      <mat-autocomplete #autoPartNumber="matAutocomplete">
        <mat-option
          *ngFor="let partNumber of filteredPartNumbers | async"
          [value]="partNumber.number + ' - ' + partNumber.description"
          (click)="updateQuantity(partNumber)">
          {{ partNumber.number }} - {{ partNumber.description }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="container-psmm-tags" *ngFor="let tag of psmmTagsCtrl; index as idx; last as isLast">
      <mat-form-field class="tag" appearance="fill">
        <mat-label>
          {{ 'common.tagPsmm' | translate }}
        </mat-label>
        <input matInput [formControl]="getFormControl('psmmTag', idx)" />
      </mat-form-field>

      <mat-form-field class="qty" appearance="fill">
        <mat-label>
          {{ 'common.qty' | translate }}
        </mat-label>
        <input matInput type="number" [formControl]="getFormControl('qty', idx)" />
      </mat-form-field>

      <button mat-icon-button color="warn" *ngIf="psmmTagsCtrl.length > 1" (click)="removeTagFromIndex(idx)">
        <mat-icon>remove_circle</mat-icon>
      </button>

      <button mat-icon-button color="primary" *ngIf="isLast" (click)="addNewTag()">
        <mat-icon>add_circle</mat-icon>
      </button>

      <mat-form-field class="half-field" appearance="fill">
        <mat-label>
          {{ 'common.operator' | translate }}
        </mat-label>
        <input matInput [formControl]="getFormControl('operator', idx)" />
      </mat-form-field>

      <mat-form-field class="half-field ml" appearance="fill">
        <mat-label>
          {{ 'common.shift' | translate }}
        </mat-label>
        <mat-select [formControl]="getFormControl('shift', idx)">
          <mat-option value="1">{{ 'reports.psmmTag.shift' | translate }} 1</mat-option>
          <mat-option value="2">{{ 'reports.psmmTag.shift' | translate }} 2</mat-option>
          <mat-option value="3">{{ 'reports.psmmTag.shift' | translate }} 3</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="half-field" appearance="fill">
        <mat-label>
          {{ 'common.date' | translate }}
        </mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="getFormControl('date', idx)" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="half-field ml" appearance="fill">
        <mat-label>
          {{ 'common.time' | translate }}
        </mat-label>
        <input
          matInput
          [format]="24"
          [ngxMatTimepicker]="startPicker"
          placeholder="12:00"
          readonly
          [formControl]="getFormControl('time', idx)" />
        <ngx-mat-timepicker-toggle matSuffix></ngx-mat-timepicker-toggle>
        <ngx-mat-timepicker color="primary" #startPicker></ngx-mat-timepicker>
      </mat-form-field>
    </div>

    <mat-form-field appearance="fill" class="half-field">
      <mat-label>
        {{ 'common.originInjector' | translate }}
      </mat-label>
      <input matInput formControlName="sourceCtrl" [matAutocomplete]="autoSource" />
      <mat-autocomplete #autoSource="matAutocomplete">
        <mat-option
          *ngFor="let injector of filteredInjectors | async"
          [value]="injector.name + ' - ' + injector.description">
          {{ injector.name }} - {{ injector.description }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="fill" class="half-field ml">
      <mat-label>
        {{ 'common.destination' | translate }}
      </mat-label>
      <mat-select formControlName="destinationCtrl">
        <mat-option value="FG01">FG01</mat-option>
        <mat-option value="PR97">PR97</mat-option>
        <mat-option value="PR99">PR99</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<h4>{{ 'totemServices.moveBox.associateBox.instructionToAssociate' | translate }}</h4>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!validateForm()" (click)="onClickAssociateBox()">
    {{ 'common.buttons.createAssociation' | translate }}
  </button>
  <button mat-button color="warn" mat-dialog-close>{{ 'common.buttons.cancel' | translate }}</button>
</div>
