<mat-card>
  <mat-card-header>
    <img mat-card-avatar src="../../../../assets/icons/blue-package-icon.svg" alt="blue_package_icon" />
    <mat-card-title>
      <b>
        {{ instruction!.partNumber + ' - ' + instruction!.partDescription }}
      </b>
      <b> #{{ instruction!.instructionId }} </b>
    </mat-card-title>
    <mat-card-subtitle>
      <!-- Data for user and destination -->
      <div>
        {{ 'instructions.list.destination' | translate }}: {{ instruction!.destination }}
        <br />
        <span *ngIf="instruction!.user !== null">
          {{ 'login.username.label' | translate }}: {{ instruction!.user }}
        </span>
      </div>

      <!-- Data for date of order and quantity of boxes -->
      <div>
        {{ 'common.boxesQty' | translate }}: {{ instruction!.boxesQty }}
        <br />
        <span *ngIf="instruction!.lastStateUpdate !== null">
          {{ 'instructions.list.updateDate' | translate }}: {{ formatDate(instruction!.lastStateUpdate) }}
        </span>
      </div>
    </mat-card-subtitle>
  </mat-card-header>
</mat-card>
