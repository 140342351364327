import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InDevelopmentDialogComponent } from '@components/in-development-dialog/in-development-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class InDevelopmentDialogService {
  constructor(private dialog: MatDialog) {}

  openDialog() {
    const dialog = this.dialog.open(InDevelopmentDialogComponent, { width: '450px' });

    const sub = dialog.afterOpened().subscribe({
      complete: () => {
        setTimeout(() => {
          dialog.close();
          sub.unsubscribe();
        }, 3000);
      },
    });
  }
}
