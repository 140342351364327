/*
 * File: \src\app\pages\index.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 09:20:32
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

// Include all pages references:

export * from './parts/parts.component';
export * from './parts/import/parts.import.dialog';
export * from './parts/import/parts.preview.dialog';
export * from './parts/import/partsProductionGuide.import.dialog';
export * from './parts/import/partsProductionGuide.preview.dialog'
export * from './racks/racks.component';
export * from './racks/import/racks.import.dialog';
export * from './racks/import/racks.preview.dialog';
export * from './racks/racks-contents/racks-contents.component';
export * from './racks/racks-contents/rack-edit.dialog/rack-edit.dialog';
export * from './racks/import/racks.state.import.dialog';
export * from './racks/import/racks.state.preview.dialog';
export * from './racks/racks-contents/ract-quantity-dialog/rack-quantity.dialog';
export * from './boxtypes/boxtypes.component';
export * from './rfidPrint/rfidtags-print-layout.component';
export * from './boxes/boxes.component';
export * from './sectors/sectors.component';
export * from './dialogs/merge.result.dialog';
export * from './logs-view/logs.component';
export * from './instructions/list/instruction.list.component';
export * from './orders/expansion-panels.component';
export * from './powerBI/powerBI.component';
export * from './quality/quality.component';
export * from './settings-profiles/settings-profiles.component';
export * from './forklifts-and-equipments/forklifts-and-equipments.component';
