<!--
File: \src\app\pages\parts\import\parts.preview.dialog.html
  Project: boxcar-console
  Created Date: 2022-02-08 14:02:32
  -----
  Copyright 2022 CPA Wernher von Braun
  -----
  HISTORY:
  Date      	By	Comments
  ----------	---	---------------------------------------------------------
  2023-07-14	PS	productionGuide UI. Initial version.
-->


<h1 mat-dialog-title>{{'catalog.review.title' | translate}}</h1>

<div mat-dialog-content>
  <p>{{'catalog.review.checkData' | translate}}</p>

  <!-- File contents preview -->
  <table class="table">
    <caption style="display: none">
      Import part info preview
    </caption>
    <thead>
      <tr>
        <th>{{'catalog.productionGuide.partnumber' | translate}}</th>
        <th>{{'catalog.productionGuide.maxQty' | translate}}</th>
        <th>{{'catalog.productionGuide.minQty' | translate}}</th>
        <th>{{'catalog.productionGuide.deliveryTarget' | translate}}</th>
        <th>{{'catalog.productionGuide.suggestedProductionSource' | translate}}</th>
        <th>{{'catalog.productionGuide.injectionMold' | translate}}</th>
        <th>{{'catalog.productionGuide.consumption_qty' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let part of data.partsCatalogRows | slice:0:10">
        <td>{{ part.number }}</td>
        <td>{{ part.maxAllowedStock }}</td>
        <td>{{ part.minRequiredStock }}</td>
        <td>{{ part.deliveryQuantityTarget }}</td>
        <td>{{ part.suggestedProductionSource }}</td>
        <td>{{ part.injectionMold }}</td>
        <td>{{ part.consumption_qty }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="data">
    {{'catalog.review.sendButton' | translate}}
  </button>
  <button mat-button (click)="onCloseDialog()">{{'catalog.review.cancelButton' | translate}}</button>
</div>