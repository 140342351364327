import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'boxcar-console-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  chipsValue = [true, true, true];
  showTitle = [false];
  refreshData = [false, false, false];
  intervalId: any;
  showTableLoading = true;

  constructor(private translate: TranslateService) {
    this.intervalId = setInterval(() => this.reloadTables(false), 2 * 60000);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  async ngOnInit(): Promise<void> {
    return;
  }

  decideTitleToShow() {
    let counter = 0;

    this.chipsValue.forEach(value => {
      if (value) {
        counter += 1;
      }
    });

    if (counter > 1) {
      this.showTitle = this.showTitle.map(value => {
        return true;
      });
      return this.translate.instant('common.alerts');
    }

    this.showTitle = this.showTitle.map(value => {
      return false;
    });
    return this.getTitleFromIndex(this.chipsValue.findIndex(value => value));
  }

  private getTitleFromIndex(index: number) {
    switch (index) {
      case 0:
        return this.translate.instant('logs.logistics.allLogs');
      case 1:
        return this.translate.instant('logs.logistics.wrongDestinyLogs');
      case 2:
        return this.translate.instant('logs.logistics.automaticReturnLogs');
      default:
        return this.translate.instant('common.alerts');
    }
  }

  handleChipClick(_: Event, index: number) {
    this.chipsValue[index] = !this.chipsValue[index];
    this.showTableLoading = true;
  }

  reloadTables(showLoading: boolean) {
    this.showTableLoading = showLoading;
    // Set refresh data on each table is enabled.
    this.chipsValue.forEach((value, index) => {
      if (value) {
        this.refreshData[index] = true;
      }
    });
  }

  resetRefreshData(idx: number) {
    this.refreshData[idx] = false;
  }
}
