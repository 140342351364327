import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Autocomplete } from 'src/app/common/autocomplete/autocomplete';
import { IAutoComplete } from 'src/app/interfaces/autocomplete/iauto-complete';
import { DataCatalogApiService } from '..';

@Injectable({
  providedIn: 'root',
})
export class AutoCompleteService {
  constructor(private translateService: TranslateService, private dataCatalogApi: DataCatalogApiService) {}

  async createInstructionAutoComplete(control: FormControl): Promise<IAutoComplete> {
    const allParts = (await this.dataCatalogApi.getAllPartsFromCache()).map(p => p.number);
    const allDestinations = (await this.dataCatalogApi.getAllDestinationsFromCache()).map(d => d.name);

    const allInstructionTypes = [
      this.translateService.instant('instructions.types.storing'),
      this.translateService.instant('instructions.types.picking'),
      this.translateService.instant('instructions.types.reallocation'),
    ];

    const allStates = [
      this.translateService.instant('instructions.states.notStarted'),
      this.translateService.instant('instructions.states.started'),
      this.translateService.instant('instructions.states.paused'),
      this.translateService.instant('instructions.states.canceled'),
      this.translateService.instant('instructions.states.concluded'),
    ];

    const allRackRails = (await this.dataCatalogApi.getSimplifiedLocationsData().toPromise())
      .map(r => r.name)
      .filter(n => !n.toLowerCase().includes('null'));

    return new Autocomplete(
      [
        { name: 'id', options: undefined, unique: false },
        { name: 'type', options: allInstructionTypes, unique: false },
        { name: 'state', options: allStates, unique: false },
        { name: 'barcode', options: undefined, unique: false },
        { name: 'epc', options: undefined, unique: true },
        { name: 't', options: undefined, unique: true },
        { name: 'origin', options: allRackRails, unique: false },
        { name: 'destination', options: [...allRackRails.concat(allDestinations)], unique: false },
        { name: 'part', options: allParts, unique: false },
      ],
      this.translateService,
      control
    );
  }
}
