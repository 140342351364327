<!--
File: \src\app\pages\boxes\pallet-tab-detail.dialog\pallet-tab-detail.dialog.component.html
Project: boxcar-console
Created Date: 2022-08-23 14:45:20
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
-->

<!-- Title for standard box -->
<h1 mat-dialog-title *ngIf="data.labelDtos[0].labelcode !== null">
  {{ 'boxes.package' | translate }} <strong>{{ data.labelDtos[0].labelcode }}</strong>
</h1>

<!-- Title for generic box-->
<h1 mat-dialog-title *ngIf="data.labelDtos[0].labelcode === null">
  <strong>{{ 'boxes.genericPackage' | translate }}</strong>
</h1>

<mat-dialog-content>
  <!-- Standard boxes -->
  <div class="psmmTag-cards" *ngIf="data.manufactureArea !== null">
    <boxcar-console-psmm-tag-card
      *ngFor="let psmmTag of data.labelDtos"
      [labelDto]="psmmTag"
      [pn]="data.boxAssemblyInfoResponseDto.partNumber + ' - ' + data.boxAssemblyInfoResponseDto.partDescription"
      [source]="
        data.boxAssemblyInfoResponseDto.manufactureSource +
        ' - ' +
        data.boxAssemblyInfoResponseDto.manufactureSourceDescription
      ">
    </boxcar-console-psmm-tag-card>
  </div>

  <!-- Generic boxes -->
  <div class="genericBox" *ngIf="data.manufactureArea === null">
    {{ 'boxes.genericBoxContent' | translate }}
  </div>
  <div>
    <p>
      <strong>{{ 'boxes.contentInfo' | translate }}</strong>
    </p>
    <span
      >{{ 'boxes.partNumber' | translate
      }}<span style="color: #3880ff">
        {{ data.boxAssemblyInfoResponseDto.partNumber }} - {{ data.boxAssemblyInfoResponseDto.partDescription }}</span
      ></span
    ><br />
    <span
      >{{ 'boxes.source' | translate }} {{ data.boxAssemblyInfoResponseDto.manufactureSource }} -
      {{ data.boxAssemblyInfoResponseDto.manufactureSourceDescription }} </span
    ><br />
    <span *ngIf="data.labelDtos !== undefined"
      >{{ 'boxes.totalQuantity' | translate }} {{ total(data.labelDtos) }}</span
    >
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'rfidTags.close' | translate }}</button>
</mat-dialog-actions>
