import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ContainerService } from '@services/container/container.service';
import { ContainerDto, RfidTagInfoDto } from 'src/app/models';

@Component({
  selector: 'boxcar-console-modify-instruction-dialog',
  templateUrl: './modify-instruction-dialog.component.html',
  styleUrls: ['./modify-instruction-dialog.component.scss'],
})
export class ModifyInstructionDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { containerInfo: ContainerDto; rfid: RfidTagInfoDto },
    private dialogRef: MatDialogRef<ModifyInstructionDialogComponent>,
    private translate: TranslateService,
    private containerService: ContainerService
  ) {}

  ngOnInit(): void {
    return;
  }

  getDialogSubtitle() {
    const stateTranslation = this.containerService.getContainerStateTranslation(this.data.containerInfo);

    return this.translate.instant('totemServices.moveBox.modifyInstruction.description', {
      rfid: this.data.rfid.barcode,
      state: stateTranslation.toUpperCase(),
    });
  }

  onClickCancelInstruction() {
    this.dialogRef.close({ instructionId: this.data.containerInfo.instructions![0].instructionId });
  }
}
