import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { DataCatalogApiService, LoggerApiService } from '@services/api';
import { MetadataService } from '@services/metadata/metadata.service';
import {
  LogDTO,
  LogDictsDto,
  LogQuery,
  LogSeverity,
  WrongDestinyLog,
  incorrectDestinyEventDetal,
} from 'src/app/models';

@Component({
  selector: 'wrong-destination-table',
  templateUrl: './wrong-destination-table.component.html',
  styleUrls: ['./wrong-destination-table.component.scss'],
})
export class WrongDestinationTableComponent implements OnInit, OnChanges {
  @Input() showTitle: boolean = true;
  @Input() showLoading: boolean = true;
  @Input() refreshData: boolean = false;
  @Input() refreshIdx: number = 0;
  @Output() resetRefreshData = new EventEmitter();
  dataSource: MatTableDataSource<WrongDestinyLog> = new MatTableDataSource();
  columnsToDisplay: string[] = [
    'icon',
    'timestamp',
    'instructionID',
    'operator',
    'instructionDestiny',
    'concludedDestiny',
    'readDestiny',
    'part',
  ];
  metadataEvent: LogDictsDto;
  isLoading = false;
  apiParams: LogQuery = {
    filter: '',
    gravity: [LogSeverity.medium, LogSeverity.high],
    eventDetail: incorrectDestinyEventDetal,
    pageNum: 1,
    qtyPerPage: 25,
  };
  localLogs: WrongDestinyLog[] = [];

  constructor(
    private logsService: LoggerApiService,
    private metadataService: MetadataService,
    private translate: TranslateService,
    private dataCatalogApiService: DataCatalogApiService
  ) {
    this.metadataEvent = this.metadataService.getLogMetadata();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.refreshData?.currentValue) {
      this.apiParams.pageNum = 1;
      await this.getLogisticLogs();
      this.dataSource.data = [...this.localLogs];
    }
  }

  async ngOnInit(): Promise<void> {
    await this.getLogisticLogs();
    this.dataSource.data = [...this.localLogs];
  }

  private async getLogisticLogs() {
    const logs: LogDTO[] = [];
    this.localLogs = [];

    this.isLoading = true;
    logs.push(...(await this.logsService.getLogEntries(this.apiParams)).logs);
    this.localLogs = await this.transformLogsIntoLogisticLogs(logs);
    this.isLoading = false;
    this.resetRefreshData.emit(this.refreshIdx);
  }

  private async transformLogsIntoLogisticLogs(logs: LogDTO[]): Promise<WrongDestinyLog[]> {
    const logisticLogs: WrongDestinyLog[] = await Promise.all(
      logs.map(async log => {
        const newLogisticLog: WrongDestinyLog = {
          icon: incorrectDestinyEventDetal.includes(log.eventDetailNumber),
          timestamp: new Date(log.timestamp + 'Z').toLocaleString(),
          instructionId: this.getInstructionId(log.data),
          operator: log.login,
          instructionDestiny: this.getInstructionInfo(log.data),
          concludedDestiny: this.getConcludedInfo(log.data),
          readDestiny: await this.getReadRailInfo(log.data),
          part: this.getPart(log.data),
          severity: this.getSeverityFromNumber(log.eventDetailNumber),
        };
        return newLogisticLog;
      })
    );

    return logisticLogs;
  }

  async requestMoreData() {
    this.showLoading = true;
    this.apiParams = { ...this.apiParams, pageNum: this.apiParams.pageNum + 1 };
    await this.getLogisticLogs();
    this.dataSource.data = this.dataSource.data.concat(this.localLogs);
  }

  getColorFromSeverity(severity: number): string {
    switch (severity) {
      case LogSeverity.high:
        return '#FDDED8';
      case LogSeverity.medium:
        return '#FFF9DB';
      default:
        return 'white';
    }
  }

  private getInstructionId(data: string) {
    const json = JSON.parse(data);

    if (Object.keys(json).includes('instructionInfo')) {
      return json.instructionInfo.id;
    }
    return this.translate.instant('logs.noData');
  }

  private getInstructionInfo(data: string) {
    const json = JSON.parse(data);

    if (Object.keys(json).includes('instructionInfo')) {
      if (json.instructionInfo.destinationLocation) {
        return json.instructionInfo.destinationLocation;
      }
      if (json.instructionInfo.originLocation) {
        return json.instructionInfo.originLocation;
      }
    }

    return this.translate.instant('logs.noData');
  }

  private getConcludedInfo(data: string) {
    const json = JSON.parse(data);

    if (Object.keys(json).includes('concludedRacks')) {
      if (json.concludedRacks.destinationLocation) {
        return json.concludedRacks.destinationLocation;
      }
      if (json.concludedRacks.originLocation) {
        return json.concludedRacks.originLocation;
      }
    }

    return this.translate.instant('logs.noData');
  }

  private async getReadRailInfo(data: string) {
    const json = JSON.parse(data);

    if (Object.keys(json).includes('concludeRequestDto')) {
      if (json.concludeRequestDto.locationReadEpc && json.concludeRequestDto.locationReadEpc !== null) {
        const rail = await this.dataCatalogApiService.getRackRailsInfoFromEPC(json.concludeRequestDto.locationReadEpc);

        return rail.regionPrefix + '.' + rail.column + '.' + rail.level;
      }
    }

    return this.translate.instant('logs.noData');
  }

  private getPart(data: string) {
    const json = JSON.parse(data);

    if (Object.keys(json).includes('instructionInfo')) {
      return json.instructionInfo.partnumber;
    }

    return this.translate.instant('logs.noData');
  }

  private getSeverityFromNumber(number: number): number {
    return this.metadataEvent.eventDetailDict[number].gravity;
  }
}
