<div class="instruction-query-container">
  <form [formGroup]="formGroup" class="form-container">
    <div class="selection-query-container" *ngIf="!isRawQuery; else stringQuery">
      <mat-form-field appearance="outline" *ngIf="activeControllers.id">
        <mat-label>ID</mat-label>
        <input matInput type="text" formControlName="idCtrl" />
        <mat-hint>
          {{ 'common.form.hints.commaSeparator' | translate }}
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="activeControllers.origin">
        <mat-label>{{ 'common.origin' | translate }}</mat-label>
        <input matInput [matAutocomplete]="autoOrigin" formControlName="originCtrl" />
        <mat-autocomplete #autoOrigin="matAutocomplete">
          <mat-option *ngFor="let origin of filteredOrigins" [value]="origin">
            {{ origin }}
          </mat-option>
        </mat-autocomplete>
        <mat-hint>
          {{ 'common.form.hints.commaSeparator' | translate }}
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="activeControllers.destination">
        <mat-label>{{ 'common.destination' | translate }}</mat-label>
        <input matInput [matAutocomplete]="autoDestination" formControlName="destinationCtrl" />
        <mat-autocomplete #autoDestination="matAutocomplete">
          <mat-option *ngFor="let destination of filteredDestinations" [value]="destination">
            {{ destination }}
          </mat-option>
        </mat-autocomplete>
        <mat-hint>
          {{ 'common.form.hints.commaSeparator' | translate }}
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="activeControllers.state">
        <mat-label>{{ 'common.states' | translate }}</mat-label>
        <mat-select [multiple]="true" formControlName="statesCtrl">
          <mat-option *ngFor="let state of instructionStates" [value]="state">
            {{ state.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="activeControllers.type">
        <mat-label>{{ 'instructions.list.type' | translate }}</mat-label>
        <mat-select [multiple]="true" formControlName="typesCtrl">
          <mat-option *ngFor="let type of instructionTypes" [value]="type">
            {{ type.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="activeControllers.time">
        <mat-label>{{ 'common.qtyDaysAgo' | translate }}</mat-label>
        <input matInput type="number" formControlName="timeCtrl" min="1" />
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="activeControllers.epc">
        <mat-label>EPC</mat-label>
        <input matInput type="text" formControlName="epcCtrl" />
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="activeControllers.rfid">
        <mat-label>{{ 'common.tag' | translate }}</mat-label>
        <input matInput type="text" formControlName="tagCtrl" [matAutocomplete]="autoTag" />
        <mat-autocomplete #autoTag="matAutocomplete">
          <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
            {{ tag }}
          </mat-option>
        </mat-autocomplete>
        <mat-hint>
          {{ 'common.form.hints.commaSeparator' | translate }}
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="activeControllers.part">
        <mat-label>{{ 'common.partNumber' | translate }}</mat-label>
        <input matInput [matAutocomplete]="autoPart" formControlName="partCtrl" />
        <mat-autocomplete #autoPart="matAutocomplete">
          <mat-option *ngFor="let part of filteredParts" [value]="part">
            {{ part }}
          </mat-option>
        </mat-autocomplete>
        <mat-hint>
          {{ 'common.form.hints.commaSeparator' | translate }}
        </mat-hint>
      </mat-form-field>
    </div>
  </form>
  <ng-template #stringQuery>
    <mat-form-field class="raw-query-form-field" appearance="outline">
      <mat-label>{{ 'instructions.query' | translate }}</mat-label>
      <input type="text" matInput [formControl]="queryCtrl" [matAutocomplete]="auto" />
      <mat-error *ngIf="autoComplete?.hasErrors()">
        {{ autoComplete?.getErrors() }}
      </mat-error>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let suggestion of autoComplete?.getSuggestions()" [value]="suggestion">
          {{ suggestion }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </ng-template>
</div>

<div class="button-container">
  <button
    type="button"
    mat-flat-button
    color="primary"
    [disabled]="isLoading || formGroup.invalid || autoComplete?.hasErrors()"
    (click)="applyQuery()">
    {{ 'common.buttons.applyFilters' | translate }}
  </button>
  <button type="button" mat-button (click)="toggleQueryMode()">
    {{ 'common.buttons.switchQuery' | translate }}
  </button>
</div>
