import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Instruction } from '@models/instruction';

@Component({
  selector: 'boxcar-console-continue-flow',
  templateUrl: './continue-flow.component.html',
})
export class ContinueFlowComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { instruction: Instruction }) {}
}
