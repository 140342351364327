/*
 * File: \src\app\models\datacatalog.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 16:58:06
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Types created for refactoring.
 */

import { InstructionSummaryDto } from './instruction';
import { BoxTypeDto } from './rfid';

export enum RegionStrategy {
  none = 0,
  jis = 1,
  jit = 2,
  pr97 = 3,
}

export type PartGroupDto = {
  id: number;
  name: string;
};

export type PartDto = {
  id: number;
  // eslint-disable-next-line id-blacklist
  number: string;
  externalCode: string;
  description: string;
  suggestedDeliveryLocation?: string;
  quantityPerBox: number;
  group: string;
  containerModel: string;
  partStrategy: RegionStrategy;
  minRequiredStock: number;
  maxAllowedStock: number;
  deliveryQuantityTarget: number;
  suggestedProductionSource: string;
  injectionMold: string;
  consumption_qty: number;
  maxPickingInstructions: number;
};

export type RackRegionDto = {
  id: number;
  name: string;
  prefix: string;
  columns: number;
  levels: number;
  regionStrategy: RegionStrategy;
  regionStorage: StorageType;
};

export type RailStorage = {
  partNumber: string;
  currentDepth: number;
  partQuantity: number;
};

export enum StorageType {
  queue = 1,
  stack = 2,
  open = 3,
}

export enum RailOperationMode {
  static = 0,
  dynamic = 1,
  fixed = 2,
}

export enum RackRailType {
  nominal = 1,
  reverse = 2,
  virtual = 3,
  fractionating = 4,
}

export type RackRailSuggestedParts = {
  partNumber: string;
  maxQuantity?: number;
};

/** The rack rail all attributes. */
export type LocationDto = {
  id: number;
  name: string;
  railType: RackRailType;
  storageType: StorageType;
  column: number;
  level: number;
  maxBoxes: number;
  storedBoxes: number;
  state: LocationState;
  region: string;
  regionPrefix: string;
  inputSideEpcs: string[] | null;
  outputSideEpcs: string[] | null;
  operationMode: RailOperationMode;
  currentPart: string;
  allowedContainerModels: string[];
  suggestedParts: RackRailSuggestedParts[];
  boxesStorage: RailStorage[];
  pickingLocations: string[];
  inputSideRFIDs: string[];
  outputSideRFIDs: string[];
};

export type LocationSimplifiedDto = {
  id: number;
  name: string;
  region: string;
  regionPrefix: string;
  column: number;
  level: number;
  railType: RackRailType;
  state: LocationState;
};

export type ModifiedRackRailDto = {
  id: number;
  name: string;
  railType: number;
  storageType: StorageType;
  column: number;
  level: number;
  maxBoxes: number;
  storedBoxes: number;
  state: LocationState;
  region: string;
  regionPrefix: string;
  inputSideEpcs: string[] | null;
  outputSideEpcs: string[] | null;
  operationMode: RailOperationMode;
  currentPart: string;
  allowedContainerModels: string[];
  suggestedParts: string[];
  boxesStorage: RailStorage[];
  pickingLocations: string[];
  inputSideRFIDs: string[];
  outputSideRFIDs: string[];
};

export type RackRailStateDto = {
  id: number;
  regionPrefix: string;
  name: string;
  column: number;
  level: number;
  storedBoxes: number;
  storage: RackRailStorageDto[];
};

/** Rack rail attributes to export/import boxes allocation. */
export type RackRailStorageDto = {
  partNumber: string;
  storedBoxes: number;
  order: number;
};

export type RegionDto = {
  id: number;
  name: string;
  description: string;
};

export type MaxAvailableContainer = {
  maxContainerQuantity: number;
};

export type RackEditDialogData = {
  rackRail: LocationDto;
  parts: PartDto[];
  boxTypes: BoxTypeDto[];
  destinations: RegionDto[];
};

export type RackQuantityDialogData = {
  rackRail: LocationDto;
  parts: PartDto[];
  region: RackRegionDto;
};

export type RackRailBasicInfo = {
  regionPrefix: string;
  column: number;
  level: number;
};

export type AssemblyDetail = {
  labelCode: string;
  quantity: number;
  operator: string;
  shift: string;
  radTimestamp: string;
  source: string;
  radDestination: string;
  partNumber: string;
  location?: string;
  instructionDestination?: string;
  creationTime: string;
  containerLifeCycleState?: number
};

export type AssemblyDetailResponse = {
  assemblyDetails: AssemblyDetail[];
  totalAssemblies: number;
};

// We manually add the part name into the base DTO.
export type RackRailExDto = LocationDto & {
  partNumber: string;
  partName: string;
  storedBoxes: number;
};

export type RackRailSummaryDto = LocationDto & InstructionSummaryDto;

export type RackRailContainersStorage = {
  partNumber: string;
  currentDepth: number;
  barcode: string;
  containerLifeCycleId: number;
};

export enum LocationState {
  INVALID = -1,
  ACTIVE = 0,
  LOCATION_STORING_BLOCKED = 1,
  LOCATION_PICKING_BLOCKED = 2,
  LOCATION_STORING_AND_PICKING_BLOCKED = 3,
  LOCATION_FULL = 4,
  LOCATION_FULL_AND_PICKING_BLOCKED = 5,
}

export type LocationBooleans = {
  locationIsFull: boolean;
  locationPickingBlocked: boolean;
  locationStoringBlocked: boolean;
};
