<!--
File: \src\app\components\notice-dialog\notice-dialog.html
Project: boxcar-console
Created Date: 2022-09-14 08:41:03
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
-->

<h1 mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content>
  <strong><p>{{ data.message }}</p></strong>
  <p *ngIf="data.errorCode !== undefined">Error code: <strong>{{ data.errorCode }}</strong></p>
  <p *ngIf="data.details !== undefined">{{ data.details }}</p>
</div>

<div mat-dialog_actions aling="end">
  <!-- Close button -->
  <button mat-button mat-dialog-close color="primary">{{ 'application.close' | translate }}</button>

  <!-- Clipboard copy button -->
  <button mat-button color="primary" (click)="onCopyToClipboard()">
    {{ 'application.copyToClipboard' | translate }}
  </button>
</div>
