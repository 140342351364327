<h1 mat-dialog-title>{{ 'totemServices.picking.operationTitle' | translate }}:</h1>

<p>{{ 'totemServices.picking.operationDescrip' | translate }}: {{ data.partNumber }}</p>

<div [formGroup]="formGroup">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'totemServices.picking.operationLabel' | translate }}</mat-label>
    <mat-select formControlName="operationType">
      <mat-option *ngIf="canDoReallocation()" value="reallocation">{{
        'totemServices.picking.reallocation' | translate
      }}</mat-option>
      <mat-option *ngIf="!canDoReallocation()" value="picking">{{
        'totemServices.picking.picking' | translate
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <p>{{ 'totemServices.picking.selectDestiny' | translate }}:</p>

  <mat-form-field appearance="fill">
    <mat-label>{{ 'common.destination' | translate }}</mat-label>
    <input matInput type="text" [matAutocomplete]="destinies" formControlName="destiny" />
    <mat-autocomplete #destinies="matAutocomplete">
      <mat-option *ngFor="let option of filteredDestinies" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!formGroup.valid" (click)="onClickCreateInstruction()">
    {{ 'common.buttons.instruction.create' | translate }}
  </button>

  <button mat-button mat-dialog-close color="warn">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</div>
