import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataCatalogApiService, InstructionApiService } from '@services/api';
import { TotemApiService } from '@services/api/totem.service';
import { AppToastService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { MetadataService } from '@services/metadata/metadata.service';
import { InstructionType, LocationDto, LocationState, RackRailType, RackRegionDto } from 'src/app/models';
import { TotemGenerateRequestInfos } from 'src/app/models/totem';
import { Subscription } from 'rxjs';

@Component({
  selector: 'boxcar-console-picking-operation-dialog',
  templateUrl: './picking-operation-dialog.component.html',
  styleUrls: ['./picking-operation-dialog.component.scss'],
})
export class PickingOperationDialogComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = new FormGroup({
    operationType: new FormControl(null, [Validators.required]),
    destiny: new FormControl('', [Validators.required, this.isValidOption()]),
  });
  allDestinies: string[] = [];
  filteredDestinies: string[] = [];
  destinySub: Subscription;
  allPickingDestinations: string[] = [];
  allReallocationDestinations: string[] = [];
  allRackRails: LocationDto[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      partNumber: string;
      region: RackRegionDto;
      containerLifeCycleId: number;
      rackRail: LocationDto;
      instructionId?: number;
    },
    private catalogApi: DataCatalogApiService,
    private translate: TranslateService,
    private totemApiService: TotemApiService,
    private toastService: AppToastService,
    private dialogRef: MatDialogRef<PickingOperationDialogComponent>,
    private metadataService: MetadataService,
    private loadingService: LoadingIndicatorService,
    private instructionApiService: InstructionApiService
  ) {
    this.getDestinationOptions();
    this.formGroup.get('destiny')!.disable();
    this.setOnChangeDestinations();
    this.destinySub = this.setSubscriptionDestinationChange();
  }

  ngOnDestroy(): void {
    this.destinySub.unsubscribe();
  }

  ngOnInit(): void {
    return;
  }

  private isValidOption(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.filteredDestinies?.includes(control.value)) {
        return null;
      }
      return { invalidData: true };
    };
  }

  private setOnChangeDestinations() {
    this.formGroup.get('operationType')!.valueChanges.subscribe({
      next: value => {
        this.formGroup.get('destiny')!.setValue('');
        if (value === 'picking') {
          this.allDestinies = [];
          this.allDestinies = [...this.allPickingDestinations];
        } else {
          this.allDestinies = [];
          this.allDestinies = [...this.allReallocationDestinations];
        }

        this.formGroup.get('destiny')!.enable();
      },
    });
  }

  private setSubscriptionDestinationChange() {
    return this.formGroup.get('destiny')!.valueChanges.subscribe({
      next: value => {
        this.filteredDestinies = this.filterDestination(value);
      },
    });
  }

  private async getDestinationOptions() {
    this.loadingService.show();
    this.getPickingOptions();
    if (this.canDoReallocation()) {
      await this.getReallocationOptions();
    }
    this.loadingService.hide();
  }

  private getPickingOptions() {
    this.allPickingDestinations = this.catalogApi.allDestinationsCached.map(value => {
      return value.name;
    });
  }

  private async getReallocationOptions() {
    this.allReallocationDestinations.push(this.translate.instant('totemServices.picking.systemDestiny'));
    await this.catalogApi.getRackRails(this.data.region.id).then(response => {
      this.allReallocationDestinations = this.allReallocationDestinations.concat(
        response.filter(rail => rail.railType == RackRailType.nominal).map(rail => rail.name)
      );
      this.allRackRails = response;
    });
  }

  private filterDestination(value: string) {
    const filterValue = value.toLowerCase();
    return this.allDestinies.filter(value => value.toLowerCase().includes(filterValue));
  }

  private getInstructionTypeNumber(instructionType: InstructionType) {
    if (instructionType === InstructionType.reallocation) {
      return InstructionType.reallocation;
    } else {
      return InstructionType.picking;
    }
  }

  private getDestination(instructionType: InstructionType) {
    const destinationValueCtrl = this.formGroup.get('destiny')!.value;

    const destination: {
      destination: string | null;
      level: number | null;
      column: number | null;
    } = {
      destination: null,
      column: null,
      level: null,
    };

    if (instructionType === InstructionType.picking) {
      destination.destination = destinationValueCtrl;
    } else if (destinationValueCtrl !== this.translate.instant('totemServices.picking.systemDestiny')) {
      const rail = this.allRackRails.find(rail => rail.name === destinationValueCtrl);

      if (rail) {
        destination.destination = rail.regionPrefix;
        destination.column = rail.column;
        destination.level = rail.level;
      }
    }
    return destination;
  }

  canDoReallocation() {
    return this.data.rackRail.railType === RackRailType.reverse || this.data.rackRail.railType === RackRailType.virtual;
  }

  private generateTotemInstruction(totemRequest: TotemGenerateRequestInfos, instructionType: InstructionType) {
    this.totemApiService
      .generateInstruction(totemRequest)
      .toPromise()
      .then(response => {
        this.toastService.success(this.translate.instant('toast.success.totemServices.instructionCreated'));
        this.dialogRef.close({
          instruction: response,
          instructionType: instructionType,
        });
        this.loadingService.hide();
      })
      .catch(err => {
        this.loadingService.hide();
        this.toastService.error(this.metadataService.getErrorDescriptionFromErrorCode(err.error.code));
      });
  }

  private cancelTotemInstruction(instructionId: number) {
    return this.instructionApiService.cancelInstruction(instructionId, true);
  }

  async onClickCreateInstruction() {
    this.loadingService.show();

    const instructionType = this.getInstructionTypeNumber(this.formGroup.get('operationType')!.value);
    const destination = this.getDestination(instructionType);
    const totemRequest: TotemGenerateRequestInfos = {
      instructionType: instructionType,
      containerLifeCycleId: this.data.containerLifeCycleId,
      origin: this.data.rackRail.regionPrefix,
      originColumn: this.data.rackRail.column,
      originLevel: this.data.rackRail.level,
      destination: destination.destination,
      destinationLevel: destination.level,
      destinationColumn: destination.column,
    };

    if (this.data.instructionId) {
      await this.cancelTotemInstruction(this.data.instructionId).catch(error => {
        this.toastService.error(
          `${this.translate.instant(
            'toast.errors.totemServices.instructionCanceled'
          )}: ${this.metadataService.getErrorDescriptionFromErrorCode(error.error.code)} `
        );
      });
    }
    this.generateTotemInstruction(totemRequest, instructionType);
  }
}
