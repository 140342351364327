<!--
File: \src\app\pages\root-page\root-page.component.html
Project: boxcar-console
Created Date: 2022-02-07 13:42:57
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-04-27	JF	Added I18N support.
-->

<div class="row justify-content-md-center">
  <div class="col-12">
    <h2 class="title">BoxCar Console</h2>
    <p class="text">
      <br /> 
      {{ 'application.subtitle1' | translate }}
      {{ 'application.subtitle2' | translate }}
    </p>
  </div>
</div>