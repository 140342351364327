/*
 * File: \src\app\pages\parts\import\parts.import.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-02-08 14:02:32
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2023-07-14	PS	productionGuide UI. Initial version.
 */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type PartsProductionGuideImportDialogData = {
  selectedFile: File;
};

@Component({
  selector: 'partsProductionGuide-import-dialog',
  templateUrl: 'partsProductionGuide.import.dialog.html',
})
export class PartsProductionGuideImportDialogComponent {
  fileTypeAsCSV: string = '.csv';

  constructor(
    public dialogRef: MatDialogRef<PartsProductionGuideImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PartsProductionGuideImportDialogData
  ) {}

  onBrowseFile(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;

    // Single selection file on browse.
    if (fileList !== null) {
      this.data.selectedFile = fileList[0];
    }
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
