/*
 * File: \src\app\pages\racks\racks-contents\rack-edit2.dialog\rack-edit2.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-11-11 13:27:21
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-11-11	JF	Upgraded rack layout edit for new relationships
 */

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { userSelectors } from '@services/login/state';
import {
  PartDto,
  RackEditDialogData,
  LocationDto,
  RackRailExDto,
  RackRailSuggestedParts,
  RailOperationMode,
  UserTypes,
  LocationBooleans,
  LocationState,
} from 'src/app/models';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DialogService } from '@services/dialog/dialog.service';

@Component({
  selector: 'rack-edit-dialog',
  templateUrl: 'rack-edit.dialog.html',
  styleUrls: ['rack-edit.dialog.scss'],
})
export class RackEditDialogComponent implements OnInit, OnDestroy {
  userRoles: string[] = [];
  form: FormGroup;

  partNumberControls: Array<{ partNumber: FormControl; maxQtd: FormControl }> = [];
  inputSideEpcControls: Array<{ inputSideEpc: FormControl }> = [];
  outputSideEpcControls: Array<{ outputSideEpc: FormControl }> = [];

  filteredOptions: Observable<PartDto[]>[] = [];
  localRackRailDTO: LocationDto;
  obsMaxQty!: Subscription;
  subMaxQty!: Subscription;
  subOperationType!: Subscription;

  constructor(
    public dialogRef: MatDialogRef<RackEditDialogComponent>,
    private store: Store,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private dialogAlertService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: RackEditDialogData
  ) {
    this.localRackRailDTO = { ...this.data.rackRail };

    this.store
      .select(userSelectors.userData)
      .subscribe({
        next: userData => {
          if (userData) {
            this.userRoles = userData.roles;
          }
        },
      })
      .unsubscribe();

    this.form = this.formBuilder.group({
      name: new FormControl({ value: null, disabled: !this.userIsAdmin() }, [
        Validators.required,
        Validators.nullValidator,
      ]),
      isStoringBlocked: new FormControl(false),
      isPickingBlocked: new FormControl(false),
      isFull: new FormControl(false),
      operationMode: new FormControl('', [Validators.required]),
      boxType: new FormControl(),
      destination: new FormControl(),
      type: new FormControl(),
      maxBoxes: new FormControl(1, [Validators.min(1)]),
      inputEPC: new FormControl({ value: null, disabled: !this.userIsAdmin() }),
      outputEPC: new FormControl({ value: null, disabled: !this.userIsAdmin() }),
    });
  }

  ngOnInit(): void {
    this.setInitialState();
  }

  ngOnDestroy(): void {
    if (this.obsMaxQty) {
      this.obsMaxQty.unsubscribe();
    }
    if (this.subMaxQty) {
      this.subMaxQty.unsubscribe();
    }
  }

  private createAdditionalPartNumberControls() {
    const numberOfSuggestedParts = this.localRackRailDTO.suggestedParts.length ?? 0;

    for (let index = 0; index < numberOfSuggestedParts; index++) {
      this.addPartNumberControl();
    }
  }

  private createAdditionalInputSideEpcControls() {
    const numberOfEpcs = this.localRackRailDTO.inputSideEpcs?.length ?? 0;

    for (let index = 0; index < numberOfEpcs; index++) {
      this.addInputSideEpcControl();
    }
  }

  private createAdditionalOutputSideEpcControls() {
    const numberOfEpcs = this.localRackRailDTO.outputSideEpcs?.length ?? 0;

    for (let index = 0; index < numberOfEpcs; index++) {
      this.addOutputSideEpcControl();
    }
  }

  private setInitialPartNumberControlsValue() {
    const numberOfSuggestedParts = this.localRackRailDTO.suggestedParts.length ?? 0;

    for (let index = 0; index < numberOfSuggestedParts; index++) {
      this.partNumberControls[index].partNumber.setValue(
        this.localRackRailDTO.suggestedParts[index].partNumber +
          ' - ' +
          this.findDescriptionByPartNumber(this.localRackRailDTO.suggestedParts[index].partNumber)
      );
    }
  }

  private setInitialMaxQtyPerPartNumberControlValue() {
    this.localRackRailDTO.suggestedParts.forEach((value, idx) => {
      this.partNumberControls[idx].maxQtd.setValue(value.maxQuantity);
    });
  }

  private setInitialInputSideEpcControlsValue() {
    const numberOfSuggestedParts = this.localRackRailDTO.inputSideEpcs?.length ?? 0;

    for (let index = 0; index < numberOfSuggestedParts; index++) {
      this.inputSideEpcControls[index].inputSideEpc.setValue(
        this.localRackRailDTO.inputSideEpcs![index]
      );
    }
  }

  private setInitialOutputSideEpcControlsValue() {
    const numberOfSuggestedParts = this.localRackRailDTO.outputSideEpcs?.length ?? 0;

    for (let index = 0; index < numberOfSuggestedParts; index++) {
      this.outputSideEpcControls[index].outputSideEpc.setValue(
        this.localRackRailDTO.outputSideEpcs![index]
      );
    }
  }

  private getOperationTypeFromString(opType: string) {
    switch (opType) {
      case this.translate.instant('catalog.racks.types.static'):
        return RailOperationMode.static;
      case this.translate.instant('catalog.racks.types.dynamic'):
        return RailOperationMode.dynamic;
      case this.translate.instant('catalog.racks.types.fixed'):
        return RailOperationMode.fixed;
      default:
        return RailOperationMode.static;
    }
  }

  private getOperationTypeFromEnum(opType: RailOperationMode) {
    switch (opType) {
      case RailOperationMode.static:
        return this.translate.instant('catalog.racks.types.static');
      case RailOperationMode.dynamic:
        return this.translate.instant('catalog.racks.types.dynamic');
      case RailOperationMode.fixed:
        return this.translate.instant('catalog.racks.types.fixed');
      default:
        return this.translate.instant('catalog.racks.types.static');
    }
  }

  private subscribeMaxQtyOnRail() {
    this.subMaxQty = this.form.get('maxBoxes')!.valueChanges.subscribe({
      next: _ => {
        this.partNumberControls.forEach(control => control.maxQtd.updateValueAndValidity());
      },
    });
  }

  private subscribeOperationTypeRail() {
    this.subOperationType = this.form.get('operationMode')!.valueChanges.subscribe({
      next: value => {
        if (this.getOperationTypeFromString(value) === RailOperationMode.fixed) {
          if (this.partNumberControls.length > 0) {
            this.partNumberControls.forEach(control => {
              control.maxQtd.clearValidators();
              control.maxQtd.addValidators([Validators.required, Validators.min(1)]);
              control.maxQtd.setValue(1);
              control.maxQtd.updateValueAndValidity();
            });
            this.setInitialMaxQtyPerPartNumberControlValue();
          }
        } else {
          this.partNumberControls.forEach(control => {
            control.partNumber.clearValidators();
            control.maxQtd.clearValidators();
            control.maxQtd.setValue(null);
            control.maxQtd.updateValueAndValidity();
          });
        }
      },
    });
  }

  private getBooleansFromLocationState(): LocationBooleans {
    const locationState = this.localRackRailDTO.state;

    switch (locationState) {
      case LocationState.LOCATION_STORING_BLOCKED:
        return { locationIsFull: false, locationPickingBlocked: false, locationStoringBlocked: true };
      case LocationState.LOCATION_PICKING_BLOCKED:
        return { locationIsFull: false, locationPickingBlocked: true, locationStoringBlocked: false };
      case LocationState.LOCATION_STORING_AND_PICKING_BLOCKED:
        return { locationIsFull: false, locationPickingBlocked: true, locationStoringBlocked: true };
      case LocationState.LOCATION_FULL:
        return { locationIsFull: true, locationPickingBlocked: false, locationStoringBlocked: false };
      case LocationState.LOCATION_FULL_AND_PICKING_BLOCKED:
        return { locationIsFull: true, locationPickingBlocked: true, locationStoringBlocked: false };
      default:
        return { locationIsFull: false, locationStoringBlocked: false, locationPickingBlocked: false };
    }
  }

  private getLocationStateFromBooleans(): LocationState {
    const isPickingBlocked = this.form.get('isPickingBlocked')!.value;
    const isStoringBlocked = this.form.get('isStoringBlocked')!.value;
    const isFull = this.form.get('isFull')!.value;

    let state = LocationState.INVALID;

    if (!isFull && !isStoringBlocked && !isPickingBlocked) {
      state = LocationState.ACTIVE;
    } else if (!isFull && isStoringBlocked && !isPickingBlocked) {
      state = LocationState.LOCATION_STORING_BLOCKED;
    } else if (!isFull && !isStoringBlocked && isPickingBlocked) {
      state = LocationState.LOCATION_PICKING_BLOCKED;
    } else if (!isFull && isStoringBlocked && isPickingBlocked) {
      state = LocationState.LOCATION_STORING_AND_PICKING_BLOCKED;
    } else if (isFull && !isStoringBlocked && !isPickingBlocked) {
      state = LocationState.LOCATION_FULL;
    } else if (isFull && !isStoringBlocked && isPickingBlocked) {
      state = LocationState.LOCATION_FULL_AND_PICKING_BLOCKED;
    }

    return state;
  }

  setInitialState() {
    const stateBooleans: LocationBooleans = this.getBooleansFromLocationState();

    this.form.get('name')?.setValue(this.data.rackRail.name);
    this.form.get('isStoringBlocked')?.setValue(stateBooleans.locationStoringBlocked);
    this.form.get('isPickingBlocked')?.setValue(stateBooleans.locationPickingBlocked);
    this.form.get('isFull')?.setValue(stateBooleans.locationIsFull);
    this.form.get('operationMode')?.setValue(this.getOperationTypeFromEnum(this.data.rackRail.operationMode));
    this.form.get('boxType')?.setValue(this.data.rackRail.allowedContainerModels);
    this.form.get('destination')?.setValue(this.data.rackRail.pickingLocations);
    this.form.get('type')?.setValue(this.data.rackRail.railType);
    this.form.get('maxBoxes')?.setValue(this.data.rackRail.maxBoxes);
    /* this.form.get('inputEPC')?.setValue(this.data.rackRail.inputSideEpc);
    this.form.get('outputEPC')?.setValue(this.data.rackRail.outputSideEpc); */

    this.subscribeMaxQtyOnRail();
    this.subscribeOperationTypeRail();

    this.createAdditionalPartNumberControls();
    this.setInitialPartNumberControlsValue();

    this.createAdditionalInputSideEpcControls();
    this.setInitialInputSideEpcControlsValue();

    this.createAdditionalOutputSideEpcControls();
    this.setInitialOutputSideEpcControlsValue();

    if (this.localRackRailDTO.operationMode === RailOperationMode.fixed) {
      this.setInitialMaxQtyPerPartNumberControlValue();
    }
  }

  findDescriptionByPartNumber(partNumber: string): string | undefined {
    return this.data.parts.find(p => p.number === partNumber)?.description;
  }

  private _filterPart(value: string): PartDto[] {
    const filterValue = value.toLowerCase();

    return this.data.parts.filter(option => (option.number + option.description).toLowerCase().includes(filterValue));
  }

  userIsAdmin() {
    return this.userRoles.includes(UserTypes.admin);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private setPartNumberControlFilter(i: number) {
    this.filteredOptions.push(
      this.partNumberControls[i].partNumber.valueChanges.pipe(
        startWith(''),
        map(value => {
          return this._filterPart(value as string);
        })
      )
    );
  }

  getFilteredOptions(i: number): Observable<PartDto[]> {
    return this.filteredOptions[i];
  }

  addPartNumberControl() {
    if (this.data.rackRail.operationMode === RailOperationMode.fixed) {
      this.partNumberControls.push({
        partNumber: new FormControl('', Validators.required),
        maxQtd: new FormControl(1, [this.validatorAllPartNumberQty(), Validators.min(1)]),
      });
    } else {
      this.partNumberControls.push({
        partNumber: new FormControl('', Validators.required),
        maxQtd: new FormControl(null),
      });
    }
    this.setPartNumberControlFilter(this.partNumberControls.length - 1 < 0 ? 0 : this.partNumberControls.length - 1);
  }

  addInputSideEpcControl() {
    this.inputSideEpcControls.push({
      inputSideEpc: new FormControl('', Validators.required)
    });
  }

  addOutputSideEpcControl() {
    this.outputSideEpcControls.push({
      outputSideEpc: new FormControl('', Validators.required)
    });
  }

  removePartNumberControl(index: number) {
    this.partNumberControls.splice(index, 1);
  }

  removeInputSideEpcControl(index: number) {
    this.inputSideEpcControls.splice(index, 1);
  }

  removeOutputSideEpcControl(index: number) {
    this.outputSideEpcControls.splice(index, 1);
  }

  getPartNumberControlByIndex(index: number): FormControl {
    return this.partNumberControls[index].partNumber;
  }

  getInputSideEpcControlByIndex(index: number): FormControl {
    return this.inputSideEpcControls[index].inputSideEpc;
  }

  getOutputSideEpcControlByIndex(index: number): FormControl {
    return this.outputSideEpcControls[index].outputSideEpc;
  }

  getPartNumberMaxQuantityControlByIndex(index: number): FormControl {
    return this.partNumberControls[index].maxQtd;
  }

  validatorAllPartNumberQty(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.partNumberControls.length <= 0) {
        return null;
      } else {
        const maxQtyRail = this.form.get('maxBoxes')?.value;
        let maxQtyFromAllPartNumbers = 0;

        this.partNumberControls.forEach(controls => {
          maxQtyFromAllPartNumbers = maxQtyFromAllPartNumbers + controls.maxQtd.value;
        });

        return maxQtyRail >= maxQtyFromAllPartNumbers
          ? null
          : { wrongQty: { maxValue: maxQtyRail, value: maxQtyFromAllPartNumbers } };
      }
    };
  }

  private validateAllPartNumberQty(): boolean {
    if (this.partNumberControls.length <= 0) {
      return false;
    } else {
      let isValid = true;
      this.partNumberControls.forEach(controls => {
        isValid = isValid && controls.maxQtd.valid;
      });
      return isValid;
    }
  }

  private validateAllPartNumber(): boolean {
    if (this.partNumberControls.length <= 0) {
      return false;
    } else {
      let isValid = true;
      this.partNumberControls.forEach(controls => {
        isValid = isValid && controls.partNumber.valid;
      });
      return isValid;
    }
  }

  validateForm(): boolean {
    if (this.partNumberControls.length > 0) {
      return this.validateAllPartNumber() && this.validateAllPartNumberQty() && this.form.valid;
    } else {
      return this.form.valid;
    }
  }

  onSubmit() {
    const newLocationState = this.getLocationStateFromBooleans();

    if (newLocationState === LocationState.INVALID) {
      this.dialogAlertService.openConfirmDialog(
        this.translate.instant('dialog.railEdit.error.title'),
        this.translate.instant('dialog.railEdit.error.description')
      );
      return;
    }

    let suggestedParts: RackRailSuggestedParts[] = [];

    this.partNumberControls.forEach(control =>
      suggestedParts.push({
        partNumber: control.partNumber.value.split(' - ')[0].trim(),
        maxQuantity: control.maxQtd.value ? control.maxQtd.value : 0,
      })
    );

    const modifiedRackRail: RackRailExDto = {
      ...this.localRackRailDTO,
      name: this.form.get('name')?.value as string,
      state: newLocationState,
      railType: this.form.get('type')?.value as number,
      maxBoxes: this.form.get('maxBoxes')?.value as number,
      partNumber:
        this.partNumberControls.length > 0
          ? this.partNumberControls[0].partNumber.value.split(' - ')[0].trim()
          : ('' as string),
      partName:
        this.partNumberControls.length > 0
          ? this.partNumberControls[0].partNumber.value.split(' - ')[1].trim()
          : ('' as string),
      inputSideEpcs: this.inputSideEpcControls.map(control => control.inputSideEpc.value),
      outputSideEpcs: this.outputSideEpcControls.map(control => control.outputSideEpc.value),
      operationMode: this.getOperationTypeFromString(this.form.get('operationMode')?.value),
      allowedContainerModels: this.form.get('boxType')?.value,
      suggestedParts: suggestedParts,
      pickingLocations: this.form.get('destination')?.value,
    };

    this.dialogRef.close(modifiedRackRail);
  }
}
