<h1 mat-dialog-title>{{ 'totemServices.moveBox.availableTag.title' | translate }}</h1>

<div mat-dialog-content>
  <p>
    {{ 'totemServices.moveBox.availableTag.description' | translate }}
  </p>

  <div class="info-cards">
    <resumed-box-card class="resumed-box-card" [container]="container"></resumed-box-card>
    <instruction-card [instruction]="container!.instructions![0]" [showActions]="false" [showTimeIndicator]="false">
    </instruction-card>
  </div>

  <p>
    {{ 'totemServices.moveBox.availableTag.subDescription' | translate }}
  </p>
</div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" [mat-dialog-close]="{ response: 'associate' }">
    {{ 'totemServices.moveBox.availableTag.buttons.newAssociation' | translate }}
  </button>
  <button mat-flat-button color="warn" [mat-dialog-close]="{ response: 'recover' }">
    {{ 'totemServices.moveBox.availableTag.buttons.recoverAssociation' | translate }}
  </button>
  <button mat-button [mat-dialog-close]="{ response: 'canceled' }">{{ 'common.buttons.cancel' | translate }}</button>
</div>
