import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RackQuantityDialogComponent } from '@pages/index';
import {
  ContainerDto,
  Instruction,
  InstructionType,
  PartDto,
  RackQuantityDialogData,
  RackRailStorageDto,
  RegionStrategy,
  StorageType,
} from 'src/app/models';
import { Observable } from 'rxjs';
import { ConfirmInfoComponent } from './confirm-info/confirm-info.component';
import { PickingOperationDialogComponent } from './picking-operation-dialog/picking-operation-dialog.component';
import { InstructionManagementDialogService } from '@services/instructions/instruction-management-dialog.service';
import { TotemDialogResponse } from 'src/app/models/totem';

@Component({
  selector: 'boxcar-console-storing-dialog',
  templateUrl: './picking-dialog.component.html',
  styleUrls: ['./picking-dialog.component.scss'],
})
export class PickingDialogComponent implements OnInit {
  rackRailStorage: RackRailStorageDto[] = [];
  partNumberFormArray = new FormArray([new FormControl('', Validators.required)]);
  boxQuantityFormArray = new FormArray([new FormControl(0, [Validators.required, Validators.min(0)])]);
  filteredOptions: Observable<PartDto[]>[] = [];
  partNumbers: string[] = [];
  rackRailIsJIT: boolean;
  isFloor: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RackQuantityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RackQuantityDialogData,
    private matDialog: MatDialog,
    private concludeInstructionService: InstructionManagementDialogService
  ) {
    if (this.data.rackRail.column === 0 && this.data.rackRail.level === 0) {
      this.isFloor = true;
    }

    this.setControlsInitialState();
    this.rackRailIsJIT = this.data.region.regionStrategy === RegionStrategy.jit;
  }

  ngOnInit(): void {
    return;
  }

  onCancel(): void {
    this.dialogRef.close({ response: TotemDialogResponse.none });
  }

  private setControlsInitialState() {
    this.sortBoxesByDepth();
    this.reduceRackRailStorage();

    this.createAdditionalPartNumberControls();
    this.setInitialPartNumberControlsValue();

    // Verify if is stack, invert PN controls and box quantities.
    if (this.isStack()) {
      this.partNumberFormArray.controls = this.partNumberFormArray.controls.reverse();
      this.boxQuantityFormArray.controls = this.boxQuantityFormArray.controls.reverse();
    }
  }

  private sortBoxesByDepth() {
    this.data.rackRail.boxesStorage = [...this.data.rackRail.boxesStorage]?.sort(
      (a, b) => a.currentDepth - b.currentDepth
    );
  }

  private reduceRackRailStorage() {
    // If floor group part number.
    if (this.isFloor) {
      this.data.rackRail.boxesStorage.forEach((storage, index) => {
        const position = this.rackRailStorage.findIndex(p => p.partNumber === storage.partNumber);
        if (position === -1) {
          this.rackRailStorage.push({
            partNumber: storage.partNumber,
            storedBoxes: 1,
            order: 0,
          });
        } else {
          this.rackRailStorage[position].storedBoxes++;
          this.rackRailStorage[position].order = index;
        }
      });
    }

    // Else display on rack rail order.
    else {
      let lastPartNumber: string = '';
      let order: number = 0;
      this.data.rackRail.boxesStorage.forEach(box => {
        if (lastPartNumber !== box.partNumber) {
          lastPartNumber = box.partNumber;
          this.rackRailStorage.push({
            partNumber: box.partNumber,
            storedBoxes: 1,
            order: order,
          });
          order += 1;
        } else {
          this.rackRailStorage[order - 1].storedBoxes += 1;
        }
      });
    }
  }

  private setInitialPartNumberControlsValue() {
    const numberOfStoredParts = this.rackRailStorage.length ?? 0;

    if (numberOfStoredParts === 0) {
      this.setupRailDefaultPartNumber();
    } else {
      for (let index = 0; index < numberOfStoredParts; index++) {
        this.partNumberFormArray
          .at(index)
          .setValue(
            this.rackRailStorage[index].partNumber +
              ' - ' +
              this.findDescriptionByPartNumber(this.rackRailStorage[index].partNumber)
          );
        this.partNumberFormArray.at(index).disable();

        this.boxQuantityFormArray.at(index).setValue(this.rackRailStorage[index].storedBoxes);
        this.boxQuantityFormArray.at(index).disable();
      }
    }
  }

  private setupRailDefaultPartNumber() {
    const defaultPartNumber = this.data.rackRail.suggestedParts[0].partNumber;
    this.partNumberFormArray
      .at(0)
      .setValue(defaultPartNumber + ' - ' + this.findDescriptionByPartNumber(defaultPartNumber));
  }

  findDescriptionByPartNumber(partNumber: string): string | undefined {
    return this.data.parts.find(p => p.number === partNumber)?.description;
  }

  private createAdditionalPartNumberControls() {
    const numberOfContainedParts = this.rackRailStorage.length ?? 0;

    for (let index = 1; index < numberOfContainedParts; index++) {
      this.addInputControlPair();
    }
  }

  private addInputControlPair() {
    this.partNumberFormArray.push(new FormControl('', Validators.required));

    this.boxQuantityFormArray.push(new FormControl('', Validators.required));
  }

  getPartNumberControlByIndex(index: number): FormControl {
    return this.partNumberFormArray.at(index) as FormControl;
  }

  getBoxQuantityControlByIndex(index: number): FormControl {
    return this.boxQuantityFormArray.at(index) as FormControl;
  }

  isStack(): boolean {
    return this.data.rackRail.storageType === StorageType.stack;
  }

  onClickRemoveBox(partNumber: string, pnCtrlIndex: number) {
    let depth = 1;
    this.boxQuantityFormArray.controls.slice(0, pnCtrlIndex).forEach(ctrl => {
      depth += ctrl.value as number;
    });

    this.openConfirmInfoDialog(partNumber, depth);
  }

  private openConfirmInfoDialog(partNumber: string, depth: number) {
    const dialog = this.matDialog.open(ConfirmInfoComponent, {
      disableClose: true,
      data: { partNumber: partNumber, rackRailId: this.data.rackRail.id, currentDepth: depth },
      autoFocus: false,
    });

    dialog
      .afterClosed()
      .toPromise()
      .then((dialogResponse: { containerLifeCycleId: number; partNumber: string; instructionId?: number }) => {
        if (dialogResponse) {
          this.openPickingOperationSelectDialog({
            partNumber: dialogResponse.partNumber,
            containerLifeCycleId: dialogResponse.containerLifeCycleId,
            instructionId: dialogResponse.instructionId,
          });
        }
      });
  }

  private openPickingOperationSelectDialog(infos: {
    partNumber: string;
    containerLifeCycleId: number;
    instructionId?: number;
  }) {
    const dialog = this.matDialog.open(PickingOperationDialogComponent, {
      data: {
        partNumber: infos.partNumber,
        region: this.data.region,
        containerLifeCycleId: infos.containerLifeCycleId,
        rackRail: this.data.rackRail,
        instructionId: infos.instructionId,
      },
      disableClose: true,
    });

    dialog
      .afterClosed()
      .toPromise()
      .then((dialogResponse: { instruction: Instruction; instructionType: InstructionType }) => {
        if (dialogResponse) {
          this.openContinueFlowDialog(dialogResponse.instruction);
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.none });
        }
      });
  }

  private openContinueFlowDialog(instruction: Instruction) {
    const dialog = this.concludeInstructionService.openContinueFlowDialog();

    dialog
      .afterClosed()
      .toPromise()
      .then(async (dialogResponse: { continueFlow: boolean }) => {
        if (dialogResponse.continueFlow) {
          this.openConcludeInstructionDialog(instruction);
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.created });
        }
      });
  }

  private openConcludeInstructionDialog(instruction: Instruction) {
    const dialog = this.concludeInstructionService.openConcludeInstructionDialog(instruction);

    dialog
      .afterClosed()
      .toPromise()
      .then(value => {
        if (value.isCompleted) {
          this.dialogRef.close({ response: TotemDialogResponse.concluded, concludedRail: value.concludedRail });
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.created });
        }
      });
  }
}
