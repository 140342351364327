<h1 mat-dialog-title>{{ 'totemServices.storing.associationTitle' | translate }} {{ data.selectedRail.name }}</h1>

<h4>{{ 'totemServices.storing.associationDescription' | translate }} {{ data.selectedRail.name }}</h4>

<div mat-dialog-content>
  <form>
    <div *ngFor="let tag of psmmTagsControls.controls; index as idx; last as isLast">
      <mat-form-field appearance="fill">
        <mat-label>
          {{ 'common.tagPsmm' | translate }}
        </mat-label>
        <input matInput [formControl]="getControlFromIndex(idx)" />
        <mat-error *ngIf="tag.getError('tagUnavailable')">
          {{ 'toast.errors.totemServices.psmmTagUnavailable' | translate }}
        </mat-error>
      </mat-form-field>

      <button mat-icon-button color="warn" *ngIf="psmmTagsControls.length > 1" (click)="removeTagFromIndex(idx)">
        <mat-icon>remove_circle</mat-icon>
      </button>

      <button mat-icon-button color="primary" *ngIf="isLast" (click)="addNewTag()">
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!psmmTagsControls.valid" (click)="verifyPSMMTags()">
    {{ 'common.buttons.createAssociation' | translate }}
  </button>
  <button mat-button (click)="onCancel()" color="warn">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</div>
