<!--
File: \src\app\pages\racks\import\racks.content.import.dialog.html
Project: boxcar-console
Created Date: 2022-08-30 10:37:59
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
-->

<h1 mat-dialog-title>{{'warehouse.racks.modal.title' | translate}}</h1>

<div mat-dialog-content>
  <p><strong>Note: </strong>{{'warehouse.racks.modal.warningMessage' | translate}}</p>
  <p>{{'warehouse.racks.modal.instructions' | translate}}</p>

  <pre>
    <code [innerHtml]="rackRailStateJsonSnippet"></code>
  </pre>

  <strong>
    <p>{{'warehouse.racks.modal.selectFile' | translate}}</p>
  </strong>

  <!-- File browse field -->
  <button type="button" mat-raised-button (click)="fileInput.click()">
    {{'warehouse.racks.modal.chooseFile' | translate}}
  </button>
  <input hidden type="file" accept="{{fileTypeAsJSON}}" #fileInput (change)="onBrowseFile($event)" />
  <p style="margin: 6px">{{data.selectedFile ? data.selectedFile.name: '' }}</p>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" [mat-dialog-close]="data" [disabled]="data.selectedFile === undefined">
    {{'warehouse.racks.modal.processButton' | translate}}
  </button>
  <button mat-button (click)="onCloseDialog()">{{'warehouse.racks.modal.cancelButton' | translate}}</button>
</div>
