/*
 * File: \src\app\models\rfid.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 16:10:46
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Types created for refactoring.
 */

export type BoxTypeDto = {
  id: number;
  name: string;
  description: string;
};

export type BatchCreationDto = {
  initialEpc: string;
  containerQtd: number;
  containerModel: string;
};

export type BatchCreationReturn = {
  paperEpcs: string[];
  rfidEpcs: string[];
};

export type PrintDto = {
  tag: string;
  identification?: string;
};
