<h1 mat-dialog-title>{{ 'totemServices.moveBox.title' | translate }}:</h1>
<h4>
  {{ 'totemServices.moveBox.dialogDescription' | translate }}
</h4>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'totemServices.moveBox.inputLabel' | translate }}</mat-label>

    <input type="text" matInput [formControl]="tagCtrl" [matAutocomplete]="auto" />

    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let rfid of rfidTagsFiltered" [value]="rfid.barcode">
        {{ rfid.barcode }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!tagCtrl.valid || isLoading" (click)="handleContinueButton()">
    {{ 'common.buttons.continue' | translate }}
  </button>
  <button mat-button mat-dialog-close color="warn">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</div>
