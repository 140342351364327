<br />
<mat-form-field appearance="outline">
  <mat-label>Buscar</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. 1612" #input />
</mat-form-field>

<div class="mat-elevation-z2 loading-container">
  <!-- Loading indicator -->
  <loading-on-page [isLoading]="isLoading"></loading-on-page>

  <table mat-table [dataSource]="dataSource" matSort>
    <caption style="display: none">
      Box type info
    </caption>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'boxTypes.name' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'boxTypes.description' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.description }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="displayedColumns.length">
        {{ 'general.nomatch' | translate }} "{{ input.value }}"
      </td>
    </tr>
  </table>

  <paginator-page-select [length]="dataSource.filteredData.length"></paginator-page-select>
</div>
