import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContainerDto } from '@models/packaging';

@Component({
  selector: 'boxcar-console-placed-detail-dialog',
  templateUrl: './placed-detail-dialog.component.html',
  styleUrls: ['./placed-detail-dialog.component.scss'],
})
export class PlacedDetailDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { container: ContainerDto }) {}
}
