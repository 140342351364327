import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MetadataService } from '@services/metadata/metadata.service';
import {
  BatchCreationDto,
  ContainerLifeCycleState,
  InstructionType,
  LogDictsDto,
  LogMessage,
  RackRailBasicInfo,
} from 'src/app/models';
import {
  BlockedRailWithReasonData,
  BoxEventInfo,
  ChecklistFormResponse,
  ConcludeInstructionRequestDto,
  ConcludedRacks,
  ContainerCreateRequest,
  ContainerInfo,
  ContainerLiberateRequestDto,
  ContainerLifeCycleInfo,
  DispatchInfo,
  GenerationRequestDto,
  InstructionBlockedRailData,
  InstructionInfo,
  InstructionUpdate,
  LabelInfo,
  LabelReadingRequestDto,
  OngoingRequestDto,
  RadLabelInfo,
  RemovedContainerData,
  RfidContentorSyncRequest,
  SettingsProfileInfo,
  SynchronizeInfo,
  UserData,
} from 'src/app/models/logs-data-structure';
import { DataCatalogApiService } from '..';

@Injectable({
  providedIn: 'root',
})
export class DataLogsService {
  metadataEvent: LogDictsDto;
  errors: Record<string, Record<string, string>> = {};
  cachedRegion: Record<string, RackRailBasicInfo> = {};

  constructor(
    private metadataService: MetadataService,
    private catalogService: DataCatalogApiService,
    private translate: TranslateService
  ) {
    this.metadataEvent = this.metadataService.getLogMetadata();
    this.errors = this.metadataService.getErrorsMetadata();
  }

  async getLexicalDataFromJSON(data: string, eventDetailNumber: number, logCode: number): Promise<LogMessage> {
    const logMessage: LogMessage = { title: '', description: '' };

    if (logCode >= 1000 && logCode <= 4000) {
      logMessage.title += this.metadataService.getErrorDescriptionFromErrorCode(logCode) + '</strong>';
    } else {
      logMessage.title += this.getDetailFromNumber(eventDetailNumber) + '</strong>';
    }

    try {
      const json = JSON.parse(data);
      let hasInstructionConcluded = false;
      const keys = Object.keys(json);

      if (keys.includes('userData')) {
        logMessage.description += '</br>' + this.getInfoFromUserData(json.userData as UserData);
      }

      if (keys.includes('settingsProfileInfo')) {
        logMessage.description += '</br>' + this.getInfoFromSettingsProfileInfo(json.settingsProfileInfo as SettingsProfileInfo);
      }

      if (keys.includes('destinyRackRailTag')) {
        logMessage.description += '</br>' + (await this.getInfoFromRackRailData(json));
      }

      if (keys.includes('containerInfo')) {
        logMessage.description += '</br>' + this.getInfoFromContainer(json.containerInfo);
      }

      if (keys.includes('instructionInfo')) {
        logMessage.description += '</br>' + this.getInfoFromInstruction(json.instructionInfo);
      }

      if (keys.includes('instructionUpdate')) {
        logMessage.description += '</br>' + this.getInfoFromInstructionUpdate(json.instructionUpdate);
      }

      if (keys.includes('concludedRacks')) {
        logMessage.description += '</br>' + this.getInfoFromConcludedRacks(json.concludedRacks);
        hasInstructionConcluded = true;
      }

      if (keys.includes('concludeRequestDto')) {
        logMessage.description +=
          '</br>' + (await this.getInfoFromConcludeRequest(json.concludeRequestDto, hasInstructionConcluded));
      }

      if (keys.includes('generationRequestDto')) {
        logMessage.description += '</br>' + this.getInfoFromGenerationDto(json.generationRequestDto);
      }

      if (keys.includes('synchronizeInfo')) {
        logMessage.description += '</br>' + this.getInfoFromSync(json.synchronizeInfo);
      }

      if (keys.includes('containerLifeCycleInfo')) {
        logMessage.description += '</br>' + this.getInfoFromContainerLifeCycle(json.containerLifeCycleInfo);
      }

      if (keys.includes('createContainerBatchRequestDto')) {
        logMessage.description += '</br>' + this.getInfoFromBatchRequest(json.createContainerBatchRequestDto);
      }

      if (keys.includes('containerCreateRequestDto')) {
        logMessage.description += '</br>' + this.getInfoFromCreateContainer(json.containerCreateRequestDto);
      }

      if (keys.includes('containerRfidRequestDto')) {
        logMessage.description += '</br>' + this.getInfoFromCreateRfid(json.containerRfidRequestDto);
      }

      if (keys.includes('ongoingRequestDto')) {
        logMessage.description += '</br>' + this.getInfoFromOnGoingRequest(json.ongoingRequestDto);
      }

      if (keys.includes('labelReadingRequestDto')) {
        logMessage.description += '</br>' + this.getInfoFromLabelReading(json.labelReadingRequestDto);
      }

      if (keys.includes('containerLiberateRequestDto')) {
        logMessage.description += '</br>' + this.getInfoFromLiberateRequest(json.containerLiberateRequestDto);
      }

      if (keys.includes('dispatchInfo')) {
        logMessage.description += '</br>' + this.getInfoFromDispatchInfo(json.dispatchInfo);
      }

      if (keys.includes('radLabelInfo')) {
        logMessage.description += '</br>' + this.getInfoFromRadLabelInfo(json.radLabelInfo);
      }

      if (keys.includes('ChecklistFormRequest')) {
        logMessage.description += '</br>' + this.getInfoFromChecklistForm(json.ChecklistFormRequest);
      }

      if (keys.includes('creationUser')) {
        logMessage.description += '</br>' + this.getInfoFromCreationUser(json.creationUser);
      }

      if (keys.includes('labelsInfo')) {
        logMessage.description += '</br>' + this.getInfoFromLabelsInfo(json.labelsInfo);
      }

      if (keys.includes('activeBarcode')) {
        logMessage.description += '</br>' + this.getInfoFromLabelsInfo(json.activeBarcode);
      }

      if (keys.includes('removedContainerData')) {
        logMessage.description += '</br>' + this.getInfoFromRemovedContainer(json.removedContainerData);
      }

      if (keys.includes('instructionBlockedRailData')) {
        logMessage.description += '</br>' + this.getInfoFromBlockedRailFromInstruction(json.instructionBlockedRailData);
      }

      if (keys.includes('boxEventInfo')) {
        logMessage.description += '</br>' + this.getInfoFromAutomaticReturnEvent(json.boxEventInfo);
      }

      if (keys.includes('blockedRailWithReasonData')) {
        logMessage.description += '</br>' + this.getInfoFromBlockedRailWithReasonData(json.blockedRailWithReasonData);
      }

      return logMessage;
    } catch {
      return logMessage;
    }
  }

  private getInfoFromAutomaticReturnEvent(boxEventInfo: BoxEventInfo){
    return this.translate.instant('logs.containerDetectedAutomaticReturn', {
      location: boxEventInfo.location,
      containerEpc: boxEventInfo.containerEpc,
      containerLifeCycleId: boxEventInfo.containerLifeCycleId !== null ? boxEventInfo.containerLifeCycleId : "N/A",
      instructionId: boxEventInfo.instructionId !== null ? boxEventInfo.instructionId : "N/A"
    });
  }

  private getInfoFromBlockedRailFromInstruction(instructionBlockedRailData: InstructionBlockedRailData) {
    return this.translate.instant('logs.blockedRailFromInstruction', {
      operator: instructionBlockedRailData.user,
      location: instructionBlockedRailData.location,
      instructionId: instructionBlockedRailData.instruction,
      reason: this.translate.instant(`logs.blockedReasons.${instructionBlockedRailData.reason}`),
    });
  }

  private getInfoFromBlockedRailWithReasonData(blockedRailWithReasonData: BlockedRailWithReasonData) {
    const reasons = this.metadataService.getManualReasonsMetadata();
    let reason = 'N/A';

    if (navigator.language === 'pt-BR') {
      reason = reasons.find((r: any) => r.reasonId === blockedRailWithReasonData.reasonId)?.reasonPT ?? reason;
    } else {
      reason = reasons.find((r: any) => r.reasonId === blockedRailWithReasonData.reasonId)?.reason ?? reason;
    }

    let html = this.translate.instant('logs.blockedRailFromInstruction', {
      operator: blockedRailWithReasonData.user,
      location: blockedRailWithReasonData.location,
      instructionId: blockedRailWithReasonData.instruction,
      reason,
    });

    if (blockedRailWithReasonData.reasonText) html += ` (${blockedRailWithReasonData.reasonText})`;

    return html;
  }


  private getInfoFromRemovedContainer(removedContainerData: RemovedContainerData) {
    let html = `${this.translate.instant('logs.instructionIdTriggered')}: ${removedContainerData.actionInstruction}`;

    if (removedContainerData.activeInstruction) {
      html += `, ${this.translate.instant('logs.instructionIdCanceled')}: ${removedContainerData.activeInstruction}}`;
    }

    html += `</br><b>${this.translate.instant('logs.box')}:</b> ${this.translate.instant('logs.barcode')}: ${
      removedContainerData.barcode
    }, ${this.translate.instant('logs.labelCodes')}: ${removedContainerData.labelCodes.join(', ')}`;

    return html;
  }

  private getDetailFromNumber(num: number): string {
    if (navigator.language === 'pt-BR') {
      return this.metadataEvent.eventDetailDict[num].namePT;
    } else {
      return this.metadataEvent.eventDetailDict[num].nameEN;
    }
  }

  public lifeCycleStateDescription(state: number) {
    switch (state) {
      case ContainerLifeCycleState.available:
        return this.translate.instant('common.containerLifeCycleStates.available');
      case ContainerLifeCycleState.onGoing:
        return this.translate.instant('common.containerLifeCycleStates.onGoing');
      case ContainerLifeCycleState.liberated:
        return this.translate.instant('common.containerLifeCycleStates.liberated');
      case ContainerLifeCycleState.transit:
        return this.translate.instant('common.containerLifeCycleStates.transit');
      case ContainerLifeCycleState.placed:
        return this.translate.instant('common.containerLifeCycleStates.placed');
      case ContainerLifeCycleState.placedQuality:
        return this.translate.instant('common.containerLifeCycleStates.placedQuality');
      case ContainerLifeCycleState.delivered:
        return this.translate.instant('common.containerLifeCycleStates.delivered');
      case ContainerLifeCycleState.canceled:
        return this.translate.instant('common.containerLifeCycleStates.canceled');
      case ContainerLifeCycleState.placedFloor:
        return this.translate.instant('common.containerLifeCycleStates.placedFloor');
      case ContainerLifeCycleState.replaced:
        return this.translate.instant('common.containerLifeCycleStates.replaced');
      case ContainerLifeCycleState.restored:
        return this.translate.instant('common.containerLifeCycleStates.restored');
      default:
        return this.translate.instant('common.containerLifeCycleStates.any');
    }
  }

  private getInfoFromUserData(data: UserData) {
    let html = '';
    if (!data) {
      return this.translate.instant('logs.noData');
    }

    if (data.application) html += data.application;
    if (data.version) html += ' ' + data.version;

    return html;
  }

  private getInfoFromSettingsProfileInfo(data: SettingsProfileInfo) {
    let html = '';
    if (!data) {
      return this.translate.instant('logs.noData');
    }

    if (data.profileName) html += `<strong>Perfil:</strong> ${data.profileName} <br>`;

    return html;
  }

  private async getInfoFromRackRailData(data: any) {
    let html = '<strong>' + this.translate.instant('logs.destinyRackRailTag') + ': </strong>';

    if (!data.destinyRackRailTag) {
      html += this.translate.instant('logs.noData');
    } else {
      if (!this.cachedRegion[data.destinyRackRailTag]) {
        const rack = await this.catalogService.getRackRailsInfoFromEPC(data.destinyRackRailTag);
        this.cachedRegion[data.destinyRackRailTag] = rack;
      }
      html +=
        this.cachedRegion[data.destinyRackRailTag].regionPrefix +
        '.' +
        this.cachedRegion[data.destinyRackRailTag].column +
        '.' +
        this.cachedRegion[data.destinyRackRailTag].level;
    }

    html += ' <strong>' + this.translate.instant('logs.originRackRailTag') + ': </strong>';

    if (!data.originRackRailTag) {
      html += this.translate.instant('logs.noData');
    } else {
      if (!this.cachedRegion[data.originRackRailTag]) {
        const rack = await this.catalogService.getRackRailsInfoFromEPC(data.originRackRailTag);
        this.cachedRegion[data.originRackRailTag] = rack;
      }
      html +=
        this.cachedRegion[data.originRackRailTag].regionPrefix +
        '.' +
        this.cachedRegion[data.originRackRailTag].column +
        '.' +
        this.cachedRegion[data.originRackRailTag].level;
    }

    html += '</br><strong>' + this.translate.instant('logs.containerTag') + ': </strong>';

    if (!data.containerTag || data.containerTag.toLowerCase().includes('null')) {
      html += this.translate.instant('logs.noData');
    } else {
      html += data.containerTag;
    }

    if (!data.fWasAutomatic) {
      html += '</br>' + this.translate.instant('logs.notAutomaticConclusion');
    } else {
      html += '</br>' + this.translate.instant('logs.automaticConclusion');
    }
    return html;
  }

  private getInfoFromInstruction(data: InstructionInfo) {
    let hasOrigin = false;
    let html =
      '<strong>' +
      this.getInstructionTypeFromEnum(data.type) +
      '</strong> <br>' +

      this.translate.instant('common.id') +
      ' ';

    if (data.id) {
      html += data.id;
    } else {
      html += this.translate.instant('logs.noData');
    }

    html += ', PN ';

    if (data.partnumber) {
      html += data.partnumber;
    } else {
      html += this.translate.instant('logs.noData');
    }

    if (data.originLocation) {
      html += '</br>' + this.translate.instant('logs.originRackInstruction') + ': ' + data.originLocation;
      hasOrigin = true;
    }

    if (data.destinationLocation) {
      if (hasOrigin) {
        html += ', ';
      } else {
        html += ', </br>';
      }
      html += this.translate.instant('logs.destinyRackInstruction') + ': ' + data.destinationLocation;
    }

    return html;
  }

  private getInstructionTypeFromEnum(idx: number) {
    switch (idx) {
      case InstructionType.storing:
        return this.translate.instant('logs.storingInstruction');
      case InstructionType.picking:
        return this.translate.instant('logs.pickingInstruction');
      case InstructionType.reallocation:
        return this.translate.instant('logs.reallocationInstruction');
      default:
        return this.translate.instant('common.instruction');
    }
  }

  private async getInfoFromConcludeRequest(data: ConcludeInstructionRequestDto, hasInstructionConcluded: boolean) {
    let html = '';

    if (!hasInstructionConcluded) {
      if (data.originLocationEpc) {
        html += this.translate.instant('logs.originRackConcluded') + ': ';
        if (!this.cachedRegion[data.originLocationEpc]) {
          const rack = await this.catalogService.getRackRailsInfoFromEPC(data.originLocationEpc);
          this.cachedRegion[data.originLocationEpc] = rack;
        }
        html +=
          this.cachedRegion[data.originLocationEpc].regionPrefix +
          '.' +
          this.cachedRegion[data.originLocationEpc].column +
          '.' +
          this.cachedRegion[data.originLocationEpc].level;
      }

      if (data.destinationLocationEpc) {
        html += this.translate.instant('logs.destinyRackConcluded') + ' ';
        if (!this.cachedRegion[data.destinationLocationEpc]) {
          const rack = await this.catalogService.getRackRailsInfoFromEPC(data.destinationLocationEpc);
          this.cachedRegion[data.destinationLocationEpc] = rack;
        }
        html +=
          this.cachedRegion[data.destinationLocationEpc].regionPrefix +
          '.' +
          this.cachedRegion[data.destinationLocationEpc].column +
          '.' +
          this.cachedRegion[data.destinationLocationEpc].level;
      }
    }

    if (data.locationReadEpc) {
      if (!this.cachedRegion[data.locationReadEpc]) {
        const rail = await this.catalogService.getRackRailsInfoFromEPC(data.locationReadEpc);
        this.cachedRegion[data.locationReadEpc] = rail;
      }

      html +=
        this.translate.instant('logs.railRead') +
        ': ' +
        this.cachedRegion[data.locationReadEpc].regionPrefix +
        '.' +
        this.cachedRegion[data.locationReadEpc].column +
        '.' +
        this.cachedRegion[data.locationReadEpc].level +
        ' ';
    }

    html +=
      this.translate.instant('logs.containerTagConcluded') + ': ' + (data.containerEpc ?? this.translate.instant('logs.noData')) + '<br>';

    html +=
      this.translate.instant('logs.readedContainerTag') + ': ' + (data.containerReadEpc ?? this.translate.instant('logs.noData'));

    return html;
  }

  private getInfoFromConcludedRacks(data: ConcludedRacks) {
    let html = '';

    if (data.originLocation) {
      html += this.translate.instant('logs.originRackConcluded') + ': ' + data.originLocation;
    }

    if (data.destinationLocation) {
      if (html !== '') {
        html += ', ';
      }
      html += this.translate.instant('logs.destinyRackConcluded') + ': ' + data.destinationLocation;
    }

    return html;
  }

  private getInfoFromContainer(data: ContainerInfo) {
    let html =
      '<strong>' + this.translate.instant('logs.box') + '</strong>: ' + this.translate.instant('common.id') + ': ';

    if (data.id) {
      html += data.id;
    } else {
      html += this.translate.instant('logs.noData');
    }

    html += ', ' + this.translate.instant('logs.barcode') + ': ';

    if (data.barcode) {
      html += data.barcode;
    } else {
      html += this.translate.instant('logs.noData');
    }

    if (data.model) {
      html += ', ' + this.translate.instant('common.containerModel') + ': ' + data.model;
    }

    if (data.radMessages && data.radMessages !== '[]') {
      html += '</br>' + data.radMessages;
    }

    return html;
  }

  private getInfoFromInstructionUpdate(data: InstructionUpdate) {
    let html = '<strong>' + this.translate.instant('logs.updatedInstruction') + ': </strong>';
    let hasChangedLocation = false;

    if (data.originalLocation !== data.currentLocation) {
      hasChangedLocation = true;

      html += this.translate.instant('logs.updatedRack', {
        old: `<strong>${data.originalLocation}</strong>`,
        new: `<strong>${data.currentLocation}</strong>`,
      });
    }

    if (data.originalLifeCycleId !== data.currentLifeCycleId) {
      if (hasChangedLocation) {
        html += ', ';
      }
      html += this.translate.instant('logs.updatedLifeCycle', {
        old: `<strong>${data.originalLifeCycleId}</strong>`,
        new: `<strong>${data.currentLifeCycleId}</strong>`,
      });
    }

    return html;
  }

  private getInfoFromGenerationDto(data: GenerationRequestDto) {
    let html = `<strong>${this.translate.instant('logs.generationRequest')}:</strong></br>${this.translate.instant(
      'common.partNumber'
    )}: `;

    if (data.partNumber) {
      html += data.partNumber + ', ';
    } else {
      html += this.translate.instant('logs.noData') + ', ';
    }

    html += this.translate.instant('logs.destiny') + ': ';

    if (data.destination) {
      html += data.destination + ', ';
    } else {
      html += this.translate.instant('logs.noData') + ', ';
    }

    html += this.translate.instant('logs.assemblyQty') + ': ';

    if (data.assembliesQtd) {
      html += data.assembliesQtd;
    } else {
      html += this.translate.instant('logs.noData');
    }

    return html;
  }

  private getInfoFromSync(data: SynchronizeInfo) {
    const html = '';
    return html;
  }

  private getInfoFromContainerLifeCycle(data: ContainerLifeCycleInfo) {
    let html = this.translate.instant('logs.containerLifeCycleState') + ': ';

    if (data.state) {
      html += this.lifeCycleStateDescription(data.state);
    }

    return html;
  }

  private getInfoFromBatchRequest(data: BatchCreationDto) {
    const html =
      this.translate.instant('common.initialEPC') +
      ': ' +
      data.initialEpc +
      ', ' +
      this.translate.instant('common.containerQuantity') +
      ': ' +
      data.containerQtd +
      ', ' +
      this.translate.instant('common.containerModel') +
      ': ' +
      data.containerModel;

    return html;
  }

  private getInfoFromCreateContainer(data: ContainerCreateRequest) {
    let html = this.translate.instant('logs.containerBarcode') + ': ';
    if (data.barCodes.length > 0) {
      html += data.barCodes.join(', ');
    } else {
      html += this.translate.instant('logs.noData');
    }
    return html;
  }

  private getInfoFromCreateRfid(data: RfidContentorSyncRequest) {
    let html = this.translate.instant('logs.newRfid') + ': ';

    if (data.knownBarCode) {
      html += data.knownBarCode + ', ';
    } else {
      html += this.translate.instant('logs.noData') + ', ';
    }

    html += this.translate.instant('logs.oldRifds') + ': ';

    if (data.barCodes.length > 0) {
      html += data.barCodes.join(', ');
    } else {
      html += this.translate.instant('logs.noData');
    }

    return html;
  }

  private getInfoFromOnGoingRequest(data: OngoingRequestDto) {
    let html = this.translate.instant('logs.psmmCode') + ': ';

    if (data.labelcode) {
      html += data.labelcode + ', ';
    } else {
      html += this.translate.instant('logs.noData') + ', ';
    }

    html += this.translate.instant('logs.barcode') + ': ';

    if (data.barcode) {
      html += data.barcode + ', ';
    } else {
      html += this.translate.instant('logs.noData') + ', ';
    }

    return html;
  }

  private getInfoFromLabelReading(data: LabelReadingRequestDto) {
    let html = this.translate.instant('logs.barcode') + ': ';

    if (data.barcode) {
      html += data.barcode;
    } else {
      html += this.translate.instant('logs.noData');
    }

    html += ', ' + this.translate.instant('logs.labelcode') + ': ';

    if (data.barcode) {
      html += data.labelcode;
    } else {
      html += this.translate.instant('logs.noData');
    }

    return html;
  }

  private getInfoFromLiberateRequest(data: ContainerLiberateRequestDto) {
    let html = this.translate.instant('common.barcode') + ': ';

    if (data.barcode) {
      html += data.barcode;
    } else {
      html += this.translate.instant('logs.noData');
    }

    html += ', ';

    if (data.fIsComplete) {
      html += this.translate.instant('logs.isBoxComplete');
    } else {
      html += this.translate.instant('logs.isNotBoxComplete');
    }
    return html;
  }

  private getInfoFromDispatchInfo(data: DispatchInfo) {
    let html =
      '<strong>' +
      this.translate.instant('logs.dispatchInfo') +
      ': </strong>' +
      this.translate.instant('logs.labelcode') +
      ': ';

    if (data.labelcode) {
      html += data.labelcode;
    } else {
      html += this.translate.instant('logs.noData');
    }

    html += ', ' + this.translate.instant('logs.spMessages') + ': ';

    if (data.SPMessages) {
      html += data.SPMessages;
    } else {
      html += this.translate.instant('logs.noData');
    }

    return html;
  }

  private getInfoFromRadLabelInfo(data: RadLabelInfo) {
    let html =
      '<strong>' +
      this.translate.instant('logs.radLabelInfo') +
      ':</strong></br>' +
      this.translate.instant('logs.labelCode') +
      ': ';

    if (data.labelcode) {
      html += data.labelcode;
    } else {
      html += this.translate.instant('logs.noData');
    }

    html += ', ' + this.translate.instant('common.partNumber') + ': ';

    if (data.partnumber) {
      html += data.partnumber;
    } else {
      html += this.translate.instant('logs.noData');
    }

    html += ', ' + this.translate.instant('common.destination') + ': ';

    if (data.destination) {
      html += data.destination;
    } else {
      html += this.translate.instant('logs.noData');
    }

    return html;
  }

  private getInfoFromChecklistForm(data: ChecklistFormResponse) {
    let html =
      '<strong>' +
      this.translate.instant('logs.kitInfo') +
      ':</strong> ' +
      '<br>' +
      this.translate.instant('logs.forkLiftID') +
      ': ';

    if (data.forkliftId) {
      html += data.forkliftId;
    } else {
      html += this.translate.instant('logs.noData');
    }

    html += ', ' + this.translate.instant('logs.tabletID') + ': ';

    if (data.tabletId) {
      html += data.tabletId;
    } else {
      html += this.translate.instant('logs.noData');
    }

    html += ', ' + this.translate.instant('logs.readerID') + ': ';

    if (data.readerId) {
      html += data.readerId;
    } else {
      html += this.translate.instant('logs.noData');
    }

    const booleanProperties = Object.entries(data).filter(([_, value]) => value === false);
    const missingParts: string[] = [];
    const damagedParts: string[] = [];

    booleanProperties.forEach(([key, value]) => {
      switch (key) {
        case 'isTabletPresent':
          missingParts.push(this.translate.instant('common.tablet'));
          break;
        case 'isReaderPresent':
          missingParts.push(this.translate.instant('common.reader'));
          break;
        case 'isAntenaPresent':
          missingParts.push(this.translate.instant('common.antenna'));
          break;
        case 'isBatteryPresent':
          missingParts.push(this.translate.instant('common.battery'));
          break;
        case 'isTabletKitHealthy':
          damagedParts.push(this.translate.instant('common.tablet'));
          break;
        case 'isStructureHealthy':
          damagedParts.push(this.translate.instant('common.structureKit'));
          break;
        case 'isReaderHealthy':
          damagedParts.push(this.translate.instant('common.reader'));
          break;
        case 'isAntennaHealthy':
          damagedParts.push(this.translate.instant('common.antenna'));
          break;
        case 'isBatteryHealthy':
          damagedParts.push(this.translate.instant('common.battery'));
          break;
      }
    });

    if (missingParts.length > 0) {
      html += '<br>' + this.translate.instant('logs.isMissing') + ': ' + missingParts.join(', ');
    }

    if (damagedParts.length > 0) {
      html += '<br>' + this.translate.instant('logs.isDamaged') + ': ' + damagedParts.join(', ');
    }

    return html;
  }

  private getInfoFromCreationUser(data: string) {
    return this.translate.instant('logs.responsibleUser') + data;
  }

  private getInfoFromLabelsInfo(data: LabelInfo[]) {
    let html = this.translate.instant('logs.labelsInfo') + ': ';

    data.forEach(labelInfo => {
      html +=
        '</br>' +
        this.translate.instant('common.partNumber') +
        ': ' +
        labelInfo.partNumber +
        ', ' +
        this.translate.instant('logs.psmmCode') +
        ': ' +
        labelInfo.labelCode;
    });

    return html;
  }

  private getInfoFromActiveBarcode(data: string) {
    if (data) {
      return this.translate.instant('common.tagRFID') + ': ' + data;
    }
    return '';
  }
}
