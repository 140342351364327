/*
 * File: \src\app\services\login\state\user-selectors.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-27	JF	Check for variables.
 */

import { LoginStatus, UserState } from './user.state';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

const userFeatureSelector = createFeatureSelector<UserState>('user');
export const userStatusSelector = createSelector(userFeatureSelector, (state: UserState) => state.status
);

export const isLogged = createSelector(userStatusSelector, (status: string) => {
  let loggedChecked = false;

  switch (status) {
    case LoginStatus.logged:
      loggedChecked = true;
      break;
    default:
      break;
  }

  return loggedChecked;
});

export const userData = createSelector(userFeatureSelector, (state: UserState) => state.userData);
