import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContainerDto, RackRailContainersStorage, RfidTagInfoDto } from 'src/app/models';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataCatalogApiService, PackagingApiService, RfidApiService } from '@services/api';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { Subscription } from 'rxjs';
import { AppToastService } from '@services/index';
import { MetadataService } from '@services/metadata/metadata.service';

@Component({
  selector: 'boxcar-console-confirm-info',
  templateUrl: './confirm-info.component.html',
  styleUrls: ['./confirm-info.component.scss'],
})
export class ConfirmInfoComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = new FormGroup({
    rfidTagCtrl: new FormControl('', [Validators.required]),
    partNumberCtrl: new FormControl('', [Validators.required]),
  });
  psmmTagsCtrls: FormArray = new FormArray([]);

  selectedContainer!: RackRailContainersStorage;
  allRfidTags: RfidTagInfoDto[] = [];
  rfidSubscription: Subscription;
  currentContainer!: ContainerDto;
  updateIsOn: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { rackRailId: number; partNumber: string; currentDepth: number },
    private dialogRef: MatDialogRef<ConfirmInfoComponent>,
    private catalogApiService: DataCatalogApiService,
    private rfidApiService: RfidApiService,
    private loadingIndicator: LoadingIndicatorService,
    private packagingApiService: PackagingApiService,
    private toastService: AppToastService,
    private metadataService: MetadataService
  ) {
    this.rfidSubscription = this.subscribeRfidTags();
  }

  ngOnDestroy(): void {
    this.rfidSubscription.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.loadingIndicator.show();
    this.selectedContainer = (await this.getContainers())[0];

    if (!this.selectedContainer.barcode.toLowerCase().includes('null_')) {
      await this.getPackageInfo(this.selectedContainer.barcode)
        .then(response => {
          this.currentContainer = response;
        })
        .catch(err => {
          this.toastService.error(this.metadataService.getErrorDescriptionFromErrorCode(err.error.code));
        });
    }
    this.disableAllControls();
    this.setControlInitialValues();
    this.disableAllControls();
    this.getRfidTags();
    this.loadingIndicator.hide();
  }

  private disableAllControls() {
    this.formGroup.disable();

    for (const psmmCtrl of this.psmmTagsCtrls.controls) {
      psmmCtrl.disable();
    }
  }

  private setControlInitialValues() {
    this.formGroup.get('partNumberCtrl')!.setValue(this.data.partNumber);
    this.formGroup.get('rfidTagCtrl')!.setValue(this.selectedContainer.barcode);
    this.setPSMMTagsControls();
  }

  private setPSMMTagsControls() {
    if (this.currentContainer) {
      for (const labelDto of this.currentContainer.boxAssembly!.labelDtos) {
        this.psmmTagsCtrls.push(new FormControl(labelDto.labelcode));
      }
    }
  }

  private getRfidTags(filter: string = '') {
    this.rfidApiService.getRfidTags(filter, 1, 250).then(response => {
      this.allRfidTags = response.rfidTagInfoDtos;
    });
  }

  private subscribeRfidTags() {
    return this.formGroup.get('rfidTagCtrl')!.valueChanges.subscribe({
      next: newValue => {
        setTimeout(() => {
          const ctrlValue = this.formGroup.get('rfidTagCtrl')!.value;

          if (ctrlValue === newValue) {
            this.getRfidTags(newValue);
          }
        }, 1000);
      },
    });
  }

  private async getContainers() {
    const partNumber = this.data.partNumber.split(' - ')[0].trim();

    return await this.catalogApiService
      .getContainersFromRackRail(this.data.rackRailId, partNumber, this.data.currentDepth)
      .toPromise();
  }

  private getPackageInfo(rfidTag: string) {
    return this.packagingApiService.getPackageFromRFID(rfidTag).toPromise();
  }

  private setNewValues() {
    if (this.currentContainer) {
      this.formGroup
        .get('partNumberCtrl')!
        .setValue(
          this.currentContainer.boxAssembly!.boxAssemblyInfoResponseDto.partNumber +
            ' - ' +
            this.currentContainer.boxAssembly!.boxAssemblyInfoResponseDto.partDescription
        );

      this.psmmTagsCtrls.clear();
      this.setPSMMTagsControls();
    }
  }

  onClickChangeInfo() {
    this.formGroup.get('rfidTagCtrl')!.enable();
    this.updateIsOn = true;
  }

  async onConfirmChanges() {
    this.loadingIndicator.show();
    await this.getPackageInfo(this.formGroup.get('rfidTagCtrl')!.value)
      .then(response => {
        this.currentContainer = response;
        this.setNewValues();
        this.disableAllControls();
        this.updateIsOn = false;
      })
      .catch(err => {
        this.toastService.error(this.metadataService.getErrorDescriptionFromErrorCode(err.error.code));
      });
    this.loadingIndicator.hide();
  }

  getPsmmTagCtrlFromIndex(index: number) {
    return this.psmmTagsCtrls.controls[index] as FormControl;
  }

  onClickContinue() {
    const rfid = this.formGroup.get('rfidTagCtrl')!.value;
    const partNumber = this.formGroup.get('partNumberCtrl')!.value.split(' - ')[0].trim();
    let containerLifeCycleId = this.selectedContainer.containerLifeCycleId;
    const instructionId =
      this.currentContainer.instructions!.length > 0 ? this.currentContainer.instructions![0].instructionId : undefined;

    if (!rfid.toLowerCase().includes('__null')) {
      containerLifeCycleId = this.currentContainer.containerLifeCycleId!;
    }

    this.dialogRef.close({
      containerLifeCycleId: containerLifeCycleId,
      partNumber: partNumber,
      instructionId: instructionId,
    });
  }
}
