<div class="instruction-filter-container">
  <instruction-filter
    [isLoading]="isLoading"
    [activeControllers]="activeFilters"
    (handleRequestButton)="applyFilters($event)"></instruction-filter>
</div>

<button mat-flat-button color="primary" class="reload-button" (click)="requestInstructions()">
  {{ 'common.buttons.refresh' | translate }}
</button>

<hr />
<div class="loading-container">
  <loading-on-page [isLoading]="isLoading"></loading-on-page>
  <div class="instruction-cards-list">
    <instruction-card
      *ngFor="let instruction of instructions"
      [showActions]="true"
      [instruction]="instruction"
      (instructionChanged)="instructionChanged($event)">
    </instruction-card>
  </div>
</div>

<paginator-page-select
  [length]="instructionsLength"
  (page)="onPaginatorChange($event)"
  aria-label="Select page"></paginator-page-select>
