<h1 mat-dialog-title>{{ 'totemServices.moveBox.boxFound' | translate }}:</h1>

<h4>{{ 'totemServices.moveBox.selectOperation.description' | translate }}</h4>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'totemServices.picking.operationLabel' | translate }}
      </mat-label>
      <mat-select formControlName="opTypeCtrl">
        <mat-option value="storing">
          {{ 'instructions.types.storing' | translate }}
        </mat-option>
        <mat-option value="picking">
          {{ 'instructions.types.picking' | translate }}
        </mat-option>
        <mat-option value="reallocation">
          {{ 'instructions.types.reallocation' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="form.get('opTypeCtrl')!.value !== ''">
      <h4>
        {{ 'totemServices.moveBox.selectOperation.selectOrigin' | translate : { opType: selectedTypeTranslated } }}
      </h4>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'common.origin' | translate }}</mat-label>
        <input matInput formControlName="originCtrl" [matAutocomplete]="autoOrigin" />
        <mat-autocomplete #autoOrigin="matAutocomplete">
          <mat-option *ngFor="let origin of filteredOrigins" [value]="origin">
            {{ origin }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <h4>
        {{ 'totemServices.moveBox.selectOperation.selectDestination' | translate : { opType: selectedTypeTranslated } }}
      </h4>

      <mat-form-field appearance="fill">
        <mat-label> {{ 'common.destination' | translate }}</mat-label>
        <input matInput formControlName="destinationCtrl" [matAutocomplete]="autoDestination" />
        <mat-autocomplete #autoDestination="matAutocomplete">
          <mat-option *ngFor="let destination of filteredDestinations" [value]="destination">
            {{ destination }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="onCreateInstruction()">
    {{ 'common.buttons.instruction.create' | translate }}
  </button>
  <button mat-button color="warn" mat-dialog-close>
    {{ 'common.buttons.cancel' | translate }}
  </button>
</div>
