<!--
File: \src\app\pages\logs\logs.component.html
Project: boxcar-console
Created Date: 2022-02-07 13:42:57
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-04-27	JF	Added I18 support.
-->

<br />
<div style="display: flex; flex-direction: row; gap: 0.5rem">
  <mat-form-field appearance="outline" style="width: 10rem">
    <mat-select (selectionChange)="onGravityFilterChange()" [(ngModel)]="selectedGravity">
      <mat-option value="">{{ ('logs.level.none' | translate).toUpperCase() }}</mat-option>
      <mat-option value="0">{{ ('logs.level.low' | translate).toUpperCase() }}</mat-option>
      <mat-option value="1">{{ ('logs.level.medium' | translate).toUpperCase() }}</mat-option>
      <mat-option value="2">{{ ('logs.level.high' | translate).toUpperCase() }}</mat-option>
    </mat-select>
    <mat-label
      ><em>{{ 'logs.level.severity' | translate }}</em></mat-label
    >
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'logs.search' | translate }}</mat-label>
    <input matInput placeholder="Ex. 2022" #input (change)="applyFilter($event)" />
  </mat-form-field>

  <!-- Refresh button -->
  <button mat-flat-button color="primary" class="reload-button" (click)="getData()">
    <img src="../../../../assets/icons/refreshIcon.svg" color="white" width="24px" height="24px" alt="refresh" />
  </button>
</div>

<div class="mat-elevation-z2 loading-container">
  <!-- Loading indicator -->
  <loading-on-page [isLoading]="isLoading"></loading-on-page>

  <table mat-table [dataSource]="dataSource">
    <caption style="display: none">
      Logs info
    </caption>
    <ng-container matColumnDef="login">
      <th mat-header-cell *matHeaderCellDef>{{ 'logs.login' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.login }}</td>
    </ng-container>

    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef>{{ 'logs.dateTime' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.timestamp }}</td>
    </ng-container>

    <ng-container matColumnDef="eventClassNumber">
      <th mat-header-cell *matHeaderCellDef>{{ 'logs.class' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.eventClassNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="contextId">
      <th mat-header-cell *matHeaderCellDef>{{ 'logs.contextId' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.contextId }}</td>
    </ng-container>

    <ng-container matColumnDef="contextTag">
      <th mat-header-cell *matHeaderCellDef>{{ 'logs.context' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.contextTag }}</td>
    </ng-container>

    <ng-container matColumnDef="data">
      <th mat-header-cell *matHeaderCellDef>{{ 'logs.data' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <span>
          <span [innerHTML]="row.data.title"></span>
          <span [innerHTML]="row.data.description"> </span>
        </span>

        <button mat-flat-button color="primary" class="detail-button"
          *ngIf="row.isDetailButtonNedded" (click)="openDetailsModal(row.rawLog)">
          Ver Detalhes
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngStyle]="{ 'background-color': row.gravity === 1 ? '#FFF9DB' : row.gravity === 2 ? '#FDDED8' : '' }"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="displayedColumns.length">
        {{ 'logs.noDataFound' | translate }}: "{{ input.value }}"
      </td>
    </tr>
  </table>

  <paginator-page-select
    [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [length]="totalLength"
    (page)="changePaginator($event)">
  </paginator-page-select>
</div>
