import { LoginResponseDto } from 'src/app/models/login';

export enum LoginStatus {
  notLogged = 'Not Logged',
  logged = 'Logged',
  tokenExpired = 'TOken Expired',
}

/**
 * Class used to represent User Module state.
 */
export interface UserState {
  status: string;
  userData?: LoginResponseDto | undefined;
  error?: string;
}
