import { Component, Input } from '@angular/core';
import { Instruction } from '@models/instruction';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'done-order-card',
  templateUrl: './done-order-card.component.html',
  styleUrls: ['./done-order-card.component.scss', './../order-card.component.scss'],
})
export class DoneOrdersComponent {
  @Input() instruction?: Instruction;

  constructor(private translate: TranslateService) {}

  formatDate(strDate: string) {
    if (strDate) {
      // Transform strDate in UTC timezone;
      return new Date(strDate + 'Z').toLocaleString('default');
    } else {
      return this.translate.instant('instructions.list.invalidData');
    }
  }
}
