<!--
File: \src\app\pages\parts\import\merge.result.dialog.html
Project: boxcar-console
Created Date: 2022-05-17 09:29:14
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-05-17	JF	Upload result message template.
-->

<h1 mat-dialog-title>{{'catalog.merge.title' | translate}}</h1>

<div mat-dialog-content>
  <div class="modal-body row">
    <div class="col-md-6">
      <p>{{'catalog.merge.totalRows' | translate}}</p>
      <p>{{'catalog.merge.createdRows' | translate}}</p>
      <p>{{'catalog.merge.updatedRows' | translate}}</p>
      <p>{{'catalog.merge.errorRows' | translate}}</p>
      <p>{{'catalog.merge.warnings' | translate}}</p>
    </div>
    <div class="col-md-6">
      <p>{{data.dataMergeRespDto.totalRows}}</p>
      <p>{{data.dataMergeRespDto.createdRows}}</p>
      <p>{{data.dataMergeRespDto.updatedRows}}</p>
      <p>{{data.dataMergeRespDto.errorRows}}</p>
      <p>{{data.dataMergeRespDto.warnings}}</p>
    </div>
  </div>

  <div *ngIf="data.dataMergeRespDto.logs.length > 0">
    <p><strong>{{ 'catalog.merge.errorDescription' | translate }}:</strong></p>
    <table class="table">
      <caption hidden>
        Feedback from upload.
      </caption>
      <thead>
        <tr>
          <th>{{'catalog.merge.element' | translate}}</th>
          <th>{{'catalog.merge.key' | translate}}</th>
          <th>{{'catalog.merge.message' | translate}}</th>
          <th>{{'catalog.merge.severity' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let log of data.dataMergeRespDto.logs">
          <td>{{ log.index }}</td>
          <td>{{ log.key }}</td>
          <td>{{ log.message }}</td>
          <td>{{ (log.isWarning ? 'catalog.merge.warning' : 'catalog.merge.error') | translate}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div mat-dialog-actions>
  <!-- Clipboard copy button -->
  <button mat-button color="primary" (click)="onCopyToClipboard()">
    {{ 'application.copyToClipboard' | translate }}
  </button>

  <!-- Close dialog button -->
  <button mat-raised-button color="primary" [mat-dialog-close]="">{{'catalog.modal.closeButton' | translate}}</button>
</div>
