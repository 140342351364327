<div class="graphicalArea loading-container">
  <loading-on-page [isLoading]="isLoading"></loading-on-page>
  <!-- Backdrop -->
  <div
    *ngIf="isOneOpen"
    class="backdrop"
    (mouseover)="mouseIsOverBackdrop = true"
    (click)="closeBackdrop()"
    (mouseleave)="exitBackdrop()"></div>

  <div class="table-div">
    <!-- Colum numbers row -->
    <table>
      <caption hidden>
        {{
          'warehouse.racks.state.tableDesc' | translate
        }}
      </caption>
      <tr>
        <th *ngFor="let colNum of columns">
          <div class="number">
            {{ colNum === 0 ? '' : colNum }}
          </div>
        </th>
      </tr>
      <tr *ngFor="let level of rackRailLevels">
        <!-- Level Number -->
        <th>
          <div class="number">
            {{ level[0] ? level[0].level : '' }}
          </div>
        </th>
        <!-- Rails of level -->
        <td *ngFor="let rail of level">
          <div
            [class]="'rackRailDiv ' + rackRailClassFromOperationMode(rail.operationMode)"
            [style.--color]="colorForRackRail(rail)"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            (click)="onRailClick(overlay)"
            (mouseenter)="onMouseEnter(overlay, rail.id)"
            (mouseout)="onMouseOut(overlay)">
            {{ rail.name }}
            <br />
            ({{ rail.storedBoxes }})
          </div>
          <ng-template
            cdkConnectedOverlay
            #overlay="cdkConnectedOverlay"
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="overlay.open">
            <mat-card (mouseover)="mouseIsOverOverlay = true" (mouseleave)="onExitOverlay()">
              <mat-card-title-group>
                <h3>
                  <strong>{{ 'warehouse.racks.state.title' | translate }}</strong>
                </h3>
                <div fxFlex></div>
                <div class="buttons">
                  <button
                    mat-raised-button
                    color="primary"
                    *ngIf="verifyUserRole(types.operation)"
                    (click)="handleBoxQuantityPress(rail)">
                    {{ 'warehouse.racks.state.setBoxQuantity' | translate }}
                  </button>
                  <button
                    mat-raised-button
                    color="primary"
                    *ngIf="verifyUserRole(types.edition)"
                    (click)="handleEditPress(rail)">
                    {{ 'catalog.racks.edit.buttonEdit' | translate }}
                  </button>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="handleDetailedAllocationPress(rail)">
                    Detalhes da alocação
                  </button>
                </div>
              </mat-card-title-group>
              <mat-card-content>
                <table>
                  <caption hidden>
                    {{
                      'warehouse.racks.state.railDesc' | translate
                    }}
                  </caption>
                  <tbody>
                    <tr>
                      <th>{{ 'catalog.racks.edit.name' | translate }}:</th>
                      <td>{{ rail.name }}</td>
                    </tr>
                    <tr>
                      <th>{{ 'catalog.racks.edit.partNumber' | translate }}:</th>
                      <td *ngIf="rail.suggestedParts.length > 0; else noSuggestedParts">
                        {{ rail.suggestedParts[0].partNumber }} -
                        {{ partDescriptionFromNumber(rail.suggestedParts[0].partNumber) }}
                      </td>
                      <ng-template #noSuggestedParts>
                        <td>-</td>
                      </ng-template>
                    </tr>
                    <tr>
                      <th>{{ 'catalog.racks.edit.capacity' | translate }}:</th>
                      <td>{{ rail.maxBoxes }} {{ 'catalog.racks.edit.boxes' | translate }}</td>
                    </tr>
                    <tr>
                      <th>{{ 'warehouse.racks.state.load' | translate }}:</th>
                      <td>
                        <strong> &nbsp; {{ rail.storedBoxes }} {{ 'catalog.racks.edit.boxes' | translate }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ 'catalog.racks.inputSideTag' | translate }}:</th>
                      <td>{{ rail.inputSideEpcs?.join(", ") }}</td>
                    </tr>
                    <tr>
                      <th>{{ 'catalog.racks.outputSideTag' | translate }}:</th>
                      <td>{{ rail.outputSideEpcs?.join(", ") }}</td>
                    </tr>
                    <tr>
                      <th>{{ 'catalog.racks.boxAllocation' | translate }}:</th>
                      <td>
                        <span style="font-size: 30px"> &#8592;</span>
                        <span
                          style="font-size: 26px; margin: 0"
                          [style.color]="colorFromPartNumber(boxInstance.partNumber)"
                          *ngFor="let boxInstance of reduceBoxesStorage(rail.boxesStorage)">
                          &#9635;
                        </span>
                        <span *ngIf="rail.boxesStorage.length > 30">...</span>
                      </td>
                    </tr>

                    <tr style="border: solid thin"></tr>

                    <!-- Catalog of stored part numbers in this rack rail. -->
                    <tr style="font-size: 12px" *ngFor="let storedPartNumber of storedPNs">
                      <th>
                        <span [style.color]="colorFromPartNumber(storedPartNumber)">&#9635;</span>
                        {{ storedPartNumber }}
                      </th>
                      <td style="font-size: 12px">
                        {{ findDescriptionByPartNumber(storedPartNumber) }} -
                        {{
                          ('common.qty' | translate) +
                            ': ' +
                            getQtyBoxPerPartNumber(storedPartNumber, rail.boxesStorage) +
                            ' ' +
                            ('common.boxes' | translate)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mat-card-content>
            </mat-card>
          </ng-template>
        </td>
      </tr>

      <!-- Floor -->
      <tr *ngIf="floor">
        <!-- Level Number -->
        <th>
          <div class="number">
            {{ 'warehouse.racks.floor' | translate }}
          </div>
        </th>
        <!-- Rails of level -->
        <td [colSpan]="totalCols">
          <div
            class="rackRailDiv floor"
            [style.--color]="'rgb(160, 160, 160)'"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            (click)="onRailClick(overlay)"
            (mouseenter)="onMouseEnter(overlay, floor.id)"
            (mouseout)="onMouseOut(overlay)">
            {{ floor.name }}
            <br />
            ({{ floor.storedBoxes }})
          </div>
          <ng-template
            cdkConnectedOverlay
            #overlay="cdkConnectedOverlay"
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="overlay.open">
            <mat-card (mouseover)="mouseIsOverOverlay = true" (mouseleave)="onExitOverlay()">
              <mat-card-title-group>
                <h3>
                  <strong>{{ 'warehouse.racks.state.title' | translate }}</strong>
                </h3>
                <div fxFlex></div>
                <div class="buttons">
                  <button
                    mat-raised-button
                    color="primary"
                    *ngIf="verifyUserRole(types.operation)"
                    (click)="handleBoxQuantityPress(floor)">
                    {{ 'warehouse.racks.state.setBoxQuantity' | translate }}
                  </button>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="handleDetailedAllocationPress(floor)">
                    {{ 'warehouse.racks.detailedAllocation.title' | translate }}
                  </button>
                </div>
              </mat-card-title-group>
              <mat-card-content>
                <table>
                  <caption hidden>
                    {{
                      'warehouse.racks.state.railDesc' | translate
                    }}
                  </caption>
                  <tbody>
                    <tr>
                      <th>{{ 'catalog.racks.edit.name' | translate }}:</th>
                      <td>{{ floor.name }}</td>
                    </tr>
                    <tr>
                      <th>{{ 'warehouse.racks.state.load' | translate }}:</th>
                      <td>
                        <strong> &nbsp; {{ floor.storedBoxes }} {{ 'catalog.racks.edit.boxes' | translate }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ 'catalog.racks.boxAllocation' | translate }}:</th>
                      <td>
                        <span style="font-size: 30px"> &#8592;</span>
                        <span
                          style="font-size: 26px; margin: 0"
                          [style.color]="colorFromPartNumber(boxInstance.partNumber)"
                          *ngFor="let boxInstance of reduceBoxesStorage(floor.boxesStorage)">
                          &#9635;
                        </span>
                        <span *ngIf="floor.boxesStorage.length > boxesLimit">...</span>
                      </td>
                    </tr>

                    <tr style="border: solid thin"></tr>

                    <!-- Catalog of stored part numbers in this rack rail. -->
                    <tr style="font-size: 12px" *ngFor="let storedPartNumber of storedPNs">
                      <th>
                        <span [style.color]="colorFromPartNumber(storedPartNumber)">&#9635;</span>
                        {{ storedPartNumber }}
                      </th>
                      <td style="font-size: 12px">
                        {{ findDescriptionByPartNumber(storedPartNumber) }} -
                        {{
                          ('common.qty' | translate) +
                            ': ' +
                            getQtyBoxPerPartNumber(storedPartNumber, floor.boxesStorage) +
                            ' ' +
                            ('common.boxes' | translate)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mat-card-content>
            </mat-card>
          </ng-template>
        </td>
      </tr>
    </table>
  </div>
</div>
