import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppToastService } from '@services/toast/apptoast.service';

@Injectable({
  providedIn: 'root',
})
export class ExportCsvService {
  private data: Record<string, unknown>[] = [];
  private headers: string[] = [];

  constructor(private toastService: AppToastService, private translateService: TranslateService) {}

  exportCSV(data: Record<string, unknown>[], fileName: string, headers?: string[]) {
    if (this.verifyData(data)) {
      const today = new Date(Date.now());

      if (headers) {
        this.verifyHeaders(headers);
      }

      let csvContent = '';

      // Put all headers.
      csvContent += this.headers.join(',') + '\n';

      this.data.forEach(row => {
        csvContent += Object.values(row).join(',') + '\n';
      });

      this.createFile(csvContent, `${fileName}-${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`);
    }
  }

  private verifyData(data: Record<string, unknown>[]) {
    if (data.length === 0 || typeof data !== 'object') {
      this.toastService.error(this.translateService.instant('export.errors.invalidData'));
      return false;
    } else {
      this.data = [...data] as Record<string, unknown>[];
      this.headers = Object.keys(this.data[0]);
      return true;
    }
  }

  private verifyHeaders(headers: string[]) {
    if (headers.length !== this.headers.length) {
      this.toastService.error(this.translateService.instant('export.errors.invalidHeaders'));
      return false;
    } else {
      this.headers = [...headers];
      return true;
    }
  }

  private createFile(exportData: string, fileName: string) {
    const data: Blob = new Blob([exportData], { type: 'text/csv' });
    const a = document.createElement('a');
    const url = URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
  }
}
