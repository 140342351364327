import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '.';
import { Observable } from 'rxjs';
import { UserListResponse, UserRequest, UserRole } from '@models/users';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private http: HttpClient) {}

  public getUsers(pageNum: number = 1, qtyPerPage: number = 10, filter?: string) {
    let queryParams: HttpParams = new HttpParams().append('pageNum', pageNum).append('qtyPerPage', qtyPerPage);

    if (filter) {
      queryParams = queryParams.append('filter', filter);
    }

    return this.http.get<UserListResponse>(`${baseUrl}users`, { params: queryParams });
  }

  public getRoles(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(`${baseUrl}roles`);
  }

  public postUsers(userData: UserRequest) {
    return this.http.post(`${baseUrl}users`, userData);
  }

  public deleteUser(userId: number) {
    return this.http.patch(`${baseUrl}users/${userId}`, undefined);
  }

  public patchUser(userId: number, userData: UserRequest) {
    return this.http.put(`${baseUrl}users/${userId}`, userData);
  }
}
