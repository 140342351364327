import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InstructionApiService, PackagingApiService, RfidApiService } from '@services/api';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import {
  ContainerDto,
  ContainerLifeCycleState,
  InfoForAssociateBox,
  Instruction,
  InstructionType,
  LocationDto,
  RegionStrategy,
  RfidTagInfoDto,
} from 'src/app/models';
import { Subscription } from 'rxjs';
import { OperationTypeSelectionDialogComponent } from './operation-type-selection-dialog/operation-type-selection-dialog.component';
import { TotemDialogResponse } from 'src/app/models/totem';
import { TotemApiService } from '@services/api/totem.service';
import { ModifyInstructionDialogComponent } from './modify-instruction-dialog/modify-instruction-dialog.component';
import { AppToastService } from '@services/index';
import { TranslateService } from '@ngx-translate/core';
import { MoveFlowAssociationDialogComponent } from './association-dialog/association-dialog.component';
import { MetadataService } from '@services/metadata/metadata.service';
import { InstructionManagementDialogService } from '@services/instructions/instruction-management-dialog.service';
import { ContainerService } from '@services/container/container.service';
import { AvailableTagComponent } from './available-tag-dialog/available-tag-dialog.component';
import { PlacedDetailDialogComponent } from './placed-detail-dialog/placed-detail-dialog.component';
import { RestoreConfirmationDialogComponent } from './restore-confirmation-dialog/restore-confirmation-dialog.component';
import { TotemService } from '@services/totem/totem.service';
import { Debounce } from 'src/app/common/debounce/debounce';

@Component({
  selector: 'boxcar-console-move-box-dialog',
  templateUrl: './move-box-dialog.component.html',
  styleUrls: ['./move-box-dialog.component.scss'],
})
export class MoveBoxDialogComponent implements OnDestroy {
  tagCtrl: FormControl = new FormControl('', [Validators.required]);
  rfidTagsFiltered: RfidTagInfoDto[] = [];
  subscription: Subscription;
  isLoading: boolean = true;

  public rfidTagBarcode?: string;

  constructor(
    private rfidTagService: RfidApiService,
    private dialogRef: MatDialogRef<MoveBoxDialogComponent>,
    private loadingIndicator: LoadingIndicatorService,
    private packagingApi: PackagingApiService,
    private matDialog: MatDialog,
    private totemApi: TotemApiService,
    private instructionApi: InstructionApiService,
    private toastService: AppToastService,
    private translate: TranslateService,
    private metadataService: MetadataService,
    private concludeInstructionService: InstructionManagementDialogService,
    private containerUtils: ContainerService,
    private totemService: TotemService,
    @Inject(MAT_DIALOG_DATA) public data?: { rfidTagBarcode: string }
  ) {
    this.subscription = this.createSubscriptionRfidTagCtrl();
    this.rfidTagBarcode = this.data?.rfidTagBarcode;
    if (this.rfidTagBarcode) {
      this.continueWithoutInput()
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async getFilteredRfidTags(filter: string) {
    this.isLoading = true;

    const [currentJITLabelNumber, rfidTagsResponse] = await Promise.all([
        this.packagingApi.getCurrentJITLabelNumber(),
        this.rfidTagService.getRfidTags(filter, undefined, 25)
    ]);

    const filteredJITTags: RfidTagInfoDto[] = [];
    let count = 0;

    for (let i = 0; i < currentJITLabelNumber - 1001 && count < 10; i++) {
      const barcode = (1001 + i).toString();

      if (barcode.includes(filter)) {
        filteredJITTags.push({
            batchNumber: '',
            epc: '',
            barcode,
            type: 1,
            entityType: 'container',
            entityId: 0,
            lastAssociation: '',
        });

        count++;
      }
    }

    rfidTagsResponse.rfidTagInfoDtos = [
        ...rfidTagsResponse.rfidTagInfoDtos,
        ...filteredJITTags,
    ];

    this.isLoading = false;

    return rfidTagsResponse.rfidTagInfoDtos;
  }

  private createSubscriptionRfidTagCtrl() {
    return Debounce.debounceFormField(this.tagCtrl, async value => await this.getFilteredRfidTags(value)).subscribe({
      next: value => {
        this.rfidTagsFiltered = value;
      },
    });
  }

  private async getContainerFromRfid(rfid: RfidTagInfoDto) {
    return await this.packagingApi.getPackageFromRFID(rfid.barcode).toPromise();
  }

  private async generateInstruction(
    dialogReturn: {
      operationType: InstructionType;
      origin: { origin: string; originLevel: number; originColumn: number };
      destination: { destination: string; destinationLevel: number; destinationColumn: number } | string;
    },
    container: ContainerDto,
    rfid: RfidTagInfoDto
  ) {
    this.loadingIndicator.show();
    switch (dialogReturn.operationType) {
      case InstructionType.storing: {
        const storingInstruction = await this.totemService.generateStoringInstruction(dialogReturn, rfid, container);

        if (storingInstruction) {
          this.openContinueFlowDialog(storingInstruction);
        }
        break;
      }
      case InstructionType.picking: {
        const pickingInstruction = await this.totemService.generatePickingInstruction(dialogReturn, container);

        if (pickingInstruction) {
          this.openContinueFlowDialog(pickingInstruction);
        }
        break;
      }
      case InstructionType.reallocation: {
        const reallocationInstruction = await this.totemService.generateReallocationInstruction(
          dialogReturn,
          container
        );

        if (reallocationInstruction) {
          this.openContinueFlowDialog(reallocationInstruction);
        }
        break;
      }
    }
    this.loadingIndicator.hide();
  }

  private openSelectOperationDialog(rfid: RfidTagInfoDto, container: ContainerDto) {
    if (!container.containerLifeCycleId) {
      this.toastService.error(this.translate.instant('toast.errors.totemServices.invalidContainerInfo'));
    } else {
      const dialog = this.matDialog.open(OperationTypeSelectionDialogComponent, {
        maxWidth: '600px',
        disableClose: true,
        data: {
          containerInfo: container,
        },
      });

      dialog
        .afterClosed()
        .toPromise()
        .then(async dialogReturn => {
          if (dialogReturn) {
            this.generateInstruction(dialogReturn, container, rfid);
          } else {
            this.dialogRef.close({ response: TotemDialogResponse.none });
          }
        });
    }
  }

  private openModifyInstructionDialog(rfid: RfidTagInfoDto, container: ContainerDto) {
    const dialog = this.matDialog.open(ModifyInstructionDialogComponent, {
      maxWidth: '860px',
      disableClose: true,
      data: { containerInfo: container, rfid: rfid },
    });

    dialog
      .afterClosed()
      .toPromise()
      .then(async value => {
        if (value) {
          this.loadingIndicator.show();
          await this.instructionApi
            .cancelInstruction(value.instructionId, true)
            .then(_ => {
              this.toastService.success(this.translate.instant('toast.success.totemServices.instructionCanceled'));
              this.loadingIndicator.hide();
              this.decideWhichDialogOpen(rfid);
            })
            .catch(error => {
              const errorText = `${this.translate.instant(
                'toast.errors.totemServices.instructionCanceled'
              )}: ${this.metadataService.getErrorDescriptionFromErrorCode(error.error.code)}`;
              this.toastService.error(errorText);
              this.loadingIndicator.hide();
            });
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.none });
        }
      });
  }

  async openAssociationDialog(rfid: RfidTagInfoDto, container: ContainerDto) {
    const dialog = this.matDialog.open(MoveFlowAssociationDialogComponent, {
      data: { box: container, rfid: rfid },
      disableClose: true,
      maxWidth: '600px',
    });

    dialog
      .afterClosed()
      .toPromise()
      .then((associateInfo: InfoForAssociateBox) => {
        if (associateInfo) {
          this.loadingIndicator.show();
          this.totemApi
            .associateBox(associateInfo)
            .toPromise()
            .then(async _ => {
              this.toastService.success(this.translate.instant('toast.success.totemServices.successToAssociate'));
              const newContainer = await this.getContainerFromRfid(rfid);
              this.openSelectOperationDialog(rfid, newContainer);
            })
            .catch(error => {
              const errorText = `${this.translate.instant(
                'toast.errors.totemServices.errorToAssociate'
              )}: ${this.metadataService.getErrorDescriptionFromErrorCode(error.error.code)}`;
              this.toastService.error(errorText);
            })
            .finally(() => {
              this.loadingIndicator.hide();
            });
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.none });
        }
      });
  }

  private openAvailableTagDialog(rfid: RfidTagInfoDto, container: ContainerDto) {
    const dialog = this.matDialog.open(AvailableTagComponent, {
      maxWidth: '860px',
      disableClose: true,
      data: {
        container: container,
      },
    });

    dialog
      .afterClosed()
      .toPromise()
      .then((dialog: { response: string }) => {
        if (dialog.response === 'associate') {
          const newContainer = this.containerUtils.createContainerAvailable(container);
          this.openAssociationDialog(rfid, newContainer);
        } else if (dialog.response === 'recover') {
          this.openSelectOperationDialog(rfid, container);
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.none });
        }
      });
  }

  private openPlacedContainerDetailsDialog(rfid: RfidTagInfoDto, container: ContainerDto) {
    const dialog = this.matDialog.open(PlacedDetailDialogComponent, {
      maxWidth: '860px',
      disableClose: true,
      data: {
        container: container,
      },
    });

    dialog
      .afterClosed()
      .toPromise()
      .then((dialog: { response: string }) => {
        if (dialog.response === 'correctData') {
          this.openSelectOperationDialog(rfid, container);
        } else if (dialog.response === 'wrongData') {
          this.openConfirmRestoreDialog(rfid, container);
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.none });
        }
      });
  }

  private openConfirmRestoreDialog(rfid: RfidTagInfoDto, container: ContainerDto) {
    const dialog = this.matDialog.open(RestoreConfirmationDialogComponent, {
      maxWidth: '80%',
      disableClose: true,
    });

    dialog
      .afterClosed()
      .toPromise()
      .then(async (dialog: { response: string }) => {
        if (dialog.response === 'continue') {
          try {
            this.loadingIndicator.show();
            container = await this.packagingApi.restoreBox(rfid.barcode).toPromise();
            this.openAssociationDialog(rfid, container);
          } catch (err: any) {
            this.toastService.error(this.metadataService.getErrorDescriptionFromErrorCode(err.error.code));
            this.dialogRef.close({ response: TotemDialogResponse.none });
          } finally {
            this.loadingIndicator.hide();
          }
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.none });
        }
      });
  }

  private async decideWhichDialogOpen(rfid: RfidTagInfoDto) {
    this.loadingIndicator.show();

    await this.totemApi
      .totemSearch(rfid.barcode)
      .toPromise()
      .then(async container => {
        this.loadingIndicator.hide();

        if (container.instructions) {
          container.instructions = [...container.instructions]
            .sort((a, b) => a.instructionId - b.instructionId)
            .reverse();
          console.log(container.instructions);
        }

        if (
          container.entityState === ContainerLifeCycleState.available ||
          container.entityState === ContainerLifeCycleState.onGoing
        ) {
          this.openAssociationDialog(rfid, container);
        } else if (!this.containerUtils.containerIsActive(container)) {
          this.openAvailableTagDialog(rfid, container);
        } else if (this.containerUtils.getActiveInstructions(container).length > 0) {
          this.openModifyInstructionDialog(rfid, container);
        } else {
          this.openPlacedContainerDetailsDialog(rfid, container);
        }
      })
      .catch(error => {
        this.toastService.error(this.metadataService.getErrorDescriptionFromErrorCode(error.error.code));
      });
  }

  private async getRFIDFromPSMMTag(): Promise<RfidTagInfoDto | undefined> {
    let rfidSearch = undefined;
    let rfid: RfidTagInfoDto | undefined = undefined;

    const response = await this.packagingApi.searchBoxes(1, 2, undefined, undefined, this.tagCtrl.value);

    if (response.containersResponseDtos.length === 1 || response.containerQuantity === 1) {
      rfidSearch = response.containersResponseDtos[0].container.activeBarcode;
    }

    if (rfidSearch) {
      const rfidFiltered = await this.getFilteredRfidTags(rfidSearch);

      if (rfidFiltered.length === 1) {
        rfid = rfidFiltered[0];
      }
    }

    return rfid;
  }

  async continueWithoutInput() {
    if (this.rfidTagBarcode) {
      this.loadingIndicator.show();

      this.rfidTagsFiltered = await this.getFilteredRfidTags(this.rfidTagBarcode);
      const selectedRfid = this.rfidTagsFiltered.find(rfid => rfid.barcode === this.rfidTagBarcode);

      if (selectedRfid) {
        await this.decideWhichDialogOpen(selectedRfid);
      }
      else {
        this.toastService.error(this.translate.instant('toast.errors.totemServices.tagNotFound'));
        this.dialogRef.close({ response: TotemDialogResponse.none });
      }

      this.loadingIndicator.hide();
    }
  }

  async handleContinueButton() {
    let selectedRfid = this.rfidTagsFiltered.find(rfid => rfid.barcode === this.tagCtrl.value);

    this.loadingIndicator.show();

    if (!selectedRfid) {
      selectedRfid = await this.getRFIDFromPSMMTag();
    }

    if (selectedRfid) {
      await this.decideWhichDialogOpen(selectedRfid);
      this.loadingIndicator.hide();
    } else {
      this.toastService.error(this.translate.instant('toast.errors.totemServices.tagNotFound'));
      this.loadingIndicator.hide();
      this.dialogRef.close({ response: TotemDialogResponse.none });
    }
  }

  private openContinueFlowDialog(instruction: Instruction) {
    const dialog = this.concludeInstructionService.openContinueFlowDialog(instruction);

    dialog
      .afterClosed()
      .toPromise()
      .then((response: { continueFlow: boolean }) => {
        if (response.continueFlow) {
          this.openConcludeInstructionDialog(instruction);
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.created });
        }
      });
  }

  private openConcludeInstructionDialog(instruction: Instruction) {
    const dialog = this.concludeInstructionService.openConcludeInstructionDialog(instruction);

    dialog
      .afterClosed()
      .toPromise()
      .then((response: { isCompleted: boolean; concludedRail: LocationDto }) => {
        if (response.isCompleted) {
          this.dialogRef.close({ response: TotemDialogResponse.concluded, concludedRail: response.concludedRail });
        } else {
          this.dialogRef.close({ response: TotemDialogResponse.created });
        }
      });
  }
}
