/*
 * File: /src/app/services/models/nav.menu.ts
 * Project: boxcar-console
 * Created Date: 2022-09-21 10:18:15
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

export type NavBarItem = {
  key: string;
  link: string;
};

export type NavBarMenu = {
  auditMenu?: NavBarItem[];
  managementMenu?: NavBarItem[];
  orderMenu?: NavBarItem;
  reportMenu?: NavBarItem;
  totemMenu?: NavBarItem;
  qualityMenu?: NavBarItem;
};

export enum NavItemName {
  'warehouse' = 'warehouse',
  'instructions' = 'instructions',
  'logs' = 'logs',
  'parts' = 'parts',
  'boxes' = 'boxes',
  'racks' = 'racks',
  'rfid' = 'rfid',
  'sectors' = 'sectors',
  'boxTypes' = 'boxTypes',
  'powerBI' = 'powerBI',
  'alerts' = 'alerts',
  'psmmTagsReport' = 'psmmTagsReport',
  'userManagement' = 'userManagement',
  'quality' = 'quality',
  'settingsProfiles' = 'settingsProfiles',
  'forkliftsAndEquipments' = 'forkliftsAndEquipments',
  'pabList' = 'pabList',
}
