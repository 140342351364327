import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataCatalogApiService, RfidApiService } from '@services/api';
import { AppToastService } from '@services/index';
import {
  DataPsmmTagContingency,
  InfoForAssociateBox,
  LabelDto,
  PartDto,
  LocationDto,
  RfidTagInfoDto,
  SourceDto,
} from 'src/app/models';
import { Subscription } from 'rxjs';
import { TotemApiService } from '@services/api/totem.service';
import { MetadataService } from '@services/metadata/metadata.service';

@Component({
  selector: 'associate-dialog',
  templateUrl: './associate-dialog.component.html',
  styleUrls: ['./associate-dialog.component.scss'],
})
export class CheckPsmmInfoComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = new FormGroup({
    tagRfidCtrl: new FormControl('', [Validators.required, this.isValidOption('rfid')]),
    partNumberCtrl: new FormControl('', [Validators.required, this.isValidOption('partNumber')]),
    sourceCtrl: new FormControl('', [Validators.required, this.isValidOption('injector')]),
    destinationCtrl: new FormControl('FG01', [Validators.required]),
  });
  psmmTagsCtrl: FormGroup[] = [];
  isLoading: boolean = false;

  filteredPartNumbers: PartDto[] = [];
  allPartNumbers: PartDto[] = [];
  subPartNumbers!: Subscription;
  allInjectors: SourceDto[] = [];
  filteredInjectors: SourceDto[] = [];
  subInjectors!: Subscription;
  filteredRFIDs: RfidTagInfoDto[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { rail: LocationDto; radData: DataPsmmTagContingency[] },
    private catalogApi: DataCatalogApiService,
    private rfidApi: RfidApiService,
    private toastService: AppToastService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<CheckPsmmInfoComponent>,
    private totemApi: TotemApiService,
    private metadataService: MetadataService
  ) {
    this.allPartNumbers = [...this.catalogApi.allPartsCached];
    this.setSubscriptionOnFormValueChanges('partNumber');
    this.setSubscriptionOnFormValueChanges('rfid');
  }

  ngOnDestroy(): void {
    if (this.subPartNumbers) {
      this.subPartNumbers.unsubscribe();
    }
    if (this.subInjectors) {
      this.subInjectors.unsubscribe();
    }
  }

  async ngOnInit(): Promise<void> {
    this.allInjectors = await this.getAllInjectors();
    this.setInitialState();
    this.setSubscriptionOnFormValueChanges('injector');
  }

  private setInitialState() {
    this.data.radData.forEach((data, idx) => {
      if (data.radResponse) {
        if (this.formGroup.get('partNumberCtrl')!.value === '') {
          const partNumber = this.allPartNumbers.find(
            partNumber => partNumber.number === data.radResponse!.psmmLabelDto.partNumber
          );
          const source = this.allInjectors.find(
            injector => injector.name === data.radResponse!.psmmLabelDto.manufactureSource
          );

          if (partNumber) {
            this.formGroup.get('partNumberCtrl')!.setValue(`${partNumber.number} - ${partNumber.description}`);
            this.formGroup.get('partNumberCtrl')!.disable();
          }

          if (source) {
            this.formGroup.get('sourceCtrl')!.setValue(`${source.name} - ${source.description}`);
            this.formGroup.get('sourceCtrl')!.disable();
          }
        }
        const date = new Date(data.radResponse.psmmLabelDto.timestamp);
        const time = `${date.getHours()}:${date.getMinutes()}`;
        this.psmmTagsCtrl.push(
          new FormGroup({
            psmmTag: new FormControl(data.tag, [Validators.required]),
            qty: new FormControl(data.radResponse.psmmLabelDto.quantity, [Validators.min(1), Validators.required]),
            operator: new FormControl(data.radResponse.psmmLabelDto.operator, [Validators.required]),
            shift: new FormControl(data.radResponse.psmmLabelDto.shift, [Validators.required]),
            date: new FormControl(date, [Validators.required]),
            time: new FormControl(time, [Validators.required]),
          })
        );

        this.psmmTagsCtrl[idx].disable();
      } else {
        this.psmmTagsCtrl.push(
          new FormGroup({
            psmmTag: new FormControl(data.tag, [Validators.required]),
            qty: new FormControl(1, [Validators.min(1), Validators.required]),
            operator: new FormControl('', [Validators.required]),
            shift: new FormControl(1, [Validators.required]),
            date: new FormControl('', [Validators.required]),
            time: new FormControl('', [Validators.required]),
          })
        );

        this.psmmTagsCtrl[idx].get('psmmTag')!.disable();
      }
    });
  }

  getFormControlFromGroup(tag: string) {
    return this.formGroup.get(tag) as FormControl;
  }

  addNewTag() {
    this.psmmTagsCtrl.push();
  }

  removeTagFromIndex(idx: number) {
    this.psmmTagsCtrl.splice(idx, 1);
  }

  getFormControl(control: string, idx: number) {
    return this.psmmTagsCtrl[idx].get(control)! as FormControl;
  }

  validateForm() {
    let labelDtosValid = true;

    for (let group of this.psmmTagsCtrl) {
      labelDtosValid = (group.valid || group.disabled) && labelDtosValid;
    }

    return this.formGroup.valid && labelDtosValid;
  }

  private async getAllInjectors() {
    return await this.catalogApi.getSectors().then(sectors => {
      let injectors: SourceDto[] = [];

      sectors.forEach(sector => {
        injectors = injectors.concat(
          sector.sourceDtos.filter(
            sector =>
              !sector.description.toLowerCase().includes('picking') &&
              !sector.description.toLowerCase().includes('null')
          )
        );
      });

      return injectors;
    });
  }

  private setSubscriptionOnFormValueChanges(ctrl: string) {
    switch (ctrl) {
      case 'partNumber':
        this.subPartNumbers = this.formGroup.get('partNumberCtrl')!.valueChanges.subscribe({
          next: value => {
            this.filteredPartNumbers = this.allPartNumbers.filter(
              partNumber =>
                partNumber.number.toLowerCase().includes(value.toLowerCase()) ||
                partNumber.description.toLowerCase().includes(value.toLowerCase())
            );
          },
        });

        break;
      case 'injector':
        this.subInjectors = this.formGroup.get('sourceCtrl')!.valueChanges.subscribe({
          next: value => {
            this.filteredInjectors = this.allInjectors.filter(
              injector =>
                injector.description.toLowerCase().includes(value.toLowerCase()) ||
                injector.name.toLowerCase().includes(value.toLowerCase())
            );
          },
        });

        break;
      case 'rfid':
        this.formGroup.get('tagRfidCtrl')!.valueChanges.subscribe({
          next: value => {
            this.isLoading = true;
            setTimeout(() => {
              if (value === this.formGroup.get('tagRfidCtrl')!.value) {
                this.rfidApi.getRfidTags(value, 1, 100).then(response => {
                  this.filteredRFIDs = response.rfidTagInfoDtos;
                  this.isLoading = false;
                });
              }
            }, 750);
          },
        });

        break;
      default:
        break;
    }
  }

  private isValidOption(ctrl: string): ValidatorFn {
    switch (ctrl) {
      case 'partNumber':
        return this.validatePartNumberValue();
      case 'rfid':
        return this.validateRfidValue();
      case 'injector':
        return this.validateInjectorValue();
      default:
        return () => {
          return null;
        };
    }
  }

  private validatePartNumberValue(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        this.filteredPartNumbers?.find(
          partNumber => partNumber.number.trim().toLowerCase() === control.value.split(' - ')[0].trim().toLowerCase()
        )
      ) {
        return null;
      }
      return { invalidData: true };
    };
  }

  private validateRfidValue(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.filteredRFIDs?.find(rfid => rfid.barcode.trim().toLowerCase() === control.value.trim().toLowerCase())) {
        return null;
      }
      return { invalidData: true };
    };
  }

  private validateInjectorValue(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        this.filteredInjectors?.find(
          injector => injector.name.trim().toLowerCase() === control.value.split(' - ')[0].trim().toLowerCase()
        )
      ) {
        return null;
      }
      return { invalidData: true };
    };
  }

  generateAssociation() {
    const rfid: RfidTagInfoDto | undefined = this.filteredRFIDs.find(
      rfid => rfid.barcode === this.formGroup.get('tagRfidCtrl')!.value
    );

    if (!rfid) {
      this.toastService.error(this.translate.instant('toast.errors.totemServices.invalidRfid'));
      this.dialogRef.close();
    } else {
      const labelDtos = this.psmmTagsCtrl.map(form => {
        const date = new Date(form.get('date')!.value);
        const splittedTime = form.get('time')!.value.split(':');
        const timeZoneHoursOffset = date.getTimezoneOffset() / 60;

        date.setHours(splittedTime[0] - timeZoneHoursOffset, splittedTime[1]);

        const labelDto: LabelDto = {
          labelcode: form.get('psmmTag')!.value as string,
          quantity: form.get('qty')!.value as number,
          timestamp: date.toISOString(),
          operator: form.get('operator')!.value,
          shift: form.get('shift')!.value,
          partNumber: this.formGroup.get('partNumberCtrl')!.value.split(' - ')[0],
          manufactureSource: this.formGroup.get('sourceCtrl')!.value.split(' - ')[0],
        };

        return labelDto;
      });

      const requestBody: InfoForAssociateBox = {
        packageEPC: rfid.epc,
        partNumber: (this.formGroup.get('partNumberCtrl')!.value as string).split(' - ')[0],
        source: (this.formGroup.get('sourceCtrl')!.value as string).split(' - ')[0],
        destination: this.formGroup.get('destinationCtrl')!.value as string,
        labelDtos: labelDtos,
      };

      this.totemApi
        .associateBox(requestBody)
        .toPromise()
        .then((response: { containerLifeCycleId: number }) => {
          this.toastService.success(this.translate.instant('toast.success.totemServices.successToAssociate'));
          this.dialogRef.close({ containerLifeCycleId: response.containerLifeCycleId, rfid: rfid });
        })
        .catch(err => {
          const errorText = `${this.translate.instant(
            'toast.errors.totemServices.errorToAssociate'
          )}: ${this.metadataService.getErrorDescriptionFromErrorCode(err.error.code)}`;
          this.toastService.error(errorText);
        });
    }
  }
}
