/*
 * File: src/app/services/nav-menu/nav-menu.reducers.ts
 * Project: boxcar-console
 * Created Date: 2022-09-22 10:00:46
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 *
 */

import { createReducer, on } from '@ngrx/store';
import { NavBarMenu } from 'src/app/models/nav.menu';
import * as NavMenuActions from '@services/nav-menu/nav-menu.actions';

export const initialState: NavBarMenu = {
  auditMenu: undefined,
  managementMenu: undefined,
  orderMenu: undefined,
  reportMenu: undefined,
  totemMenu: undefined,
  qualityMenu: undefined,
};

export const navReducer = createReducer(
  initialState,
  on(NavMenuActions.loadNavBarMenu, (state, { defaultMenu: menu }) => menu)
);
