/*
 * File: \src\app\services\api\rfid.api.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 13:24:09
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Service created for refactoring.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { baseUrl } from '.';

import { RfidAllotmentRequestDto, RfidManufacturerDto, RfidTagResponseDto } from 'src/app/models/tags';
import { BatchCreationDto, BatchCreationReturn, BoxTypeDto } from 'src/app/models/rfid';

@Injectable({
  providedIn: 'root',
})
export class RfidApiService {
  allBoxTypesCached: BoxTypeDto[] = [];

  constructor(private httpClient: HttpClient) {}

  /** Returns a (filtered) list of all registered RFID tags */
  getRfidTags(filter: string, pageNum: number = 1, qtyPerPage: number = 10): Promise<RfidTagResponseDto> {
    return this.httpClient
      .get<RfidTagResponseDto>(baseUrl + `rfid/tags?filter=${filter}&pageNum=${pageNum}&qtyPerPage=${qtyPerPage}`)
      .toPromise();
  }

  /** Registers a set of RFID tags (a.k.a. allotment) */
  createRfidAllotment(request: RfidAllotmentRequestDto): Promise<string[]> {
    return this.httpClient.post<string[]>(baseUrl + 'rfid/allotment', request).toPromise();
  }

  /** Returns complete list of RFID tag manufacturers. */
  getRfidManufacturers(): Promise<RfidManufacturerDto[]> {
    return this.httpClient.get<RfidManufacturerDto[]>(baseUrl + 'rfid/manufacturers').toPromise();
  }

  getBoxTypes() {
    return this.httpClient.get<BoxTypeDto[]>(baseUrl + 'catalog/containerModels').toPromise();
  }

  createRfidContainerBatch(newContainerBatch: BatchCreationDto) {
    return this.httpClient
      .post<BatchCreationReturn>(`${baseUrl}rfid/createcontainer/batch`, newContainerBatch)
      .toPromise();
  }

  // State saving methods
  getAllBoxTypesFromCache() {
    if (this.allBoxTypesCached.length === 0) {
      this.getBoxTypes()
        .then(response => {
          this.allBoxTypesCached = response;
        })
        .catch(error => {
          throw new Error("Can't fetch all box types from backend");
        });
    }
  }
}
