<!--
File: \src\app\pages\boxes\rfid-tag-detail.dialog\rfid-tag-detail.dialog.html
Project: boxcar-console
Created Date: 2022-02-18 08:26:16
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-11-23	TC	Fix null active-barcode cases
2022-04-26	JF	Added I18N support
-->

<div
  *ngIf="data.rfidTag.container.containerModel.toLowerCase().includes('null'); then genericBox else defaultBox"></div>

<!-- Template for default title and subtitle -->
<ng-template #defaultBox>
  <h2 class="header-margin">{{data.rfidTag.container.activeBarcode ?? data.rfidTag.container.barcodes![0]}}</h2>
  <h3 class="header-margin">{{ 'rfidTags.type' | translate }} {{data.rfidTag.container.containerModel}}</h3>
</ng-template>

<!-- Template for generic title and subtitle -->
<ng-template #genericBox>
  <div *ngIf="data.rfidTag.boxAssemblies.length === 1">
    <h2 class="header-margin">{{ 'boxes.genericBox' | translate }}</h2>
    <h3 class="header-margin">{{ 'boxes.createdBySystem' | translate }}</h3>
  </div>
  <div *ngIf="data.rfidTag.boxAssemblies.length > 1">
    <h2 class="header-margin">{{ 'boxes.genericPalletTitle' | translate }}</h2>
    <h3 class="header-margin">{{ 'boxes.palletCreatedBySystem' | translate }}</h3>
  </div>
</ng-template>

<mat-dialog-content class="mat-typography">
  <!-- Standard boxes and pallets -->
  <div *ngIf="!data.rfidTag.container.activeBarcode?.toLowerCase()?.includes('__null') ; else generic ">
    <div *ngIf="data.rfidTag.boxAssemblies.length > 0; else availableBox">
      <div class="content" *ngFor="let assembly of data.rfidTag.boxAssemblies">
        <div>
          <h3><strong>{{ 'rfidTags.information' | translate }}</strong></h3>
          <p>
            {{ 'rfidTags.partNumber' | translate }}: {{assembly.boxAssemblyInfoResponseDto.partNumber}} -
            {{assembly.boxAssemblyInfoResponseDto.partDescription}}
          </p>
          <p>
            {{ 'rfidTags.source' | translate }}: {{assembly.boxAssemblyInfoResponseDto.manufactureSource}} -
            {{assembly.boxAssemblyInfoResponseDto.manufactureSourceDescription}}
          </p>
          <p>{{ 'rfidTags.totalQuantity' | translate }}: {{total(assembly.labelDtos)}}</p>
        </div>

        <div class="tags-area">
          <div class="rfid-tags">
            <h3><strong>{{ 'rfidTags.rfidTags' | translate }}</strong></h3>
            <p>{{ formatBarcodes(data.rfidTag.container.barcodes!) }}</p>
          </div>

          <div class="psmm-tags" *ngIf="assembly.labelDtos !== null ">
            <h3><strong>{{ 'rfidTags.psmmStickers' | translate }}</strong></h3>
            <p *ngFor="let psmmTag of assembly.labelDtos">{{psmmTag.labelcode}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Available boxes -->
  <ng-template #availableBox>
    <div class="content">
      <div>
        <h3><strong>{{ 'rfidTags.information' | translate }}</strong></h3>
        <p>{{ 'rfidTags.partNumber' | translate }}: -</p>
        <p>{{ 'rfidTags.source' | translate }}: -</p>
        <p>{{ 'rfidTags.totalQuantity' | translate }}: 0</p>
      </div>
      <div class="rfid-tags">
        <h3><strong>{{ 'rfidTags.rfidTags' | translate }}</strong></h3>
        <p *ngFor="let rfidTag of data.rfidTag.container.barcodes">{{rfidTag}}</p>
      </div>
    </div>
  </ng-template>

  <!-- Generic boxes and pallets -->
  <ng-template #generic>
    <div class="content">
      <div>
        <h3><strong>{{ 'rfidTags.information' | translate }}</strong></h3>
        <p>
          {{ 'rfidTags.partNumber' | translate }}:
          {{data.rfidTag.boxAssemblies[0].boxAssemblyInfoResponseDto.partNumber}} -
          {{data.rfidTag.boxAssemblies[0].boxAssemblyInfoResponseDto.partDescription}}
        </p>
        <p>
          {{ 'rfidTags.source' | translate }}:
          {{data.rfidTag.boxAssemblies[0].boxAssemblyInfoResponseDto.manufactureSource}} -
          {{data.rfidTag.boxAssemblies[0].boxAssemblyInfoResponseDto.manufactureSourceDescription}}
        </p>
        <p>{{ 'rfidTags.totalQuantity' | translate }}: {{total(data.rfidTag.boxAssemblies[0].labelDtos)}}</p>
      </div>
    </div>
  </ng-template>

  <div
    *ngIf="!data.rfidTag.container.activeBarcode?.toLowerCase()?.includes('__null')"
    style="margin: 0 1rem"
  >
    <h3 style="margin: 0.2rem 0"><strong>{{ 'rfidTags.lastUpdates' | translate }}</strong></h3>

    <div class="table mat-elevation-z2 loading-container">
      <!-- Loading indicator -->
      <loading-on-page [isLoading]="isLoading"></loading-on-page>

      <table mat-table [dataSource]="dataSource" aria-describedby="rfid tag detail table">
        <ng-container matColumnDef="login">
          <th mat-header-cell *matHeaderCellDef>{{ 'logs.login' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.login }}</td>
        </ng-container>

        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef>{{ 'logs.dateTime' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.timestamp }}</td>
        </ng-container>

        <ng-container matColumnDef="eventClassNumber">
          <th mat-header-cell *matHeaderCellDef>{{ 'logs.class' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.eventClassNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="contextId">
          <th mat-header-cell *matHeaderCellDef>{{ 'logs.contextId' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.contextId }}</td>
        </ng-container>

        <ng-container matColumnDef="contextTag">
          <th mat-header-cell *matHeaderCellDef>{{ 'logs.context' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.contextTag }}</td>
        </ng-container>

        <ng-container matColumnDef="data">
          <th mat-header-cell *matHeaderCellDef>{{ 'logs.data' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <span>
              <span [innerHTML]="row.data.title"></span>
              <span [innerHTML]="row.data.description"> </span>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngStyle]="{ 'background-color': row.gravity === 1 ? '#FFF9DB' : row.gravity === 2 ? '#FDDED8' : '' }"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="displayedColumns.length">{{ 'rfidTags.noRecentUpdate' | translate }}</td>
        </tr>
      </table>

      <paginator-page-select
        *ngIf="dataSource.data.length"
        [pageSizeOptions]="[10, 25, 100]"
        aria-label="Select page of users"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [length]="totalLength"
        (page)="changePaginator($event)">
      </paginator-page-select>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>{{ 'rfidTags.return' | translate }}</button>
</mat-dialog-actions>
