<div class="loading-container">
  <loading-on-page [isLoading]="isLoading" *ngIf="showLoading"></loading-on-page>
  <div
    class="table-wrapper"
    infinite-scroll
    [scrollWindow]="false"
    [infiniteScrollDistance]="1"
    (scrolled)="requestMoreData()">
    <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows aria-label="All high and medium alerts">
      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <img *ngIf="row.icon" src="../../../../assets/icons/warning_icon.svg" alt="warning icon" />
        </td>
      </ng-container>

      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef>{{ 'common.timestamp' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.timestamp }}</td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>{{ 'common.user' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.user }}</td>
      </ng-container>

      <ng-container matColumnDef="class">
        <th mat-header-cell *matHeaderCellDef>{{ 'common.class' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.class }}</td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>{{ 'common.message' | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <!-- <div class="message-container"> -->
          <span>
            <span class="title" [innerHTML]="row.message.title"></span>
            <span [innerHTML]="row.message.description"></span>
          </span>
          <!-- <div class="helper-icon">
              <img
                alt="Help icon"
                src="../../../../assets/icons/help_icon.svg"
                cdkOverlayOrigin
                #helpTrigger="cdkOverlayOrigin"
                (mouseenter)="overlay.open = true"
                (mouseleave)="overlay.open = false" />
            </div>
          </div> -->
          <!-- <ng-template
            cdkConnectedOverlay
            #overlay="cdkConnectedOverlay"
            [cdkConnectedOverlayOrigin]="helpTrigger"
            [cdkConnectedOverlayOpen]="overlay.open">
            <mat-card class="overlay">
              <mat-card-content>
                <div class="help-body">
                  <div>
                    <h4>{{ 'logs.helpSession.why' | translate }}</h4>
                    <ul>
                      <li>Lorem ipsum</li>
                      <li>Lorem ipsum</li>
                      <li>Lorem ipsum</li>
                    </ul>
                  </div>
                  <mat-divider [vertical]="true"></mat-divider>
                  <div>
                    <h4>{{ 'logs.helpSession.todo' | translate }}</h4>
                    <ul>
                      <li>Lorem ipsum</li>
                      <li>Lorem ipsum</li>
                      <li>Lorem ipsum</li>
                    </ul>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </ng-template> -->
        </td>
      </ng-container>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [colSpan]="columnsToDisplay.length">
          {{ 'logs.noData' | translate }}
        </td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        [style.--color]="getColorFromSeverity(row.severity)"></tr>
    </table>
  </div>
</div>
