import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TotemAssociateResponse, TotemGenerateRequestInfos } from 'src/app/models/totem';
import { baseUrl } from '.';
import { ContainerDto, InfoForAssociateBox, Instruction } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class TotemApiService {
  constructor(private httpClient: HttpClient) {}

  generateInstruction(infos: TotemGenerateRequestInfos) {
    return this.httpClient.post<Instruction>(`${baseUrl}totem/generate`, infos);
  }

  associateBox(info: InfoForAssociateBox) {
    return this.httpClient.post<TotemAssociateResponse>(`${baseUrl}totem/associate`, info);
  }

  totemSearch(barcode: string) {
    return this.httpClient.get<ContainerDto>(`${baseUrl}totem/search/${barcode}`);
  }
}
