<mat-tab-group (selectedTabChange)="onTabChange($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      {{ 'totemServices.tabs.contingencyServices' | translate }}
    </ng-template>

    <br />

    <boxcar-console-contingency-functions (onInstructionCreated)="refreshInstructions()" #warehouseTotem>
    </boxcar-console-contingency-functions>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      {{ 'totemServices.tabs.instructionsManager' | translate }}
    </ng-template>

    <br />

    <boxcar-console-contingency-instructions-manager #instructionsList>
    </boxcar-console-contingency-instructions-manager>
  </mat-tab>
</mat-tab-group>
