/*
 * File: \src\app\services\api\login.api.service.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Service refactored.
 */

import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { LoginResponseDto, LoginRequestDto, LoginVersionDto } from 'src/app/models/login';
import { baseUrl } from '@services/api';

@Injectable()
export class LoginAPIService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  /** Performs login on the backend and saves returned token. */
  async doLogin(userCredentials: LoginRequestDto): Promise<LoginResponseDto> {
    let retrievedUserData: LoginResponseDto = {} as LoginResponseDto;

    // Transform user and pass in base64
    const authToken = btoa(`${userCredentials.login}:${userCredentials.password}`);
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Basic ${authToken}`);

    const body: LoginVersionDto = {
      application: 'BoxCar/Console',
      version: environment.version,
    };

    await this.httpClient
      .post<LoginResponseDto>(baseUrl + 'login', body, { headers })
      .toPromise()
      .then((userData: LoginResponseDto) => {
        if (userData === undefined) {
          throw userData;
        }

        retrievedUserData = userData;
      })
      .catch(error => {
        throw error;
      });

    return retrievedUserData;
  }
}
