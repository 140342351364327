import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PackagingApiService } from '@services/api';
import { TotemApiService } from '@services/api/totem.service';
import { AppToastService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { MetadataService } from '@services/metadata/metadata.service';
import { ContainerDto, InstructionType, LocationDto } from 'src/app/models';
import { TotemGenerateRequestInfos } from 'src/app/models/totem';
import { Subscription } from 'rxjs';

@Component({
  selector: 'boxcar-console-create-instruction-dialog',
  templateUrl: './create-instruction-dialog.component.html',
  styleUrls: ['./create-instruction-dialog.component.scss'],
})
export class CreateInstructionDialogComponent implements OnInit, OnDestroy {
  isCustom: boolean = false;
  filteredRails: LocationDto[] = [];
  private destinationSub: Subscription;
  private destinationTypeSubscription!: Subscription;
  private localRackRails: LocationDto[] = [];
  private formGroup: FormGroup = new FormGroup({
    destinationCtrl: new FormControl('', [Validators.required]),
    customDestinationCtrl: new FormControl('', [this.isValidOption()]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { rail: LocationDto; regionRackRails: LocationDto[]; container: ContainerDto },
    private dialogRef: MatDialogRef<CreateInstructionDialogComponent>,
    private totemApi: TotemApiService,
    private packagingApi: PackagingApiService,
    private toastService: AppToastService,
    private translate: TranslateService,
    private loadingIndicator: LoadingIndicatorService,
    private metadataService: MetadataService
  ) {
    this.localRackRails = [...data.regionRackRails.filter(rail => rail.name !== data.rail.name)];

    this.createSubscriptionOfDestination();
    this.destinationSub = this.setSubscriptionFormCustomDestinationCtrl();
  }

  ngOnDestroy(): void {
    this.destinationTypeSubscription.unsubscribe();
    this.destinationSub.unsubscribe();
  }

  ngOnInit(): void {
    return;
  }

  getControlFromForm(type: string) {
    return this.formGroup.controls[type] as FormControl;
  }

  private createSubscriptionOfDestination() {
    this.destinationTypeSubscription = this.formGroup.get('destinationCtrl')!.valueChanges.subscribe({
      next: value => {
        if (value === 'custom') {
          this.formGroup.get('customDestinationCtrl')!.addValidators([Validators.required]);
          this.isCustom = true;
        } else {
          this.isCustom = false;
        }
      },
    });
  }

  private isValidOption(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.filteredRails?.find(rail => rail.name.trim().toLowerCase() === control.value.trim().toLowerCase())) {
        return null;
      }
      return { invalidData: true };
    };
  }

  private setSubscriptionFormCustomDestinationCtrl() {
    return this.formGroup.get('customDestinationCtrl')!.valueChanges.subscribe({
      next: value => {
        this.filteredRails = this.localRackRails.filter(rail => rail.name.toLowerCase().includes(value.toLowerCase()));
      },
    });
  }

  private generateInstructionWithAutoDestination() {
    return this.packagingApi.liberateBox(this.data.container.activeBarcode!).toPromise();
  }

  private generateInstructionSelectedDestination(destinationType: string) {
    const instructionInfo: TotemGenerateRequestInfos = {
      instructionType: InstructionType.storing,
      containerLifeCycleId: this.data.container.containerLifeCycleId!,
      origin: this.data.container.boxAssembly!.boxAssemblyInfoResponseDto.manufactureSource,
      originColumn: null,
      originLevel: null,
      destination: null,
      destinationLevel: null,
      destinationColumn: null,
    };
    switch (destinationType) {
      case 'custom':
        const selectedRail = this.formGroup.get('customDestinationCtrl')!.value;
        const selectedRailStructure = this.localRackRails.find(value => value.name === selectedRail);

        if (selectedRailStructure) {
          instructionInfo.destination = selectedRailStructure.regionPrefix;
          instructionInfo.destinationColumn = selectedRailStructure.column;
          instructionInfo.destinationLevel = selectedRailStructure.level;
        }
        break;
      case 'selected':
        instructionInfo.destination = this.data.rail.regionPrefix;
        instructionInfo.destinationColumn = this.data.rail.column;
        instructionInfo.destinationLevel = this.data.rail.level;
        break;
      default:
        break;
    }

    return this.totemApi.generateInstruction(instructionInfo).toPromise();
  }

  async createInstruction() {
    const destinationType = this.formGroup.get('destinationCtrl')!.value;
    let instructionId = undefined;

    this.loadingIndicator.show();
    if (destinationType === 'auto') {
      await this.generateInstructionWithAutoDestination()
        .then(response => {
          instructionId = response.instructions![0].instructionId;
          this.toastService.success(this.translate.instant('toast.success.totemServices.instructionCreated'));
          this.loadingIndicator.hide();
          this.dialogRef.close({ instructionId: instructionId });
        })
        .catch(err => {
          const errorText = `${this.translate.instant(
            'toast.errors.totemServices.instructionCreated'
          )}: ${this.metadataService.getErrorDescriptionFromErrorCode(err.error.code)}`;
          this.toastService.error(errorText);
          this.loadingIndicator.hide();
          this.dialogRef.close();
        });
    } else {
      await this.generateInstructionSelectedDestination(destinationType)
        .then(response => {
          instructionId = response.instructionId;
          this.toastService.success(this.translate.instant('toast.success.totemServices.instructionCreated'));
          this.loadingIndicator.hide();
          this.dialogRef.close({ instructionId: instructionId });
        })
        .catch(err => {
          const errorText = `${this.translate.instant(
            'toast.errors.totemServices.instructionCreated'
          )}: ${this.metadataService.getErrorDescriptionFromErrorCode(err.error.code)}`;
          this.toastService.error(errorText);
          this.loadingIndicator.hide();
          this.dialogRef.close();
        });
    }
  }
}
