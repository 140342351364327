/*
 * File: \src\app\components\notice-dialog\notice-dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-09-14 08:40:54
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

import { Component, Inject, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export type NoticeDialogData = {
  title: string;
  errorCode: string | undefined;
  message: string;
  details: string | undefined;
};

@Component({
  selector: 'boxcar-console-notice-dialog',
  templateUrl: './notice-dialog.html',
  styleUrls: ['./notice-dialog.scss'],
})
export class NoticeDialogComponent implements OnInit {
  constructor(
    private clipboard: Clipboard,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: NoticeDialogData
  ) {}

  ngOnInit(): void {
    return;
  }

  onCopyToClipboard(): void {
    this.clipboard.copy(this.buildClipboardContent());
  }

  buildClipboardContent(): string {
    return (
      this.data.message +
      (this.data.details ? '\n' + this.data.details : '') +
      (this.data.errorCode ? '\nError Code: ' + this.data.errorCode : '')
    );
  }
}
