/*
 * File: \src\app\components\rack-viewer\themes\rack-viewer-themes.ts
 * Project: boxcar-console
 * Created Date: 2022-05-10 16:40:48
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

export type RackVisualTheme = {
  itemBorderColor: string;
  itemNominalColor: string;
  itemWithoutPartColor: string;
  itemInactiveColor: string;
  itemReturningColor: string;
  itemSpecialColor: string;
  itemLabelColor: string;
  itemLabelFont: string;
  itemLabelFontWeight: string;
};

// Theme colors:
export const defaultTheme: RackVisualTheme = {
  itemBorderColor: 'rgb(180, 140, 20)',
  itemNominalColor: 'rgb(0, 140, 255)',
  itemWithoutPartColor: 'rgb(160, 160, 160)',
  itemInactiveColor: 'rgb(240, 100, 100)',
  itemReturningColor: 'rgb(22, 182, 52)',
  itemSpecialColor: 'rgb(180, 130, 80)',
  itemLabelColor: 'white',
  itemLabelFont: 'bold 12px sans-serif',
  itemLabelFontWeight: 'normal',
};

export const gradientColors = {
  gradientFullColor: 'rgba(0, 140, 255, 1)',
  gradientInitialColor: 'rgba(0, 140, 255, 0.15)',
};
