import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PsmmTagDetailDialogComponent } from '@pages/boxes/psmm-tag-detail.dialog/psmm-tag-detail.dialog';
import { BoxDto, LabelDto, PsmmDetail, RegionStrategy } from 'src/app/models';

@Component({
  selector: 'normal-box-card',
  templateUrl: './normal-box-card.component.html',
  styleUrls: ['./normal-box-card.component.scss'],
})
export class NormalBoxCardComponent implements OnInit {
  @Input() box!: BoxDto;
  @Input() boxStateMetadata: any = [];
  @Output() detailRFID = new EventEmitter<BoxDto>();
  @Output() retrieveState = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    return;
  }

  tryagain() {
    this.retrieveState.emit();
  }

  openPsmmTagDetail(psmmTag: LabelDto, partNumber: string, origin: string) {
    const psmmDetail: PsmmDetail = {
      psmm: psmmTag,
      partNumberDesc: partNumber,
      sourceDesc: origin,
    };
    // Copy data for a variable, for don't break any data from labelDto;
    let label = { ...psmmDetail.psmm };

    if (label.timestamp) label.timestamp = new Date(psmmDetail.psmm.timestamp).toLocaleString('default');

    this.dialog.open(PsmmTagDetailDialogComponent, {
      data: { labelDto: label, pn: psmmDetail.partNumberDesc, source: psmmDetail.sourceDesc },
    });
  }

  openRfidTagDetail() {
    this.detailRFID.emit(this.box);
  }

  total(labelDtos: LabelDto[]) {
    let total = 0;
    if (labelDtos != null && labelDtos.length > 0) {
      labelDtos.forEach(label => {
        total += label.quantity;
      });
    }
    return total;
  }

  isJITContainer(): boolean {
    return this.box.boxAssemblies[0]?.boxAssemblyInfoResponseDto?.partStrategy === RegionStrategy.jit;
  }

  getFormatedJITLabel(activeBarcode?: string): string {
    if (!activeBarcode) return 'N/A';

    const id = Number(activeBarcode) % 1000;
    const serie = Math.floor(Number(activeBarcode) / 1000);

    return `SERIE <strong>${serie.toString().padStart(4, '0')}</strong> ID <strong>${id.toString().padStart(3, '0')}</strong>`;
  }
}
