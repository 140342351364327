<h1 mat-dialog-title>{{ 'totemServices.moveBox.boxFound' | translate }}:</h1>

<div mat-dialog-content>
  <h4>{{ getDialogSubtitle() }}</h4>

  <instruction-card [showActions]="false" [instruction]="data.containerInfo.instructions![0]"></instruction-card>

  <h4>{{ 'totemServices.moveBox.modifyInstruction.instructionToCancel' | translate }}</h4>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="onClickCancelInstruction()">
    {{ 'common.buttons.instruction.cancel' | translate }}
  </button>
  <button mat-button color="primary" mat-dialog-close>
    {{ 'common.buttons.instruction.leftThisWay' | translate }}
  </button>
</div>
