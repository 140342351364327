<!--
File: \src\app\pages\parts\import\parts.import.dialog.html
Project: boxcar-console
Created Date: 2022-02-08 14:02:32
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2023-07-14	PS	productionGuide UI. Initial version.
-->

<h1 mat-dialog-title>{{'catalog.modal.titlePartsProductionGuide' | translate}}</h1>

<div mat-dialog-content>
  <p><strong>{{ 'catalog.modal.note' | translate }}: </strong>{{'catalog.modal.warningMessage' | translate}}</p>
  <p>{{'catalog.modal.instructionsPartsProductionGuide' | translate}}</p>
  <!-- <br> -->
  <strong>
    <p>{{'catalog.modal.selectFile' | translate}}</p>
  </strong>

  <!-- File browse field -->
  <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
  <input hidden type="file" accept="{{fileTypeAsCSV}}" #fileInput (change)="onBrowseFile($event)" />
  <p style="margin: 6px">{{data.selectedFile ? data.selectedFile.name : ''}}</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="data" [disabled]="data.selectedFile === undefined">
    {{'catalog.modal.processButton' | translate}}
  </button>
  <button mat-button (click)="onCloseDialog()">{{'catalog.modal.cancelButton' | translate}}</button>
</div>