/*
 * File: \src\app\user\state\user.effects.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Component refactoring.
 * 2022-04-27	JF	Added I18N support.
 */

import { MetadataService } from '@services/metadata/metadata.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import { LoginResponseDto } from 'src/app/models/login';
import { LoginAPIService } from '../../api/login.api.service';
import { UserActionTypes } from './user.action-types';
import * as userActions from './user.actions';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LocalDataService } from '@services/local-data/local.data.service';
import { NavMenuService } from '@services/nav-menu/nav-menu.service';
import { loadNavBarMenu } from '@services/nav-menu/nav-menu.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class UserEffects {
  doLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loginStarted),
      exhaustMap(async action => {
        try {
          const userData: LoginResponseDto = await this.loginService.doLogin(action.userCredentials);
          this.localDataService.setToken(userData.token);
          this.localDataService.setRoles(userData.roles);
          const navBar = this.navMenuService.getNavMenuFromUserType(userData.roles);
          this.store.dispatch(loadNavBarMenu({ defaultMenu: navBar }));
          return { type: UserActionTypes.loginSucceeded, userData };
        } catch (error: any) {
          const errorMessage = error.error.message
            ? error.error.message
            : error.message
            ? error.message
            : this.translateService.instant('application.unknownError');
          return { type: UserActionTypes.loginFailed, error: errorMessage };
        }
      })
    )
  );

  loginSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.loginSucceeded),
        map(() => {
          this.metadataService.load();
        })
      ),
    { dispatch: false }
  );

  loginFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.loginFailed),
        map(action => {
          this.snackBar.open(action.error, this.translateService.instant('application.dismiss'), { duration: 1000 });
        })
      ),
    { dispatch: false }
  );

  navigateToDashBoard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.loginSucceeded),
        exhaustMap(async () => {
          this.router.navigateByUrl('/home');
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.logout),
        map(() => {
          localStorage.clear();
          this.router.navigate(['/home']);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private loginService: LoginAPIService,
    private localDataService: LocalDataService,
    private navMenuService: NavMenuService,
    private router: Router,
    private metadataService: MetadataService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private store: Store
  ) {}
}
