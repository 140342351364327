<mat-card>
    <mat-card-header>
      <mat-card-title>
        <!-- Panel title -->
        <h2>{{ 'catalog.psmmTag.pabList' | translate }}</h2>
      </mat-card-title>
    </mat-card-header>
  
  <div class="row geral-group" style="margin: 1rem auto">
    <div style="width: 100%">
      <div class="table-options">
        <!-- Parts table search -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'common.search' | translate }}</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. 822700088512" #input />
        </mat-form-field>
  
        <!-- Refresh button -->
        <button mat-flat-button color="primary" class="reload-button" (click)="onChangeGroup()">
          <img src="../../../../assets/icons/refreshIcon.svg" color="white" width="24px" height="24px" alt="refresh" />
        </button>
      </div>
  
      <!-- PabList table -->
      <div class="mat-elevation-z2 loading-container">
        <loading-on-page [isLoading]="isLoading"></loading-on-page>
        <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
          <caption style="display: none">
            Pab Info
          </caption>
          <ng-container matColumnDef="partNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'common.partNumber' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.partNumber }}</td>
          </ng-container>
  
          <ng-container matColumnDef="partDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.parts.description' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.partDescription }}</td>
          </ng-container>
  
          <ng-container matColumnDef="labelCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.psmmTag.labelCode' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.labelCode }}</td>
          </ng-container>
  
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.psmmTag.quantity' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.quantity }}</td>
          </ng-container>
  
          <ng-container matColumnDef="operator">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.psmmTag.operator' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.operator }}</td>
          </ng-container>
  
          <ng-container matColumnDef="shift">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.psmmTag.shift' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.shift }}</td>
          </ng-container>
  
          <ng-container matColumnDef="radTimestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'catalog.psmmTag.radTimestamp' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.radTimestamp }}</td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [colSpan]="displayedColumns.length">{{ 'general.nomatch' | translate }} "{{ input.value }}"</td>
          </tr>
        </table>
  
        <paginator-page-select
          [pageSizeOptions]="[10, 25, 100]"
          aria-label="Select page of users"
          [length]="dataSource.filteredData.length">
        </paginator-page-select>
      </div>
    </div>
  </div>
  