import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LoggerApiService } from '@services/api';
import { DataLogsService } from '@services/logs/data-logs.service';
import { MetadataService } from '@services/metadata/metadata.service';
import { LogDTO, LogDictsDto, LogQuery, LogSeverity, AutomaticReturnLog, incorrectDestinyEventDetal } from 'src/app/models';

@Component({
  selector: 'automatic-return-table',
  templateUrl: './automatic-return-table.component.html',
  styleUrls: ['./automatic-return-table.component.scss']
})
export class AutomaticReturnTableComponent implements OnInit, OnChanges {
  @Input() showTitle: boolean = true;
  @Input() refreshData: boolean = false;
  @Input() showLoading: boolean = true;
  @Input() refreshIdx: number = 0;
  @Output() resetRefreshData = new EventEmitter();
  dataSource: MatTableDataSource<AutomaticReturnLog> = new MatTableDataSource();
  columnsToDisplay: string[] = ['icon', 'timestamp', 'event', 'message'];
  apiParams: LogQuery = {
    filter: '',
    gravity: [LogSeverity.medium, LogSeverity.high],
    // Remove authentication event class
    eventClass: [100],
    pageNum: 1,
    qtyPerPage: 25,
  };
  isLoading = false;
  metadataEvent: LogDictsDto;
  localLogs: AutomaticReturnLog[] = [];

  constructor(
    private logsService: LoggerApiService,
    private dataService: DataLogsService,
    private metadataService: MetadataService
  ) {
    this.metadataEvent = this.metadataService.getLogMetadata();
  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.refreshData?.currentValue) {
      this.apiParams.pageNum = 1;
      await this.getAutomaticReturnLogs();
      this.dataSource.data = [...this.localLogs];
    }
  }

  async ngOnInit(): Promise<void> {
    await this.getAutomaticReturnLogs();
    this.dataSource.data = [...this.localLogs];
  }

  private async getAutomaticReturnLogs() {
    const logs: LogDTO[] = [];
    this.localLogs = [];

    this.isLoading = true;
    logs.push(...(await this.logsService.getLogEntries(this.apiParams)).logs);
    this.localLogs = await this.transformLogsIntoAutomaticReturnLogs(logs);
    this.isLoading = false;
    this.resetRefreshData.emit(this.refreshIdx);
  }

  private async transformLogsIntoAutomaticReturnLogs(logs: LogDTO[]): Promise<AutomaticReturnLog[]> {
    const automaticReturnLogs: AutomaticReturnLog[] = await Promise.all(
      logs.map(async log => {
        const newAutomaticReturnLog: AutomaticReturnLog = {
          icon: incorrectDestinyEventDetal.includes(log.eventDetailNumber),
          event: await this.getDetailNameFromNumber(log.eventDetailNumber),
          message: await this.dataService.getLexicalDataFromJSON(log.data, log.eventDetailNumber, log.code),
          timestamp: new Date(log.timestamp + 'Z').toLocaleString(),
          severity: this.getSeverityFromNumber(log.eventDetailNumber),
        };
        return newAutomaticReturnLog;
      })
    );

    return automaticReturnLogs;
  }

  private getSeverityFromNumber(number: number): number {
    return this.metadataEvent.eventDetailDict[number].gravity;
  }

  private getDetailNameFromNumber(number: number): string {
    if (navigator.language === 'pt-BR') {
      return this.metadataEvent.eventDetailDict[number].namePT;
    } else {
      return this.metadataEvent.eventDetailDict[number].nameEN;
    }
  }

  async requestMoreData() {
    this.showLoading = true;
    this.apiParams = { ...this.apiParams, pageNum: this.apiParams.pageNum + 1 };
    await this.getAutomaticReturnLogs();
    this.dataSource.data = this.dataSource.data.concat(this.localLogs);
  }

  getColorFromSeverity(severity: number): string {
    switch (severity) {
      case LogSeverity.high:
        return '#FDDED8';
      case LogSeverity.medium:
        return '#FFF9DB';
      default:
        return 'white';
    }
  }

}
