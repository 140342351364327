import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserApiService } from '@services/api/users.service';
import { AppToastService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { UserRequest, UserResponse, UserRole } from '@models/users';

@Component({
  selector: 'boxcar-console-update-user-dialog',
  templateUrl: './update-user-dialog.component.html',
  styleUrls: ['./update-user-dialog.component.scss'],
})
export class UpdateUserDialogComponent implements OnInit {
  passwordIsVisible: boolean = false;
  changePassword: boolean = false;
  formCtrls: FormGroup = new FormGroup({
    loginCtrl: new FormControl(this.data.user.login, [Validators.required]),
    nameCtrl: new FormControl(this.data.user.name, [Validators.required]),
    emailCtrl: new FormControl(this.data.user.email, [Validators.required, Validators.email]),
    isActiveCtrl: new FormControl(this.data.user.active, [Validators.required]),
    rolesCtrl: new FormControl(this.data.user.roles),
  });
  allRoles: UserRole[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: UserResponse },
    private userApiSerice: UserApiService,
    private loadingIndicator: LoadingIndicatorService,
    private toastService: AppToastService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<UpdateUserDialogComponent>
  ) {}

  async ngOnInit(): Promise<void> {
    this.formCtrls.get('loginCtrl')!.disable();
    this.allRoles = await this.userApiSerice.getRoles().toPromise();
  }

  activePasswordField() {
    this.changePassword = true;
    this.formCtrls.addControl('passwordCtrl', new FormControl(null, [Validators.required]));
  }

  deactivatePassword() {
    this.changePassword = false;
    this.formCtrls.removeControl('passwordCtrl');
  }

  onEditClick() {
    this.loadingIndicator.show();
    const dialogReturn = { edited: false };
    let authStr = this.formCtrls.get('loginCtrl')!.value + ':';

    if (this.changePassword) {
      authStr += this.formCtrls.get('passwordCtrl')!.value;
    }

    const newUserData: UserRequest = {
      auth: btoa(authStr),
      name: this.formCtrls.get('nameCtrl')!.value,
      email: this.formCtrls.get('emailCtrl')!.value,
      active: this.formCtrls.get('isActiveCtrl')!.value,
      roles: [],
    };

    for (let role of this.formCtrls.get('rolesCtrl')!.value) {
      const userRole = this.allRoles.find(userRole => userRole.name === role);
      newUserData.roles = newUserData.roles.concat(userRole!.id);
    }

    this.userApiSerice
      .patchUser(this.data.user.id, newUserData)
      .toPromise()
      .then(_ => {
        dialogReturn.edited = true;
        this.toastService.success(this.translate.instant('toast.success.userManagement.userEdited'));
      })
      .catch(err => {
        this.toastService.error(this.translate.instant('toast.errors.userManagement.userEdited'));
      })
      .finally(() => {
        this.loadingIndicator.hide();
        this.dialogRef.close(dialogReturn);
      });
  }
}
