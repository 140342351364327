<!--
File: \src\app\pages\boxes\psmm-tag-detail.dialog\psmm-tag-detail.dialog.html
Project: boxcar-console
Created Date: 2022-02-18 08:26:16
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-04-26	JF	Added I18N support
-->

<h1 mat-dialog-title>Etiqueta PSMM</h1>

<mat-dialog-content>
  <ng-container *ngIf="!data.labelDto.labelcode">
    ({{ 'boxes.genericLabel' | translate }})<br />
  </ng-container>

  <ng-container *ngIf="data.labelDto.labelcode">
    {{ 'rfidTags.barcode' | translate }}: <strong>{{data.labelDto.labelcode}}</strong><br />
  </ng-container>

  <ng-container *ngIf="data.labelDto.operator">
    {{ 'rfidTags.operator' | translate }}: {{data.labelDto.operator}}<br />
  </ng-container>

  <ng-container *ngIf="data.labelDto.shift">
    {{ 'rfidTags.workShift' | translate }}: {{data.labelDto.shift}}<br />
  </ng-container>

  <ng-container *ngIf="data.labelDto.timestamp">
    {{data.labelDto.timestamp}}<br />
  </ng-container>

  <br />

  {{data.pn}}<br />
  {{ 'rfidTags.quantity' | translate }}: {{data.labelDto.quantity}}<br />

  <ng-container *ngIf="!data.source.includes('null')">
    {{ 'rfidTags.source' | translate }}: {{data.source}}<br />
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'rfidTags.close' | translate }}</button>
</mat-dialog-actions>
