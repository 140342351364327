/*
 * File: \src\app\pages\racks\import\racks.import.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-05-17 11:12:10
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-05-17	JF	Racks import dialog controller.
 */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type RacksImportDialogData = {
  selectedFile: File;
};

@Component({
  selector: 'racks-import-dialog',
  templateUrl: 'racks.import.dialog.html',
})
export class RacksImportDialogComponent {
  fileTypeToBeImported: string = '.json';

  rackRailJsonSnippet: string = `[{
        "id": 164,
        "regionPrefix": "E",
        "type": 1,
        "name": "E.1.1",
        "column": 1,
        "level": 1,
        "maxBoxes": 13,
        "isActive": true,
        "operationMode": 0,
        "suggestedParts": [ "22690011001", "22690011038" ],
        "allowedContainerModels": [ "1015", "4022" ],
        "inputSideRFIDs": [ "H000000000020220711001665" ],
        "outputSideRFIDs": [ "H000000000020220711001669" ],
        "pickingLocations": ["G1"]
      }]`;

  constructor(
    public dialogRef: MatDialogRef<RacksImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RacksImportDialogData
  ) {}

  onBrowseFile(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;

    // Single selection file on browse.
    if (fileList !== null) {
      this.data.selectedFile = fileList[0];
    }
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
