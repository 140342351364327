import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BoxDto, LabelDto } from 'src/app/models';

@Component({
  selector: 'generic-box-card',
  templateUrl: './generic-box-card.component.html',
  styleUrls: ['./generic-box-card.component.scss'],
})
export class GenericBoxCardComponent implements OnInit {
  @Input() box!: BoxDto;
  @Input() boxStateMetadata: any = [];
  @Output() detailRFID = new EventEmitter<BoxDto>();
  @Output() retrieveState = new EventEmitter();

  ngOnInit(): void {
    return;
  }

  tryAgain() {
    this.retrieveState.emit();
  }
  openRfidTagDetail() {
    this.detailRFID.emit(this.box);
  }

  total(labelDtos: LabelDto[]) {
    let total = 0;
    if (labelDtos != null && labelDtos.length > 0) {
      labelDtos.forEach(label => {
        total += label.quantity;
      });
    }
    return total;
  }
}
