// ! This component needs to be inside a div with class 'loading-container'.

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loading-on-page',
  templateUrl: './loading-on-page.component.html',
})
export class LoadingOnPageComponent implements OnInit {
  @Input() isLoading: boolean = false;
  constructor() {
    return;
  }

  ngOnInit(): void {
    return;
  }
}
