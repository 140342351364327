import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'boxcar-console-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  title: string = '';
  description: string = '';
  confirmButtonColor: string = 'primary';

  constructor(@Inject(MAT_DIALOG_DATA) private data: { title: string; description: string; confirmButtonColor?: string }) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.description = this.data.description;
    this.confirmButtonColor = this.data.confirmButtonColor ?? 'primary';
  }
}
