<h1 mat-dialog-title>{{ 'totemServices.storing.instructionGenerated' | translate }}</h1>

<div *ngIf="data.instruction">
  <instruction-card
    [instruction]="data.instruction"
    [showActions]="false"
    [showTimeIndicator]="false"></instruction-card>
</div>

<br />

<h4>{{ 'totemServices.storing.instructionsForConcludeInstruction' | translate }}</h4>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="{ continueFlow: false }">
    {{ 'common.buttons.back' | translate }}
  </button>
</div>
