import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from '@components/loading-indicator/loading-indicator.component';

@Injectable({ providedIn: 'root' })
export class LoadingIndicatorService {
  private dialogReference!: MatDialogRef<any>;
  private isOnScreen: boolean = false;

  constructor(private dialog: MatDialog) {}

  public isOpen() {
    return this.isOnScreen;
  }

  public show() {
    if (!this.isOnScreen) {
      this.isOnScreen = true;
      this.dialogReference = this.dialog.open(LoadingIndicatorComponent, {
        hasBackdrop: true,
        disableClose: true,
        width: '25vw',
      });
    }
  }

  public hide() {
    if (this.isOnScreen) {
      this.isOnScreen = false;
      if (this.dialogReference) {
        this.dialogReference.close();
      }
    }
  }
}
