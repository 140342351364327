<!--
File: src\app\racks\racks.component.html
Project: boxcar-console
Created Date: 2022-02-18 11:02:20
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-02-18	JF	Rack catalog view. Initial version.
2022-10-13  VR  Refactoring rack pages.
-->

<mat-tab-group mat-align-tabs="start" (focusChange)="refreshTabs($event)">
  <mat-tab label="{{ 'catalog.racks.racksContents' | translate }}">
    <div class="tabBody">
      <racks-contents></racks-contents>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'catalog.racks.title' | translate }}">
    <div class="tabBody">
      <racks-structure #rackStructure></racks-structure>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'catalog.racks.rackSearch' | translate }}">
    <div class="tabBody">
      <rack-search></rack-search>
    </div>
  </mat-tab>
</mat-tab-group>
