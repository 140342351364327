import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'paginator-page-select',
  templateUrl: './paginator-page-select.component.html',
  styleUrls: ['./paginator-page-select.component.scss'],
})
export class PaginatorPageSelectComponent implements OnChanges {
  @Input() length: number = 0;
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = 10;
  selectedPageControl: FormControl = new FormControl(1);
  private totalPages: number = 0;
  private lastPage: number = 0;

  @Input() disabled: boolean = false;
  @Input() hidePageSize: boolean = false;
  @Input() pageSizeOptions: number[] = [10, 25, 100];
  @Input() showFirstLastButtons: boolean = true;
  @Output() page = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  ngOnChanges(): void {
    this.selectedPageControl.setValue(this.pageIndex + 1);
    this.totalPages = Math.ceil(this.length / this.pageSize);
  }

  getTotalPages() {
    return this.totalPages;
  }

  resetData() {
    this.lastPage = 0;
    this.totalPages = Math.ceil(this.length / this.pageSize);
    this.selectedPageControl.clearValidators();
    this.selectedPageControl.addValidators([Validators.max(this.totalPages), Validators.min(1)]);

    if (this.paginator) {
      this.selectedPageControl.setValue(this.pageIndex + 1);
    }
  }

  onPaginatorChange(event: PageEvent) {
    this.lastPage = event.pageIndex + 1;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.length = event.length;
    this.resetData();
    this.page.emit(event);
  }

  onChangePage() {
    if (this.paginator) {
      if (!this.selectedPageControl.valid) {
        this.selectedPageControl.setValue(this.lastPage);
      }
      this.lastPage = this.selectedPageControl.value;
      this.paginator.pageIndex = this.selectedPageControl.value - 1;
      const event: PageEvent = {
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length,
      };
      this.paginator.page.next(event);
      this.onPaginatorChange(event);
    }
  }
}
