/*
 * File: \src\app\pages\racks\racks-contents\racks-contents.component.ts
 * Project: boxcar-console
 * Created Date: 2022-08-23 10:17:54
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

import { Component, OnInit, ViewChild } from '@angular/core';

import { RackViewerComponent } from '@components/rack-viewer/rack-viewer.component';
import { NoticeDialogService, RackStateApiService } from '@services/index';

import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@components/error-dialog/error-dialog.component';
import { defaultTheme, gradientColors } from '@components/rack-viewer/themes/rack-viewer-themes';
import { TranslateService } from '@ngx-translate/core';
import { MergeResultDialogComponent, MergeResultDialogData } from '@pages/dialogs/merge.result.dialog';
import { BoxCarHttpErrorResponse, DataCatalogApiService, RfidApiService, boxCarCoreErrorDetails } from '@services/api';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import {
  BoxTypeDto,
  DataMergeRespDto,
  LocationDto,
  PartDto,
  RackRailExDto,
  RackRailStateDto,
  RackRailStorageDto,
  RackRegionDto,
} from 'src/app/models';
import { RacksStateImportDialogComponent, RacksStateImportDialogData } from '../import/racks.state.import.dialog';
import { RacksStatePreviewDialogComponent, RacksStatePreviewDialogData } from '../import/racks.state.preview.dialog';

@Component({
  selector: 'racks-contents',
  templateUrl: './racks-contents.component.html',
  styleUrls: ['./racks-contents.component.scss'],
})
export class RackContentsComponent implements OnInit {
  // Page element references
  @ViewChild('rackViewer') rackViewer!: RackViewerComponent;

  // Internal state variables:

  noneRackRegion: RackRegionDto = {
    id: 0,
    name: '-- NONE SELECTED --',
    prefix: 'NONE',
    columns: 0,
    levels: 0,
    regionStrategy: 0,
    regionStorage: 1,
  };
  selectedRackRegion: RackRegionDto = this.noneRackRegion;
  rackRegions: RackRegionDto[] = [];
  selectedRegionRacks: LocationDto[] = [];
  totalLevels: number = 0;
  totalCols: number = 0;
  importedRows: RackRailStateDto[] = [];
  selectedRackFloor?: LocationDto;
  isLoading: boolean = false;

  focusedRackRail: Partial<RackRailExDto> = {};
  rackInfoVisible: boolean = false;
  isSubmitting: boolean = false;
  partsCatalog: PartDto[] = [];
  boxTypes: BoxTypeDto[] = [];
  isOperationMode: boolean = false;
  hasBackDrop: boolean = false;
  rackRailsState: RackRailStateDto[] = [];

  dataMergeRespDto: DataMergeRespDto | undefined;

  // Colors catalog:
  rackRailColor = {
    nominal: defaultTheme.itemNominalColor,
    inactive: defaultTheme.itemInactiveColor,
    withoutPart: defaultTheme.itemWithoutPartColor,
    returning: defaultTheme.itemReturningColor,
  };

  gradientColor = gradientColors;

  // Constructors:
  constructor(
    private catalogApiService: DataCatalogApiService,
    private rackStateService: RackStateApiService,
    private rfidApiService: RfidApiService,
    private matDialog: MatDialog,
    private noticeDialog: NoticeDialogService,
    private loadingIndicator: LoadingIndicatorService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.catalogApiService.getAllPartsFromCache();
    this.catalogApiService.getAllDestinationsFromCache();
    this.rfidApiService.getAllBoxTypesFromCache();

    this.loadAllRackRegions();
  }

  // Class implementation:

  private loadAllRackRegions() {
    this.isLoading = true;
    this.catalogApiService
      .getRackRegions()
      .then(rackRegions => {
        this.rackRegions = rackRegions;

        // Remove "not mapped" from rack regions.
        this.rackRegions = this.rackRegions.filter(rackRegion => rackRegion.name.toLowerCase() !== 'não mapeada');
        this.isLoading = false;
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
  }

  private getMaxColsLevels(rails: LocationDto[]) {
    this.totalCols = Math.max(...rails.map(element => element.column));
    this.totalLevels = Math.max(...rails.map(element => element.level));
  }

  onChangeRackRegion(): void {
    this.totalCols = 0;
    this.totalLevels = 0;
    this.selectedRackFloor = undefined;
    this.selectedRegionRacks = [];
    if (this.selectedRackRegion.id !== 0) {
      this.isLoading = true;

      this.catalogApiService
        .getRackRails(this.selectedRackRegion.id)
        .then(rackRails => {
          this.getMaxColsLevels(rackRails);

          // Sort stored boxes from each rail.
          rackRails.forEach(
            rail => (rail.boxesStorage = [...rail.boxesStorage.sort((a, b) => a.currentDepth - b.currentDepth)])
          );

          // Sort making biggest level and smallest column first.
          rackRails.sort(
            (a, b) =>
              a.column +
              this.totalCols * (this.totalLevels - a.level) -
              (b.column + this.totalCols * (this.totalLevels - b.level))
          );
          this.selectedRackFloor = rackRails.find(rail => rail.column === 0 && rail.level === 0);
          this.selectedRegionRacks = rackRails;
          this.isLoading = false;
        })
        .catch((error: BoxCarHttpErrorResponse) => {
          this.noticeDialog.show(...boxCarCoreErrorDetails(error));
        });
    }
  }

  // Update unique rail when edit anything on rail
  updateRail(id: number) {
    const railPosition = this.selectedRegionRacks.findIndex(rack => rack.id === id);

    this.catalogApiService
      .getRackRail(id)
      .then(newRackRail => {
        let updateRackRail = [...this.selectedRegionRacks];

        // Sort box storage for this updated rack rail.
        newRackRail.boxesStorage.sort((a, b) => a.currentDepth - b.currentDepth);

        // Remove old rack rail and put new rack rail.
        updateRackRail.splice(railPosition, 1, newRackRail);

        this.selectedRegionRacks = [...updateRackRail];
      })
      .catch(error => {
        this.matDialog.open(ErrorDialogComponent, {
          data: {
            title: this.translate.instant('catalog.racks.edit.updateRail.errorTitle'),
            description: this.translate.instant('catalog.racks.edit.updateRail.errorDescription'),
          },
        });
      });
  }

  showRacksImportDialog(): void {
    const dialogRef = this.matDialog.open(RacksStateImportDialogComponent, {
      width: '80vh',
      data: {},
    });

    dialogRef.afterClosed().subscribe((dialogData: RacksStateImportDialogData) => {
      if (dialogData != undefined) {
        this.onConfirmFileRead(dialogData.selectedFile);
      }
    });
  }

  onConfirmFileRead(selectedFile: File): void {
    // Read and check file contents;
    selectedFile
      ?.text()
      .then(contents => {
        this.importedRows = JSON.parse(contents);
        this.showRacksPreviewDialog();
      })
      .catch(error => {
        this.noticeDialog.show(error);
      });
  }

  showRacksPreviewDialog(): void {
    const dialogRef = this.matDialog.open(RacksStatePreviewDialogComponent, {
      width: '120vh',
      data: {
        racksStateRows: this.importedRows,
      } as RacksStatePreviewDialogData,
    });

    dialogRef.afterClosed().subscribe((dialogData: RacksStatePreviewDialogData) => {
      if (dialogData != undefined) {
        this.onConfirmDataUpload();
      }
    });
  }

  async onConfirmDataUpload() {
    this.loadingIndicator.show();

    await this.rackStateService
      .updateRackContents(this.importedRows)
      .then(response => {
        this.dataMergeRespDto = response;
        this.showMergeResultDialog();
        this.onChangeRackRegion();
      })
      .catch(error => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });

    this.loadingIndicator.hide();
  }

  showMergeResultDialog(): void {
    this.matDialog.open(MergeResultDialogComponent, {
      width: '100vh',
      data: {
        dataMergeRespDto: this.dataMergeRespDto,
      } as MergeResultDialogData,
    });
  }

  isValidString = (s: string): boolean => {
    return s === null || s.length === 0;
  };

  createRackRailExportLink(rackRailsStorage: Partial<LocationDto>[]) {
    const downloadLink = document.createElement('a');

    const now = new Date();
    downloadLink.download = `rack_${
      this.selectedRackRegion.prefix
    }_State-(${now.toLocaleDateString()}-${now.toLocaleTimeString()}).json`;

    const jsonData = 'text/json; charset=utf-8, ' + encodeURIComponent(JSON.stringify(rackRailsStorage, null, '  '));
    downloadLink.href = 'data:' + jsonData;

    downloadLink.click();
  }

  prepareRackRailExportData(rackRails: LocationDto[]): Partial<RackRailStateDto>[] {
    const rackRailsStorage: Partial<RackRailStateDto>[] = [];

    rackRails.forEach(rackRail => {
      let railStorage: RackRailStorageDto[] = [];
      let lastPartNumber: string = '';
      let order: number = 0;

      // Sort boxes storage.
      rackRail.boxesStorage.sort((a, b) => a.currentDepth - b.currentDepth);

      // Create storage for export.
      rackRail.boxesStorage.forEach(box => {
        if (lastPartNumber !== box.partNumber) {
          lastPartNumber = box.partNumber;
          railStorage.push({
            partNumber: box.partNumber,
            storedBoxes: 1,
            order: order,
          });
          order += 1;
        } else {
          railStorage[order - 1].storedBoxes += 1;
        }
      });

      const rackRailForExport: Partial<RackRailStateDto> = {
        id: rackRail.id,
        regionPrefix: rackRail.regionPrefix,
        name: rackRail.name,
        column: rackRail.column,
        level: rackRail.level,
        storedBoxes: rackRail.storedBoxes,
        storage: railStorage,
      };
      rackRailsStorage.push(rackRailForExport);
    });

    return rackRailsStorage;
  }

  onExportRackState(): void {
    const rackRailsExport: Partial<LocationDto>[] = this.prepareRackRailExportData(this.selectedRegionRacks);

    this.createRackRailExportLink(rackRailsExport);
  }
}
