/*
 * File: \src\app\services\api\instruction.api.service.ts
 * Project: boxcar-console
 * Created Date: 2022-05-24 08:04:59
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-05-24	JF	Instructions API client.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  InstructionConcludeRequestBody,
  InstructionDto,
  InstructionRequestDto,
  InstructionSearchQuery,
  InstructionSummaryDto,
  InstructionType,
  QualityInstructionsBatchGenerateRequestDto,
} from 'src/app/models';
import { baseUrl } from '.';
import { Observable } from 'rxjs';

@Injectable()
export class InstructionApiService {
  constructor(private http: HttpClient) {}

  /**
   * Request a list of instructions for a given criteria.
   *
   * @param request request criteria composition.
   */
  public requestInstructionList(request: InstructionRequestDto): Promise<InstructionDto> {
    return this.http.post<InstructionDto>(baseUrl + 'instructions/list', request).toPromise();
  }

  public searchInstructions(query: InstructionSearchQuery): Observable<InstructionDto> {
    const params = new HttpParams()
      .append('query', query.query)
      .append('pageNum', query.pageNum)
      .append('qtyPerPage', query.qtyPerPage);

    return this.http.get<InstructionDto>(baseUrl + 'instructions/search', { params });
  }

  /**
   * Request a summary of instruction for a given region.
   *
   * @param regionPrefix regios prefix, generally a single letter case.
   * @param startDate if defined, must be in the format 'YYYYMMDD'
   * @param endDate if defined, must be in the format 'YYYYMMDD'
   */
  public requestInstructionSummary(
    regionPrefix: string,
    startDate: string = '',
    endDate: string = ''
  ): Promise<InstructionSummaryDto[]> {
    let queryParams = '';

    if (startDate !== '') {
      queryParams = `?startDate=${startDate}&endDate=${endDate}`;
    }

    return this.http
      .get<InstructionSummaryDto[]>(baseUrl + `instructions/summary/${regionPrefix}` + queryParams)
      .toPromise();
  }

  public cancelInstruction(instructionId: number, fromContingency: boolean = false) {
    return this.http.patch(`${baseUrl}instructions/cancel/${instructionId}`, { fromContingency }).toPromise();
  }

  public prioritizeInstructionByPN(partNumber: string, location: string) {
    return this.http.patch(`${baseUrl}instructions/prioritize/partNumber`, {partNumber: partNumber, destination: location}).toPromise();
  }

  public createInstruction(bodyRequest: any) {
    return this.http.post(`${baseUrl}instructions/generate`, bodyRequest).toPromise();
  }

  public generateQualityInstruction(bodyRequest: QualityInstructionsBatchGenerateRequestDto) {
    return this.http.post(`${baseUrl}instructions/generate/quality`, bodyRequest).toPromise();
  }

  public createInstructionFromTotem(
    containerLifeCycleId: number,
    instructionType: InstructionType,
    origin: { origin: string; originColumn?: number; originLevel?: number },
    destination: { destination: string; destinationColumn?: number; destinationLevel?: number }
  ) {
    const body = {
      instructionType,
      containerLifeCycleId,
      origin: origin.origin,
      originColumn: origin.originColumn ?? null,
      originLevel: origin.originLevel ?? null,
      destination: destination.destination,
      destinationLevel: destination.destinationLevel ?? null,
      destinationColumn: destination.destinationColumn ?? null,
    };
    return this.http.post(`${baseUrl}instructions/totem/generate`, body);
  }

  public pickInstruction(instructionId: number) {
    return this.http.patch(`${baseUrl}instructions/pick/${instructionId}`, {});
  }

  /**
   * Request to unpick instruction.
   *
   * @param instructionId
   */
  public unpickInstruction(instructionId: number) {
    return this.http.patch(`${baseUrl}instructions/unpick/${instructionId}`, {});
  }

  public concludeInstruction(instructionId: number, requestBody: InstructionConcludeRequestBody) {
    return this.http.patch(`${baseUrl}instructions/conclude/${instructionId}`, requestBody);
  }
}
