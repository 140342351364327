/*
 * File: \src\app\services\login\state\user.actions.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-27	JF	Reviewed.
 */

import { createAction, props } from '@ngrx/store';
import { LoginRequestDto, LoginResponseDto } from 'src/app/models/login';
import { UserActionTypes } from './user.action-types';

export const loginStarted = createAction(UserActionTypes.loginStarted, props<{ userCredentials: LoginRequestDto }>());

export const loginSucceeded = createAction(UserActionTypes.loginSucceeded, props<{ userData: LoginResponseDto }>());

export const loginFailed = createAction(UserActionTypes.loginFailed, props<{ error: string }>());

export const logout = createAction(UserActionTypes.logout);
