<h1 mat-dialog-title>{{ 'totemServices.moveBox.boxFound' | translate }}:</h1>

<p>{{ 'totemServices.moveBox.placedDetailTag.description' | translate }}</p>

<div mat-dialog-content>
  <resumed-box-card [container]="data.container"></resumed-box-card>
</div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" [mat-dialog-close]="{ response: 'correctData' }">
    {{ 'totemServices.moveBox.placedDetailTag.buttons.correctData' | translate }}
  </button>
  <button mat-flat-button color="warn" [mat-dialog-close]="{ response: 'wrongData' }">
    {{ 'totemServices.moveBox.placedDetailTag.buttons.incorrectData' | translate }}
  </button>
  <button mat-button [mat-dialog-close]="{ response: 'canceled' }">{{ 'common.buttons.cancel' | translate }}</button>
</div>
