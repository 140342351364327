<!--
File: \src\app\pages\racks\import\racks.content.preview.dialog.html
Project: boxcar-console
Created Date: 2022-08-30 11:24:46
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
-->

<h1 mat-dialog-title>{{'warehouse.racks.review.title' | translate}}</h1>

<div mat-dialog-content>
  <p>{{'warehouse.racks.review.checkData' | translate}}</p>

  <!-- File contents preview -->
  <table class="table">
    <caption hidden>
      Preview from import file.
    </caption>
    <thead>
      <tr>
        <th>{{'catalog.racks.region' | translate}}</th>
        <th>{{'catalog.racks.name' | translate}}</th>
        <th>{{'catalog.racks.column' | translate}}</th>
        <th>{{'catalog.racks.level' | translate}}</th>
        <th>{{'catalog.racks.storedBoxes' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rackRailState of data.racksStateRows | slice:0:10">
        <td>{{ rackRailState.regionPrefix }}</td>
        <td>{{ rackRailState.name }}</td>
        <td>{{ rackRailState.column }}</td>
        <td>{{ rackRailState.level }}</td>
        <td>{{ storageArrayToString(rackRailState.storage) }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="data">
    {{'warehouse.racks.review.sendButton' | translate}}
  </button>
  <button mat-button (click)="onCloseDialog()">{{'warehouse.racks.review.cancelButton' | translate}}</button>
</div>
