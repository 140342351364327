<!--
File: \src\app\pages\rfidTags\dialog-content.html
Project: boxcar-console
Created Date: 2022-02-09 16:51:33
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-04-26	JF	Added I18N support.
-->

<h2 mat-dialog-title>{{ 'rfidTags.printTitle' | translate }}</h2>

<mat-dialog-content class="mat-typography">
  <ngx-barcode6 *ngFor="let barcode of data.barcodes" [bc-value]="barcode" [bc-display-value]="true"></ngx-barcode6>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'rfidTags.cancel' | translate }}</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{ 'rfidTags.print' | translate }}</button>
</mat-dialog-actions>
