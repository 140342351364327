/*
 * File: \src\app\services\api\index.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 13:25:59
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Index created for refactoring.
 */

import { environment } from '@env';
import { HttpErrorResponse } from '@angular/common/http';

export const baseUrl = environment.applicationApiUrl;

export * from './datacatalog.api.service';
export * from './rfid.api.service';
export * from './logger.api.service';
export * from './packaging.api.service';
export * from './metadata.api.service';
export * from './instruction.api.service';
export * from './rackstate.api.service';
export * from './equipments.api.service';
export * from './settings.api.service';

export type ApiResult = {
  code: number;
  message: string;
};

export type BoxCarHttpErrorResponse = HttpErrorResponse & { error: ApiResult };

export const boxCarCoreErrorDetails = (response: BoxCarHttpErrorResponse): [string, number, string] => [
  response.message ?? 'Unknown backend error response',
  response.error?.code,
  response.error?.message,
];
