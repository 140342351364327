<!-- Color subtitle -->
<div class="colorSubtitle">
  <table>
    <caption hidden>
      Subtitles for rack colors
    </caption>
    <tr>
      <th colspan="3">
        <h2>
          {{ 'catalog.racks.subtitle.colorTitle' | translate }}
        </h2>
      </th>
    </tr>
    <tr>
      <td>
        <div class="colorContainer" [style.--color]="rackRailColor.nominal"></div>
        <span>
          {{ 'catalog.racks.subtitle.colorDefinedPN' | translate }}
        </span>
      </td>
      <td>
        <div class="colorContainer rightContainer" [style.--color]="rackRailColor.withoutPart"></div>
        <span>
          {{ 'catalog.racks.subtitle.colorNotDefinedPN' | translate }}
        </span>
      </td>
      <td>
        <div class="colorContainer fixed rightContainer" [style.--color]="'white'"></div>
        <span>
          {{ 'catalog.racks.subtitle.fixedRail' | translate }}
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <div class="colorContainer" [style.--color]="rackRailColor.inactive"></div>
        <span>
          {{ 'catalog.racks.subtitle.colorBlocked' | translate }}
        </span>
      </td>
      <td>
        <div class="colorContainer rightContainer" [style.--color]="rackRailColor.returning"></div>
        <span>
          {{ 'catalog.racks.subtitle.colorReturn' | translate }}
        </span>
      </td>
      <td>
        <div class="colorContainer dynamic rightContainer" [style.--color]="'white'"></div>
        <span>
          {{ 'catalog.racks.subtitle.dynamicRail' | translate }}
        </span>
      </td>
    </tr>
  </table>
</div>
