<h1 mat-dialog-title>
  {{ 'userManagement.dialogs.' + (data.user.active ? 'deactivate' : 'activate') + 'User' | translate }}
</h1>

<h4>
  {{
    'userManagement.dialogs.' + (data.user.active ? 'deactivate' : 'activate') + 'Description'
      | translate : { user: data.user.login }
  }}
</h4>

<div mat-dialog-actions>
  <button mat-flat-button color="warn" (click)="onClickDeleteUser()">
    {{ 'common.buttons.' + (data.user.active ? 'deactivate' : 'activate') | translate }}
  </button>

  <button mat-button color="primary" [mat-dialog-close]="{ deleted: false }">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</div>
