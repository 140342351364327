<div class="paginator-container">
  <!-- Page selector -->
  <div class="page-selector">
    <span> Page: </span>
    <form>
      <mat-form-field>
        <input matInput type="number" [formControl]="selectedPageControl" (change)="onChangePage()" />
        <span matSuffix>/{{ getTotalPages() }}</span>
      </mat-form-field>
    </form>
  </div>

  <!-- Paginator -->
  <mat-paginator
    [disabled]="disabled"
    [hidePageSize]="hidePageSize"
    [length]="length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"
    aria-label="Select page of users"
    showFirstLastButtons
    (page)="onPaginatorChange($event)">
  </mat-paginator>
</div>
