import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { DataCatalogApiService } from '@services/api';
import { ExportCsvService } from '@services/export/csv.service';
import { AssemblyDetail, SourceDto } from 'src/app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'boxcar-console-read-psmm-tags-reports',
  templateUrl: './read-psmm-tags-reports.component.html',
  styleUrls: ['./read-psmm-tags-reports.component.scss'],
})
export class ReadPSMMTagsReportsComponent implements OnInit, OnDestroy {
  operator: string = '';
  allInjectors: SourceDto[] = [];
  selectedInjectors: FormControl = new FormControl([]);
  selectedShifts: FormControl = new FormControl([]);
  columnsToDisplay: string[] = ['dateTime', 'shift', 'source', 'operator', 'tag'];
  dataSource: MatTableDataSource<AssemblyDetail> = new MatTableDataSource();
  startDate!: Date;
  endDate!: Date;
  maxEndDate!: Date;
  endTime: string = '';
  startTime: string = '';
  allData: AssemblyDetail[] = [];
  totalTags: number = 0;
  isRequested: boolean = false;
  subInj: Subscription;
  subShift: Subscription;
  isFiltersVisible: boolean = false;

  constructor(
    private dataCatalogApi: DataCatalogApiService,
    private translate: TranslateService,
    private exportService: ExportCsvService
  ) {
    this.selectedInjectors.disable();
    this.selectedShifts.disable();

    this.subInj = this.selectedInjectors.valueChanges.subscribe({
      next: _ => {
        this.filterData();
      },
    });
    this.subShift = this.selectedShifts.valueChanges.subscribe({
      next: _ => {
        this.filterData();
      },
    });
  }

  ngOnDestroy(): void {
    this.subInj.unsubscribe();
    this.subShift.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.getAllInjectors();
  }

  private async getAllInjectors() {
    this.allInjectors = [
      ...(await this.dataCatalogApi.getSectors().then(sectors => {
        let injectors: SourceDto[] = [];

        sectors.forEach(sector => {
          injectors = injectors.concat(
            sector.sourceDtos.filter(sector => sector.description.toLowerCase().includes('inje'))
          );
        });

        return injectors;
      })),
    ];
  }

  resetEndDateAndTime() {
    this.endDate = new Date(this.maxEndDate);
    this.resetEndTime();
  }

  resetEndTime() {
    this.endTime = '';
  }

  onStartDateChange() {
    const localStartDate = new Date(this.startDate);
    this.maxEndDate = new Date(localStartDate.setDate(localStartDate.getDate() + 1));

    this.resetEndDateAndTime();
  }

  getMaxEndTime() {
    if (this.startDate && this.endDate) {
      if (this.startDate.getDate() === this.endDate.getDate()) {
        return '23:59';
      } else {
        return this.startTime;
      }
    }
    return '';
  }

  getMinEndTime() {
    if (this.startDate && this.endDate) {
      if (this.startDate.getDate() === this.endDate.getDate()) {
        return this.startTime;
      }
    }
    return '00:00';
  }

  requestData() {
    this.startDate.setHours(parseInt(this.startTime.split(':')[0]), parseInt(this.startTime.split(':')[1]));
    this.endDate.setHours(parseInt(this.endTime.split(':')[0]), parseInt(this.endTime.split(':')[1]));

    this.dataCatalogApi.getAssemblyDetails(this.startDate.toISOString(), this.endDate.toISOString()).subscribe({
      next: res => {
        this.totalTags = res.totalAssemblies;
        this.dataSource.data = [];
        this.dataSource.data = res.assemblyDetails.map(assembly => {
          return {
            ...assembly,
            radTimestamp: new Date(assembly.radTimestamp + 'Z').toLocaleString('default'),
            creationTime: new Date(assembly.creationTime + 'Z').toLocaleString('default'),
          };
        });
        this.allData = this.dataSource.data;
      },
      complete: () => {
        if (this.totalTags > 0) {
          this.selectedShifts.enable();
          this.selectedInjectors.enable();
        }
        this.selectedShifts.setValue([]);
        this.selectedInjectors.setValue([]);
        this.operator = '';
        this.isRequested = true;
      },
    });
  }

  filterData() {
    let data = this.allData;

    if (this.operator) {
      data = data.filter(row => row.operator.toLowerCase().includes(this.operator.toLowerCase()));
    }

    if (this.selectedShifts.value.length > 0) {
      data = data.filter(row => this.selectedShifts.value.includes(row.shift));
    }

    if (this.selectedInjectors.value.length > 0) {
      data = data.filter(row => this.selectedInjectors.value.includes(row.source));
    }

    data = data.filter(row => row.labelCode);

    this.totalTags = data.length;
    this.dataSource.data = data;
  }

  getSummaryQtd(): string {
    return this.translate.instant('reports.psmmTag.qtdRead', { numTags: this.totalTags });
  }

  getSummaryDescrip(): string {
    return this.translate.instant('reports.psmmTag.periodDescription', {
      startDate: this.startDate.toLocaleString('default'),
      endDate: this.endDate.toLocaleString('default'),
    });
  }

  exportData() {
    const fileName = `report_read_PSMM_TAGS`;
    const headers = [
      this.translate.instant('reports.psmmTag.tableColumns.dateTime'),
      this.translate.instant('reports.psmmTag.tableColumns.shift'),
      this.translate.instant('reports.psmmTag.tableColumns.source'),
      this.translate.instant('reports.psmmTag.tableColumns.operator'),
      this.translate.instant('reports.psmmTag.tableColumns.tag'),
    ];

    const exportData = this.dataSource.data.map(row => {
      return {
        creationTime: row.creationTime.replace(', ', '-'),
        shift: `T${row.shift}`,
        source: row.source,
        operator: row.operator,
        labelCode: row.labelCode,
      };
    });

    this.exportService.exportCSV(exportData, fileName, headers);
  }
}
