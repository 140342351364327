<!--
File: \src\app\pages\sectors\sectors.component.html
Project: boxcar-console
Created Date: 2022-02-07 13:42:57
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-04-27	JF	Added I18N support.
-->

<br />
<mat-form-field appearance="outline">
  <mat-label>{{ 'sections.search' | translate }}</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Setor 1" #input />
</mat-form-field>

<div class="mat-elevation-z2 loading-container">
  <!-- Loading indicator -->
  <loading-on-page [isLoading]="isLoading"> </loading-on-page>

  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
    <caption style="display: none">
      Sectors info
    </caption>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'sections.name' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'sections.description' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.description }}</td>
    </ng-container>

    <ng-container matColumnDef="sourceDtos">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'sections.machines' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <p *ngFor="let source of row.sourceDtos">{{ source.name }} - {{ source.description }}</p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="displayedColumns.length">
        {{ 'sections.noDataFound' | translate }}: "{{ input.value }}"
      </td>
    </tr>
  </table>

  <paginator-page-select
    [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users"
    [length]="dataSource.filteredData.length">
  </paginator-page-select>
</div>
