/*
 * File: apptoast.component.ts
 * Project: boxcar-console
 * File Created: Thursday, 14th October 2021 12:46:01 pm
 * Author: Jorge Herrera (jfelix@vonbraunlabs.com.br)
 * -----
 * Copyright 2021 - Von Braun Labs.
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2021-10-14	JH	Toast rendering.
 */

import { Component, TemplateRef } from '@angular/core';
import { AppToastService } from '@services/index';

@Component({
  selector: 'app-toasts',
  templateUrl: './apptoast.component.html',
})
export class AppToastsComponent {
  constructor(public toastService: AppToastService) {}

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
