import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { navReducer } from '@services/nav-menu/nav-menu.reducers';
import { environment } from '../../environments/environment';
import { userReducer } from '../services/login/state';
import { AppState } from './app.state';

export const appReducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  user: userReducer,
  nav: navReducer,
};

export const appMetaReducers: MetaReducer<AppState>[] = [];
