<!--
File: \src\app\app.component.html
Project: boxcar-console
Created Date: 2022-02-07 13:02:02
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-02-09	JF	Added toast visual support.
-->

<boxcar-console-nav-bar [navBarMenu]="navMenu" *ngIf="!loading"> </boxcar-console-nav-bar>
<router-outlet name="print"></router-outlet>

<!-- Toast application support -->
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
