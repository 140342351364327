/*
 * File: \src\app\models\tags.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 10:45:55
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Types created for refactoring.
 */

export type RfidTagInfoDto = {
  batchNumber: string;
  epc: string;
  barcode: string;
  type: number;
  entityType: string;
  entityId: number;
  lastAssociation: string;
};

export type RfidTagResponseDto = {
  tagQuantity: number;
  rfidTagInfoDtos: RfidTagInfoDto[];
};

export type RfidManufacturerDto = {
  manufacturerId: number;
  manufacturerName: string;
};

export type RfidAllotmentRequestDto = {
  manufacturerId: number;
  batchNumber: string;
  startEPC: string;
  quantity: number;
  fIsPaper: boolean;
};

export type RfidAllotmentResponsetDto = {
  results: string[];
};
