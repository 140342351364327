<!--
File: \src\app\pages\instructions\expansion-panels\expansion_panels.component.html
Project: boxcar-console
Created Date: 2022-08-08 10:22:00
Author: Vitor Recoaro (vitor.recoaro@vonbraunlabs.com.br)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-08-08	VR	Creating instruction page with expansion panels.
-->

<div class="optionsHeader">
  <instruction-filter
    [activeControllers]="activeControllers"
    (handleRequestButton)="handleApplyFilters($event)"
    [isLoading]="isLoading">
  </instruction-filter>
</div>

<div class="loading-container">
  <!-- Loading overlay -->
  <loading-on-page [isLoading]="isLoading"></loading-on-page>

  <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChange($event)">
    <!-- Waiting orders -->
    <mat-tab label="{{ 'instructions.list.ordersWaiting' | translate }}">
      <br />
      <mat-accordion multi class="defaultExpansionPanels" displayMode="default" togglePosition="before">
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'instructions.list.ordersWaiting' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <waiting-order-card
            *ngFor="let instruction of this.waitingInstructions"
            [instruction]="instruction"
            (makeRequest)="makeRequest($event)">
          </waiting-order-card>

          <paginator-page-select
            [pageSizeOptions]="[5, 10, 15]"
            [pageIndex]="waitingPag.pageIndex"
            [length]="waitingPag.length"
            [pageSize]="waitingPag.pageSize"
            (page)="paginatorChange($event, typeOrders.WAITING)">
          </paginator-page-select>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>

    <!-- In progress orders -->
    <mat-tab label="{{ 'instructions.list.ordersInProgress' | translate }}">
      <br />
      <mat-accordion multi class="defaultExpansionPanels" displayMode="default" togglePosition="before">
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'instructions.list.ordersInProgress' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <working-order-card *ngFor="let instruction of this.inProgressInstructions" [instruction]="instruction">
          </working-order-card>

          <paginator-page-select
            [pageSizeOptions]="[5, 10, 15]"
            [pageIndex]="inProgressPag.pageIndex"
            [length]="inProgressPag.length"
            [pageSize]="inProgressPag.pageSize"
            (page)="paginatorChange($event, typeOrders.IN_PROGRESS)">
          </paginator-page-select>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>

    <!-- Done orders -->
    <mat-tab label="{{ 'instructions.list.ordersDone' | translate }}">
      <br />
      <mat-accordion multi class="defaultExpansionPanels" displayMode="default" togglePosition="before">
        <mat-expansion-panel class="donePanel" expanded="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'instructions.list.ordersDone' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <done-order-card *ngFor="let instruction of this.doneInstructions" [instruction]="instruction">
          </done-order-card>

          <paginator-page-select
            [pageSizeOptions]="[5, 10, 15]"
            [pageIndex]="doneInstructionsPag.pageIndex"
            [length]="doneInstructionsPag.length"
            [pageSize]="doneInstructionsPag.pageSize"
            (page)="paginatorChange($event, typeOrders.DONE)">
          </paginator-page-select>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>
  </mat-tab-group>
</div>

<div id="fabContainer">
  <button mat-fab (click)="openNewInstructionDialog()">
    <img matSuffix src="../../../assets/icons/addIcon.svg" alt="add_icon" class="imgIconVertical" />
    {{ ('instructions.list.newOrder' | translate).toUpperCase() }}
  </button>
</div>
