/*
 * File: \src\app\pages\pablist\pablist.component.ts
 * Project: boxcar-console
 * Created Date: 2024-06-12 12:25
 * Author: Pedro Salviano (pedro.salviano@vonbraunlabs.com)
 * -----
 * Copyright 2024 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2024-06-12	PS	Pab list
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AssemblyDetail } from '@models/datacatalog';
import { PaginatorPageSelectComponent } from '@components/paginator-page-select/paginator-page-select.component';
import { DataCatalogApiService } from '@services/api/datacatalog.api.service';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { BoxCarHttpErrorResponse, boxCarCoreErrorDetails } from '@services/api';
import { MatPaginator } from '@angular/material/paginator';
import { NoticeDialogService } from '@services/index';

@Component({
  selector: 'boxcar-console-pablist',
  templateUrl: './pablist.component.html',
  styleUrls: ['./pablist.component.scss']
})
export class PablistComponent implements OnInit {

  displayedColumns: string[] = [
    'partNumber',
    'partDescription',
    'labelCode',
    'quantity',
    'operator',
    'shift',
    'radTimestamp'
  ]

  public dataSource = new MatTableDataSource<AssemblyDetail>([]);
  @ViewChild(PaginatorPageSelectComponent) paginator: PaginatorPageSelectComponent | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  // Data table options.
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  // Internal state variables
  isLoading: boolean = false;

  constructor(
    private apiService: DataCatalogApiService,
    private translate: TranslateService,
    private noticeDialog: NoticeDialogService,
    private loadingIndicator: LoadingIndicatorService
  ) { }

  async ngOnInit() {
    this.isLoading = true;
    try{
      const pabListResponse = await this.apiService.getPabList().toPromise();
      this.dataSource = new MatTableDataSource(pabListResponse.assemblyDetails);
      if (this.paginator) this.dataSource.paginator = this.paginator?.paginator;
      this.dataSource.sort = this.sort;
    }
    catch(error: BoxCarHttpErrorResponse | any){
      this.noticeDialog.show(...boxCarCoreErrorDetails(error));
    }
    finally{
      this.isLoading = false;
    }
    
  }

  async onChangeGroup() {
    this.isLoading = true;
    try{
      const pabListResponse = await this.apiService.getPabList().toPromise();
      this.dataSource = new MatTableDataSource(pabListResponse.assemblyDetails);
      if (this.paginator) this.dataSource.paginator = this.paginator?.paginator;
      this.dataSource.sort = this.sort;
    }
    catch(error: BoxCarHttpErrorResponse | any){
      this.noticeDialog.show(...boxCarCoreErrorDetails(error));
    }
    finally{
      this.isLoading = false;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
