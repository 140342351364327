import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PalletTabDetailDialogComponent } from '@pages/boxes/pallet-tab-detail.dialog/pallet-tab-detail.dialog.component';
import { BoxAssembly, BoxDto } from 'src/app/models';

@Component({
  selector: 'generic-pallet-card',
  templateUrl: './generic-pallet-card.component.html',
  styleUrls: ['./generic-pallet-card.component.scss'],
})
export class GenericPalletCardComponent implements OnInit {
  @Input() box!: BoxDto;
  @Input() boxStateMetadata: any = [];
  @Output() detailRFID = new EventEmitter<BoxDto>();
  @Output() retrieveState = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    return;
  }

  tryAgain() {
    this.retrieveState.emit();
  }

  openPsmmBoxDetail(box: BoxAssembly) {
    this.dialog.open(PalletTabDetailDialogComponent, {
      data: box,
      maxWidth: '65vw',
    });
  }

  openRfidTagDetail() {
    this.detailRFID.emit(this.box);
  }

  totalPallet(assembly: any[]): number {
    let total = 0;
    if (assembly != null && assembly.length > 0) {
      for (let box of assembly) {
        for (let labelDto of box.labelDtos) {
          total += labelDto.quantity;
        }
      }
    }
    return total;
  }
}
