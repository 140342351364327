<div class="box-card">
  <mat-card class="box-mat-card mat-elevation-z0">
    <div class="box-card-header">
      <!-- Header for generic pallet -->
      <div>
        {{ 'boxes.type' | translate }} <strong>{{ 'boxes.genericPalletTitle' | translate }}</strong>
        <mat-card-subtitle>
          {{ 'boxes.genericPalletSubTitle' | translate }}
        </mat-card-subtitle>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
        <img src="../../../assets/icons/moreVertIcon.svg" width="24px" height="24px" alt="menu more options icon" />
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openRfidTagDetail()">
          <span>{{ 'boxes.seeDetails' | translate }}</span>
        </button>
      </mat-menu>
    </div>

    <!-- Cards for Pallets -->
    <div class="card-content">
      <div class="barcodes-list" *ngIf="box.boxAssemblies.length > 0">
        <mat-card class="barcode-card" *ngFor="let assembly of box.boxAssemblies" (click)="openPsmmBoxDetail(assembly)">
          <img src="assets/icons/box_icon.svg" alt="" />
          <strong>{{ 'boxes.genericPackage' | translate }}</strong>
        </mat-card>
      </div>
      <div class="empty-barcode-list" *ngIf="box.boxAssemblies.length <= 0">
        {{ 'boxes.noBoxAssociated' | translate }}
      </div>
      <mat-card-content>
        <p>
          <strong>{{ 'boxes.contentInfo' | translate }}</strong>
        </p>
        <span>
          {{ 'boxes.partNumber' | translate }}
          <span style="color: #3880ff">
            {{ box.boxAssemblies[0] ? box.boxAssemblies[0].boxAssemblyInfoResponseDto.partNumber : '' }} -
            {{ box.boxAssemblies[0] ? box.boxAssemblies[0].boxAssemblyInfoResponseDto.partDescription : '' }}
          </span>
        </span>
        <br />
        <span>
          {{ 'boxes.source' | translate }}
          {{ box.boxAssemblies[0] ? box.boxAssemblies[0].boxAssemblyInfoResponseDto.manufactureSource : '' }}
          -
          {{ box.boxAssemblies[0] ? box.boxAssemblies[0].boxAssemblyInfoResponseDto.manufactureSourceDescription : '' }}
        </span>
        <br />
        <div class="qtyInformation">
          <span>{{ 'boxes.resumedTotalParts' | translate }} {{ totalPallet(box.boxAssemblies) }}</span>
          <span>{{ 'boxes.resumedTotalPackages' | translate }} {{ box.boxAssemblies.length }}</span>
        </div>
      </mat-card-content>
      <div class="box-card-footer">
        <span style="display: flex; gap: 5px">
          {{ 'boxes.state' | translate }}
          <strong *ngIf="box.container.entityState !== undefined && boxStateMetadata">{{
            boxStateMetadata[box.container.entityState]?.namePT ?? box.container.entityState
          }}</strong>
          <mat-spinner *ngIf="!boxStateMetadata" [diameter]="15">{{ tryAgain() }}</mat-spinner>
        </span>
        <p>{{ 'boxes.inCharge' | translate }} {{ box.container.teamLeader }}</p>
      </div>
    </div>
  </mat-card>
</div>
