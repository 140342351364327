<mat-card style="margin-top: 2rem; padding: 2rem">
  <mat-card-title>Cadastrar contentores em lote</mat-card-title>
  <mat-card-content>
    <br />
    <form class="example-form" [formGroup]="form" (submit)="onSubmit()">
      <mat-form-field class="example-full-width" appearance="outline" [hideRequiredMarker]="true">
        <mat-label>{{ 'rfidTags.initialEPC' | translate }}</mat-label>
        <input name="startEPC" matInput formControlName="startEPC" />
        <mat-hint class="errorHint" *ngIf="form.get('startEPC')!.hasError('invalidEPC')">
          {{ form.get('startEPC')!.getError('invalidEPC') | translate }}
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="example-full-width margin-top" appearance="outline" [hideRequiredMarker]="true">
        <mat-label>{{ 'rfidTags.containerQuantity' | translate }}</mat-label>
        <input name="quantity" matInput type="number" formControlName="quantity" max="500" min="1" />
        <mat-hint>{{ 'rfidTags.maxContainerRegister' | translate }}</mat-hint>
      </mat-form-field>

      <mat-form-field class="example-full-width margin-top" appearance="outline" [hideRequiredMarker]="true">
        <mat-label>{{ 'rfidTags.containerModel' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="containerModel"
          [matAutocomplete]="auto"
          (change)="isValidModel()" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let boxType of filteredOptions | async" [value]="boxType.name">
            {{ boxType.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-hint class="errorHint" *ngIf="form.get('containerModel')!.hasError('invalidModel')">
          {{ form.get('containerModel')!.getError('invalidModel') | translate }}
        </mat-hint>
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        style="margin: 1.5rem auto; padding: 5px 25px"
        [disabled]="buttonDisable()">
        {{ ('rfidTags.register' | translate).toUpperCase() }}
      </button>
    </form>
  </mat-card-content>
</mat-card>
