/*
 * File: \src\app\services\metadata\metadata.service.ts
 * Project: boxcar-console
 * Created Date: 2022-02-18 08:26:16
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Service refactored.
 */

import { Injectable } from '@angular/core';
import { MetadataApiService } from '@services/api';
import { LogDictsDto } from 'src/app/models';

@Injectable()
export class MetadataService {
  public logMetadata: any;
  public boxMetadata: any;
  public errorMetadata: any;
  public reasonsMetadata: any;
  public manualReasonsMetadata: any;

  constructor(private metadataApiService: MetadataApiService) {}

  getLogMetadata(): LogDictsDto {
    return this.logMetadata;
  }

  getBoxMetadata() {
    return this.boxMetadata;
  }

  getErrorsMetadata() {
    return this.errorMetadata;
  }

  getReasonsMetadata() {
    return this.reasonsMetadata;
  }

  getManualReasonsMetadata() {
    return this.manualReasonsMetadata;
  }

  async load() {
    [
      this.logMetadata,
      this.boxMetadata,
      this.errorMetadata,
      this.reasonsMetadata,
      this.manualReasonsMetadata
    ] = await Promise.all([
      this.metadataApiService.getLogMetadata(),
      this.metadataApiService.getBoxMetadata(),
      this.metadataApiService.getErrorMetadata(),
      this.metadataApiService.getReasonsMetadata(),
      this.metadataApiService.getManualReasonsMetadata()
    ]);
  }

  getErrorDescriptionFromErrorCode(errorCode: number) {
    if (this.errorMetadata) {
      if (navigator.language === 'pt-BR') return this.errorMetadata[errorCode].messagePT;
      return this.errorMetadata[errorCode].message;
    }
  }
}
