import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {
  BoxTypeDto,
  DataMergeRespDto,
  PartDto,
  LocationDto,
  RackRailExDto,
  RackRailStateDto,
  RackRegionDto,
} from 'src/app/models';
import { BoxCarHttpErrorResponse, DataCatalogApiService, RfidApiService, boxCarCoreErrorDetails } from '@services/api';
import { NoticeDialogService } from '@services/index';
import { MatDialog } from '@angular/material/dialog';
import { defaultTheme, gradientColors } from '@components/rack-viewer/themes/rack-viewer-themes';
import { RackTotemViewerComponent } from '@components/rack-totem-viewer/rack-totem-viewer.component';
import { MoveBoxDialogComponent } from '@components/move-box-dialog/move-box-dialog.component';
import { TotemDialogResponse } from 'src/app/models/totem';
import { ErrorDialogComponent } from '@components/error-dialog/error-dialog.component';
@Component({
  selector: 'boxcar-console-contingency-functions',
  templateUrl: './contingency-functions.component.html',
  styleUrls: ['./contingency-functions.component.scss'],
})
export class ContingencyFunctionsComponent implements OnInit {
  // Page element references
  @ViewChild('rackViewer') rackViewer!: RackTotemViewerComponent;

  isLoading: boolean = false;

  // Internal state variables:

  noneRackRegion: RackRegionDto = {
    id: 0,
    name: '-- NONE SELECTED --',
    prefix: 'NONE',
    columns: 0,
    levels: 0,
    regionStrategy: 0,
    regionStorage: 1,
  };
  selectedRackRegion: RackRegionDto = this.noneRackRegion;
  rackRegions: RackRegionDto[] = [];
  selectedRegionRacks: LocationDto[] = [];
  totalLevels: number = 0;
  totalCols: number = 0;
  importedRows: RackRailStateDto[] = [];
  selectedRackFloor?: LocationDto;

  focusedRackRail: Partial<RackRailExDto> = {};
  rackInfoVisible: boolean = false;
  isSubmitting: boolean = false;
  partsCatalog: PartDto[] = [];
  boxTypes: BoxTypeDto[] = [];
  isOperationMode: boolean = false;
  hasBackDrop: boolean = false;
  rackRailsState: RackRailStateDto[] = [];

  dataMergeRespDto: DataMergeRespDto | undefined;

  @Output() instructionCreated = new EventEmitter();

  // Colors catalog:
  rackRailColor = {
    nominal: defaultTheme.itemNominalColor,
    inactive: defaultTheme.itemInactiveColor,
    withoutPart: defaultTheme.itemWithoutPartColor,
    returning: defaultTheme.itemReturningColor,
  };

  gradientColor = gradientColors;
  translate: any;

  // Constructors:
  constructor(
    private catalogApiService: DataCatalogApiService,
    private rfidApiService: RfidApiService,
    private matDialog: MatDialog,
    private noticeDialog: NoticeDialogService
  ) {}

  ngOnInit(): void {
    this.catalogApiService.getAllPartsFromCache();
    this.catalogApiService.getAllDestinationsFromCache();
    this.rfidApiService.getAllBoxTypesFromCache();
    this.loadAllRackRegions();
  }

  private loadAllRackRegions() {
    this.catalogApiService
      .getRackRegions()
      .then(rackRegions => {
        this.rackRegions = rackRegions;

        // Remove "not mapped" from rack regions.
        this.rackRegions = this.rackRegions.filter(rackRegion => rackRegion.name.toLowerCase() !== 'não mapeada');
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
  }

  private getMaxColsLevels(rails: LocationDto[]) {
    this.totalCols = Math.max(...rails.map(element => element.column));
    this.totalLevels = Math.max(...rails.map(element => element.level));
  }

  async updateRackRails(): Promise<void> {
    this.totalCols = 0;
    this.totalLevels = 0;
    this.selectedRackFloor = undefined;
    this.selectedRegionRacks = [];
    this.isLoading = true;

    if (this.selectedRackRegion.id !== 0) {
      await this.catalogApiService
        .getRackRails(this.selectedRackRegion.id)
        .then(rackRails => {
          this.getMaxColsLevels(rackRails);

          // Sort stored boxes from each rail.
          rackRails.forEach(
            rail => (rail.boxesStorage = [...rail.boxesStorage].sort((a, b) => a.currentDepth - b.currentDepth))
          );

          // Sort making biggest level and smallest column first.
          rackRails.sort(
            (a, b) =>
              a.column +
              this.totalCols * (this.totalLevels - a.level) -
              (b.column + this.totalCols * (this.totalLevels - b.level))
          );
          this.selectedRackFloor = rackRails.find(rail => rail.column === 0 && rail.level === 0);
          this.selectedRegionRacks = rackRails;
        })
        .catch((error: BoxCarHttpErrorResponse) => {
          this.noticeDialog.show(...boxCarCoreErrorDetails(error));
        });
    }

    this.isLoading = false;
  }

  // Update unique rail when edit anything on rail
  updateRail(id: number) {
    const railPosition = this.selectedRegionRacks.findIndex(rack => rack.id === id);

    this.catalogApiService
      .getRackRail(id)
      .then(newRackRail => {
        let updateRackRail = [...this.selectedRegionRacks];

        // Sort box storage for this updated rack rail.
        newRackRail.boxesStorage.sort((a, b) => a.currentDepth - b.currentDepth);

        // Remove old rack rail and put new rack rail.
        updateRackRail.splice(railPosition, 1, newRackRail);

        this.selectedRegionRacks = [...updateRackRail];
      })
      .catch(error => {
        this.matDialog.open(ErrorDialogComponent, {
          data: {
            title: this.translate.instant('catalog.racks.edit.updateRail.errorTitle'),
            description: this.translate.instant('catalog.racks.edit.updateRail.errorDescription'),
          },
        });
      });
  }

  openMoveBoxDialog() {
    const dialog = this.matDialog.open(MoveBoxDialogComponent);

    dialog
      .afterClosed()
      .toPromise()
      .then((dialogResponse: { response: TotemDialogResponse; concludedRail?: LocationDto }) => {
        if (dialogResponse.response === TotemDialogResponse.created) {
          this.instructionCreated.emit();
        } else if (dialogResponse.response === TotemDialogResponse.concluded) {
          if (
            dialogResponse.concludedRail &&
            this.selectedRackRegion.prefix === dialogResponse.concludedRail.regionPrefix
          ) {
            this.updateRail(dialogResponse.concludedRail.id);
          }
        }
      });
  }
}
