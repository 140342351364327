/*
 * File: \src\app\services\notice\notice.dialog.service.ts
 * Project: boxcar-console
 * Created Date: 2022-09-15 11:32:30
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoticeDialogComponent } from '@components/notice-dialog/notice-dialog';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class NoticeDialogService {

  constructor(
    private matDialog: MatDialog,
    private translate: TranslateService
  ) { }

  show(message: string,
    errorCode: number | undefined = undefined,
    details: string | undefined = undefined,
    title: string = this.translate.instant('application.errorTitle')) {

    const dialogRef = this.matDialog.open(NoticeDialogComponent, {
      width: '80vh',
      data: { title, errorCode, message, details }
    });

  }
}
