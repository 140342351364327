import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'boxcar-console-in-development-dialog',
  templateUrl: './in-development-dialog.component.html',
  styleUrls: [],
})
export class InDevelopmentDialogComponent implements OnInit {
  constructor() {
    return;
  }

  ngOnInit(): void {
    return;
  }
}
