import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { appRoutes } from '../routes';
import { selectAllPathsPermited } from '@services/nav-menu/nav-menu.selectors';

/**
 * This class verifies if the Login route can be activated or not
 */
@Injectable()
export class CanAccessRestrictedPages implements CanActivate {
  userPaths: string[] = [];

  constructor(private router: Router, public jwtHelper: JwtHelperService, private store: Store) {
    // Recover all permitted paths from Side nav.
    this.store.select(selectAllPathsPermited).subscribe({
      next: paths => {
        this.userPaths = paths;
      },
    });
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const canAccessRestrictedPages = !this.jwtHelper.isTokenExpired();

    if (canAccessRestrictedPages) {
      // Verify if user can access the page.
      if (this.userPaths.includes(`/${state.url.split('/')[1]}`)) return true;
      else {
        return this.router.parseUrl('/');
      }
    } else {
      return this.router.parseUrl(appRoutes.LOGIN_SCREEN);
    }
  }
}
