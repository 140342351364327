<h1 mat-dialog-title>{{ 'dialog.instructions.concludeInstruction.title' | translate }}</h1>

<h4>{{ 'dialog.instructions.concludeInstruction.description' | translate }}</h4>

<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>
      {{ 'common.address' | translate }}
    </mat-label>
    <input matInput [formControl]="addressCtrl" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let address of filteredAddresses" [value]="address.name">
        {{ address.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!addressCtrl.valid" (click)="concludeInstruction()">
    {{ 'common.buttons.instruction.conclude' | translate }}
  </button>
  <button mat-button [mat-dialog-close]="{ isCompleted: false }" color="warn">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</div>
