import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { RfidApiService } from '@services/api/rfid.api.service';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { RfidAllotmentRequestDto } from 'src/app/models/tags';

@Component({
  selector: 'boxcar-console-print-tags',
  templateUrl: './print-tags.component.html',
  styleUrls: ['./print-tags.component.scss'],
})
export class PrintTagsComponent implements OnInit {
  form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: RfidApiService,
    private snackBar: MatSnackBar,
    private router: Router,
    private loadingIndicator: LoadingIndicatorService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      quantity: new FormControl('', [Validators.required]),
    });
  }

  async onSubmit() {
    this.loadingIndicator.show();

    try {
      if (!this.form.get('quantity')?.value) {
        return;
      }

      const request: RfidAllotmentRequestDto = {
        manufacturerId: 0,
        batchNumber: '',
        startEPC: '',
        quantity: this.form.get('quantity')?.value,
        fIsPaper: true,
      };

      const tags = await this.apiService.createRfidAllotment(request);
      this.printTags(tags);

      this.form.get('quantity')?.reset();
      this.form.get('quantity')?.markAsPending();
    } catch (error: any) {
      const message = error.error.message ? error.error.message : error.error.title ? error.error.title : error.message;
      await this.snackBar.open(message, 'Fechar', {
        duration: 3000,
      });
    }
    this.loadingIndicator.hide();
  }

  printTags(barcodes: string[]) {
    this.router.navigate(
      [
        '/',
        {
          outlets: {
            print: ['print'],
          },
        },
      ],
      { state: { rfids: barcodes } }
    );
    setTimeout(() => {
      window.print();
      this.router.navigate([{ outlets: { print: null } }]);
    }, 1000);
  }
}
