import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { userSelectors } from '@services/login/state';
import { logout } from '@services/login/state/user.actions';
import { environment } from 'src/environments/environment';
import { NavBarMenu } from 'src/app/models/nav.menu';

@Component({
  selector: 'boxcar-console-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {
  version: string = environment.version;

  @ViewChild('drawer')
  drawer!: MatSidenav;

  userLogged$ = this.store.select(userSelectors.isLogged);
  userData$ = this.store.select(userSelectors.userData);

  // Itens for backup.
  @Input()
  navBarMenu: NavBarMenu = {};

  private routerChanges: Subscription;
  isHomeRoute(): boolean {
    return this.router.url === '/';
  }

  currentRoute = '/';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private store: Store) {
    this.routerChanges = this.router.events.subscribe((event: Event) => {
      // Gets the current route when it changes
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  /**
   * Only closes the drawer when it is handset
   */
  controlDrawer() {
    this.isHandset$.subscribe(result => {
      if (result) {
        this.drawer.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.routerChanges.unsubscribe();
  }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
  }

  doLogout() {
    localStorage.clear();
    this.router.navigate(['/home']);
    this.store.dispatch(logout());
  }
}
