/*
 * File: \src\app\services\api\rackstate.api.service.ts
 * Project: boxcar-console
 * Created Date: 2022-08-31 13:20:05
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { baseUrl } from '@services/api';
import { AllocationDetailsResponseDto, DataMergeRespDto, RackRailStateDto } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class RackStateApiService {
  constructor(private httpClient: HttpClient) {}

  updateRackContents(rackRailStateDto: RackRailStateDto[]) {
    return this.httpClient.put<DataMergeRespDto>(baseUrl + 'rackstate/reset', rackRailStateDto).toPromise();
  }

  getDetailedAllocation(locationId: number, quality: boolean = false) {
    let params: HttpParams = new HttpParams();

    if (quality) {
      params = params.append('quality', quality);
    }

    return this.httpClient.get<AllocationDetailsResponseDto>(baseUrl + 'rackstate/checkAllocationDetails/' + locationId, {
      params,
    }).toPromise();
  }
}
