/*
 * File: \src\app\pages\parts\import\parts.import.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-05-16 17:46:20
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-05-16	JF	Parts import dialog controller.
 */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type PartsImportDialogData = {
  selectedFile: File;
};

@Component({
  selector: 'parts-import-dialog',
  templateUrl: 'parts.import.dialog.html',
})
export class PartsImportDialogComponent {
  fileTypeAsCSV: string = '.csv';

  constructor(
    public dialogRef: MatDialogRef<PartsImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PartsImportDialogData
  ) {}

  onBrowseFile(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;

    // Single selection file on browse.
    if (fileList !== null) {
      this.data.selectedFile = fileList[0];
    }
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
