import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InstructionApiService } from '@services/api';
import { AppToastService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { MetadataService } from '@services/metadata/metadata.service';
import { Instruction, InstructionConcludeRequestBody, InstructionState, InstructionType } from 'src/app/models';

@Component({
  selector: 'boxcar-console-conclude-instruction-without-addrs',
  templateUrl: './conclude-instruction-without-addrs.component.html',
  styleUrls: ['./conclude-instruction-without-addrs.component.scss'],
})
export class ConcludeInstructionWithoutAddrsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { instruction: Instruction },
    private loadingIndicator: LoadingIndicatorService,
    private instructionApi: InstructionApiService,
    private toastService: AppToastService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ConcludeInstructionWithoutAddrsComponent>,
    private metadataService: MetadataService
  ) {}

  private getConcludeRequestBasedOnInstructionType(): InstructionConcludeRequestBody {
    const genericRequest: InstructionConcludeRequestBody = {
      containerEpc: this.data.instruction.containerEpcs[0],
      wasAutomatic: false,
    };

    switch (this.data.instruction.instructionType) {
      case InstructionType.reallocation:
        return {
          ...genericRequest,
          destinationLocationEpc: this.data.instruction.destinationEpcs[0],
          originLocationEpc: this.data.instruction.originEpcs[0],
        };
      case InstructionType.picking:
        return { ...genericRequest, originLocationEpc: this.data.instruction.originEpcs[0] };
      case InstructionType.storing:
        return { ...genericRequest, destinationLocationEpc: this.data.instruction.destinationEpcs[0] };
      default:
        return genericRequest;
    }
  }

  async concludeInstruction() {
    this.loadingIndicator.show();
    try {
      if (this.data.instruction.instructionState === InstructionState.notStarted) {
        await this.instructionApi.pickInstruction(this.data.instruction.instructionId).toPromise();
      }

      const concludeBodyRequest = this.getConcludeRequestBasedOnInstructionType();

      await this.instructionApi
        .concludeInstruction(this.data.instruction.instructionId, concludeBodyRequest)
        .toPromise();

      this.toastService.success(this.translate.instant('toast.success.totemServices.instructionConclude'));
      this.loadingIndicator.hide();
      this.dialogRef.close({ isCompleted: true });
    } catch (e: any) {
      if (this.metadataService.errorMetadata[e.error.code]) {
        this.toastService.error(this.metadataService.getErrorDescriptionFromErrorCode(e.error.code));
      } else {
        this.toastService.error(
          this.translate.instant('toast.errors.totemServices.errorToConclude', { errorCode: e.error.code })
        );
      }

      this.loadingIndicator.hide();
      this.dialogRef.close({ isCompleted: false });
    }
  }
}
