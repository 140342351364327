<h1 mat-dialog-title>{{ (title | translate).toUpperCase() }}</h1>
<div mat-dialog-content>
  <p>
    <strong>
      {{ description | translate }}
    </strong>
  </p>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close color="primary">
    {{ ('instructions.list.continue' | translate).toUpperCase() }}
  </button>
</div>
