<!--
File: \src\app\pages\boxes\pallet-tab-detail.dialog\pallet-tab-detail.dialog.component.html
Project: boxcar-console
Created Date: 2022-08-23 16:14:20
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
-->

<mat-card>
  <h1 mat-dialog-title>Etiqueta PSMM</h1>

  <mat-dialog-content>
    {{ 'rfidTags.barcode' | translate }}: <strong>{{ labelDto.labelcode }}</strong
    ><br />
    {{ 'rfidTags.operator' | translate }}: {{ labelDto.operator }}<br />
    {{ 'rfidTags.workShift' | translate }}: {{ labelDto.shift }}<br />
    {{ formatDate(labelDto.timestamp) }}<br />
    <br />
    {{ pn }}<br />
    {{ 'rfidTags.quantity' | translate }}: {{ labelDto.quantity }}<br />
    {{ 'rfidTags.source' | translate }}: {{ source }}<br />
  </mat-dialog-content>
</mat-card>
<br />
