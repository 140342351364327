<div class="box-card">
  <mat-card class="box-mat-card mat-elevation-z0">
    <div class="box-card-header">
      <!-- Header for generic boxes -->
      <div>
        {{ 'boxes.type' | translate }} <strong>{{ 'boxes.genericBox' | translate }}</strong>
        <mat-card-subtitle>
          {{ 'boxes.createdBySystem' | translate }}
        </mat-card-subtitle>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
        <img src="../../../assets/icons/moreVertIcon.svg" width="24px" height="24px" alt="menu more options icon" />
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openRfidTagDetail()">
          <span>{{ 'boxes.seeDetails' | translate }}</span>
        </button>
      </mat-menu>
    </div>

    <!-- Cards for boxes. -->
    <div class="card-content">
      <div *ngFor="let assembly of box.boxAssemblies">
        <!-- Content for generic boxes -->
        <div class="empty-barcode-list">
          {{ 'boxes.genericBoxContent' | translate }}
        </div>
        <mat-card-content>
          <p>
            <strong>{{ 'boxes.contentInfo' | translate }}</strong>
          </p>
          <span>
            {{ 'boxes.partNumber' | translate }}
            <span style="color: #3880ff">
              {{ assembly.boxAssemblyInfoResponseDto.partNumber }} -
              {{ assembly.boxAssemblyInfoResponseDto.partDescription }}
            </span>
          </span>
          <br />
          <span>
            {{ 'boxes.source' | translate }} {{ assembly.boxAssemblyInfoResponseDto.manufactureSource }} -
            {{ assembly.boxAssemblyInfoResponseDto.manufactureSourceDescription }}
          </span>
          <br />
          <span *ngIf="assembly.labelDtos !== undefined">
            {{ 'boxes.totalQuantity' | translate }} {{ total(assembly.labelDtos) }}
          </span>
        </mat-card-content>
      </div>
      <div *ngIf="box.boxAssemblies.length === 0 || box.boxAssemblies === undefined">
        <!-- Content for generic boxes -->
        <div class="empty-barcode-list">
          {{ 'boxes.noPSMMLabel' | translate }}
        </div>
        <mat-card-content>
          <p>
            <strong>{{ 'boxes.contentInfo' | translate }}</strong>
          </p>
          <span>
            {{ 'boxes.partNumber' | translate }}
            <span style="color: #3880ff"> - </span>
          </span>
          <br />
          <span> {{ 'boxes.source' | translate }} - </span>
          <br />
          <span> {{ 'boxes.totalQuantity' | translate }} 0 </span>
        </mat-card-content>
      </div>
      <div class="box-card-footer">
        <span style="display: flex; gap: 5px">
          {{ 'boxes.state' | translate }}
          <strong *ngIf="box.container.entityState !== undefined && boxStateMetadata">{{
            boxStateMetadata[box.container.entityState]?.namePT ?? box.container.entityState
          }}</strong>
          <mat-spinner *ngIf="!boxStateMetadata" [diameter]="15">{{ tryAgain() }}</mat-spinner>
        </span>
        <p>{{ 'boxes.inCharge' | translate }} {{ box.container.teamLeader }}</p>
      </div>
    </div>
  </mat-card>
</div>
