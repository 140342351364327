import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserApiService } from '@services/api/users.service';
import { AppToastService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { UserResponse } from '@models/users';

@Component({
  selector: 'boxcar-console-switch-user-active-dialog',
  templateUrl: './switch-user-active-dialog.component.html',
  styleUrls: ['./switch-user-active-dialog.component.scss'],
})
export class SwitchUserActivatedDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: UserResponse },
    private userApiService: UserApiService,
    private loadingIndicatorService: LoadingIndicatorService,
    private toastService: AppToastService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<SwitchUserActivatedDialogComponent>
  ) {}

  async onClickDeleteUser() {
    this.loadingIndicatorService.show();

    const dialogResponse = { changed: false };

    await this.userApiService
      .deleteUser(this.data.user.id)
      .toPromise()
      .then(_ => {
        dialogResponse.changed = true;
        this.toastService.success(
          this.translate.instant(
            `toast.success.userManagement.user${this.data.user.active ? 'Deactivated' : 'Activated'}`
          )
        );
      })
      .catch(_ => {
        this.toastService.error(
          this.translate.instant(
            `toast.errors.userManagement.user${this.data.user.active ? 'Deactivated' : 'Activated'}`
          )
        );
      })
      .finally(() => {
        this.loadingIndicatorService.hide();
        this.dialogRef.close(dialogResponse);
      });
  }
}
