import { Component, Inject, OnInit } from '@angular/core';
import {
  boxCarCoreErrorDetails,
  BoxCarHttpErrorResponse,
  InstructionApiService,
  MetadataApiService,
} from '@services/api';
import { Instruction, InstructionDto, InstructionSearchQuery, InstructionState, InstructionType, OrderTypes } from 'src/app/models';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent } from '@components/warning-dialog/warning-dialog.component';
import { ErrorDialogComponent } from '@components/error-dialog/error-dialog.component';
import { NoticeDialogService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { MetadataService } from '@services/metadata/metadata.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'boxcar-prioritize-order-diaolog',
  templateUrl: './prioritize-order-dialog.component.html',
  styleUrls: ['./prioritize-order-dialog.component.scss'],
})
export class PrioritizeDiaologComponent implements OnInit {
  allMetadataErrors: any;
  allOrders: Instruction[] = [];
  selectedDestination: string = '';
  selectedPartNumber: string = '';
  isLoadingOrders: boolean = true;

  // Not started orders.
  waitingInstructions: Instruction[] = [];
  private waitingQueryPattern = `state=${InstructionState.notStarted},${InstructionState.paused}  AND
  type=${InstructionType.picking} AND destination=${this.selectedDestination} AND part=${this.selectedPartNumber} `;
  waitingPag: PageEvent = {
    length: 0,
    pageSize: 50,
    pageIndex: 0,
    previousPageIndex: 0,
  };
  requestWaiting: InstructionSearchQuery = {
    query: this.waitingQueryPattern,
    qtyPerPage: this.waitingPag.pageSize,
    pageNum: this.waitingPag.pageIndex + 1,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {partnumber: string, destination: string},
    public translate: TranslateService,
    private instructionApiService: InstructionApiService,
    private metadataApi: MetadataApiService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private noticeDialog: NoticeDialogService,
    private loadingService: LoadingIndicatorService,
    private metadataService: MetadataService
  ) {}

  ngOnInit(): void {
    this.isLoadingOrders = true;

    this.selectedDestination = this.data.destination;
    this.selectedPartNumber = this.data.partnumber;

    this.waitingQueryPattern = `state=${InstructionState.notStarted},${InstructionState.paused}  AND
    type=${InstructionType.picking} AND destination=${this.selectedDestination} AND part=${this.selectedPartNumber} `;

    this.requestWaiting = {
      query: this.waitingQueryPattern,
      qtyPerPage: this.waitingPag.pageSize,
      pageNum: this.waitingPag.pageIndex + 1,
    };

    this.makeRequest();

    // Get all metadata error.
    this.metadataApi
      .getErrorMetadata()
      .then(response => {
        this.allMetadataErrors = response;
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });
  }

  openErrorDialog(title: string, description: string) {
    this.dialog.open(ErrorDialogComponent, {
      data: {
        title: title,
        description: description,
      },
      minWidth: '300px',
      width: '450px',
    });
  }

  openWarningDialog(title: string, description: string) {
    this.dialog.open(WarningDialogComponent, {
      data: {
        title: title,
        description: description,
      },
      minWidth: '300px',
      width: '30%',
    });
  }

  async makeRequest() {
    this.isLoadingOrders = true;
    const request = this.requestWaiting;

    await this.instructionApiService
      .searchInstructions(request)
      .toPromise()
      .then((response: InstructionDto) => {
          this.waitingInstructions = response.instructions;
        }
      )
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });

    this.isLoadingOrders = false;
  }

  async prioritizeInstructions() {
    this.loadingService.show();

    await this.instructionApiService
      .prioritizeInstructionByPN( this.selectedPartNumber.trim(), this.selectedDestination.trim() )
      .then(() => {
        this.snackBar.open('Pedidos de picking priorizados com sucesso.', undefined, { duration: 2000 });
      })
      .catch((response: BoxCarHttpErrorResponse) => {
        let errorMessage = this.metadataService.getErrorDescriptionFromErrorCode(response.error.code);
        this.dialog.openDialogs.pop();
        this.openErrorDialog('instructions.priorizationErrors', errorMessage);
      });
    this.loadingService.hide();
  }
}
