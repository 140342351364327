/*
 * File: \src\app\pages\rfidPrint\rfidtags-print-layout.component.ts
 * Project: boxcar-console
 * Created Date: 2022-02-09 16:51:33
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-27	JF	Component refactored.
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrintDto } from 'src/app/models';

@Component({
  selector: 'boxcar-console-rfidtags-print-layout',
  templateUrl: './rfidtags-print-layout.component.html',
  styleUrls: ['./rfidtags-print-layout.component.scss'],
})
export class RfidtagsPrintLayoutComponent {
  rfidTags: string[] = [];
  identificationTag: string[] = [];
  pages: any[] = [];

  constructor(private route: Router) {
    const nav = this.route.getCurrentNavigation();

    if (nav?.extras.state) {
      this.rfidTags = nav.extras.state.rfids;

      if (nav.extras.state.identification) {
        this.identificationTag = nav.extras.state.identification;
      }
    }

    const quantityPerPage = 14; // 7x2 Layout
    let x = 0; // Lines starts with zero

    // Variable to create RFID Identification.
    let y = 0;

    for (let i = 0; i < this.rfidTags.length; i += quantityPerPage) {
      let pagePairs: any[] = [];
      let pairs: PrintDto[] = [];

      this.rfidTags.slice(i, i + quantityPerPage).forEach(barcode => {
        pairs.push({ tag: barcode, identification: this.identificationTag[y] });
        if (x === this.rfidTags.length - 1 && pairs.length != 2) {
          pagePairs.push([{ tag: barcode, identification: this.identificationTag[y] }, null]);
        } else if (x % 2 === 1) {
          pagePairs.push(pairs);
          pairs = [];
        }
        x++;
        if (x % 2 == 0) {
          y++;
        }
      });

      this.pages.push(pagePairs);
      pagePairs = [];
    }
  }
}
