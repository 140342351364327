/*
 * File: \src\app\pages\root-page\root-page.component.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-27	JF	Component refactored.
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'boxcar-console-root-page',
  templateUrl: './root-page.component.html',
  styleUrls: ['./root-page.component.scss'],
})
export class RootPageComponent {
  constructor() {}
}
