/*
 * File: \src\app\models\logs.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 13:21:08
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Types created for refactoring.
 */

export type LogDTO = {
  login: string;
  timestamp: string;
  code: number;
  contextTag: string;
  contextId: number;
  data: string;
  eventDetailNumber: number;
  eventClassNumber: number;
  gravity?: number;
};

export type LogEntryDto = {
  logsQuantity: number;
  logs: LogDTO[];
};

export type BoxLogDto = {
  login: string;
  timestamp: string;
  code: number;
  contextTag: string;
  contextId: number;
  data: string;
  eventDetailNumber: number;
  eventClassNumber: number;
};

export type LogsDataTablesResponse = {
  id: number;
  login: string;
  timestamp: Date;
  eventclass: string;
  event: string;
  data: string;
};

export type LogQuery = {
  eventClass?: number[];
  eventDetail?: number[];
  gravity?: number[] | string[];
  filter?: string;
  pageNum: number;
  qtyPerPage: number;
};

export type LogisticLog = {
  icon: boolean;
  user: string;
  class: string;
  message: LogMessage;
  timestamp: string;
  severity: number;
};

export type AutomaticReturnLog = {
  icon: boolean;
  event: string;
  message: LogMessage;
  timestamp: string;
  severity: number;
};

export type WrongDestinyLog = {
  icon: boolean;
  timestamp: string;
  instructionId: number | string;
  operator: string;
  instructionDestiny: string;
  concludedDestiny: string;
  readDestiny: string | undefined;
  part: string;
  severity: number;
};

export type LogMessage = {
  title: string;
  description: string;
};

export type LogLocal = {
  login: string;
  timestamp: string;
  contextTag: string;
  contextId: number;
  data: LogMessage;
  eventClassNumber: number;
  eventDetailNumber: number;
  gravity?: number;
  isDetailButtonNedded?: boolean;
  rawLog?: LogDTO;
};

export enum LogSeverity {
  'low' = 0,
  'medium' = 1,
  'high' = 2,
}

export const incorrectDestinyEventDetal = [4012, 4013, 4014, 4022];
