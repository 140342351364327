/*
 * File: src/app/services/nav-menu/nav-menu.service.ts
 * Project: boxcar-console
 * Created Date: 2022-09-22 10:00:46
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-09-23	VR	Including default option.
 *
 */

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserTypes } from 'src/app/models/login';
import { NavBarItem, NavBarMenu, NavItemName } from 'src/app/models/nav.menu';

@Injectable({
  providedIn: 'root',
})
export class NavMenuService {
  private allAuditOptions = {
    warehouse: {
      key: this.translate.instant('application.menu.warehouse'),
      link: '/warehouse',
    },
    boxes: {
      key: this.translate.instant('application.menu.boxes'),
      link: '/boxes',
    },
    instructions: {
      key: this.translate.instant('application.menu.instructions'),
      link: '/instructions',
    },
    logs: {
      key: this.translate.instant('application.menu.logEntries'),
      link: '/logs',
    },
    pabList: {
      key: this.translate.instant('application.menu.pabList'),
      link: '/pablist',
    }
  };

  private allManagementOptions = {
    alerts: {
      key: this.translate.instant('application.menu.alerts'),
      link: '/alerts',
    },
    parts: {
      key: this.translate.instant('application.menu.parts'),
      link: '/parts',
    },
    rfid: {
      key: this.translate.instant('application.menu.rfidSticks'),
      link: '/rfidTags',
    },
    psmmTagsReport: {
      key: this.translate.instant('application.menu.psmmTagsReport'),
      link: '/psmmTagsReport',
    },
    sectors: {
      key: this.translate.instant('application.menu.sections'),
      link: '/sectors',
    },
    boxTypes: {
      key: this.translate.instant('application.menu.boxTypes'),
      link: '/boxTypes',
    },
    userManagement: {
      key: this.translate.instant('application.menu.userManagement'),
      link: '/users',
    },
    settingsProfiles: {
      key: this.translate.instant('application.menu.settingsProfiles'),
      link: '/settingsProfiles',
    },
    forkliftsAndEquipments: {
      key: this.translate.instant('application.menu.forkliftsAndEquipments'),
      link: '/forkliftsAndEquipments',
    },
  };

  private orderOption: NavBarItem = {
    key: this.translate.instant('application.menu.orders'),
    link: '/orders',
  };

  private reportsOption: NavBarItem = {
    key: this.translate.instant('application.menu.powerBI'),
    link: '/powerBI',
  };

  private totemOption: NavBarItem = {
    key: this.translate.instant('application.menu.totem'),
    link: '/totem',
  };

  private qualityOption: NavBarItem = {
    key: this.translate.instant('application.menu.quality'),
    link: '/quality',
  }

  private auditItens: Map<string, NavBarItem> = new Map();
  private managementItens: Map<string, NavBarItem> = new Map();
  private orderItem: NavBarItem | undefined = undefined;
  private reportsItem: NavBarItem | undefined = undefined;
  private totemItem: NavBarItem | undefined = undefined;
  private qualityItem: NavBarItem | undefined = undefined;

  constructor(private translate: TranslateService) {}

  getNavMenuFromUserType(roles?: string[]): NavBarMenu {
    const navMenu: NavBarMenu = {
      auditMenu: undefined,
      managementMenu: undefined,
      orderMenu: undefined,
      reportMenu: undefined,
      totemMenu: undefined,
      qualityMenu: undefined,
    };

    if (roles) {
      this.createNavFromRoles(roles);
      this.setNavItems(navMenu);
    }

    return navMenu;
  }

  private createNavFromRoles(roles: string[]) {
    // Reset all itens;
    this.auditItens.clear();
    this.managementItens.clear();
    this.orderItem = undefined;
    this.reportsItem = undefined;
    this.totemItem = undefined;

    // Add menu items according to each user type.
    for (const role of roles) {
      switch (role) {
        case UserTypes.expedition:
          this.orderItem = this.orderOption;
          break;
        case UserTypes.manager:
          this.managementItens
            .set(NavItemName.alerts, this.allManagementOptions.alerts)
            .set(NavItemName.parts, this.allManagementOptions.parts)
            .set(NavItemName.rfid, this.allManagementOptions.rfid)
            .set(NavItemName.sectors, this.allManagementOptions.sectors)
            .set(NavItemName.boxTypes, this.allManagementOptions.boxTypes)
            .set(NavItemName.alerts, this.allManagementOptions.alerts)
            .set(NavItemName.settingsProfiles, this.allManagementOptions.settingsProfiles)
            .set(NavItemName.forkliftsAndEquipments, this.allManagementOptions.forkliftsAndEquipments);
          this.reportsItem = this.reportsOption;
          this.qualityItem = this.qualityOption;
          break;
        case UserTypes.auditor:
          this.auditItens
            .set(NavItemName.warehouse, this.allAuditOptions.warehouse)
            .set(NavItemName.boxes, this.allAuditOptions.boxes)
            .set(NavItemName.instructions, this.allAuditOptions.instructions)
            .set(NavItemName.logs, this.allAuditOptions.logs)
            .set(NavItemName.pabList, this.allAuditOptions.pabList);
          this.reportsItem = this.reportsOption;
          break;
        case UserTypes.admin:
          this.auditItens
            .set(NavItemName.warehouse, this.allAuditOptions.warehouse)
            .set(NavItemName.boxes, this.allAuditOptions.boxes)
            .set(NavItemName.instructions, this.allAuditOptions.instructions)
            .set(NavItemName.logs, this.allAuditOptions.logs)
            .set(NavItemName.pabList, this.allAuditOptions.pabList);
          this.managementItens
            .set(NavItemName.alerts, this.allManagementOptions.alerts)
            .set(NavItemName.parts, this.allManagementOptions.parts)
            .set(NavItemName.rfid, this.allManagementOptions.rfid)
            .set(NavItemName.psmmTagsReport, this.allManagementOptions.psmmTagsReport)
            .set(NavItemName.sectors, this.allManagementOptions.sectors)
            .set(NavItemName.boxTypes, this.allManagementOptions.boxTypes)
            .set(NavItemName.alerts, this.allManagementOptions.alerts)
            .set(NavItemName.userManagement, this.allManagementOptions.userManagement)
            .set(NavItemName.settingsProfiles, this.allManagementOptions.settingsProfiles)
            .set(NavItemName.forkliftsAndEquipments, this.allManagementOptions.forkliftsAndEquipments);
          this.orderItem = this.orderOption;
          this.reportsItem = this.reportsOption;
          this.totemItem = this.totemOption;
          this.qualityItem = this.qualityOption;
          break;
        default:
          break;
      }
    }
  }

  private setNavItems(menu: NavBarMenu) {
    // Transform on NavBarMenu.
    if (this.auditItens.size > 0) {
      menu.auditMenu = [];
      for (const item of this.auditItens.values()) {
        menu.auditMenu = menu.auditMenu.concat(item);
      }
    }

    if (this.managementItens.size > 0) {
      menu.managementMenu = [];
      for (const item of this.managementItens.values()) {
        menu.managementMenu = menu.managementMenu.concat(item);
      }
    }

    if (this.orderItem) {
      menu.orderMenu = this.orderItem;
    }

    if (this.reportsItem) {
      menu.reportMenu = this.reportsItem;
    }

    if (this.totemItem) {
      menu.totemMenu = this.totemItem;
    }

    if (this.qualityItem) {
      menu.qualityMenu = this.qualityItem;
    }
  }
}
