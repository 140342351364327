<!--
File: \src\app\pages\racks\import\racks.import.dialog.html
Project: boxcar-console
Created Date: 2022-05-17 11:12:05
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-05-17	JF	racks import dialog template.
-->

<h1 mat-dialog-title>{{'catalog.racks.modal.title' | translate}}</h1>

<div mat-dialog-content>
  <p><strong>Note: </strong>{{'catalog.racks.modal.warningMessage' | translate}}</p>
  <p>{{'catalog.racks.modal.instructions' | translate}}</p>

  <pre>
    <code [innerHtml]="rackRailJsonSnippet"></code>
  </pre>

  <strong>
    <p>{{'catalog.racks.modal.selectFile' | translate}}</p>
  </strong>

  <!-- File browse field -->
  <button type="button" mat-raised-button (click)="fileInput.click()">
    {{'catalog.racks.modal.chooseFile' | translate}}
  </button>
  <input hidden type="file" accept="{{fileTypeToBeImported}}" #fileInput (change)="onBrowseFile($event)" />
  <p style="margin: 6px">{{ data.selectedFile ? data.selectedFile.name: '' }}</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="data" [disabled]="data.selectedFile === undefined">
    {{'catalog.racks.modal.processButton' | translate}}
  </button>
  <button mat-button (click)="onCloseDialog()">{{'catalog.racks.modal.cancelButton' | translate}}</button>
</div>
