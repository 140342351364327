<h1 mat-dialog-title>{{ 'instructions.list.newOrder' | translate }}</h1>
<div mat-dialog-content>
  <p>
    {{ 'instructions.list.instructionCreatingNewInstruction' | translate }}
  </p>
  <div>
    <mat-form-field appearance="outline">
      <mat-label
        >{{ 'instructions.list.partName' | translate }} - {{ 'instructions.list.partNumber' | translate }}
      </mat-label>
      <input
        type="text"
        matInput
        [matAutocomplete]="auto"
        [formControl]="myControl"
        (blur)="verifyModel()"
        [disabled]="isLoadingParts" />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let part of filteredOptions | async" [value]="part.number + ' - ' + part.description">
          {{ part.number + ' - ' + part.description }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <br />

    <div *ngIf="visibleQtyBox">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'instructions.list.qtyContainer' | translate }}
        </mat-label>
        <input type="number" matInput [(ngModel)]="assembliesQtd" min="1" (change)="verifyAssembliesQty()" />
      </mat-form-field>
      <br />
    </div>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'instructions.list.destination' | translate }}</mat-label>
      <mat-select [(ngModel)]="selectedDestination">
        <mat-option *ngFor="let dest of allDestinations" value="{{ dest.name }}">
          {{ 'instructions.list.pickingSector' | translate }} {{ dest.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close color="primary">
    {{ ('instructions.list.cancel' | translate).toUpperCase() }}
  </button>
  <button mat-button mat-dialog-close color="primary" (click)="generateInstruction()">
    {{ ('instructions.list.makeOrder' | translate).toUpperCase() }}
  </button>
</div>
