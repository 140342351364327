import { Component, ViewChild } from '@angular/core';
import { MatTab } from '@angular/material/tabs';
import { ContingencyFunctionsComponent } from '@components/contingency-functions/contingency-functions.component';
import { ContingencyInstructionsManagerComponent } from '@components/contingency-instructions-manager/contingency-instructions-manager.component';

@Component({
  selector: 'totem-services',
  templateUrl: './totem-services.component.html',
  styleUrls: ['./totem-services.component.scss'],
})
export class TotemServicesComponent {
  @ViewChild('instructionsList') instructionsListChild!: ContingencyInstructionsManagerComponent;
  @ViewChild('warehouseTotem') totemWarehouseChild!: ContingencyFunctionsComponent;

  onTabChange(event: { index: number; tab: MatTab }) {
    if (event.index === 1) {
      this.refreshInstructions();
    } else {
      this.refreshRackRails();
    }
  }

  private refreshRackRails() {
    this.totemWarehouseChild.updateRackRails();
  }

  refreshInstructions() {
    this.instructionsListChild.requestInstructions();
  }
}
