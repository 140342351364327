/*
 * File: \src\app\models\boxing.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 14:56:09
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Types created for refactoring.
 */

import { RegionStrategy } from './datacatalog';
import { Instruction } from './instruction';

export type SourceDto = {
  name: string;
  description: string;
};

export type SectorDto = {
  name: string;
  description: string;
  sourceDtos: SourceDto[];
};

export type LabelDto = {
  labelcode: string;
  quantity: number;
  timestamp: string;
  operator: string;
  shift: string;
  partNumber: string;
  manufactureSource: string;
};

export type PsmmDetail = {
  psmm: LabelDto;
  partNumberDesc?: string;
  sourceDesc?: string;
};

export type BoxAssembly = {
  manufactureArea: string;
  boxAssemblyInfoResponseDto: BoxAssemblyInfo;
  assemblyState: {
    nameEN: string;
    namePT: string;
    nameES: string;
  };
  complete: true;
  labelDtos: LabelDto[];
};

export type PackagingSearchResponse = {
  containerQuantity: number;
  containersResponseDtos: BoxDto[];
};

export type BoxDto = {
  container: ContainerDto;
  boxAssemblies: BoxAssembly[];
};

export type ContainerDto = {
  containerId: number;
  entityState: number;
  containerModel: string;
  location?: string;
  activeBarcode?: string;
  teamLeader: string;
  instructions?: Instruction[];
  boxAssembly?: BoxAssembly;
  lastStateUpdate?: Date;
  barcodes?: string[];
  epcs?: string[];
  maxAssembliesExpected: number;
  containerLifeCycleId?: number;
};

export type BoxAssemblyInfo = {
  boxAssemblyId: number;
  manufactureSource: string;
  manufactureSourceDescription: string;
  partNumber: string;
  partDescription: string;
  typicalPartQuantity: number;
  partStrategy: RegionStrategy;
};

export enum ContainerLifeCycleState {
  any = -1,
  available = 0,
  onGoing = 1,
  liberated = 2,
  transit = 3,
  placed = 4,
  placedQuality = 5,
  delivered = 6,
  canceled = 7,
  placedFloor = 8,
  replaced = 9,
  restored = 10,
}

type BoxAssemblyInfoResponse = {
  boxAssemblyId: number;
  manufactureSource: string;
  manufactureSourceDescription: string;
  partNumber: string;
  partDescription: string;
  typicalPartQuantity: number;
};

export type LabelStateResponseDTO = {
  boxAssemblyInfoResponseDto: BoxAssemblyInfoResponse;
  psmmLabelDto: LabelDto;
  assemblyDetailId: number;
};

export type DataPsmmTagContingency = {
  tag: string;
  radResponse: LabelStateResponseDTO | null;
};

export type InfoForAssociateBox = {
  packageEPC: string;
  partNumber: string;
  source: string;
  destination: string;
  labelDtos: LabelDto[];
};
