<h1 mat-dialog-title>{{ 'userManagement.dialogs.createUser' | translate }}</h1>

<h4>{{ 'userManagement.dialogs.createDescription' | translate }}</h4>

<div mat-dialog-content>
  <form [formGroup]="formCtrls">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'userManagement.table.headers.login' | translate }}</mat-label>
      <input type="text" matInput formControlName="loginCtrl" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="password-field">
      <mat-label>{{ 'userManagement.table.headers.password' | translate }}</mat-label>
      <input type="{{ passwordIsVisible ? 'text' : 'password' }}" matInput formControlName="passwordCtrl" />
      <button mat-icon-button matSuffix (click)="passwordIsVisible = !passwordIsVisible">
        <img
          width="24"
          alt="Toggle password visibility"
          src="../../../assets/icons/{{ passwordIsVisible ? 'disable_visibility_icon.svg' : 'visibility_icon.svg' }}" />
      </button>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'userManagement.table.headers.name' | translate }}</mat-label>
      <input type="text" matInput formControlName="nameCtrl" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'userManagement.table.headers.email' | translate }}</mat-label>
      <input type="email" matInput formControlName="emailCtrl" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'userManagement.table.headers.roles' | translate }}</mat-label>
      <mat-select multiple formControlName="rolesCtrl">
        <mat-option *ngFor="let role of allRoles" [value]="role.name">{{ role.name.toUpperCase() }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="onCreateClick()" [disabled]="!formCtrls.valid">
    {{ 'common.buttons.userManagement.create' | translate }}
  </button>

  <button mat-button [mat-dialog-close]="{ created: false }">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</div>
