import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CustomValidatorsService {
  endIsValid(invalidChars: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = (control.value as string).trim();

      if (invalidChars.includes(value.charAt(value.length - 1))) {
        return { invalidEndChar: true };
      }

      return null;
    };
  }
}
