/*
 * File: src/app/services/nav-menu/nav-menu.selectors.ts
 * Project: boxcar-console
 * Created Date: 2022-09-22 10:00:46
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 *
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NavBarMenu } from 'src/app/models/nav.menu';

export const nav = createFeatureSelector<NavBarMenu>('nav');

export const selectFullMenu = createSelector(nav, (menu: NavBarMenu) => menu);

export const selectAllPathsPermited = createSelector(nav, (menu: NavBarMenu) => {
  let paths: string[] = [];

  if (menu.auditMenu) {
    for (const item of menu.auditMenu) {
      paths = paths.concat(item.link);
    }
  }

  if (menu.managementMenu) {
    for (const item of menu.managementMenu) {
      paths = paths.concat(item.link);
    }
  }

  if (menu.orderMenu) paths = paths.concat(menu.orderMenu.link);

  if (menu.reportMenu) paths = paths.concat(menu.reportMenu.link);

  if (menu.totemMenu) paths = paths.concat(menu.totemMenu.link);

  if (menu.qualityMenu) paths = paths.concat(menu.qualityMenu.link);

  return paths;
});
