/*
 * File: \src\app\services\api\logger.api.service.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 14:02:41
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Service created for refactoring.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogEntryDto, LogQuery } from 'src/app/models/logger';

import { baseUrl } from '@services/api';

@Injectable({
  providedIn: 'root',
})
export class LoggerApiService {
  constructor(private httpClient: HttpClient) {}

  getContainerLogs(barCode: string, logParam: LogQuery) {
    const params: HttpParams = new HttpParams().set('pageNum', logParam.pageNum).set('qtyPerPage', logParam.qtyPerPage);

    return this.httpClient.get<LogEntryDto>(baseUrl + `logger/containerlogs/${barCode}`, { params }).toPromise();
  }

  /** Retrieve a set of log entries.*/
  getLogEntries(logParam: LogQuery) {
    let params: HttpParams = new HttpParams().set('pageNum', logParam.pageNum).set('qtyPerPage', logParam.qtyPerPage);

    if (logParam.filter) {
      params = params.set('filter', logParam.filter);
    }

    if (logParam.gravity && logParam.gravity.length > 0) {
      logParam.gravity.forEach(gravity => {
        params = params.append('gravity', gravity);
      });
    }

    if (logParam.eventDetail && logParam.eventDetail.length > 0) {
      logParam.eventDetail.forEach(detail => {
        params = params.append('eventDetail', detail);
      });
    }

    if (logParam.eventClass && logParam.eventClass.length > 0) {
      logParam.eventClass.forEach(eventClass => {
        params = params.append('eventClass', eventClass);
      });
    }

    return this.httpClient.get<LogEntryDto>(baseUrl + 'logger/logs', { params }).toPromise();
  }
}
