/*
 * File: \src\app\pages\parts\import\parts.preview.dialog.ts
 * Project: boxcar-console
 * Created Date: 2022-05-17 08:38:54
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-05-17	JF	Parts preview dialog controller.
 */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { PartDto, RegionStrategy } from 'src/app/models/datacatalog';

export type PartsPreviewDialogData = {
  partsCatalogRows: PartDto[];
};

@Component({
  selector: 'parts-preview-dialog',
  templateUrl: 'parts.preview.dialog.html',
})
export class PartsPreviewDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PartsPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PartsPreviewDialogData,
    private translate: TranslateService
  ) {}

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  getStringRegionType(num: number) {
    if (num) {
      switch (num) {
        case RegionStrategy.jis:
          return 'JIS';
        case RegionStrategy.jit:
          return 'JIT';
        default:
          return this.translate.instant('catalog.parts.unmapped');
      }
    }
  }
}
