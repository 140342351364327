/*
 * File: \src\app\core\model\DataMerge.ts
 * Project: boxcar-console
 * Created Date: 2022-02-18 13:02:12
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-02-18	JF	Types created for refactoring.
 */

import { Instruction } from "./instruction";

export type DataMergeLogDto = {
  index: number;
  key: string;
  message: string;
  details: string;
  isWarning: boolean;
};

export type DataMergeRespDto = {
  totalRows: number;
  createdRows: number;
  updatedRows: number;
  errorRows: number;
  warnings: number;

  logs: DataMergeLogDto[];
};

export type AllocationDetailsResponseDto = {
  containersAllocation: ContainerAllocationDto[];
  instructions: Instruction[]
}

export type ContainerAllocationDto = {
  depth: number;
  rfidTagBarcode: string;
  containerModel: string;
  partNumber: string;
  partDescription: string;
  partsQuantity?: number
  labelCodes: string[];
  instruction?: Instruction;
}
