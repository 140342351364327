import { Component, Input, OnInit } from '@angular/core';
import { StateDictDto } from '@models/metadata';
import { ContainerDto, LabelDto } from '@models/packaging';
import { ContainerService } from '@services/container/container.service';

@Component({
  selector: 'resumed-box-card',
  templateUrl: './resumed-box-card.component.html',
  styleUrls: ['./resumed-box-card.component.scss'],
})
export class ResumedBoxCardComponent implements OnInit {
  @Input() container?: ContainerDto;
  boxStateMetadata: StateDictDto = {};

  constructor(private containerUtils: ContainerService) {}

  async ngOnInit() {
    this.boxStateMetadata = await this.containerUtils.getBoxMetadata();
  }

  total(labelDtos: LabelDto[]) {
    let total = 0;
    if (labelDtos != null && labelDtos.length > 0) {
      labelDtos.forEach(label => {
        total += label.quantity;
      });
    }
    return total;
  }
}
