import { TranslateService } from '@ngx-translate/core';
import { AvailableFilter } from '../../models/autocomplete';
import { TokenData, TokenState } from 'src/app/models/token';

export class Token {
  private state: TokenState = TokenState.EMPTY;
  private filter?: AvailableFilter;
  private strToken: string;
  private tokenData: TokenData = {
    filterName: '',
    filterOptions: [],
  };
  private errors: string[] = [];

  constructor(strToken: string, private translate: TranslateService, filter?: AvailableFilter) {
    this.filter = filter;
    this.strToken = strToken;
    this.buildToken();
  }

  private buildToken() {
    this.splitTokenName();

    if (this.tokenData.filterName !== '') {
      this.validateFilterName();
      this.state = TokenState.NAMED;

      if (!this.verifyAttributionSign()) {
        this.errors.push(this.translate.instant('autocomplete.errors.attributionSign'));
      } else {
        this.state = TokenState.ATTRIBUTION_SIGN;

        if (this.strToken.endsWith('=')) {
          this.errors.push(this.translate.instant('autocomplete.errors.missingOption'));
        } else {
          this.splitTokenOptions();

          if (this.tokenData.filterOptions.length > 0) {
            this.verifyFilterOptions();
          }
        }
      }
    }
  }

  private verifyFilterOptions() {
    this.validateFilterOptions();

    if (this.filter?.unique) {
      this.state = TokenState.COMPLETED;
    } else if (this.strToken.endsWith(',')) {
      this.tokenData.filterOptions.push('');
      this.errors.push(this.translate.instant('autocomplete.errors.missingOption'));
      this.state = TokenState.OPTION_INCOMPLETE;
    } else if (this.strToken.endsWith(' ')) {
      this.state = TokenState.COMPLETED;
    }
  }

  private splitTokenName() {
    if (this.strToken.includes('=')) {
      this.tokenData.filterName = this.strToken.split('=')[0];
    } else {
      this.tokenData.filterName = this.strToken;
    }
  }

  private verifyAttributionSign() {
    if (this.strToken.includes('=')) {
      return true;
    }
    return false;
  }

  private splitTokenOptions() {
    const optionsStr = this.strToken.split('=')[1];

    for (const option of optionsStr.split(',')) {
      if (option !== '') {
        this.tokenData.filterOptions.push(option);
      }
    }
  }

  private validateFilterName() {
    if (!this.filter) {
      this.errors.push(`${this.translate.instant('autocomplete.errors.filterName')}: ${this.tokenData.filterName}`);
    }
  }

  private validateFilterOptions() {
    if (this.filter?.options) {
      const optionsWithError = [];

      for (const option of this.tokenData.filterOptions) {
        if (!this.filter.options.includes(option)) {
          optionsWithError.push(option);
        }
      }

      if (optionsWithError.length > 0) {
        this.errors.push(
          `${this.translate.instant('autocomplete.errors.filterOption')} '${optionsWithError.join(', ')}'`
        );
        return false;
      }
    }

    if (this.filter?.unique && this.tokenData.filterOptions.length > 1) {
      this.errors.push(this.translate.instant('autocomplete.errors.unique'));
      return false;
    }
    return true;
  }

  getState() {
    return this.state;
  }

  getErrors() {
    return this.errors;
  }

  hasErrors() {
    return this.errors.length > 0;
  }

  getStr() {
    return this.strToken;
  }

  getData(): TokenData {
    return this.tokenData;
  }

  setStr(str: string) {
    this.strToken = str;
  }
}
