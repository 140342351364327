/*
 * File: \src\app\app-routing.module.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-26	JF	Module refactored.
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanAccessHome } from './route/guards/can-access-home';
import { CanAccessRestrictedPages } from './route/guards/can-access-restricted-pages';
import { CanActivateLogin } from './route/guards/can-activate-login';

import { RootPageComponent } from '@pages/root-page/root-page.component';
import { RfidTagsComponent } from '@pages/rfidTags/rfid-tags.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { LogsComponent } from '@pages/logs-view/logs.component';
import { AddRfidTagsComponent } from '@pages/rfidTags/add-rfid-tags/add-rfid-tags.component';
import { PrintTagsComponent } from '@pages/rfidTags/print-tags/print-tags.component';
import { SectorsComponent } from '@pages/sectors/sectors.component';
import { RfidtagsPrintLayoutComponent } from '@pages/rfidPrint/rfidtags-print-layout.component';
import { BoxesComponent } from '@pages/boxes/boxes.component';
import { InstructionExpansionPanelsComponent } from '@pages/orders/expansion-panels.component';
import { AddContainersInBatchComponent } from '@pages/rfidTags/add-containers-in-batch/add-containers-in-batch.component';
import { AlertComponent } from '@pages/alerts/alerts.component';
import { ReadPSMMTagsReportsComponent } from '@pages/read-psmm-tags-reports/read-psmm-tags-reports.component';
import { TotemServicesComponent } from '@pages/totem-services/totem-services.component';
import { UserManagementComponent } from '@pages/user-management/user-management.component';

import {
  BoxtypesComponent,
  PartsComponent,
  RacksComponent,
  InstructionListComponent,
  PowerBIComponent,
  QualityComponent
} from '@pages/.';
import { SettingsProfilesComponent } from '@pages/settings-profiles/settings-profiles.component';
import { ForkliftsAndEquipmentsComponent } from '@pages/forklifts-and-equipments/forklifts-and-equipments.component';
import { PablistComponent } from '@pages/pablist/pablist.component';


/**
 * Routes of the App Module
 */
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    pathMatch: 'prefix',
    canActivate: [CanAccessHome],
    component: RootPageComponent,
  },
  {
    path: 'user',
    pathMatch: 'prefix',

    loadChildren: () => import('@services/login/user.module').then(m => m.UserModule),
    canActivate: [CanActivateLogin],
  },
  {
    path: 'dashboard',
    pathMatch: 'full',
    component: DashboardComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'logs',
    pathMatch: 'full',
    component: LogsComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'rfidTags',
    pathMatch: 'full',
    component: RfidTagsComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'rfidTags/add',
    pathMatch: 'full',
    component: AddRfidTagsComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'rfidTags/print',
    pathMatch: 'full',
    component: PrintTagsComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'rfidTags/addContainers',
    pathMatch: 'full',
    component: AddContainersInBatchComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'sectors',
    pathMatch: 'full',
    component: SectorsComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'boxes',
    pathMatch: 'full',
    component: BoxesComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'boxTypes',
    pathMatch: 'full',
    component: BoxtypesComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'print',
    outlet: 'print',
    component: RfidtagsPrintLayoutComponent,
  },
  {
    path: 'parts',
    pathMatch: 'full',
    component: PartsComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'instructions',
    pathMatch: 'full',
    component: InstructionListComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'orders',
    pathMatch: 'full',
    component: InstructionExpansionPanelsComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'warehouse',
    pathMatch: 'full',
    component: RacksComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'powerBI',
    pathMatch: 'full',
    component: PowerBIComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'alerts',
    pathMatch: 'full',
    component: AlertComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'psmmTagsReport',
    pathMatch: 'full',
    component: ReadPSMMTagsReportsComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'totem',
    pathMatch: 'full',
    component: TotemServicesComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'users',
    pathMatch: 'full',
    component: UserManagementComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'quality',
    pathMatch: 'full',
    component: QualityComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'quality',
    pathMatch: 'full',
    component: QualityComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'settingsProfiles',
    pathMatch: 'full',
    component: SettingsProfilesComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'forkliftsAndEquipments',
    pathMatch: 'full',
    component: ForkliftsAndEquipmentsComponent,
    canActivate: [CanAccessRestrictedPages],
  },
  {
    path: 'pablist',
    pathMatch: 'full',
    component: PablistComponent,
    canActivate: [CanAccessRestrictedPages],
  },
];

/**
 * Main module of the application.
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
