import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AssemblyDetail, QualityInstructionsBatchGenerateRequestDto } from '@models/.';
import { InstructionApiService } from '@services/api';
import { AppToastService } from '@services/index';
import { DataLogsService } from '@services/logs/data-logs.service';

@Component({
  selector: 'boxcar-console-quality-dialog',
  templateUrl: './quality-dialog.component.html',
  styleUrls: ['./quality-dialog.component.scss']
})
export class QualityDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AssemblyDetail[],
    public dialogRef: MatDialogRef<QualityDialogComponent>,
    public dataLogsService: DataLogsService,
    private instructionApiService: InstructionApiService,
    private toastService: AppToastService,
  ) { }

  ngOnInit(): void { return; }

  closeDialog(): void {
    this.dialogRef.close();
  }

  async sendToQuality() {
    try {
      const bodyRequest: QualityInstructionsBatchGenerateRequestDto = {
        labelcodes: this.data.map(ad => ad.labelCode)
      }

      await this.instructionApiService.generateQualityInstruction(bodyRequest);

      this.toastService.success('Instruções para qualidade geradas com successo!');
    }
    catch (error) {
      this.toastService.error('Algo impediu que as instruções fossem geradas, verifique as etiquetas selecionadas.');
      console.error(error);
    }
    finally {
      this.closeDialog();
    }
  }
}
