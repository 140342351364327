<h2 *ngIf="showTitle">
  {{ 'logs.logistics.wrongDestinyLogs' | translate }}
</h2>

<div class="loading-container">
  <loading-on-page [isLoading]="isLoading" *ngIf="showLoading"></loading-on-page>
  <div
    class="table-wrapper"
    infinite-scroll
    [scrollWindow]="false"
    [infiniteScrollDistance]="0.25"
    (scrolled)="requestMoreData()">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      multiTemplateDataRows
      aria-label="Wrong destination alerts table">
      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <img *ngIf="row.icon" src="../../../../assets/icons/warning_icon.svg" alt="warning icon" />
        </td>
      </ng-container>

      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef>{{ 'logs.timestamp' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.timestamp }}</td>
      </ng-container>

      <ng-container matColumnDef="instructionID">
        <th mat-header-cell *matHeaderCellDef>{{ 'logs.instructionID' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.instructionId }}</td>
      </ng-container>

      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef>{{ 'logs.operator' | translate }}</th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.operator"></td>
      </ng-container>

      <ng-container matColumnDef="instructionDestiny">
        <th mat-header-cell *matHeaderCellDef>{{ 'logs.instructionDestiny' | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <strong>
            {{ row.instructionDestiny }}
          </strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="concludedDestiny">
        <th mat-header-cell *matHeaderCellDef>{{ 'logs.concludedDestiny' | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <strong>
            {{ row.concludedDestiny }}
          </strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="readDestiny">
        <th mat-header-cell *matHeaderCellDef>{{ 'logs.railRead' | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <strong>
            {{ row.readDestiny }}
          </strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="part">
        <th mat-header-cell *matHeaderCellDef>{{ 'logs.part' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.part }}</td>
      </ng-container>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [colSpan]="columnsToDisplay.length">
          {{ 'logs.noData' | translate }}
        </td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        [style.--color]="getColorFromSeverity(row.severity)"></tr>
    </table>
  </div>
</div>
