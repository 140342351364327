<div class="box-card">
  <mat-card class="box-mat-card mat-elevation-z0">
    <div class="box-card-header">
      <!-- Header for standard boxes -->
      <div *ngIf="container!.containerModel.toLowerCase() !== 'virtual'" class="text-header">
        <div>
          <p>
            RFID <strong>{{ container!.activeBarcode ?? container!.barcodes![0] }}</strong>
          </p>
          <mat-card-subtitle>{{ 'boxes.type' | translate }} {{ container!.containerModel }}</mat-card-subtitle>
        </div>
        <p>
          {{ 'common.tag' | translate }}:
          <strong *ngIf="container!.boxAssembly!.labelDtos[0].labelcode; else genericLabel">
            {{ container!.boxAssembly!.labelDtos[0].labelcode }}
          </strong>

          <ng-template #genericLabel>
            {{ 'boxes.genericLabel' | translate }}
          </ng-template>
        </p>
      </div>
      <!-- Header for Virtual Boxes -->
      <div *ngIf="container!.containerModel.toLowerCase() === 'virtual'" class="text-header">
        <div>
          <p>
            {{ 'boxes.type' | translate }} <strong>{{ container!.containerModel }}</strong>
          </p>
          <mat-card-subtitle>{{ 'boxes.directSent' | translate }}</mat-card-subtitle>
        </div>
        <p>
          {{ 'common.tag' | translate }}:
          <strong>
            {{ container!.boxAssembly!.labelDtos[0].labelcode }}
          </strong>
        </p>
      </div>
    </div>

    <mat-card-content *ngIf="container!.boxAssembly">
      <p>
        <strong>{{ 'boxes.contentInfo' | translate }}</strong>
      </p>
      <span
        >{{ 'boxes.partNumber' | translate
        }}<span style="color: #3880ff">
          {{ container!.boxAssembly!.boxAssemblyInfoResponseDto.partNumber }} -
          {{ container!.boxAssembly!.boxAssemblyInfoResponseDto.partDescription }}</span
        ></span
      ><br />
      <span>
        {{ 'boxes.source' | translate }} {{ container!.boxAssembly!.boxAssemblyInfoResponseDto.manufactureSource }} -
        {{ container!.boxAssembly!.boxAssemblyInfoResponseDto.manufactureSourceDescription }}
      </span><br />
      <span *ngIf="container!.boxAssembly!.labelDtos !== undefined">
        {{ 'boxes.totalQuantity' | translate }} {{ total(container!.boxAssembly!.labelDtos) }}
      </span>
    </mat-card-content>

    <div class="box-card-footer">
      <span style="display: flex; gap: 5px">
        {{ 'boxes.state' | translate }}
        <strong *ngIf="container!.entityState !== undefined && boxStateMetadata">
          {{ boxStateMetadata[container!.entityState]?.namePT }}
        </strong>

        <strong *ngIf="container!.location">
          ({{ container!.location }})
        </strong>
      </span>
      <p>{{ 'boxes.inCharge' | translate }} {{ container!.teamLeader }}</p>
    </div>
  </mat-card>
</div>
