/*
 * File: \src\app\models\metadata.ts
 * Project: boxcar-console
 * Created Date: 2022-04-25 15:03:06
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Types created for refactoring.
 */

export type EventDetailDto = {
  nameEN: string;
  namePT: string;
  nameES: string;
  gravity: number;
};

export type EventClassDto = {
  nameEN: string;
  namePT: string;
  nameES: string;
};

export type LogDictsDto = {
  eventDetailDict: { [key: string]: EventDetailDto };
  eventClassDict: { [key: string]: EventClassDto };
};

export type StateNameDto = {
  nameEN: string;
  namePT: string;
  nameES: string;
};

export type StateDictDto = {
  [key: string]: StateNameDto;
};
