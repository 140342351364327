import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import {
  BoxCarHttpErrorResponse,
  DataCatalogApiService,
  InstructionApiService,
  boxCarCoreErrorDetails,
} from '@services/api';
import { AppToastService, NoticeDialogService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import {
  ActiveInstructionFilters,
  Instruction,
  InstructionDto,
  InstructionSearchQuery,
  InstructionState,
  InstructionType,
  OrderTypes,
  RegionDto,
} from 'src/app/models';
import { CreateDiaologComponent } from './create-order-dialog/create-order-dialog.component';

@Component({
  selector: 'boxcar-console-expansion-panels',
  templateUrl: './expansion-panels.component.html',
  styleUrls: ['./expansion-panels.component.scss'],
})
export class InstructionExpansionPanelsComponent implements OnInit {
  destination: string = '';
  filterSearch: string = '';
  typeOrders = OrderTypes;
  isLoading: boolean = false;
  selectedTab: number = 0;
  activeControllers: ActiveInstructionFilters = {
    id: true,
    type: false,
    state: false,
    rfid: true,
    epc: true,
    time: true,
    origin: true,
    destination: true,
    part: true,
  };

  // Not started orders.
  waitingInstructions: Instruction[] = [];
  private waitingQueryPattern = `state=${InstructionState.notStarted},${InstructionState.paused}  AND
  type=${InstructionType.picking}`;
  waitingPag: PageEvent = {
    length: 0,
    pageSize: 5,
    pageIndex: 0,
    previousPageIndex: 0,
  };
  requestWaiting: InstructionSearchQuery = {
    query: this.waitingQueryPattern,
    qtyPerPage: this.waitingPag.pageSize,
    pageNum: this.waitingPag.pageIndex + 1,
  };

  // In progress orders.
  inProgressInstructions: Instruction[] = [];
  private inProgressQueryPattern = `state=${InstructionState.started} AND
  type=${InstructionType.picking}`;
  inProgressPag: PageEvent = {
    length: 0,
    pageSize: 5,
    pageIndex: 0,
    previousPageIndex: 0,
  };
  requestInProgress: InstructionSearchQuery = {
    query: this.inProgressQueryPattern,
    qtyPerPage: this.inProgressPag.pageSize,
    pageNum: this.inProgressPag.pageIndex + 1,
  };

  // Done orders variables.
  doneInstructions: Instruction[] = [];
  private doneQueryPattern = `state=${InstructionState.concluded} AND
    type=${InstructionType.picking}`;
  doneInstructionsPag: PageEvent = {
    length: 0,
    pageIndex: 0,
    pageSize: 5,
    previousPageIndex: 0,
  };
  requestDone: InstructionSearchQuery = {
    query: this.doneQueryPattern,
    qtyPerPage: this.doneInstructionsPag.pageSize,
    pageNum: this.doneInstructionsPag.pageIndex + 1,
  };

  destinationOptions: RegionDto[] = [];

  @ViewChild(MatAccordion) accordion: MatAccordion = new MatAccordion();

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private catalogApi: DataCatalogApiService,
    private instructionApiService: InstructionApiService,
    private noticeDialog: NoticeDialogService,
    private toast: AppToastService,
    private loadingIndicator: LoadingIndicatorService
  ) {}

  ngOnInit(): void {
    this.catalogApi.getDestinations().then(response => (this.destinationOptions = response));

    this.makeAllRequests();
  }

  makeAllRequests() {
    this.makeRequest(OrderTypes.WAITING);
    this.makeRequest(OrderTypes.IN_PROGRESS);
    this.makeRequest(OrderTypes.DONE);
  }

  allFirstPage() {
    this.waitingPag.pageIndex = 0;
    this.requestWaiting = this.updateRequest(OrderTypes.WAITING, 'pagination', this.waitingPag);
    this.inProgressPag.pageIndex = 0;
    this.requestInProgress = this.updateRequest(OrderTypes.IN_PROGRESS, 'pagination', this.inProgressPag);
    this.doneInstructionsPag.pageIndex = 0;
    this.requestDone = this.updateRequest(OrderTypes.DONE, 'pagination', this.doneInstructionsPag);
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTab = event.index;
    switch (event.index) {
      case 0:
        this.makeRequest(OrderTypes.WAITING);
        break;
      case 1:
        this.makeRequest(OrderTypes.IN_PROGRESS);
        break;
      case 2:
        this.makeRequest(OrderTypes.DONE);
        break;
    }
  }

  openNewInstructionDialog() {
    this.dialog
      .open(CreateDiaologComponent)
      .afterClosed()
      .subscribe({
        next: () => {
          this.makeRequest(OrderTypes.WAITING);
        },
      });
  }

  handleApplyFilters(queryStr: string) {
    this.requestWaiting = this.updateRequest(OrderTypes.WAITING, 'data', queryStr);
    this.requestInProgress = this.updateRequest(OrderTypes.IN_PROGRESS, 'data', queryStr);
    this.requestDone = this.updateRequest(OrderTypes.DONE, 'data', queryStr);

    this.makeRequest(this.selectedTab);
  }

  private selectRequestStructure(type: OrderTypes) {
    let request: InstructionSearchQuery;

    switch (type) {
      case OrderTypes.IN_PROGRESS:
        request = this.requestInProgress;
        break;
      case OrderTypes.DONE:
        request = this.requestDone;
        break;
      default:
        request = this.requestWaiting;
        break;
    }

    return request;
  }

  private selectQueryPattern(type: OrderTypes) {
    switch (type) {
      case OrderTypes.WAITING:
        return this.waitingQueryPattern;
      case OrderTypes.IN_PROGRESS:
        return this.inProgressQueryPattern;
      case OrderTypes.DONE:
        return this.doneQueryPattern;
    }
  }

  private selectPaginatorStructure(type: OrderTypes) {
    let paginator: PageEvent;

    switch (type) {
      case OrderTypes.IN_PROGRESS:
        paginator = this.inProgressPag;
        break;
      case OrderTypes.DONE:
        paginator = this.doneInstructionsPag;
        break;
      default:
        paginator = this.waitingPag;
        break;
    }

    return paginator;
  }

  async makeRequest(type: OrderTypes) {
    this.isLoading = true;
    const request = this.selectRequestStructure(type);
    let paginator = this.selectPaginatorStructure(type);

    await this.instructionApiService
      .searchInstructions(request)
      .toPromise()
      .then((response: InstructionDto) => {
        switch (type) {
          case OrderTypes.IN_PROGRESS:
            this.inProgressInstructions = response.instructions;
            break;
          case OrderTypes.DONE:
            this.doneInstructions = response.instructions;
            break;
          default:
            this.waitingInstructions = response.instructions;
            break;
        }
        paginator.length = response.instructionsQuantity;
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });

    this.isLoading = false;
  }

  // Update a request field.
  updateRequest(type: OrderTypes, field: string, value: any): InstructionSearchQuery {
    let request = this.selectRequestStructure(type);

    switch (field) {
      case 'data':
        request = {
          ...request,
          query:
            value === null || value === ''
              ? this.selectQueryPattern(type)
              : value + ' AND ' + this.selectQueryPattern(type),
        };
        break;
      case 'pagination':
        request = {
          ...request,
          pageNum: value.pageIndex + 1,
          qtyPerPage: value.pageSize,
        };
        break;
    }
    return request;
  }

  async paginatorChange(pageEvent: PageEvent, type: OrderTypes) {
    switch (type) {
      case OrderTypes.IN_PROGRESS:
        this.inProgressPag = pageEvent;
        this.requestInProgress = this.updateRequest(type, 'pagination', this.inProgressPag);
        break;
      case OrderTypes.DONE:
        this.doneInstructionsPag = pageEvent;
        this.requestDone = this.updateRequest(type, 'pagination', this.doneInstructionsPag);
        break;
      default:
        this.waitingPag = pageEvent;
        this.requestWaiting = this.updateRequest(OrderTypes.WAITING, 'pagination', this.waitingPag);
        break;
    }

    // Request for done instructions.
    this.makeRequest(type);
  }
}
