/*
 * File: \src\app\services\local-data\local.data.service.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Service refactored.
 * 2022-09-20 VR  Save user role on local storage.
 */

import { Injectable } from '@angular/core';

/** Saves and retrieves the token from the local storage. */
@Injectable()
export class LocalDataService {
  tokenKey = 'auth_token';
  rolesKey = 'roles';

  public getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  public getRoles(): string[] | null {
    const roles = localStorage.getItem(this.rolesKey);

    if (roles) {
      return JSON.parse(roles);
    }

    return null;
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  setRoles(roles: string[]): void {
    localStorage.setItem(this.rolesKey, JSON.stringify(roles));
  }
}
