<div class="table-options">
  <div class="filters">
    <mat-form-field appearance="outline" class="full-input">
      <mat-label>{{ 'userManagement.inputs.search' | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="searchValue"
        (ngModelChange)="handleFilterChange()"
        [disabled]="isLoading" />
      <img
        matSuffix
        *ngIf="searchValue === ''; else closeIcon"
        src="../../../assets/icons/searchIcon.svg"
        alt="Search icon"
        width="24"
        class="imgIcon" />
      <ng-template #closeIcon>
        <img
          matSuffix
          src="../../../assets/icons/closeIcon.svg"
          alt="Clear icon"
          width="24"
          class="imgIcon clickable"
          (click)="clearFilter()" />
      </ng-template>
      <mat-hint>{{ 'userManagement.inputs.helpers.search' | translate }}</mat-hint>
    </mat-form-field>

    <button mat-flat-button color="primary" class="reload-button" (click)="requestUsers()">
      <img src="../../../../assets/icons/refreshIcon.svg" color="white" width="24px" height="24px" alt="refresh" />
    </button>
  </div>

  <button mat-flat-button color="primary" class="full-width" (click)="openCreateDialog()">
    {{ 'common.buttons.userManagement.create' | translate }}
  </button>
</div>

<hr />

<div class="table-container mat-elevation-z2 loading-container loading-container">
  <loading-on-page [isLoading]="isLoading"></loading-on-page>
  <table mat-table [dataSource]="dataSource">
    <caption style="display: none">
      Users table
    </caption>

    <ng-container matColumnDef="login">
      <th mat-header-cell *matHeaderCellDef>{{ 'userManagement.table.headers.login' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.login }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'userManagement.table.headers.name' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>{{ 'userManagement.table.headers.email' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef>{{ 'userManagement.table.headers.roles' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ getRolesString(element) }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'common.actions' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <button mat-flat-button color="primary" (click)="openEditDialog(element)">
          {{ 'common.buttons.edit' | translate }}
        </button>

        <button mat-flat-button color="warn" (click)="openSwitchUserActivatedDialog(element)">
          {{ 'common.buttons.' + (element.active ? 'deactivate' : 'activate') | translate }}
        </button>
      </td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell empty" [colSpan]="displayedColumns.length">
        {{ 'userManagement.table.noneUsers' | translate }}
      </td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <paginator-page-select [length]="totalUsers" (page)="onPaginatorChange($event)" aria-label="Select page">
  </paginator-page-select>
</div>
