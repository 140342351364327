import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { userSelectors } from './state';

@Injectable()
export class UserService {
  constructor(private store: Store) { }

  isLogged() {
    const isLogged = false;
    return this.store.pipe(
      select(userSelectors.userStatusSelector)
    );
  }
}
