/*
 * File: \src\app\pages\boxtypes\boxtypes.component.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-25	JF	Component refactoring.
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BoxTypeDto } from 'src/app/models/rfid';
import { RfidApiService } from '@services/api';
import { PaginatorPageSelectComponent } from '@components/paginator-page-select/paginator-page-select.component';

@Component({
  selector: 'boxcar-console-boxtypes',
  templateUrl: './boxtypes.component.html',
  styleUrls: ['./boxtypes.component.scss'],
})
export class BoxtypesComponent implements OnInit {
  boxtypes: any[] = [];
  displayedColumns: string[] = ['name', 'description'];
  dataSource: MatTableDataSource<BoxTypeDto>;
  isLoading: boolean = false;

  @ViewChild(MatSort) sort: MatSort | null = null;
  @ViewChild(PaginatorPageSelectComponent) paginator: PaginatorPageSelectComponent | null = null;

  constructor(private apiService: RfidApiService) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit() {
    this.isLoading = true;

    await this.apiService.getBoxTypes().then(boxtypes => {
      boxtypes = boxtypes.filter(type => !type.name.toLowerCase().includes('__null'));
      this.dataSource = new MatTableDataSource(boxtypes);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator!.paginator;
    });
    this.isLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
