<h1 mat-dialog-title>{{ 'totemServices.moveBox.confirmRestore.title' | translate }}</h1>

<p>{{ 'totemServices.moveBox.confirmRestore.description' | translate }}</p>

<div mat-dialog-actions>
  <button mat-flat-button color="warn" [mat-dialog-close]="{ response: 'continue' }">
    {{ 'totemServices.moveBox.confirmRestore.buttons.restore' | translate }}
  </button>
  <button mat-button [mat-dialog-close]="{ response: 'canceled' }">{{ 'common.buttons.cancel' | translate }}</button>
</div>
