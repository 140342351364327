/*
 * File: \src\app\app.component.ts
 * Project: boxcar-console
 * Created Date: 2022-02-07 13:42:57
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-04-27	JF	Added I18N support.
 * 2022-09-21 VR  Added saving roles and dynamic nav bar.
 */

import { MetadataService } from './services/metadata/metadata.service';
import { Store } from '@ngrx/store';
import { LoginResponseDto } from 'src/app/models/login';
import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { loginSucceeded } from './services/login/state/user.actions';
import { NavBarMenu } from 'src/app/models/nav.menu';
import { LocalDataService } from '@services/local-data/local.data.service';
import { loadNavBarMenu } from '@services/nav-menu/nav-menu.actions';
import { selectFullMenu } from '@services/nav-menu/nav-menu.selectors';
import { NavMenuService } from '@services/nav-menu/nav-menu.service';

@Component({
  selector: 'boxcar-console-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'boxcar-console';
  loading = true;

  navMenu: NavBarMenu = {};

  constructor(
    private jwtHelper: JwtHelperService,
    private localDataService: LocalDataService,
    private store: Store,
    private navMenuService: NavMenuService,
    private metadataService: MetadataService
  ) {
    this.store.select(selectFullMenu).subscribe({
      next: menu => {
        this.navMenu = menu;
      },
    });

    const token = this.localDataService.getToken();

    if (token && !this.jwtHelper.isTokenExpired(token)) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      const userData: LoginResponseDto = {
        login: decodedToken.unique_name,
        email: decodedToken.email,
        roles: this.localDataService.getRoles()!,
        token,
      };
      this.store.dispatch(
        loginSucceeded({
          userData,
        })
      );
    } else {
      localStorage.clear();
    }

    const navMenuFromRole = this.navMenuService.getNavMenuFromUserType(this.localDataService.getRoles()!);
    this.store.dispatch(loadNavBarMenu({ defaultMenu: navMenuFromRole }));
  }

  async ngOnInit() {
    await this.metadataService.load();
    this.loading = false;
  }
}
