<div class="main-filters">
  <div class="date-time-filters">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'common.start' | translate }}</mat-label>
      <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="startDate" (dateChange)="onStartDateChange()" />
      <mat-hint>{{ 'reports.psmmTag.startDate' | translate }}</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input
        matInput
        [format]="24"
        [ngxMatTimepicker]="startPicker"
        placeholder="12:00"
        readonly
        [(ngModel)]="startTime"
        [disabled]="!startDate" />
      <mat-hint>{{ 'reports.psmmTag.startTime' | translate }}</mat-hint>
      <ngx-mat-timepicker-toggle matSuffix></ngx-mat-timepicker-toggle>
      <ngx-mat-timepicker color="primary" #startPicker></ngx-mat-timepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'common.end' | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="endDatePicker"
        [(ngModel)]="endDate"
        [min]="startDate"
        [max]="maxEndDate"
        [disabled]="!startDate"
        (dateChange)="resetEndTime()" />
      <mat-hint>{{ 'reports.psmmTag.endDate' | translate }}</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input
        matInput
        [format]="24"
        [ngxMatTimepicker]="endPicker"
        [(ngModel)]="endTime"
        [min]="getMinEndTime()"
        [max]="getMaxEndTime()"
        placeholder="12:00"
        readonly
        [disabled]="!endDate"
        (ngModelChange)="requestData()" />
      <mat-hint>{{ 'reports.psmmTag.endTime' | translate }}</mat-hint>
      <ngx-mat-timepicker-toggle matSuffix></ngx-mat-timepicker-toggle>
      <ngx-mat-timepicker color="primary" #endPicker></ngx-mat-timepicker>
    </mat-form-field>
  </div>

  <div>
    <button mat-raised-button color="primary" (click)="isFiltersVisible = !isFiltersVisible">
      <img src="../../../assets/icons/filter_icon.svg" alt="filter icon" *ngIf="!isFiltersVisible" />
      <img src="../../../assets/icons/turn_off_filter_icon.svg" alt="turn off filter icon" *ngIf="isFiltersVisible" />
    </button>
  </div>
</div>

<div class="filters" *ngIf="isFiltersVisible">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'reports.psmmTag.operator' | translate }}</mat-label>
    <input matInput type="text" [(ngModel)]="operator" (change)="filterData()" [disabled]="!isRequested" />
    <img class="mat-icon" matSuffix alt="search Icon" src="../../../assets/icons/searchIcon.svg" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{ 'reports.psmmTag.injector' | translate }}</mat-label>
    <mat-select [formControl]="selectedInjectors" multiple>
      <mat-select-trigger>
        {{ selectedInjectors.value ? selectedInjectors.value[0] : '' }}
        <span *ngIf="selectedInjectors.value?.length > 1" class="example-additional-selection">
          ( +{{ selectedInjectors.value.length - 1 }}
          {{ (selectedInjectors.value?.length === 2 ? 'reports.psmmTag.other' : 'reports.psmmTag.others') | translate }}
          )
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let injector of allInjectors" [value]="injector.name">{{ injector.description }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{ 'reports.psmmTag.shift' | translate }}</mat-label>
    <mat-select [formControl]="selectedShifts" multiple>
      <mat-select-trigger>
        {{ selectedShifts.value }}
      </mat-select-trigger>
      <mat-option value="1">{{ 'reports.psmmTag.shift' | translate }} 1</mat-option>
      <mat-option value="2">{{ 'reports.psmmTag.shift' | translate }} 2</mat-option>
      <mat-option value="3">{{ 'reports.psmmTag.shift' | translate }} 3</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<br />

<mat-card>
  <mat-card-header>
    <mat-card-title> {{ 'reports.psmmTag.title' | translate }}:</mat-card-title>
    <button mat-raised-button color="primary" [disabled]="totalTags <= 0" (click)="exportData()">
      {{ 'common.exportCSV' | translate }}
    </button>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="totalTags > 0" class="summary">
      <h4>{{ 'reports.psmmTag.summary' | translate }}:</h4>
      <p>
        <strong>{{ getSummaryQtd() }}</strong>
      </p>
      <p>{{ getSummaryDescrip() }}</p>
    </div>

    <br />

    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      multiTemplateDataRows
      aria-label="Wrong destination alerts table">
      <ng-container matColumnDef="dateTime">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.psmmTag.tableColumns.dateTime' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.creationTime }}</td>
      </ng-container>

      <ng-container matColumnDef="shift">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.psmmTag.tableColumns.shift' | translate }}</th>
        <td mat-cell *matCellDef="let row">T{{ row.shift }}</td>
      </ng-container>

      <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.psmmTag.tableColumns.source' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.source }}</td>
      </ng-container>

      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.psmmTag.tableColumns.operator' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.operator }}</td>
      </ng-container>

      <ng-container matColumnDef="tag">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.psmmTag.tableColumns.tag' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.labelCode }}</td>
      </ng-container>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="endTime" class="mat-cell" [colSpan]="columnsToDisplay.length">{{ 'logs.noData' | translate }}.</td>
        <td *ngIf="!endTime" class="mat-cell" [colSpan]="columnsToDisplay.length">
          {{ 'reports.psmmTag.selectPeriod' | translate }}.
        </td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </table>
  </mat-card-content>
</mat-card>
