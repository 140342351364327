/*
 * File: user-management.component.ts
 * Project: boxcar-console
 * Created Date: 2023-08-04 14:49:08
 * Author: Vitor de Almeida Recoaro (vitor.recoaro@vonbraunlabs.com.br)
 * -----
 * Copyright 2023 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date        By  Comments
 * ---------- --- ---------------------------------------------------------
 * 2023-08-07	VAR	 - Getting page users from API.
 * 2023-08-04	VAR	 - Using API to get users.
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserResponse } from '@models/users';
import { SwitchUserActivatedDialogComponent } from './switch-user-active-dialog/switch-user-active-dialog.component';
import { UpdateUserDialogComponent } from './update-user-dialog/update-user-dialog.component';
import { UserApiService } from '@services/api/users.service';
import { PaginatorPageSelectComponent } from '@components/paginator-page-select/paginator-page-select.component';
import { CreateUserDialogComponent } from './create-user-dialog/create-user-dialog.component';

@Component({
  selector: 'boxcar-console-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  @ViewChild(PaginatorPageSelectComponent) paginator: PaginatorPageSelectComponent = new PaginatorPageSelectComponent();
  dataSource: MatTableDataSource<UserResponse> = new MatTableDataSource();
  searchValue: string = '';
  totalUsers: number = 0;
  userPromise?: Promise<void>;
  displayedColumns: string[] = ['login', 'name', 'email', 'roles', 'actions'];
  isLoading: boolean = false;

  constructor(private matDialog: MatDialog, private userApiService: UserApiService) {}

  async ngOnInit(): Promise<void> {
    await this.requestUsers();
  }

  async onPaginatorChange(pageEvent: PageEvent) {
    await this.requestUsers();
  }

  getRolesString(user: UserResponse) {
    // Transform all role on Upper case, and return a string of all roles separated with commas.
    return user.roles.map(role => role.toUpperCase()).join(', ');
  }

  openEditDialog(user: UserResponse) {
    const dialog = this.matDialog.open(UpdateUserDialogComponent, {
      autoFocus: false,
      width: '50%',
      maxWidth: '640px',
      data: { user: user },
    });

    dialog.afterClosed().subscribe({
      next: (response: { edited: boolean }) => {
        if (response.edited) {
          this.requestUsers();
        }
      },
    });
  }

  openSwitchUserActivatedDialog(user: UserResponse) {
    const dialog = this.matDialog.open(SwitchUserActivatedDialogComponent, {
      autoFocus: false,
      width: '25%',
      maxWidth: '640px',
      data: { user: user },
    });

    dialog.afterClosed().subscribe({
      next: (response: { changed: boolean }) => {
        if (response.changed) {
          this.requestUsers();
        }
      },
    });
  }

  openCreateDialog() {
    const dialog = this.matDialog.open(CreateUserDialogComponent, {
      autoFocus: false,
      width: '50%',
      maxWidth: '640px',
    });

    dialog.afterClosed().subscribe({
      next: (response: { created: boolean }) => {
        if (response.created) {
          this.requestUsers();
        }
      },
    });
  }

  async requestUsers() {
    this.isLoading = true;

    const response = await this.userApiService
      .getUsers(this.paginator.pageIndex + 1, this.paginator.pageSize, this.searchValue)
      .toPromise();

    this.dataSource.data = response.users;
    this.totalUsers = response.userQty;
    this.isLoading = false;
  }

  handleFilterChange() {
    const actualValue = this.searchValue;

    this.paginator.pageIndex = 0;

    // Manually set an debouncer.
    if (this.userPromise == undefined) {
      setTimeout(() => {
        if (actualValue === this.searchValue) {
          this.userPromise = this.requestUsers();
          this.userPromise.finally(() => {
            this.userPromise = undefined;
          });
        }
      }, 500);
    }
  }

  clearFilter() {
    this.searchValue = '';
    this.paginator.pageIndex = 0;

    this.requestUsers();
  }
}
