import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MetadataApiService } from '@services/api';
import { ContainerDto, ContainerLifeCycleState, InstructionState, StateDictDto } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class ContainerService {
  boxMetadata: any;
  metadataPromise: Promise<StateDictDto>;

  constructor(private translate: TranslateService, private metadataService: MetadataApiService) {
    this.metadataPromise = this.metadataService.getBoxMetadata();
  }

  getContainerStateTranslation(container: ContainerDto) {
    switch (container.entityState) {
      case ContainerLifeCycleState.available:
        return this.translate.instant('common.containerLifeCycleStates.available');
      case ContainerLifeCycleState.canceled:
        return this.translate.instant('common.containerLifeCycleStates.canceled');
      case ContainerLifeCycleState.delivered:
        return this.translate.instant('common.containerLifeCycleStates.delivered');
      case ContainerLifeCycleState.liberated:
        return this.translate.instant('common.containerLifeCycleStates.liberated');
      case ContainerLifeCycleState.onGoing:
        return this.translate.instant('common.containerLifeCycleStates.onGoing');
      case ContainerLifeCycleState.placed:
        return this.translate.instant('common.containerLifeCycleStates.placed');
      case ContainerLifeCycleState.placedFloor:
        return this.translate.instant('common.containerLifeCycleStates.placedFloor');
      case ContainerLifeCycleState.placedQuality:
        return this.translate.instant('common.containerLifeCycleStates.placedQuality');
      case ContainerLifeCycleState.replaced:
        return this.translate.instant('common.containerLifeCycleStates.replaced');
      case ContainerLifeCycleState.transit:
        return this.translate.instant('common.containerLifeCycleStates.transit');
      default:
        return this.translate.instant('common.containerLifeCycleStates.any');
    }
  }

  containerIsActive(container: ContainerDto) {
    return (
      container.entityState !== ContainerLifeCycleState.canceled &&
      container.entityState !== ContainerLifeCycleState.delivered &&
      container.entityState !== ContainerLifeCycleState.replaced &&
      container.entityState !== ContainerLifeCycleState.restored
    );
  }

  getActiveInstructions(container: ContainerDto) {
    return container.instructions!.filter(
      i => i.instructionState !== InstructionState.canceled && i.instructionState !== InstructionState.concluded
    );
  }

  async getBoxMetadata() {
    if (!this.boxMetadata) {
      this.boxMetadata = await this.metadataPromise;
    }
    return this.boxMetadata;
  }

  createContainerAvailable(container: ContainerDto) {
    const newContainer = { ...container };
    newContainer.activeBarcode = undefined;
    newContainer.entityState = ContainerLifeCycleState.available;
    newContainer.location = undefined;
    newContainer.activeBarcode = undefined;
    newContainer.teamLeader = 'System';
    newContainer.instructions = undefined;
    newContainer.boxAssembly = undefined;
    newContainer.lastStateUpdate = undefined;
    newContainer.containerLifeCycleId = undefined;

    return newContainer;
  }
}
