/*
 * File: \src\app\pages\powerBI\powerBI.component.ts
 * Project: boxcar-console
 * Created Date: 2022-10-19 15:16:25
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-10-19	PS	PowerBI Dashboard
 */

import { Component } from '@angular/core';

@Component({
  selector: 'boxcar-console-powerBI',
  templateUrl: './powerBI.component.html',
  styleUrls: ['./powerBI.component.scss'],
})
export class PowerBIComponent {}
